import React from 'react';
import PuzzleLab from "./components/lab/puzzleLab";
import UserFacingGame from "./components/userFacingGame";
import {initializeGame} from "./initialization";

// import {Elements} from '@stripe/react-stripe-js';
// import {loadStripe} from '@stripe/stripe-js';

import './css/app.css';
import Constants from "./constants";
import {isDev} from "./buildModeChecker";

// TODO: Progressive Web App stuff.
//  This is probably the right way for me to package this fella as an app for app stores.
//  https://www.simicart.com/blog/pwa-app-stores/touchmove
//  https://blog.logrocket.com/from-create-react-app-to-pwa/
//  https://developers.google.com/web/tools/workbox/guides/get-started
//  https://webpack.js.org/guides/progressive-web-application/
//  https://web.dev/progressive-web-apps/

// https://deanhume.com/displaying-a-new-version-available-progressive-web-app/

// Favicon tweaks at this good website: https://realfavicongenerator.net/

// TODO: Maybe this thing for apple compatibility https://developers.google.com/web/updates/2018/07/pwacompat

// Splash screen https://medium.com/@applification/progressive-web-app-splash-screens-80340b45d210


function App() {
    initializeGame();

    // Make sure to call `loadStripe` outside of a component’s render to avoid
    // recreating the `Stripe` object on every render.
    // const stripePromise = loadStripe('pk_test_51IlPwIJpC21Z1KK1S7JazLgBo8UeBwAJSHwKGy5Hbt5scC2vdWMdC9BSeviQvI57iXL6pMNSfCypHiyRpKw7qKNM00QXC9VRDl');

    return (

        // <Elements stripe={stripePromise}>
            <div className="App">
                {Constants.designMode && isDev() ?
                    <PuzzleLab/> :
                    <UserFacingGame/>}
            </div>
        // </Elements>
    );
}

export default App;
