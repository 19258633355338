import Puzzle, {PuzzleOrientation} from "../../puzzle/puzzle";
import PuzzleSelector from "../puzzleSelector";
import React from "react";
import SearchControls from "./searchControls";
import SearchResultsPanel from "./searchResultsPanel";
import SolutionDescriber from "./solutionDescriber";
import Solver, {puzzlesFromSolutionSet, SearchConfig} from "../../puzzle/solver";
import Utility from "../../utility";
import {VisitableThingInfo} from "../../puzzle/movement";
import {WorldBuilder} from "./worldBuilder";
import {WorldSelector} from "./worldSelector";
import {World} from "../../puzzles/world";
import {doSearch, SearchResults} from "../../puzzle/search";
import PuzzleLayout from "../puzzleLayout";
import ActivePuzzle from "../../puzzle/activePuzzle";
import Constants from "../../constants";
import LayoutConstants from "../../layoutConstants";
import SetProgress from "../../progress/setProgress";
import MoveControlsPanel from "./moveControlsPanel";

import '../../css/lab/puzzleLab.css';
import '../../css/lab/react-grid-layout-styles.css';
import '../../css/lab/react-resizable-styles.css';
import PuzzleComment from "./puzzleComment";
import PuzzleDefinitionControls from "./puzzleDefinitionControls";
import {devLog} from "../../buildModeChecker";

interface PuzzleLabProps {
}

interface PuzzleLabState {
    puzzle: Puzzle,
    puzzleOrientation: PuzzleOrientation,
    activePuzzle: ActivePuzzle,
    world: World,
    puzzleTextBoxContent: string,
    showSolutionPath: boolean,
    // searchResultsFilterFn: (s: Puzzle) => boolean,
}

class PuzzleLab extends React.Component<PuzzleLabProps, PuzzleLabState> {
    private readonly activePuzzleRef: React.RefObject<PuzzleLayout>;
    private readonly solutionDescriberRef: React.RefObject<SolutionDescriber>;
    private readonly searchResultsRef: React.RefObject<SearchResultsPanel>;
    private readonly worldBuilderRef: React.RefObject<WorldBuilder>;

    constructor(props: PuzzleLabProps) {
        super(props);
        let defaultPuzzleText = "mge1\n" +
            "d121\n" +
            "11M1\n"
        let puzzle = Puzzle.fromString(defaultPuzzleText);

        let world = Utility.getFromStorage('WorldUnderConstruction', World.fromJSON);
        this.state = {
            puzzle: puzzle,
            puzzleOrientation: PuzzleOrientation.tall,
            activePuzzle: new ActivePuzzle(puzzle),
            world: world || new World('blankworld', []),
            puzzleTextBoxContent: defaultPuzzleText,
            showSolutionPath: true,
            // searchResultsFilterFn: () => true,
        };
        // We hold a reference to these so we can do things like UndoMove on them.
        this.activePuzzleRef = React.createRef();
        this.solutionDescriberRef = React.createRef();
        this.searchResultsRef = React.createRef();
        this.worldBuilderRef = React.createRef();

        // Bind callbacks.
        this.searchDropOne = this.searchDropOne.bind(this);
        this.searchSmaller = this.searchSmaller.bind(this);

    }

    private toggleTheme = () => {
        SetProgress.toggleTheme();
        this.forceUpdate();
    };

    private toggleshowSolutionPath = () => {
        this.setState(prev => ({showSolutionPath: !prev.showSolutionPath,}));
    }

    private selectInPuzzleSelector = (puzzle: Puzzle) => {
        this.loadPuzzleWithSolution(puzzle);
        const index = this.state.world.puzzles.indexOf(puzzle);
        const wb = this.worldBuilderRef.current;
        if (wb && index !== -1) {
            wb.focusSelection([index]);
        }

    };

    private handleKeyDown = (event: KeyboardEvent) => {
        const keyMap: Map<string, () => void> = new Map([
            ["ArrowLeft", this.undoMove],
            ["ArrowRight", this.redoMove],
            ["r", this.undoAll],
            // ["t", this.toggleTheme],
            // Show a nice little map of the different move types.
            ["?", () => this.loadPuzzleWithSolution(Puzzle.fromString(Constants.keyboardPuzzleText))],
        ]);
        let fn = keyMap.get(event.key);
        if (fn) fn();
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    getSnapshotBeforeUpdate(nextProps: Readonly<PuzzleLabProps>, nextState: Readonly<PuzzleLabState>) {
        devLog('Utility.setInStorage(\'WorldUnderConstruction\', nextState.world);');
        Utility.setInStorage('WorldUnderConstruction', nextState.world);
        return null;
    }

    componentDidUpdate(prevProps: Readonly<PuzzleLabProps>, prevState: Readonly<PuzzleLabState>, snapshot?: any): void {
    }

    private undoMove = () => {
        let p = this.activePuzzleRef.current;
        if (p) p.undoMove();
    }

    private redoMove = () => {
        let p = this.activePuzzleRef.current;
        if (p) p.redoMove();
    }

    private generateAndLoadSolution = () => {
        // We optionally do some surgery, implanting the solution on the activePuzzle's puzzle.
        const puzzle = this.state.activePuzzle.puzzle;
        if (puzzle) {
            let solutionSet = new Solver().solve(puzzle);
            if (solutionSet.solutions.length > 0) {
                puzzle.setCanonicalSolutionWithSolutionSet(solutionSet);
            }

            this.searchResultsRef.current!.appendSolvedPuzzles(puzzlesFromSolutionSet(solutionSet));
        }
    }

    private undoAll = () => {
        let p = this.activePuzzleRef.current;
        if (p) p.undoAll();
    }

    private resetFilters = () => {
        if (!this.solutionDescriberRef.current) return;
        this.solutionDescriberRef.current!.resetFilters();
    }

    // This works when the underlying input has name="<name of state variable tracking the content>"
    private handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        // @ts-ignore
        this.setState({
            [name]: value,
        });
    }

    private setFilter = (filterFn: (s: Puzzle) => boolean) => {
        // console.log(`setFilter called`);
        this.searchResultsRef.current?.setFilter(filterFn);
    }
    //
    // private loadSolution = (solution: Solution) => {
    //     // Just make sure we're gonna see the solution intended.
    //     // TODO: This is a cheap hack. We need a uniform way to track solutions inside puzzles, unify the 2 views,
    //     //  solution and puzzle, somehow.
    //     let puzzle = solution.puzzle.setCanonicalSolutionWithMoves(solution.moves);
    //     this.solutionDescriberRef.current!.setSolution(solution);
    //     this.setState({
    //         puzzle: solution.puzzle,
    //         activePuzzle: new ActivePuzzle(solution.puzzle),
    //         puzzleTextBoxContent: solution.puzzle.toString()
    //     });
    // }

    private loadSolvedPuzzle = (solution: Puzzle) => {
        console.assert(solution.canonicalSolutionMoves);

        this.solutionDescriberRef.current!.setSolvedPuzzle(solution);
        this.setState({
            puzzle: solution,
            activePuzzle: new ActivePuzzle(solution),
            puzzleTextBoxContent: solution.toString()
        });
    }

    // private filterSolutions(s: Solution): boolean {
    //     if (!this.solutionDescriberRef.current) return true;
    //     return this.solutionDescriberRef.current.searchResultsFilterFn(s);
    // }

    private loadPuzzleWithSolution = (puzzle: Puzzle) => {
        if (!this.activePuzzleRef.current) return;

        // We try to use an implanted witness if it's there.
        let solvedPuzzles: Puzzle[] = [];
        if (puzzle.canonicalSolutionMoves) {
            solvedPuzzles = [puzzle];
        } else {
            let solver = new Solver({
                returnEmptyOnAnySkip: false,
                maxSolutions: 25,
                excludeEndOnMovement: false,
                excludeBackTrackInSolution: false,
                excludeBoomlessBombPuzzles: false,
                excludeUnusedMovementType: false
            });
            const solutionSet = solver.solve(puzzle);

            // Inscribe the solution as unique.
            if (solutionSet.solutions.length === 1) {
                puzzle.setCanonicalSolutionWithSolutionSet(solutionSet);
            }

            // Just dump the solvedPuzzles here. Who cares.
            solvedPuzzles = puzzlesFromSolutionSet(solutionSet);
        }

        this.searchResultsRef.current?.setSolvedPuzzles(solvedPuzzles);
        let activePuzzle = puzzle.solutionInfo?.getSolvedActivePuzzle().undoAll() || new ActivePuzzle(puzzle);
        this.setState({
            puzzle: puzzle,
            activePuzzle: activePuzzle,
            puzzleTextBoxContent: puzzle.toString(),
        }, () => {
            if (solvedPuzzles.length > 0) {
                this.solutionDescriberRef.current?.setSolvedPuzzle(solvedPuzzles[0]);
            }
        });
    }

    private updateTextAreaHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const target = event.target;
        const newText = target.value;
        const name = target.name;
        // @ts-ignore
        this.setState({[name]: newText,});
    }

    private async searchSmaller() {
        const searchProps: SearchConfig = {
            excludeMultiSolutionPuzzles: true,
            solverConfig: {
                excludeEndOnMovement: false,
                excludeUnusedMovementType: false,
                maxSolutions: 2,
                excludeBoomlessBombPuzzles: false,
                excludeBackTrackInSolution: false,
                returnEmptyOnAnySkip: true,
            },
            puzzleGeneratorProps: {
                template: this.state.puzzleTextBoxContent,
                tokensToInsert: ['0'],
                wildcard: "1",
                defaultToken: "1",
                omitIsomorphic: true,
                excludeBoringDiagonalParityRatio: false,
                excludeInteractingBombs: false,
            },
        }

        let results = await doSearch(searchProps);
        this.searchResultsRef.current!.registerSearchResult(results);

        // this.solutionResultsRef.current!.setSolutions(rawSolutions);
        // this.state.rawSolutions.push(...newsol);

        this.forceUpdate();
    }

    private async searchDropOne() {
        let starts = this.state.puzzle.visitableThingsList.map(v => VisitableThingInfo.keyCode(v));
        let template = this.state.puzzleTextBoxContent;
        starts.forEach(v => template = template.replace(v, '.'));
        const searchProps: SearchConfig = {
            excludeMultiSolutionPuzzles: true,
            solverConfig: {
                excludeEndOnMovement: false,
                excludeUnusedMovementType: false,
                excludeBoomlessBombPuzzles: false,
                excludeBackTrackInSolution: false,
                maxSolutions: 2,
                returnEmptyOnAnySkip: true,
            },
            puzzleGeneratorProps: {
                template: template,
                tokensToInsert: Utility.dropOneVariations(starts.join('')),
                wildcard: ".",
                defaultToken: "1",
                omitIsomorphic: true,
                excludeBoringDiagonalParityRatio: false,
                excludeInteractingBombs: false,
            },
        }

        let results = await doSearch(searchProps);
        this.searchResultsRef.current!.registerSearchResult(results);

        // this.solutionResultsRef.current!.setSolutions(rawSolutions);
        // this.state.rawSolutions.push(...newsol);

        this.forceUpdate();
    }


    render() {
        const puzzleTextBoxContent = this.state.puzzleTextBoxContent;
        let likePuzzleEntryBox = puzzleTextBoxContent === this.state.puzzle.toString() ?
            'puzzle-lab__text-area--same-as-puzzle' : 'puzzle-lab__text-area--different-from-puzzle';

        const injectedColorScheme = {majorHue: 120, minorHue: 60};


        return (
            <div id={"PuzzleLab"}>
                {/*<GridLayout className="layout" layout={this.layout} cols={13} maxRows={29} rowHeight={30} width={1600}>*/}
                <div className="puzzle-lab__layout">
                    <PuzzleDefinitionControls
                        currentlyDisplayedPuzzle={this.state.puzzle}
                        loadPuzzleWithSolutionFn={this.loadPuzzleWithSolution}
                        searchDropOneFn={this.searchDropOne}
                        searchSmallerFn={this.searchSmaller}
                        showSolutionPathChecked={this.state.showSolutionPath}
                        toggleThemeFn={this.toggleTheme}
                        toggleshowSolutionPathFn={this.toggleshowSolutionPath}
                    />

                    <div key={"PuzzleComponent"}
                         className={"panel lab-active-puzzle-panel"}>
                        <PuzzleLayout
                            injectedColorScheme={injectedColorScheme}
                            currentView={{view: 'PUZZLE'}}
                            constantsToPutInCss={LayoutConstants.layoutConstantsToPutInCss()}
                            showSolutionPath={this.state.showSolutionPath}
                            fixedSize={{widthPx: 552, heightPx: 680}}
                            puzzleOrientation={this.state.puzzleOrientation}
                            activePuzzle={this.state.activePuzzle}
                            howToRenderTheVisitableThings={'background'}
                            ref={this.activePuzzleRef}/>
                    </div>

                    <div key={"SearchControls"}
                         className={"panel search-controls-panel"}>
                        <SearchControls
                            // loadSolution={this.loadSolution}
                            registerSearchResult={(result: SearchResults) => {
                                this.searchResultsRef.current?.registerSearchResult(result);
                            }}/>
                    </div>

                    <div key={"MoveControls"}
                         className={"panel move-controls-panel"}>
                        <MoveControlsPanel
                            undoMoveFn={this.undoMove}
                            redoMoveFn={this.redoMove}
                            solveFn={this.generateAndLoadSolution}
                            undoAllFn={this.undoAll}
                        />
                    </div>

                    <div key={"SolutionDescriber"}
                         className={"panel solution-describer-panel"}>
                        <SolutionDescriber
                            ref={this.solutionDescriberRef}
                            callToSetFilter={this.setFilter}
                            callToFocusSearchResults={() => {
                                this.searchResultsRef.current?.focusSearchResults()
                            }}
                            callToSort={compareFn => {
                                this.searchResultsRef.current?.sortSolvedPuzzles(compareFn);
                            }}/>
                    </div>

                    <div key={"SearchResultsPanel"}
                         className={"panel search-results-panel"}>
                        <SearchResultsPanel ref={this.searchResultsRef}
                                            searchResultsHeight={12}
                                            solutionsHeight={20}
                            // loadSolutionOLD={this.loadSolution}
                                            loadSolvedPuzzle={this.loadSolvedPuzzle}
                                            resetFilters={this.resetFilters}/>
                    </div>

                    <div key={"WorldBuilder"}
                         className={"panel world-builder-panel"}>
                        <WorldBuilder
                            ref={this.worldBuilderRef}
                            worldToBuild={this.state.world}
                            loadPuzzle={this.loadPuzzleWithSolution}
                            clearWorldFn={() => {
                                this.setState({world: new World('clearedWorld', [])})
                            }}
                            worldModFn={() => this.forceUpdate()}
                            rowCount={29}
                            currentDisplayedPuzzle={this.state.puzzle}
                        />
                    </div>

                    <div className={'panel puzzle-comment-panel'}>
                        <PuzzleComment puzzle={this.state.puzzle}/>
                    </div>

                    <div key={"WorldSelector"}
                         className={'panel world-selector-panel'}>
                        <WorldSelector
                            loadPuzzle={this.loadPuzzleWithSolution}
                            pushToWorldBuilderEnabled={this.state.world.size === 0}
                            pushToWorldBuilder={(world: World) => {
                                this.setState({world: world.copy()});
                            }}/>
                    </div>

                    <div key={"PuzzleSelector"}
                         className={'panel puzzle-selector-panel'}>
                        <PuzzleSelector
                            world={this.state.world}
                            displayDisabledPuzzles
                            alwaysHideHintPurchaseQuantity
                            fixedSize={{widthPx: 436, heightPx: 1035}}
                            injectedColorScheme={injectedColorScheme}
                            puzzleOrientation={this.state.puzzleOrientation}
                            highlighted={this.worldBuilderRef.current ?
                                this.worldBuilderRef.current.state.levelListSelection?.map(i => this.state.world.puzzles[i]) :
                                undefined}
                            loadPuzzleFn={(w, puz) => this.selectInPuzzleSelector(puz)}
                        />
                    </div>

                </div>
            </div>);
    }

}


export default PuzzleLab;
