import Puzzle from "../puzzle/puzzle";
import {World} from "../puzzles/world";
import SetProgress from "./setProgress";

interface EventAttribution {
    puzzle?: Puzzle,
    world?: World,
}

// TODO: Implement type checking on event names by filling out and propagating this through the event counter
//  and related things.
// This works fine for now, but next time we should implement an event handler like so:
//  https://stackoverflow.com/questions/12881212/does-typescript-support-events-on-classes
export type EventName =
    "puzzle-start" | "deadend" | "restart" | "completion" | "re-solve" | "url-load-puzzle-completion" |
    "move" | "rewind" |
    "acquire-hint" | "acquire-free-hint" | "allocate-hint" |
    "animation-on" | "animation-off" | "sound-on" | "sound-off" | "hints-show" | "hints-hide" |
    "set-theme-3d-theme" | "set-theme-original-theme" |
    "world-next" | "world-previous" |
    "update-available" | "update-accepted" |
    "quack"

export class EventCounter {
    constructor() {
        this._counter = new Map<EventName, number>()
    }

    private _counter: Map<EventName, number>;

    get counter(): Map<EventName, number> {
        return this._counter;
    }

    static fromParsedJSON(data: any) {
        let ret = new EventCounter();
        ret._counter = new Map<EventName, number>(data);
        return ret;
    }

    get(key: EventName) {
        return this._counter.get(key);
    }

    // forEach(param: (value: number, key: string, map: Map<string, number>) => void) {
    //     this._counter.forEach(param);
    // }
    //
    // entries() {
    //     return this._counter.entries();
    // }

    public toString(): string {
        let ret = '';
        Array.from(this._counter.keys()).sort().forEach(key => ret += `${key}: ${this._counter.get(key)} `);
        // this._counter.forEach((v, k) => ret += `${k}: ${v} `);
        return ret;
    }

    public registerEvent(eventName: EventName) {
        const oldCount = this._counter.get(eventName);
        if (!oldCount) this._counter.set(eventName, 1);
        else this._counter.set(eventName, oldCount + 1);
    }

    registerEvents(eventCounter: EventCounter) {
        eventCounter._counter.forEach((val, key) => {
            const oldVal = this._counter.get(key) || 0;
            this._counter.set(key, oldVal + val);
        })

    }

    toJSON() {
        return JSON.stringify(Array.from(this._counter.entries()))
    }

    clear() {
        this._counter.clear();
    }
}

function getOrInitCounter(counterName: string, counters: Map<string, EventCounter>): EventCounter {
    if (!counters.has(counterName)) {
        counters.set(counterName, new EventCounter());
    }
    return counters.get(counterName)!;
}

class UserEventTracker {
    private static puzzleEventCounters: Map<string, EventCounter> = new Map<string, EventCounter>();
    private static worldEventCounters: Map<string, EventCounter> = new Map<string, EventCounter>();
    private static globalCounter: EventCounter = new EventCounter();
    private static eventsTriggeringMergeToLocalStorage = [
        'completion',
        'acquire-hint',
        'allocate-hint',
        'update-accepted',
    ];

    public static RegisterEvent(eventName: EventName, attribution: EventAttribution | undefined = undefined) {
        this.globalCounter.registerEvent(eventName);
        if (attribution?.puzzle) {
            const puzName = attribution.puzzle.toString();
            getOrInitCounter(puzName, this.puzzleEventCounters).registerEvent(eventName);
        }
        if (attribution?.world) {
            const worldName = attribution.world.worldName;
            getOrInitCounter(worldName, this.worldEventCounters).registerEvent(eventName,);
        }

        // Make sure important events are saved immediately.
        if (this.eventsTriggeringMergeToLocalStorage.includes(eventName)) {
            this.mergeToLocalStorage();
        }
    }

    public static mergeToLocalStorage() {
        // TODO: Some callbacks or something for when this is triggered, maybe.
        SetProgress.mergeIntoStoredEventCounter('global', this.globalCounter);
        this.worldEventCounters.forEach((eventCounter, worldName) =>
            SetProgress.mergeIntoStoredEventCounter(worldName, eventCounter));
        this.puzzleEventCounters.forEach((eventCounter, puzzle) =>
            SetProgress.mergeIntoStoredEventCounter(puzzle, eventCounter));

        // Once these are safely tucked away, we can purge them here since we go off the values given by Progress.
        this.worldEventCounters.clear();
        this.globalCounter.clear();
        this.puzzleEventCounters.clear();
    }

}


export default UserEventTracker;

