import {puzzlesFromSolutionSet, SearchConfig, SolutionSet, solutionSetFromJSON} from "./solver";
import Puzzle from "./puzzle";
import {Worker} from "../worker";
import {playSound} from "../audio/sounds";


export class SearchResults {
    constructor(public searchProps: SearchConfig,
                public results: Puzzle[]) {
    }

    public static fromJSON(s: any): SearchResults {
        if (!s.searchProps || !s.results) {
            console.log(s)
            throw new Error('SearchResults JSON busted')
        }
        const searchProps = s.searchProps;
        const results = s.results.map(Puzzle.fromJSON);
        // console.log(results);
        return new SearchResults(searchProps, results);
    }

    public toJSON() {
        return {
            searchProps: this.searchProps,
            results: this.results.map(s => s.toJSON()),
        }
    }

    public shortDescription() {
        // HAHA, we're abusing Puzzle.toString here since the thing is full of template characters and is useless as
        // a puzzle. We just want the dimensions.
        let p = Puzzle.fromString(this.searchProps.puzzleGeneratorProps.template);
        return `${this.searchProps.puzzleGeneratorProps.tokensToInsert} ${p.height}x${p.width} ${this.results.length} puzzles`;
    }
}

export type SearchProgress = {
    percentComplete: number,
    foundPuzzles?: number,
    timeSpentSec?: number,
    naiveExpectedTotalTimeSec?: number,
    naiveExpectedTimeRemainingSec?: number,
    cancelFn?: () => void,
}

async function doSearchWork(searchProps: SearchConfig,
                            progressCallback: (progress: SearchProgress) => void = (p) => {
                            }): Promise<SolutionSet[]> {
    const startTimeMs = performance.now();
    let searchResult: SolutionSet[] = [];
    {
        // console.config("Deploying worker...")
        // Create new instance of our Puzzle Searching Web Worker.
        const worker = new Worker();
        // let r = await worker.processDataWithWebWorker("hello");
        await worker.init(searchProps);
        const postInitTimeMs = performance.now();
        const initDurationMs = postInitTimeMs - startTimeMs;
        // This gets cancelled in a callback if we want to stop the worker short.
        let cancelObj = {shouldCancel: false};
        let progress: SearchProgress = {
            percentComplete: 0,
        };
        do {
            // Do the work!
            progress = await worker.doWork(.001);
            const timeSpentMs = performance.now() - postInitTimeMs;
            progress.timeSpentSec = (timeSpentMs + initDurationMs) / 1000;
            progress.naiveExpectedTimeRemainingSec = (timeSpentMs * 100 / progress.percentComplete - timeSpentMs) / 1000;
            progress.naiveExpectedTotalTimeSec = (timeSpentMs * 100 / progress.percentComplete + initDurationMs) / 1000;
            progress.cancelFn = () => {
                cancelObj.shouldCancel = true;
            };
            progressCallback(progress);
        } while (progress.percentComplete < 100 && !cancelObj.shouldCancel);
        const workerResult = await worker.getStringifiedResult();
        searchResult = JSON.parse(workerResult).map(solutionSetFromJSON);
    }
    console.log(`Puzzle search took ${((performance.now() - startTimeMs) / 1000).toFixed(1)} seconds.`);
    return searchResult;
}


export async function doSearch(searchProps: SearchConfig,
                        progressCallback: (progress: SearchProgress) => void = (p) => {
                        }): Promise<SearchResults> {
    let searchResult = await doSearchWork(searchProps, progressCallback);
    let solvedPuzzles = searchResult.map(puzzlesFromSolutionSet).flat();

    if (solvedPuzzles.length === 0) {
        playSound('deadend');
    }
    if (solvedPuzzles.length > 0) {
        playSound('completion');
    }

    return new SearchResults(searchProps, solvedPuzzles);
}