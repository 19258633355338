import {VisitableThingInfo, VisitableThing} from "./movement";
import {VisitCountRequirements} from "./puzzle";

export class Square {
    constructor(public visits: VisitCountRequirements,
                public thingType: VisitableThing = VisitableThing.None) {
    }

    public toString() {
        // If it's not a schema spot, this is easy.
        if (this.thingType === VisitableThing.None)
            return this.visits.toString();
        if (this.visits.visitCount === 1) {
            return VisitableThingInfo.keyCode(this.thingType);
        }

        console.error("Weird place in Square.toString().")
        return '{' + VisitableThingInfo.keyCode(this.thingType) + '}';
    }

    rotated() {
        return new Square(this.visits, VisitableThingInfo.rotatedVersion(this.thingType));
    }

    hFlipped() {
        return new Square(this.visits, VisitableThingInfo.hFlippedVersion(this.thingType));
    }

    vFlipped() {
        return new Square(this.visits, VisitableThingInfo.vFlippedVersion(this.thingType));
    }

    transposed() {
        return new Square(this.visits, VisitableThingInfo.transposeVersion(this.thingType));
    }
}