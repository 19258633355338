import GetProgress from "../progress/getProgress";
import Progress from "../progress/progress";
import Puzzle, {PuzzleOrientation} from "../puzzle/puzzle";
import React, {ReactNode} from "react";
import StaticPuzzleComponent from "./staticPuzzleComponent";
import Utility, {ScreenDimensions} from "../utility";
import {isDev, isProd} from "../buildModeChecker";
import {LoadPuzzleFn} from "./userFacingGame";
import {World, WorldTheme} from "../puzzles/world";

import '../css/puzzleSelector.css';

interface PuzzleSelectorProps {
    world: World,
    loadPuzzleFn: LoadPuzzleFn,
    puzzleOrientation: PuzzleOrientation,

    mostRecentlyPlayedPuzzle?: Puzzle,

    // These are just for the lab
    displayDisabledPuzzles?: boolean,
    alwaysHideHintPurchaseQuantity?: boolean,
    injectedColorScheme?: WorldTheme,
    // Only used in the lab, to get us a reliable layout size not dependent on the screen characteristics.
    fixedSize?: ScreenDimensions,
    highlighted?: Puzzle[],
}

interface PuzzleSelectorState {
    // lastLoadedPuzzle?: Puzzle,
}

class PuzzleSelector extends React.PureComponent<PuzzleSelectorProps, PuzzleSelectorState> {

    constructor(props: PuzzleSelectorProps) {
        super(props);
        this.state = {};

        // This might be inadvisable. It's certainly not done well enough, but once I saw the PuzzleSelector
        //  pop up when it didn't know the solved puzzle yet.
        // TODO: Need a better solution to make sure this PuzzleSelector gets updated on puzzle completion.
        Progress.registerProgressChangeCallback(this.props.world, () => this.forceUpdate())
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    // This is from a nice tip: https://stackoverflow.com/questions/19014250/rerender-view-on-browser-resize-with-react
    updateDimensions = () => {
        // this.setState({screenSize: Utility.effectiveScreenSize()});
        this.forceUpdate();
    }

    render(): ReactNode {
        const puzzleOrientation = this.props.puzzleOrientation;
        const world = this.props.world;
        if (world === undefined) return <></>;
        const puzzles = this.props.displayDisabledPuzzles ? world.puzzles : world.enabledPuzzles();
        const selectorLayout = Utility.calculatePuzzleSelectorLayout(puzzles.length, this.props.fixedSize);

        const worldTheme: WorldTheme = this.props.injectedColorScheme ?
            this.props.injectedColorScheme :
            this.props.world.getColorSchemeTheme();

        let style = {
            "--num-rows": selectorLayout.height,
            "--num-cols": selectorLayout.width,
            "--row-gap": `${selectorLayout.rowGapPx}px`,
            "--col-gap": `${selectorLayout.colGapPx}px`,
            '--square-size': `${selectorLayout.squareSizePx}px`,

            // The colors here shade the ones defined up at .user-facing-game
            '--theme-major-hue': worldTheme.majorHue,
            '--theme-minor-hue': worldTheme.minorHue,
            // '--theme-complement-hue': worldTheme.complementHue,
            // '--theme-random-number-1': worldTheme.randomNumber1,
            // '--theme-random-number-2': worldTheme.randomNumber2,
        } as React.CSSProperties;

        const progressionInfo = GetProgress.getWorldProgress(this.props.world);
        const getLevelProgress = (i: number) => {
            function devInjectProgress(i: number) {
                if (isProd()) return undefined;
                if (i === 0) return {
                    puzzleHintState: {ownedHints: 2, hintPurchases: 1},
                    played: true,
                    solved: true,
                    puzzle: progressionInfo.levelProgress[i].puzzle
                };
                if (i === 1) return {
                    puzzleHintState: {ownedHints: 0, hintPurchases: 0},
                    played: true,
                    solved: false,
                    puzzle: progressionInfo.levelProgress[i].puzzle
                };
                if (i === 2) return {
                    puzzleHintState: {ownedHints: 0, hintPurchases: 0},
                    played: true,
                    solved: true,
                    puzzle: progressionInfo.levelProgress[i].puzzle
                };
                if (i === 3) return {
                    puzzleHintState: {ownedHints: 0, hintPurchases: 0},
                    played: false,
                    solved: false,
                    puzzle: progressionInfo.levelProgress[i].puzzle
                };
                else return undefined;
            }

            // Hack to show styles in dev mode.
            return devInjectProgress(i) || progressionInfo.levelProgress[i];
        }

        // Hack to show styles in dev mode.
        const sufficientProgress = (i: number) =>
            (i <= progressionInfo.lastPlayable || this.props.fixedSize || (isDev() && i < 4));


        let elemClasses = (i: number) => {
            const ret = ['puzzle-selector__elem'];
            let levelProgress = this.props.fixedSize ?
                {
                    solved: false,
                    played: false,
                }
                : getLevelProgress(i);

            //
            // // More restrained styling in the lab.
            // if (this.props.fixedSize) {
            //     levelProgress = {
            //         solved: false,
            //         played: false,
            //     }
            // }

            // Hack to show styles in dev mode.
            const lastPlayed = this.props.mostRecentlyPlayedPuzzle === puzzles[i] || (isDev() && i < 2);

            if (levelProgress) {
                if (levelProgress.solved) {
                    ret.push('puzzle-selector__elem--previously-solved');
                }
                if (levelProgress.played) ret.push('puzzle-selector__elem--previously-played')
                else ret.push('puzzle-selector__elem--previously-unplayed');
            }
            if (lastPlayed) {
                ret.push('puzzle-selector__elem--last-loaded-puzzle');
            }
            // We ignore progressioninfo in the puzzle lab where we render this with fixed size.
            (sufficientProgress(i)) ?
                ret.push('puzzle-selector__elem--sufficient-progress') :
                ret.push("puzzle-selector__elem--insufficient-progress");
            if (this.props.highlighted?.some(p => p === puzzles[i])) {
                ret.push('puzzle-selector__elem--highlighted');
            }
            if (!puzzles[i].enabled) ret.push("puzzle-selector__elem--not-enabled");
            return ret.join(' ');
        }
        let innerClasses = (i: number) => {
            let ret = ['puzzle-selector__elem__inner'];
            if (this.props.mostRecentlyPlayedPuzzle === puzzles[i]) {
                ret.push('puzzle-selector__elem__inner--last-loaded-puzzle');
            }
            return ret.join(' ');
        }

        let puzzleSelectorClasses = [
            'puzzle-selector',
            this.props.fixedSize ? 'puzzle-selector--fixed-size' : '',
        ].join(' ');

        const elemStyle = (i: number) => {
            return {
                '--col-num': i % selectorLayout.width,
                '--row-num': Math.floor(i / selectorLayout.width),
                // zIndex: Math.floor(i / selectorLayout.width) - i % selectorLayout.width + 50,
            } as React.CSSProperties;
        }

        // We lay out the grid of puzzles 3 times.
        // 1. The unvisitables that we don't have access to. They're on the ground and above them is:
        // 2. Shadows of the accessible puzzles. This has to be done in a different phase so they don't spread onto
        //    the squares of some of their neighbours. We might usually get away with using a filter: DropShadow
        //    but here we want the shadow to show up on the puzzles in layer 1, and them only.
        // 3. The accessible puzzles.
        // This is cumbersome but it works and is awesome.

        return <div className={puzzleSelectorClasses} style={style}>
            {/* Layer 1: Inaccessible puzzles. */}
            <div className={'puzzle-selector-grid'} key={'layer-1'}>
                {puzzles.map((puz, i) => {
                        let puzzleClasses = elemClasses(i);
                        if (sufficientProgress(i)) {
                            return <div className={'puzzle-selector__elem--layer-1-filler'} key={+i + puz.label}/>
                        } else {
                            return <div className={puzzleClasses}
                                        style={elemStyle(i)}
                                        key={puz.label}>
                                <div className={innerClasses(i)}>
                                    <StaticPuzzleComponent
                                        puzzle={puz}
                                        renderMethod={'background'}
                                        orientation={puzzleOrientation}/>
                                </div>
                            </div>;
                        }
                    }
                )}
            </div>
            {/* Layer 2: Shadows for the accessible puzzles */}
            <div className={'puzzle-selector-grid'} key={'layer-2'}>
                {puzzles.map((puz, i) => {
                        if (sufficientProgress(i)) {
                            return <div className={'puzzle-selector__elem--layer-2-shadow'} key={+i + puz.label}/>
                        } else {
                            return <div className={'puzzle-selector__elem--layer-2-filler'} key={+i + puz.label}/>
                        }
                    }
                )}
            </div>
            {/* Layer 3: Accessible Puzzles */}
            <div className={'puzzle-selector-grid'} key={'layer-3'}>
                {puzzles.map((puz, i) => {
                        if (sufficientProgress(i)) {
                            const hideHintBubble = this.props.alwaysHideHintPurchaseQuantity ||
                                getLevelProgress(i).puzzleHintState.hintPurchases === 0;
                            return <div className={elemClasses(i)}
                                        style={elemStyle(i)}
                                        key={+i + puz.label}
                                        onClick={(e) => this.onPuzzleClick(e, puz)}>
                                <div className={innerClasses(i)}>
                                    <StaticPuzzleComponent
                                        puzzle={puz}
                                        renderMethod={'background'}
                                        orientation={puzzleOrientation}/>
                                    {hideHintBubble ? <></> :
                                        <div className={'puzzle-selector__number'}>
                                        <span className={'puzzle-selector__number__inner'}>
                                            {getLevelProgress(i).puzzleHintState.hintPurchases}
                                        </span>
                                        </div>}
                                </div>
                            </div>;
                        } else {
                            return <div className={'puzzle-selector__elem--layer-3-filler'} key={+i + puz.label}/>
                        }
                    }
                )}
            </div>
        </div>
    }

    private onPuzzleClick = (e: React.MouseEvent<HTMLDivElement>, puz: Puzzle) => {
        if (!this.props.loadPuzzleFn) return;
        e.preventDefault();
        this.props.loadPuzzleFn(this.props.world, puz);
        // this.setState({lastLoadedPuzzle: puz}, this.forceUpdate);
    }

}

export default PuzzleSelector;