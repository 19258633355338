import Puzzle, {PuzzleOrientationHelper} from "./puzzle/puzzle";
import Constants from "./constants";
import Location from './puzzle/location'
import LayoutConstants from "./layoutConstants";
// import localForage from "localforage";

export type ScreenDimensions = {
    heightPx: number,
    widthPx: number,
};

export type GridLayoutConstants = {
    height: number,
    width: number,
    rowGapPx: number,
    colGapPx: number,
    squareSizePx: number,
}

class Utility {

    // Take just the digits from a string, excluding other characters.
    public static justDigits(str: string) {
        let numb = str.match(/\d/g);
        return numb ? numb.join("") : "";
    }


    public static getFromStorage<T>(key: string, fn: (json: any) => T): T | undefined {
        if (typeof window === 'undefined') return undefined;
        let lsValue = localStorage.getItem(key);
        if (!lsValue) {
            // devLog(`getFromStorage couldn't find key ${key}`);
            return undefined;
        }
        try {
            let json = JSON.parse(lsValue);
            return fn(json);
        } catch (e) {
            console.log(e)
            return undefined;
        }

    }

    public static setInStorage<T>(key: string, object: T) {
        localStorage.setItem(key, JSON.stringify(object));
    }

    static removeFromStorage(key: string) {
        localStorage.removeItem(key);
    }

    // Pad out lines with 0s if the string comes in malformed.
    public static tryToCleanPuzzleDefinition(template: string) {
        const pad = (s: string, len: number) => (s + '0'.repeat(len - s.length));
        let lines = template.trim().split('\n');
        const maxLen = Math.max(...lines.map(s => s.length));
        if (maxLen <= 10) lines = lines.map(s => pad(s, maxLen))
        return lines.join('\n');
    }

    public static effectiveScreenSize(): ScreenDimensions {
        // console.log(`window. ${window.innerHeight} ${window.innerWidth}`)
        // console.log(`document.documentElement. ${document.documentElement.clientHeight} ${document.documentElement.clientWidth}`)
        return {
            heightPx: window.innerHeight,
            widthPx: window.innerWidth,
            // heightPx: document.documentElement.clientHeight,
            // widthPx: document.documentElement.clientWidth,
        }
    }

    public static getGameBodyAspectRatio() {
        const effectiveScreenSize = Utility.effectiveScreenSize();
        const occupiedHeight = (LayoutConstants.gameLayout.textOverlayHeightPct + LayoutConstants.gameLayout.buttonHeightPct) / 100;
        return (effectiveScreenSize.heightPx * (1 - occupiedHeight)) / effectiveScreenSize.widthPx * .95;
    }

    public static formatTime(timeSec: number): string {
        if (timeSec < 100) return timeSec.toFixed(1);
        const minutes = Math.floor(timeSec / 60);
        const seconds = Utility.prefixPaddedNumber(Math.floor(timeSec % 60), 2)
        return `${minutes}:${seconds}`
    }

// Generate all permutations of a string without repetitions.
    public static findPermutations(str: string) {
        if (str.length < 2) {
            return [str];
        }
        let permutationsArray: string[] = [];

        for (let i = 0; i < str.length; i++) {
            let char = str[i];
            if (str.indexOf(char) !== i) continue;
            let remainingChars = str.slice(0, i) + str.slice(i + 1, str.length);
            for (let permutation of Utility.findPermutations(remainingChars)) {
                permutationsArray.push(char + permutation)
            }
        }
        return permutationsArray
    }

    public static setClipboard(value: string) {
        console.log(`Setting clipboard to ${value.length} length string.`);
        let tempInput = document.createElement("textarea");
        // tempInput.style = new CSSStyleDeclaration( {position: "absolute", left: "-1000px", top: "-1000px"});
        tempInput.value = value;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        localStorage.setItem("LastCopiedWorld", value);
    }

    public static optionalS(n: number, str: string) {
        return `${n} ${str}${n === 1 ? "" : "s"}`;
        // return str + (n === 1 ? "" : "s");
    }

// Stolen from https://stackoverflow.com/a/24392281/6998241
// returns true iff the line from (a,b)->(c,d) lineSegmentsIntersect with (p,q)->(r,s)
    public static lineSegmentsIntersect(a: number, b: number, c: number, d: number,
                                        p: number, q: number, r: number, s: number,
                                        includeEndpoints: boolean = true) {
        let det, gamma, lambda: number;
        det = (c - a) * (s - q) - (r - p) * (d - b);
        if (det === 0) {
            return false;
        } else {
            lambda = ((s - q) * (r - a) + (p - r) * (s - b)) / det;
            gamma = ((b - d) * (r - a) + (c - a) * (s - b)) / det;
            if (includeEndpoints) return (0 <= lambda && lambda <= 1) && (0 <= gamma && gamma <= 1);
            return (0 < lambda && lambda < 1) && (0 < gamma && gamma < 1);
        }
    }

    /**
     * Get an array of every variation of the input string with one of its characters spliced out.
     * @param s String to work with
     */
    public static dropOneVariations(s: string): string[] {
        let ret = [];
        for (let i = 0; i < s.length; i++) {
            let arr = Array.from(s);
            arr.splice(i, 1);
            ret.push(arr.join(''));
        }
        return ret;
    }

    public static prefixPaddedNumber(n: number, width: number, prefix: string = '0') {
        let ns = n.toString();
        if (ns.length >= width) return ns;
        return prefix.repeat(width - ns.length) + ns;
    }

    public static async showStorageUsage(showEachItem: boolean) {
        let _lsTotal = 0,
            _xLen, _x;
        for (_x in localStorage) {
            if (!localStorage.hasOwnProperty(_x)) {
                continue;
            }
            _xLen = ((localStorage[_x].length + _x.length) * 2);
            _lsTotal += _xLen;
            if (showEachItem) console.log(_x.substr(0, 50) + " = " + (_xLen / 1024).toFixed(2) + " KB")
        }
        console.log("LocalStorage Total = " + (_lsTotal / 1024).toFixed(2) + " KB");

        if (navigator.storage && navigator.storage.estimate) {
            const quota = await navigator.storage.estimate();
            if (quota.usage && quota.quota) {
                // quota.usage -> Number of bytes used.
                // quota.quota -> Maximum number of bytes available.
                const percentageUsed = (quota.usage / quota.quota) * 100;
                const remaining = quota.quota - quota.usage;
                console.log(`You've used ${percentageUsed}% of the available storage.`);
                console.log(`You can write up to ${remaining} more bytes.`);
            } else {
                console.log(quota);
            }
        }
        // Request persistent storage for site
        if (navigator.storage && navigator.storage.persist) {
            const isPersisted = await navigator.storage.persist();
            console.log(`Persisted storage granted: ${isPersisted}`);
        }
    }

    public static hashStringToNumber(str: string): number {
        let hash = 0, i, chr;
        if (str.length === 0) return hash;
        for (i = 0; i < str.length; i++) {
            chr = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }

    public static simpleShortStringHash(str: string) {
        const length = 4;
        const hash = Utility.hashStringToNumber(str);
        return hash.toString(36).substr(2, length);
    }

    static vibrate(timeMs: number) {
        // enable vibration support
        // @ts-ignore
        navigator.vibrate = navigator.vibrate || navigator.webkitVibrate || navigator.mozVibrate || navigator.msVibrate;

        if (navigator.vibrate) {
            // vibration API supported
            navigator.vibrate(timeMs);
        }
    }

    public static puzzleUrl(puzzle: Puzzle) {
        // TODO: Obfuscate this with btoa to encode and atob to decode
        let url = new URLSearchParams();
        url.append('puzzle', puzzle.toString());
        const baseUrl = 'https://dukx.app/index.html';
        return `${baseUrl}?${url.toString()}`;
    }

    public static squareArray<T>(rows: number, cols: number, item: T): Array<Array<T>> {
        const ret = new Array<Array<T>>();
        for (let i = 0; i < rows; i++) {
            let a = new Array(cols);
            for (let j = 0; j < cols; ++j) a[j] = item;
            ret[i] = a;
        }
        return ret;
    }

    public static randomDirection(loc: Location, puzzle: Puzzle) {
        // I just picked some primes from this list
        // https://primes.utm.edu/lists/small/10000.txt
        let x = ((65701 * loc.column + 63311 * loc.row + 69899 * puzzle.height + 104651 * puzzle.width) % 100) - 50;
        let y = ((61819 * loc.column + 97369 * loc.row + 84463 * puzzle.height + 76249 * puzzle.width) % 100) - 50;


        // let x = Utility.hashStringToNumber(loc.row + puzzle.getAnalyticsName() + loc.toString()) / 100000;
        // let y = Utility.hashStringToNumber(puzzle.getAnalyticsName() + loc.column + loc.toString()) / 100000;
        let magnitude = Math.sqrt(x ** 2 + y ** 2);
        let ret = {
            randomDirX: x / magnitude,
            randomDirY: y / magnitude,
        };
        return ret;
    }

    /**
     * Get a number in [1..numCompletionAnimations] determined by the puzzle. This should only change if the puzzle
     *  moves around to a different world or place in its world or if the number of animations changes.
     * @param puzzle The puzzle whose completion animation we want to know.
     */
    public static pseudoRandomCompletionAnimationNumber(puzzle: Puzzle) {
        const n = Constants.numCompletionAnimations;
        // The constant below is just to let us pick which animation is used by the first puzzle.
        const adjustment = 3;
        let hash = ((Utility.hashStringToNumber(puzzle.analyticsName) % n) + n + adjustment) % n;
        return hash + 1;
    }

    public static calculateActivePuzzleLayoutConstants(orientationHelper: PuzzleOrientationHelper, fixedSize: ScreenDimensions | undefined = undefined): GridLayoutConstants {
        // Awful.
        const {height, width} = orientationHelper;
        const effectiveScreenSize = Utility.effectiveScreenSize();
        const windowWidth = effectiveScreenSize.widthPx;
        const windowHeight = effectiveScreenSize.heightPx;
        const verticalPaddingToAvoidOverlappingTheButtons = 2;
        const paneHeight = fixedSize ? fixedSize.heightPx :
            windowHeight * (100 - verticalPaddingToAvoidOverlappingTheButtons - LayoutConstants.gameLayout.buttonHeightPct - LayoutConstants.gameLayout.textOverlayHeightPct) / 100;
        const paneWidth = fixedSize ? fixedSize.widthPx : windowWidth * .94;
        const availableHeight = paneHeight;
        const availableWidth = paneWidth;

        const gridGapPct = LayoutConstants.layoutConstantsToPutInCss().gridGapSquarePct;
        const gridGapRatio = (height - 1) * gridGapPct / 100;

        const availableSquareSpace = Math.min(availableHeight / (height + gridGapRatio), availableWidth / (width + gridGapRatio));

        const gridGapPx = gridGapPct * availableSquareSpace * 0.01;
        return {
            height: height,
            width: width,
            squareSizePx: availableSquareSpace,
            rowGapPx: gridGapPx,
            colGapPx: gridGapPx,
        };
    }

    static calculatePuzzleSelectorLayout(numPuzzles: number, fixedSize: ScreenDimensions | undefined = undefined): GridLayoutConstants {
        const effectiveScreenSize = Utility.effectiveScreenSize();
        const windowWidth = effectiveScreenSize.widthPx;
        const windowHeight = effectiveScreenSize.heightPx;
        // Vestigal, used to be a bad hack. Remains as a witness to not doing things the right way the first time.
        const verticalPaddingToAvoidOverlappingTheButtons = 0;
        const paneHeight = fixedSize ?
            fixedSize.heightPx :
            windowHeight * (100 - verticalPaddingToAvoidOverlappingTheButtons -
            LayoutConstants.gameLayout.buttonHeightPct - LayoutConstants.gameLayout.textOverlayHeightPct) / 100;
        const paneWidth = fixedSize ? fixedSize.widthPx : windowWidth * .95;

        const waste = (cols: number) => {
            const rows = Math.ceil(numPuzzles / cols);
            const sq = Math.min(paneWidth / cols, paneHeight / rows);
            let waste = sq * sq * (rows * cols - numPuzzles);
            waste += paneHeight * (paneWidth - cols * sq);
            waste += paneWidth * (paneHeight - rows * sq);
            return waste;
        }

        let best = Number.MAX_SAFE_INTEGER;
        let bestCols = -1;
        for (let cols = 1; cols < numPuzzles; cols++) {
            const wasted = waste(cols);
            // console.log(`Waste with ${cols} cols: ${wasted}`);
            if (wasted < best) {
                bestCols = cols;
                best = wasted;
            }
        }
        const height = Math.ceil(numPuzzles / bestCols);
        let sq = Math.min(paneWidth / bestCols, paneHeight / height);

        // Shave this off the square for use in the tight direction.
        sq = sq * (1 - Constants.puzzleSelectorMinimumPermissibleGapProportionOfSquare);
        const rowGapPx = (paneHeight - height * sq) / (height - 1);
        const colGapPx = (paneWidth - bestCols * sq) / (bestCols - 1);

        return {
            height: height,
            width: bestCols,
            colGapPx: colGapPx,
            rowGapPx: rowGapPx,
            squareSizePx: sq,
        }
    }

    static findSquareCentre(layoutConstants: GridLayoutConstants, swapped: boolean, loc: Location) {
        const squareSize = layoutConstants.squareSizePx;

        const x = (loc.column + 0.5) * squareSize + loc.column * layoutConstants.colGapPx;
        const y = (loc.row + 0.5) * squareSize + loc.row * layoutConstants.rowGapPx
        if (swapped) {
            return {x: y, y: x,}
        } else {
            return {x: x, y: y,}
        }
    }

    static sum(numberList: number[]) {
        return numberList.reduce((total, nextValue) => total + nextValue, 0);
    }

    static initializeStorage() {
        // localForage.config({
        //     name: 'DUKX storage',
        // });
    }
}

export default Utility;