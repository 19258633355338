import {intro} from "./intro";
import {world_6} from "./world_6";
import {liegeworld} from "./liegeworld";
import {bomb_world} from "./bomb_world";
import {goldworld} from "./goldworld-backup";
import {mddj_world} from "./mddj_world";
import {multitap_world} from "./multitap_world";
import {world_whatever} from "./world_whatever";
import {jumpworld} from "./jumpworld";
import {chineseHorseWorld} from "./chineseHorseWorld";
import {slipperyWorld} from "./slipperyWorld";
import {fontworld} from "./fontworld";
import {bulletWorld} from "./bulletWorld";
import {slipperyMultitapWorld} from "./slipperyMultitapWorld";
import {World} from "./world";
import Constants from "../constants";
import Utility from "../utility";
import {devLog, isDev} from "../buildModeChecker";
import Solver from "../puzzle/solver";
import {VisitableThingInfo} from "../puzzle/movement";

// TODO: Fontworld helpers:
//  https://www.dafont.com/gamer-2.font
//  https://www.dafont.com/re-do.font
//  https://www.dafont.com/bitmap.php


// TODO: Maybe be a little fancier here. There's a lot of shitty convention going on about what properties are
//  caused by initialization and how we need to use those or the basic barebones worlds. Probably doesn't really
//  need doing, but makes sense to tidy up if we have the time.
export let rawWorlds = [
    intro,
    jumpworld,
    bomb_world,
    liegeworld,
    slipperyWorld,
    bulletWorld,
    fontworld,
    goldworld,
    mddj_world,
    multitap_world,
    slipperyMultitapWorld,
    world_whatever,
    world_6,
    chineseHorseWorld,
];

class StringCounter {
    private cnt: Map<string, number>;

    constructor() {
        this.cnt = new Map<string, number>();
    }

    public add(s: string, count = 1) {
        const cur = this.cnt.get(s);
        if (cur === undefined) this.cnt.set(s, count);
        else this.cnt.set(s, cur + count);

    }

    public mergeFrom(other: StringCounter) {
        other.cnt.forEach((c, s) => this.add(s, c))
    }

    public show() {
        // console.log(this.cnt);
        const sortedKeys = new Array<string>();
        this.cnt.forEach((c, s) => sortedKeys.push(s));
        sortedKeys.sort();
        // console.log(sortedKeys)
        sortedKeys.forEach(s => {
            const line = `${s}: ${this.cnt.get(s)}`;
            console.log(line);
        })
    }
}

export class Worlds {
    public static worlds: World[];

    public static validateCanonicalSolutions() {
        if (!isDev()) return;

        let badCount = 0;
        console.log('Puzzles without canonical solutions:');
        Worlds.worlds.forEach((world, worldIndex) => {
            world.puzzles.forEach((puzzle, puzzleIndex) => {
                if (!puzzle.canonicalSolutionMoves) {
                    console.log(`${world.worldName} ${puzzle.label}`);
                } else {
                    if (!puzzle.validateCanonicalSolutionMoves()) {
                        badCount++;
                        console.error(`Puzzle ${puzzle.label} in ${world.worldName} has no solution or a bad witness.`)
                    }

                }
            })
        });
        if (badCount === 0) console.log('Congratulations! All puzzles are solvable!');
        else console.log(`You fool! There are ${badCount} insoluble puzzles!`);
    }

    public static initialize() {
        this.trimWorlds();
        this.setNeighbourhoods();
        this.addRuntimeInfoOnPuzzlesAndWorlds();
        this.validateCanonicalSolutions();
        this.solveAllFromScratch();
        this.reportMovementTypeUsageStats()
    }

    private static setNeighbourhoods() {
        Worlds.worlds.forEach((world, worldIndex) => {
            const prevWorld = Worlds.worlds[worldIndex - 1];
            const nextWorld = Worlds.worlds[worldIndex + 1];
            // devLog(world.worldName)
            // console.log(prevWorld, nextWorld, worldIndex)
            world.setNeighbourhood(prevWorld, nextWorld, worldIndex);
        });
    }

    private static addRuntimeInfoOnPuzzlesAndWorlds() {
        Worlds.worlds.forEach((world, worldIndex) => {
            world.puzzles.forEach((puzzle, puzzleIndex) => {
                const nextPuzzle = puzzleIndex + 1 < world.size ? world.puzzles[puzzleIndex + 1] : undefined;
                puzzle.setWorldNeighbourhood(world, puzzleIndex, nextPuzzle);
                puzzle.setLabel(`${worldIndex + 1}-${Utility.prefixPaddedNumber(puzzleIndex + 1, 3)}`);
                try {
                    puzzle.generateHintLocations();
                } catch (e) {
                    console.log(e);
                }
            })
        });
    }

    private static trimWorlds() {
        Worlds.worlds = [];
        rawWorlds.forEach((world, index) => {
            // Only copy in the worlds and puzzles we're committing to for the user facing game.
            if (index < Constants.totalNumberOfWorlds) {
                devLog(`Adding world ${world.worldName} to the runtime worlds.`)
                Worlds.worlds.push(new World(world.worldName, world.enabledPuzzles()));
            }
        });
    }

    private static solveAllFromScratch() {
        if (!Constants.initSolveAllFromScratch) {
            console.log('Skipping solve from scratch phase.');
            return;
        }
        console.log('Solving all puzzles from scratch...')
        let wrongSolutionCountCount = 0;
        Worlds.worlds.forEach(world => {
            world.puzzles.forEach(puzzle => {
                const solver = new Solver();
                const soln = solver.solve(puzzle);
                if (soln.solutions.length === 1) return;

                console.log(`Puzzle ${puzzle.label} has ${soln.solutions.length} solutions.`)
                wrongSolutionCountCount++;
            });
        });
        if (wrongSolutionCountCount === 0) {
            console.log('Found no puzzles without a unique solution.');
        } else {
            console.log(`Found ${wrongSolutionCountCount} puzzles without a unique solution. This is maybe a little bad?`);
        }
    }

    private static reportMovementTypeUsageStats() {
        const individually = new StringCounter();
        const all = new StringCounter();
        Worlds.worlds.forEach(world => {
            const worldCnt = new StringCounter();
            world.puzzles.forEach(puzzle => {
                const individuals = puzzle.visitableThingsList.map(VisitableThingInfo.keyCode);
                individuals.forEach(s => individually.add(s));
                const visitables = individuals.join('');
                // console.log(visitables)
                worldCnt.add(visitables);
            })
            console.log(`MovementTypes for world ${world.worldName}:`);
            worldCnt.show();
            all.mergeFrom(worldCnt);
        })

        console.log(`Individual Movement Type Usage:`);
        individually.show();
        console.log(`MovementTypes for entire game:`);
        all.show();
    }
}