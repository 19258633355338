// world_6 generated by WorldBuilder
import Puzzle from "../puzzle/puzzle";
import {World} from "./world";
import Location from "../puzzle/location";

export let world_6 = new World("world_6", [
//
// mp 3x4 unique
// Path: p:5 m:5  Entropy: 3.47
    Puzzle.fromString(`
p11m
1111
1110
`).setCanonicalSolutionWithWitness(JSON.parse('["0,0","2,1","1,3","0,1","2,2","0,3","0,2","1,2","1,1","1,0","2,0"]').map(Location.fromString)),
//
// mmdd 5x6 unique
// Path: m:6 d:4 m:7 d:4  Entropy: 5.55
    Puzzle.fromString(`
0d1110
11001m
11m111
110011
01d110
`).setCanonicalSolutionWithWitness(JSON.parse('["1,5","2,5","3,5","3,4","4,4","4,3","4,2","3,1","2,0","1,1","2,2","2,3","2,4","1,4","0,4","0,3","0,2","0,1","1,0","2,1","3,0","4,1"]').map(Location.fromString)),
//
// mp 4x4 unique
// Path: m:11 p:3  Entropy: 7.05
    Puzzle.fromString(`
111m
1111
1111
1p10
`).setCanonicalSolutionWithWitness(JSON.parse('["0,3","1,3","1,2","0,2","0,1","0,0","1,0","2,0","2,1","2,2","3,2","3,1","2,3","1,1","3,0"]').map(Location.fromString)),
//
// mdp 4x5 unique
// Path: m:13 d:3 p:1  Entropy: 6.07
    Puzzle.fromString(`
d11m1
110p1
11111
11011
`).setCanonicalSolutionWithWitness(JSON.parse('["0,3","0,4","1,4","2,4","3,4","3,3","2,3","2,2","2,1","3,1","3,0","2,0","1,0","0,0","1,1","0,2","1,3","0,1"]').map(Location.fromString)),
//
// myd 4x4 unique
// Path: m:4 y:8 d:3  Entropy: 6.76
    Puzzle.fromString(`
1m11
1111
1111
yd11
`).setCanonicalSolutionWithWitness(JSON.parse('["0,1","0,0","1,0","2,0","3,0","2,1","1,2","0,3","1,3","2,3","3,3","3,2","3,1","2,2","1,1","0,2"]').map(Location.fromString)),
//
// r 4x4 unique
// Path: r:15  Entropy: 3.47
    Puzzle.fromString(`
111r
1111
1111
1111
`).setCanonicalSolutionWithWitness(JSON.parse('["0,3","1,3","0,2","1,2","0,1","1,1","0,0","1,0","2,0","3,0","3,1","3,2","2,1","2,2","2,3","3,3"]').map(Location.fromString)),
//
// r 4x4 unique
// Path: r:15  Entropy: 3.87
    Puzzle.fromString(`
1111
11r1
1111
1111
`).setCanonicalSolutionWithWitness(JSON.parse('["1,2","0,1","0,2","0,3","1,3","2,3","3,3","2,2","1,1","0,0","1,0","2,0","3,0","3,1","3,2","2,1"]').map(Location.fromString)),
//
// S 4x4 unique
// Path: S:8  Entropy: 1.10
    Puzzle.fromString(`
1S11
1111
1111
1111
`).setCanonicalSolutionWithWitness(JSON.parse('["0,1","0,0","3,0","3,3","0,3","0,2","2,2","2,1","1,1"]').map(Location.fromString)),
]);