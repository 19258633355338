import React from "react";
import Puzzle from "../../puzzle/puzzle";
import {rawWorlds} from "../../puzzles/worlds";
import {World} from "../../puzzles/world";

interface WorldSelectorProps {
    loadPuzzle: (puzzle: Puzzle) => void,
    pushToWorldBuilder?: ((world: World) => void),
    pushToWorldBuilderEnabled?: boolean,
}

interface WorldSelectorState {
    worldSelection: string,
    world: World,
    levelNumber: number,
}

class WorldSelector extends React.Component<WorldSelectorProps, WorldSelectorState> {
    constructor(props: WorldSelectorProps) {
        super(props);
        this.state = {
            worldSelection: "1",
            levelNumber: 0,
            world: rawWorlds[0],
        }
        // this.handleInputChange = this.handleInputChange.bind(this);
        // this.worldChangeHandler = this.worldChangeHandler.bind(this);
        // this.levelChangeHandler = this.levelChangeHandler.bind(this);
    }
    //
    // // This works when the underlying input has name="<name of state variable tracking the content>"
    // handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    //     const target = event.target;
    //     const value = target.type === 'checkbox' ? target.checked : target.value;
    //     const name = target.name;
    //     // @ts-ignore
    //     this.setState({
    //         [name]: value
    //     });
    // }

    worldChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) =>{
        let newWorld = event.target.value;
        this.loadWorld(newWorld);
        console.log('World ' + newWorld + ' selected');
    }

    levelChangeHandler =(event: React.ChangeEvent<HTMLSelectElement>) =>{
        console.log("Level changed to " + event.target.value);
        let newLevel = +event.target.value;
        this.setLevelByNumber(newLevel);
    }

    public PreviousLevel() {
        this.setLevelByNumber(this.state.levelNumber - 1);
    }

    public NextLevel() {
        this.setLevelByNumber(this.state.levelNumber + 1);
    }

    private setLevelByNumber(newLevel: number) {
        let worldSize = this.state.world.size;
        if (newLevel >= 0 && newLevel < worldSize) {
            this.setState({levelNumber: newLevel},
                () => {
                    this.props.loadPuzzle(this.state.world.puzzles[newLevel])
                });
        } else console.error("Can't go to level " + newLevel + ". World has rowCount " + worldSize + " :(")
        // console.log(newLevel + " level selected");
    }

    private loadWorld(worldSelection: string) {
        let world = rawWorlds.find(w => w.worldName == worldSelection);
        if (!world) {
            console.error("Can't find world " + worldSelection);
            return;
        }
        this.setState({
            worldSelection: worldSelection,
            world: world,
            levelNumber: 0,
        }, () => {
            this.props.loadPuzzle(world!.puzzles[0])
        })
    }

    render() {
        return <div className={"Panel"}>
            <h2 className={'panel-header'}>WorldSelector</h2>
            <select name="worldSelection"
                    value={this.state.worldSelection}
                    onChange={this.worldChangeHandler}>
                {rawWorlds.map(w => <option value={w.worldName}
                                            key={w.worldName}>{w.worldName} ({w.enabledSize()}/{w.size})
                </option>)}
            </select>
            <select id="levelSelection"
                    className={'level-list'}
                    value={this.state.levelNumber}
                    onChange={this.levelChangeHandler}>
                {this.state.world.puzzles.map((p, i) =>
                    <option key={i}
                            className={`level-list__level ${p.enabled ? 'level-list__level--enabled' : 'level-list__level--disabled'}`}
                            value={i.toString()}>{(i + 1).toString()}</option>)
                }
            </select>
            <br/>
            <input type={"submit"}
                   className={'puzzle-lab__button'}
                   value={"Last Level"}
                   onClick={() => {
                       this.PreviousLevel();
                   }}/>
            <input type={"submit"}
                   className={'puzzle-lab__button'}
                   value={"Next Level"}
                   onClick={() => {
                       this.NextLevel();
                   }}/>
            <br/>
            <input type={"submit"}
                   className={'puzzle-lab__button'}
                   value={"Push to WorldBuilder"}
                // TODO: Get this to update correctly by having everything filtered through the PuzzleLab like
                //       you're not an asshole shortcut artist.
                   disabled={!this.props.pushToWorldBuilderEnabled}
                   onClick={() => {
                       if (this.props.pushToWorldBuilder) this.props.pushToWorldBuilder(this.state.world);
                       else console.error('You should define this.props.pushToWorldBuilder');
                   }}/>
        </div>
    }

}

export {WorldSelector};