// chineseHorseWorld generated by WorldBuilder
import Puzzle from "../puzzle/puzzle";
import {World} from "./world";
import Location from "../puzzle/location";

export let chineseHorseWorld = new World("chineseHorseWorld", [
//
// G 4x4 unique
// Path: G:7  Entropy: 2.48
    Puzzle.fromString(`
G110
1110
1111
0111
`).setCanonicalSolutionWithWitness(JSON.parse('["0,0","1,2","3,3","2,1","0,2","1,0","3,1","2,3"]').map(Location.fromString)),
//
// G 5x5 unique
// Path: G:15  Entropy: 7.74
    Puzzle.fromString(`
01110
1G111
11111
11111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["1,1","2,3","4,4","3,2","4,0","2,1","4,2","3,4","1,3","0,1","2,0","4,1","2,2","1,0","0,2","1,4"]').map(Location.fromString)),
//
// G 4x5 unique
// Path: G:9  Entropy: 4.16
    Puzzle.fromString(`
01110
01111
11111
11G11
`).setCanonicalSolutionWithWitness(JSON.parse('["3,2","2,4","0,3","1,1","3,0","2,2","3,4","1,3","0,1","2,0"]').map(Location.fromString)),
//
// T 5x5 unique
// Path: T:15  Entropy: 9.06
    Puzzle.fromString(`
011T1
11111
11111
11111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["0,3","2,4","1,2","0,4","2,3","1,1","3,0","4,2","3,4","2,2","1,0","0,2","2,1","4,0","3,2","4,4"]').map(Location.fromString)),
//
// T 5x5 unique
// Path: T:14  Entropy: 5.09
    Puzzle.fromString(`
01111
11110
11011
111T1
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["3,3","4,1","2,0","1,2","0,4","2,3","4,4","3,2","2,4","4,3","3,1","1,0","0,2","2,1","4,0"]').map(Location.fromString)),
//
// GT 5x5 unique
// Path: T:14 G:2  Entropy: 8.25
    Puzzle.fromString(`
T1111
11111
1111G
11111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["0,0","2,1","4,0","3,2","4,4","2,3","4,2","3,4","1,3","0,1","2,0","4,1","2,2","0,3","2,4","1,2","0,4"]').map(Location.fromString)),
//
// mM 5x5 unique
// Path: M:6 m:17  Entropy: 8.44
    Puzzle.fromString(`
m1111
11111
11M10
11111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["2,2","0,1","2,0","3,2","4,0","2,1","0,0","1,0","1,1","1,2","0,2","0,3","0,4","1,4","1,3","2,3","3,3","3,4","4,4","4,3","4,2","4,1","3,1","3,0"]').map(Location.fromString)),
//
// mM 5x5 unique
// Path: M:6 m:17  Entropy: 8.15
    Puzzle.fromString(`
01M11
111m1
11111
11111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["0,2","1,0","3,1","2,3","4,4","3,2","1,3","0,3","0,4","1,4","2,4","3,4","3,3","4,3","4,2","4,1","4,0","3,0","2,0","2,1","2,2","1,2","1,1","0,1"]').map(Location.fromString)),
//
// mM 5x5 unique
// Path: M:2 m:21  Entropy: 8.30
    Puzzle.fromString(`
1m111
11111
11111
10111
111M1
`).setCanonicalSolutionWithWitness(JSON.parse('["4,3","2,2","0,1","0,0","1,0","1,1","2,1","2,0","3,0","4,0","4,1","4,2","3,2","3,3","2,3","1,3","1,2","0,2","0,3","0,4","1,4","2,4","3,4","4,4"]').map(Location.fromString)),
//
// mmM 5x5 unique
// Path: m:7 M:3 m:14  Entropy: 10.8
    Puzzle.fromString(`
1M111
11111
1111m
111m1
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["2,4","2,3","1,3","1,4","0,4","0,3","0,2","0,1","2,0","1,2","3,3","3,4","4,4","4,3","4,2","4,1","4,0","3,0","3,1","3,2","2,2","2,1","1,1","1,0","0,0"]').map(Location.fromString)),
//
// mmM 5x5 unique
// Path: m:4 M:5 m:15  Entropy: 8.15
    Puzzle.fromString(`
M11m1
11111
11m11
11111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["2,2","1,2","0,2","0,1","0,0","2,1","4,0","3,2","2,4","0,3","0,4","1,4","1,3","2,3","3,3","3,4","4,4","4,3","4,2","4,1","3,1","3,0","2,0","1,0","1,1"]').map(Location.fromString)),
//
// mmM 7x4 unique
// Path: m:7 M:7 m:13  Entropy: 11.3
    Puzzle.fromString(`
1111
M111
1111
111m
11m1
1111
1111
`).setCanonicalSolutionWithWitness(JSON.parse('["3,3","2,3","1,3","0,3","0,2","0,1","0,0","1,0","2,2","4,1","2,0","3,2","5,1","6,3","4,2","4,3","5,3","5,2","6,2","6,1","6,0","5,0","4,0","3,0","3,1","2,1","1,1","1,2"]').map(Location.fromString)),
//
// mmM 7x4 unique
// Path: m:6 M:3 m:18  Entropy: 11.0
    Puzzle.fromString(`
1111
M111
1111
1111
1m11
1m11
1111
`).setCanonicalSolutionWithWitness(JSON.parse('["4,1","4,0","3,0","3,1","2,1","2,0","1,0","2,2","4,3","5,1","5,0","6,0","6,1","6,2","6,3","5,3","5,2","4,2","3,2","3,3","2,3","1,3","0,3","0,2","1,2","1,1","0,1","0,0"]').map(Location.fromString)),
//
// mmM 5x5 unique
// Path: m:9 M:3 m:12  Entropy: 10.6
    Puzzle.fromString(`
m1111
1m111
11111
1111M
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["0,0","0,1","0,2","1,2","1,3","0,3","0,4","1,4","2,4","3,4","4,2","2,3","1,1","1,0","2,0","3,0","4,0","4,1","3,1","2,1","2,2","3,2","3,3","4,3","4,4"]').map(Location.fromString)),
//
// mJM 5x5 unique
// Path: M:3 J:3 m:15  Entropy: 7.68
    Puzzle.fromString(`
Mm111
11111
1J111
11111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["0,0","1,2","3,3","2,1","2,3","0,3","0,1","1,1","1,0","2,0","3,0","4,0","4,1","3,1","3,2","4,2","4,3","4,4","3,4","2,4","1,4","0,4"]').map(Location.fromString)),
//
// mJM 5x5 unique
// Path: M:3 m:18 J:2  Entropy: 11.1
    Puzzle.fromString(`
mM111
11111
11111
111J1
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["0,1","1,3","2,1","0,0","1,0","2,0","3,0","4,0","4,1","4,2","4,3","4,4","3,4","2,4","1,4","0,4","0,3","0,2","1,2","2,2","2,3","3,3","3,1","1,1"]').map(Location.fromString)),
//
// mJM 5x5 unique
// Path: m:21 J:2  Entropy: 10.6
    Puzzle.fromString(`
Jm111
11M11
11111
11111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["0,1","1,1","2,1","3,1","3,2","3,3","2,3","1,3","0,3","0,4","1,4","2,4","3,4","4,4","4,3","4,2","4,1","4,0","3,0","2,0","1,0","0,0","0,2","2,2"]').map(Location.fromString)),
//
// mJM 5x5 unique
// Path: M:3 J:2 m:18  Entropy: 7.39
    Puzzle.fromString(`
1M111
111J1
11111
1m111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["0,1","2,0","3,2","1,3","3,3","3,1","3,0","4,0","4,1","4,2","4,3","4,4","3,4","2,4","1,4","0,4","0,3","0,2","1,2","2,2","2,1","1,1","1,0","0,0"]').map(Location.fromString)),
//
// mMd 5x5 unique
// Path: M:7 d:2 m:15  Entropy: 9.76
    Puzzle.fromString(`
d1m11
11111
11111
11M11
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["3,2","1,3","0,1","2,2","1,0","3,1","1,2","0,0","1,1","0,2","0,3","0,4","1,4","2,4","2,3","3,3","3,4","4,4","4,3","4,2","4,1","4,0","3,0","2,0","2,1"]').map(Location.fromString)),
]);