// slipperyWorld generated by WorldBuilder
import Puzzle from "../puzzle/puzzle";
import {World} from "./world";
import Location from "../puzzle/location";


export let slipperyWorld = new World("slipperyWorld", [
//
// S 5x6 unique
// Path: S:9  Entropy: 1.00
    Puzzle.fromString(`
S11111
111111
111111
111111
111111
`)
        .setCanonicalSolutionWithWitness(`0,0/0,5/4,5/4,0/1,0/1,4/3,4/3,1/2,1/2,3`.split('/').map(Location.fromString))
    ,
//
// S 5x6 unique
// Path: S:10  Entropy: 1.00
    Puzzle.fromString(`
S11111
111101
111111
111111
111111
`)
        .setCanonicalSolutionWithWitness(`0,0/4,0/4,5/0,5/0,1/3,1/3,4/2,4/2,2/1,2/1,3`.split('/').map(Location.fromString))
    ,
//
// S 5x4 unique
// Path: S:8  Entropy: 1.58
    Puzzle.fromString(`
1S11
1111
1111
1111
1111
`)
        .setCanonicalSolutionWithWitness(`0,1/0,0/4,0/4,3/0,3/0,2/3,2/3,1/1,1`.split('/').map(Location.fromString))
    ,
//
// S 5x6 unique
// Path: S:10  Entropy: 1.58
    Puzzle.fromString(`
111111
111101
S11111
111111
111111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`2,0/0,0/0,5/4,5/4,0/3,0/3,4/2,4/2,1/1,1/1,3`.split('/').map(Location.fromString))
    ,
//
// S 6x5 unique
// Path: S:11  Entropy: 3.00
    Puzzle.fromString(`
11111
1S111
11111
11111
10111
11111
`)
        .setCanonicalSolutionWithWitness(`1,1/1,4/0,4/0,0/5,0/5,4/2,4/2,1/3,1/3,3/4,3/4,2`.split('/').map(Location.fromString))
    ,
//
// S 6x5 unique
// Path: S:13  Entropy: 3.00
    Puzzle.fromString(`
11111
11111
11S11
11110
11011
01111
`)
        .setCanonicalSolutionWithWitness(`2,2/2,4/0,4/0,0/4,0/4,1/5,1/5,4/4,4/4,3/3,3/3,1/1,1/1,3`.split('/').map(Location.fromString))
    ,
//
// S 7x5 unique
// Path: S:13  Entropy: 3.00
    Puzzle.fromString(`
11111
1S111
11111
11011
11110
11111
11111
`)
        .setCanonicalSolutionWithWitness(`1,1/1,4/0,4/0,0/6,0/6,4/5,4/5,1/2,1/2,4/3,4/3,3/4,3/4,2`.split('/').map(Location.fromString))
        .setComment(
            `samey`)
    ,
//
// S 6x5 unique
// Path: S:10  Entropy: 3.00
    Puzzle.fromString(`
00001
01111
01S11
01111
11111
11111
`)
        .setCanonicalSolutionWithWitness(`2,2/2,4/5,4/5,0/4,0/4,3/3,3/3,1/1,1/1,4/0,4`.split('/').map(Location.fromString))
    ,
//
// S 7x5 unique
// Path: S:12  Entropy: 3.58
    Puzzle.fromString(`
11111
11111
10S11
11111
11011
11111
11111
`)
        .setCanonicalSolutionWithWitness(`2,2/3,2/3,0/0,0/0,4/6,4/6,0/4,0/4,1/5,1/5,3/1,3/1,1`.split('/').map(Location.fromString))
        .setComment(
            `decent`)
    ,
//
// S 6x5 unique
// Path: S:15  Entropy: 4.00
    Puzzle.fromString(`
11111
11111
01S11
11110
10111
11111
`)
        .setCanonicalSolutionWithWitness(`2,2/0,2/0,0/1,0/1,1/3,1/3,0/5,0/5,4/4,4/4,2/3,2/3,3/0,3/0,4/2,4`.split('/').map(Location.fromString))
        .setComment(
            `a bit samey`)
    ,
//
// S 7x5 unique
// Path: S:15  Entropy: 4.00
    Puzzle.fromString(`
01111
11011
11111
11S11
11111
11101
11111
`)
        .setCanonicalSolutionWithWitness(`3,2/2,2/2,0/1,0/1,1/0,1/0,4/6,4/6,0/3,0/3,1/5,1/5,2/4,2/4,3/1,3`.split('/').map(Location.fromString))
    ,
//
// S 7x5 unique
// Path: S:17  Entropy: 4.00
    Puzzle.fromString(`
01110
11011
1S111
11111
11101
11011
01110
`)
        .setCanonicalSolutionWithWitness(`2,1/2,4/1,4/1,3/0,3/0,1/1,1/1,0/5,0/5,1/6,1/6,3/5,3/5,4/3,4/3,1/4,1/4,2`.split('/').map(Location.fromString))
        .setComment(
            `I like it. not tooo samey`)
    ,
//
// S 7x4 unique
// Path: S:13  Entropy: 4.00
    Puzzle.fromString(`
1111
1S11
1111
1101
0111
1111
1111
`)
        .setCanonicalSolutionWithWitness(`1,1/1,3/0,3/0,0/3,0/3,1/2,1/2,3/6,3/6,0/5,0/5,2/4,2/4,1`.split('/').map(Location.fromString))
        .setComment(
            `very slightly new`)
    ,
//
// S 7x6 unique
// Path: S:18  Entropy: 5.00
    Puzzle.fromString(`
011110
110011
1S1111
111111
111101
110111
011110
`)
        .setCanonicalSolutionWithWitness(`2,1/2,5/1,5/1,4/0,4/0,1/1,1/1,0/5,0/5,1/6,1/6,4/5,4/5,5/3,5/3,1/4,1/4,3/5,3`.split('/').map(Location.fromString))
    ,
//
// mS 5x5 unique
// Path: S:1 m:19  Entropy: 6.58
    Puzzle.fromString(`
11111
10S01
11111
11m11
10111
`)
        .setCanonicalSolutionWithWitness(`1,2/3,2/4,2/4,3/4,4/3,4/3,3/2,3/2,4/1,4/0,4/0,3/0,2/0,1/0,0/1,0/2,0/2,1/3,1/3,0/4,0`.split('/').map(Location.fromString))
    ,
//
// mS 7x5 unique
// Path: S:3 m:23  Entropy: 11.8
    Puzzle.fromString(`
01111
111m1
11111
11S11
11111
11111
11110
`)
        .setCanonicalSolutionWithWitness(`3,2/6,2/6,3/1,3/1,2/2,2/2,1/3,1/4,1/5,1/6,1/6,0/5,0/4,0/3,0/2,0/1,0/1,1/0,1/0,2/0,3/0,4/1,4/2,4/3,4/4,4/5,4`.split('/').map(Location.fromString))
        .setComment(
            `Cute solution`)
    ,
//
// mS 5x5 unique
// Path: m:14 S:6  Entropy: 11.2
    Puzzle.fromString(`
11111
111S1
11011
11110
m1111
`)
        .setCanonicalSolutionWithWitness(`4,0/4,1/4,2/3,2/3,1/3,0/2,0/2,1/1,1/1,0/0,0/0,1/0,2/1,2/1,3/0,3/0,4/2,4/2,3/4,3/4,4`.split('/').map(Location.fromString))
    ,
//
// mSS 5x5 unique
// Path: S:4 m:3 S:5  Entropy: 4.58
    Puzzle.fromString(`
1SS11
11111
m1111
11111
11111
`)
        .setCanonicalSolutionWithWitness(`0,2/0,4/4,4/4,0/2,0/1,0/0,0/0,1/3,1/3,3/1,3/1,2/2,2`.split('/').map(Location.fromString))
    ,
//
// mwS 5x5 unique
// Path: m:15 S:4  Entropy: 13.3
// Contraptions do 1 visits
    Puzzle.fromString(`
11111
11111
11w11
111m0
1S111
`)
        .setCanonicalSolutionWithWitness(`3,3/2,3/2,4/1,4/0,4/0,3/0,2/1,2/1,1/0,1/0,0/1,0/2,0/3,0/4,0/4,1/2,1/2,2/4,2/4,4`.split('/').map(Location.fromString))
    ,
//
// mwS 5x5 unique
// Path: m:10 S:6  Entropy: 9.75
// Contraptions do 3 visits
    Puzzle.fromString(`
11111
11S11
11w11
m1110
11111
`)
        .setCanonicalSolutionWithWitness(`3,0/4,0/4,1/3,1/2,1/2,0/1,0/0,0/0,1/1,1/1,2/0,2/0,4/2,4/2,2/4,2/4,3`.split('/').map(Location.fromString))
        .setComment(
            `tough`)
    ,
//
// mwS 5x5 unique
// Path: m:9 S:5  Entropy: 9.17
// Contraptions do 3 visits
    Puzzle.fromString(`
1S11m
11111
11w11
11110
11101
`)
        .setCanonicalSolutionWithWitness(`0,4/1,4/2,4/2,3/1,3/0,3/0,2/1,2/1,1/0,1/0,0/4,0/4,2/2,2/2,1`.split('/').map(Location.fromString))
        .setComment(
            `ok i guess`)
    ,
//
// Sd 5x5 unique
// Path: S:9 d:1  Entropy: 4.00
    Puzzle.fromString(`
11111
1dS11
11111
11111
11111
`)
        .setCanonicalSolutionWithWitness(`1,2/0,2/0,0/4,0/4,4/0,4/0,3/3,3/3,1/1,1/2,2`.split('/').map(Location.fromString))
        .setComment(
            `Easy`)
    ,
//
// mS 5x4 unique
// Path: m:5 S:5  Entropy: 9.34
    Puzzle.fromString(`
1011
1mS1
1111
1111
1111
`)
        .setCanonicalSolutionWithWitness(`1,1/2,1/3,1/3,2/2,2/1,2/0,2/0,3/4,3/4,0/0,0`.split('/').map(Location.fromString))
        .setComment(
            `easy`)
    ,
//
// LS 7x5 unique
// Path: S:5 L:6  Entropy: 6.00
    Puzzle.fromString(`
S1111
111L1
11111
11011
11111
11111
11111
`)
        .setCanonicalSolutionWithWitness(`0,0/0,4/6,4/6,0/1,0/1,3/5,3/5,1/4,2/4,1/2,1/2,2`.split('/').map(Location.fromString))
    ,
//
// LS 3x5 unique
// Path: L:8 S:2  Entropy: 9.91
    Puzzle.fromString(`
11L11
0S111
11111
`)
        .setCanonicalSolutionWithWitness(`0,2/0,4/2,4/1,3/2,2/2,3/0,1/0,0/1,1/2,1/2,0`.split('/').map(Location.fromString))
    ,
//
// LS 7x5 unique
// Path: L:9 S:5  Entropy: 12.6
    Puzzle.fromString(`
1S111
111L1
11111
11011
11111
11111
11111
`)
        .setCanonicalSolutionWithWitness(`1,3/6,3/3,0/6,0/6,2/5,1/4,2/2,0/0,0/0,1/2,1/2,2/0,2/0,4/6,4`.split('/').map(Location.fromString))
        .setComment(
            `unintuitive. Not sure I like the luge much`)
    ,
//
// Ld 4x4 unique
// Path: d:3 L:7  Entropy: 9.58
    Puzzle.fromString(`
L111
111d
1111
1111
`)
        .setCanonicalSolutionWithWitness(`1,3/0,2/1,1/0,0/0,1/1,0/3,0/3,3/2,3/2,1/0,3`.split('/').map(Location.fromString))
        .setComment(
            `not too bad`)
    ,
//
// DL 7x5 unique
// Path: L:12 D:4  Entropy: 17.8
    Puzzle.fromString(`
11111
11111
D1111
11011
1L111
11111
11111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`4,1/3,0/0,3/0,4/6,4/6,0/4,0/5,1/5,3/1,3/0,2/0,0/2,0/1,1/2,2/3,1/4,2`.split('/').map(Location.fromString))
        .setComment(
            `! diagonal skate is trash`)
    ,
//
// L 5x5 unique
// Path: L:9  Entropy: 6.00
    Puzzle.fromString(`
1L111
10101
11111
11101
11111
`)
        .setCanonicalSolutionWithWitness(`0,1/0,4/4,4/4,0/2,2/3,2/2,3/1,2/3,0/0,0`.split('/').map(Location.fromString))
        .setComment(
            `5/10`)
    ,
//
// Ld 5x4 unique
// Path: L:7 d:6  Entropy: 13.3
    Puzzle.fromString(`
0111
1111
L11d
1111
1111
`)
        .setCanonicalSolutionWithWitness(`2,0/0,2/1,3/4,0/3,0/4,1/4,3/2,3/3,2/2,1/1,0/0,1/1,2/0,3`.split('/').map(Location.fromString))
    ,
//
// Ld 5x4 unique
// Path: d:3 L:11  Entropy: 13.3
    Puzzle.fromString(`
1d11
1111
1111
L111
1101
`)
        .setCanonicalSolutionWithWitness(`0,1/1,2/2,1/3,0/4,0/4,1/3,1/2,0/0,0/3,3/4,3/3,2/2,3/0,3/0,2`.split('/').map(Location.fromString))
    ,
//
// mL 3x4 unique
// Path: m:3 L:5  Entropy: 7.17
    Puzzle.fromString(`
1m11
111L
1011
`)
        .setCanonicalSolutionWithWitness(`0,1/0,2/0,3/1,3/2,3/1,2/2,2/0,0/2,0`.split('/').map(Location.fromString))
    ,
//
// L 5x5 unique
// Path: L:12  Entropy: 10.7
    Puzzle.fromString(`
10111
10111
11111
11101
1L111
`)
        .setCanonicalSolutionWithWitness(`4,1/4,4/0,4/0,2/1,3/2,3/3,2/1,2/3,0/4,0/3,1/2,0/0,0`.split('/').map(Location.fromString))
    ,
//
// LS 7x5 unique
// Path: L:16 S:3  Entropy: 18.0
    Puzzle.fromString(`
11111
0S111
1L111
11111
11111
11111
11111
`)
        .setCanonicalSolutionWithWitness(`2,1/3,0/6,3/6,0/4,0/5,1/2,4/6,4/5,3/4,3/3,2/1,4/0,3/0,4/3,1/2,0/1,1/1,2/0,2/0,0`.split('/').map(Location.fromString))
    ,
//
// LS 7x5 unique
// Path: L:16 S:2  Entropy: 18.6
    Puzzle.fromString(`
11111
01111
11111
11111
11S11
111L1
11111
`)
        .setCanonicalSolutionWithWitness(`5,3/4,4/0,4/0,0/3,3/4,3/5,4/6,4/6,0/2,0/2,1/1,2/1,3/2,3/4,1/3,1/4,2/5,2/5,1`.split('/').map(Location.fromString))
    ,
]);