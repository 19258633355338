import React from "react";
import Utility from "../../utility";
import {SearchConfig} from "../../puzzle/solver";
import {doSearch, SearchProgress, SearchResults} from "../../puzzle/search";
import Constants from "../../constants";
import {letterGrid} from "../../css/typefaces/blockLettering";

import '../../css/lab/searchControls.css'

import ReactiveButton from 'reactive-button';

interface SearchControlsProps {
    registerSearchResult: (results: SearchResults) => void,
}

interface SearchControlsState {
    searchText: string,
    tokenText: string,
    defaultToken: string,
    insertionSquareSize: string,
    searchBoxReplaceFrom: string,
    searchBoxReplaceTo: string,
    excludeMultiSolutionPuzzles: boolean,
    excludeEndOnMovement: boolean,
    excludeBoringDiagonalParityRatio: boolean,
    omitReflectionsAndRotations: boolean,
    excludeUnusedMovementType: boolean,
    excludeBoomlessBombPuzzles: boolean,
    excludeBackTrackInSolution: boolean,
    excludeInteractingBombs: boolean,

    workerStatus: "WORKING" | "IDLE",
    searchProgress?: SearchProgress,
}

class SearchControls extends React.Component<SearchControlsProps, SearchControlsState> {
    constructor(props: SearchControlsProps) {
        super(props);
        this.state = {
            searchText: '....\n....\n....',
            defaultToken: '1',
            insertionSquareSize: '4',
            searchBoxReplaceFrom: '1',
            searchBoxReplaceTo: '.',
            excludeEndOnMovement: true,
            excludeMultiSolutionPuzzles: true,
            excludeBoringDiagonalParityRatio: false,
            excludeUnusedMovementType: false,
            excludeBoomlessBombPuzzles: true,
            excludeBackTrackInSolution: false,
            omitReflectionsAndRotations: true,
            excludeInteractingBombs: false,
            tokenText: "mdd",
            workerStatus: "IDLE",
        }

        this.searchPuzzles = this.searchPuzzles.bind(this);
        this.updateTextAreaHandler = this.updateTextAreaHandler.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    // This works when the underlying input has name="<name of state variable tracking the content>"
    private handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        // @ts-ignore
        this.setState({
            [name]: value
        });
    }

    private updateTextAreaHandler(event: React.ChangeEvent<HTMLTextAreaElement>) {
        const target = event.target;
        const newText = target.value;
        const name = target.name;
        // @ts-ignore
        this.setState({[name]: newText});
    }

    private async searchPuzzles() {
        const {
            excludeMultiSolutionPuzzles, excludeEndOnMovement, excludeBoringDiagonalParityRatio, defaultToken,
            omitReflectionsAndRotations, excludeUnusedMovementType, excludeBoomlessBombPuzzles,
            excludeBackTrackInSolution, excludeInteractingBombs
        } = this.state;

        // Some hacks for convenience!
        let searchText = this.state.searchText;
        if (searchText.length <= 2) {
            searchText = letterGrid(searchText) || searchText;
        }

        searchText = Utility.tryToCleanPuzzleDefinition(searchText);
        let tokenText = this.state.tokenText;
        if (tokenText === '*') tokenText = Constants.movementTypesThanCanSoloAPuzzle;

        const searchConfig: SearchConfig = {
            excludeMultiSolutionPuzzles: excludeMultiSolutionPuzzles,
            solverConfig: {
                excludeEndOnMovement: excludeEndOnMovement,
                excludeUnusedMovementType: excludeUnusedMovementType,
                excludeBoomlessBombPuzzles: excludeBoomlessBombPuzzles,
                excludeBackTrackInSolution: excludeBackTrackInSolution,
                maxSolutions: 2,
                returnEmptyOnAnySkip: true,
            },
            puzzleGeneratorProps: {
                template: searchText,
                tokensToInsert: tokenText.split(','),
                wildcard: ".",
                defaultToken: defaultToken,
                omitIsomorphic: omitReflectionsAndRotations,
                excludeBoringDiagonalParityRatio: excludeBoringDiagonalParityRatio,
                excludeInteractingBombs: excludeInteractingBombs,
            },
        }

        let handleProgress = (sp: SearchProgress) => {
            this.setState({searchProgress: sp});
        }

        this.setState({
            workerStatus: "WORKING",
            searchProgress: undefined,
        });
        let results = await doSearch(searchConfig, handleProgress);
        this.setState({workerStatus: "IDLE",});

        this.props.registerSearchResult(results);
    }

    render(): React.ReactNode {

        return <div className={'search-controls-panel--grid'}>
            <div>
                <h2 className={'panel-header'}>Search Controls</h2>
                <textarea value={this.state.searchText}
                          tabIndex={1}
                          className={"puzzle-lab__text-area puzzle-lab__text-area--puzzle-text search-controls__big-query-box"}
                          spellCheck={"false"}
                          name={"searchText"}
                          rows={7}
                          onChange={this.updateTextAreaHandler}/>

                {/* https://stackoverflow.com/questions/6205518/disable-textbox-suggestions */}
                <input type={"text"}
                       tabIndex={2}
                       name={"tokenText"}
                       autoComplete={'off'}
                       spellCheck={'false'}
                       className={"puzzle-lab__text-box text-box--medium"}
                       value={this.state.tokenText}
                       onChange={this.handleInputChange}
                       onKeyPress={e => {
                           if (e.code === "Enter" || e.code === 'NumpadEnter')
                               this.searchPuzzles();
                       }}/>
                <input type={"submit"}
                       className={'puzzle-lab__button'}
                       value={"Cancel"}
                       onClick={() => {
                           if (this.state.searchProgress?.cancelFn)
                               this.state.searchProgress.cancelFn();
                       }}
                       disabled={this.state.workerStatus === "IDLE"}/>

            </div>
            <div id={"searchBoxReplaceTool"}>
                <div className={'puzzle-lab__checkboxes'}>
                    <label className={'search-controls__label'}>
                        <input type="checkbox"
                               className={'puzzle-lab__checkbox'}
                               onChange={this.handleInputChange}
                               name="excludeEndOnMovement"
                               checked={this.state.excludeEndOnMovement}/>
                        No ending on a MovementType
                    </label>
                    <br/>
                    <label className={'search-controls__label'}>
                        <input type="checkbox"
                               className={'puzzle-lab__checkbox'}
                               onChange={this.handleInputChange}
                               name="excludeMultiSolutionPuzzles"
                               checked={this.state.excludeMultiSolutionPuzzles}/>
                        Exclude non-uniques
                    </label>
                    <br/>
                    <label className={'search-controls__label'}>
                        <input type="checkbox"
                               className={'puzzle-lab__checkbox'}
                               onChange={this.handleInputChange}
                               name="omitReflectionsAndRotations"
                               checked={this.state.omitReflectionsAndRotations}/>
                        Don't try isomorphic variants
                    </label>
                    <br/>
                    <label className={'search-controls__label'}>
                        <input type="checkbox"
                               className={'puzzle-lab__checkbox'}
                               onChange={this.handleInputChange}
                               name="excludeInteractingBombs"
                               checked={this.state.excludeInteractingBombs}/>
                        Exclude interacting bombs
                    </label>
                    <br/>
                    <label className={'search-controls__label'}>
                        <input type="checkbox"
                               className={'puzzle-lab__checkbox'}
                               onChange={this.handleInputChange}
                               name="excludeBoringDiagonalParityRatio"
                               checked={this.state.excludeBoringDiagonalParityRatio}/>
                        Need imbalanced diagonal parity
                    </label>
                    <br/>
                    <label className={'search-controls__label'}>
                        <input type="checkbox"
                               className={'puzzle-lab__checkbox'}
                               onChange={this.handleInputChange}
                               name="excludeUnusedMovementType"
                               checked={this.state.excludeUnusedMovementType}/>
                        Require all Movement Types used
                    </label>
                    <br/>
                    <label className={'search-controls__label'}>
                        <input type="checkbox"
                               className={'puzzle-lab__checkbox'}
                               onChange={this.handleInputChange}
                               name="excludeBoomlessBombPuzzles"
                               checked={this.state.excludeBoomlessBombPuzzles}/>
                        No boomless bombs
                    </label>
                    <br/>
                    <label className={'search-controls__label'}>
                        <input type="checkbox"
                               className={'puzzle-lab__checkbox'}
                               onChange={this.handleInputChange}
                               name="excludeBackTrackInSolution"
                               checked={this.state.excludeBackTrackInSolution}/>
                        No backtracks
                    </label>
                </div>
            </div>
            <div>
                <input type={"submit"}
                       className={'puzzle-lab__button'}
                       value={"Insert nxm"}
                       onClick={this.performBlankTemplateInsertion}
                />
                <input type={"text"}
                       className={'text-box text-box--tiny'}
                       name={"insertionSquareSize"}
                       spellCheck={"false"}
                       value={this.state.insertionSquareSize}
                       onFocus={e => e.currentTarget.select()}
                       onChange={this.handleInputChange}
                       onKeyPress={e => {
                           if (e.code === "Enter" || e.code === 'NumpadEnter')
                               this.performBlankTemplateInsertion();
                       }}
                />
                <br/>
                <label className={'search-controls__label'}>
                    Default token&nbsp;
                    <input type={"text"}
                           className={'text-box text-box--tiny'}
                           name={"defaultToken"}
                           spellCheck={"false"}
                           value={this.state.defaultToken}
                           onFocus={e => e.currentTarget.select()}
                           onChange={this.handleInputChange}/>
                </label>
                <br/>
                <input type={"text"}
                       name={"searchBoxReplaceFrom"}
                       spellCheck={"false"}
                       value={this.state.searchBoxReplaceFrom}
                       className={'text-box text-box--tiny'}
                       onFocus={e => e.currentTarget.select()}
                       onChange={this.handleInputChange}/>
                <input type={"text"}
                       name={"searchBoxReplaceTo"}
                       spellCheck={"false"}
                       value={this.state.searchBoxReplaceTo}
                       className={'text-box text-box--tiny'}
                       onFocus={e => e.currentTarget.select()}
                       onChange={this.handleInputChange}/>
                <input type={"submit"}
                       className={'puzzle-lab__button'}
                       value={"Swap"}
                       onClick={e => this.setState(prev => {
                           return {
                               searchText: prev.searchText
                                   .split(prev.searchBoxReplaceFrom)
                                   .join(prev.searchBoxReplaceTo)
                           }
                       })}/>
                <br/>
            </div>
            <div>
                {/*<p className={'info-text'}>*/}
                {/*    {this.state.searchProgress ?*/}
                {/*        `Total time: ${Utility.formatTime(this.state.searchProgress.timeSpentSec!)}` : ''}*/}
                {/*</p>*/}
                <p className={'info-text'}>
                    {this.state.searchProgress ?
                        'Found ' + this.state.searchProgress?.foundPuzzles : this.state.workerStatus}
                </p>
                <ReactiveButton
                    buttonRef={null}
                    buttonState={this.state.workerStatus === 'IDLE' ? 'idle' : 'loading'}
                    onClick={this.searchButtonClickHandler}
                    color={this.searchButtonColor()}
                    idleText={'Search'}
                    loadingText={this.searchButtonText()}
                    // successText={'Success'}
                    // errorText={'Error'}
                    className={'search-controls__progress-button'}
                    outline={false}
                    shadow={false}
                    rounded
                    size={'large'}
                    block={false}
                    width={'180px'}
                    height={null}
                    animation={true}
                />
            </div>
        </div>

    }

    private performBlankTemplateInsertion = (e: any = undefined)=> {
         this.setState(prev => {
            let n = +prev.insertionSquareSize;
            let m = n;
            if (n > 9) {
                [m, n] = [Math.min(n % 10, Math.floor(n / 10)), Math.max(n % 10, Math.floor(n / 10))];
            }
            return {
                searchText: ('.'.repeat(n) + '\n').repeat(m),
            }
        });
    }

    searchButtonText = () => {
        if (!this.state.searchProgress) return 'Initializing search';
        const elapsed = Utility.formatTime(this.state.searchProgress.timeSpentSec!);
        const totalTime = Utility.formatTime(this.state.searchProgress.naiveExpectedTimeRemainingSec!);
        return `${elapsed} / ${totalTime} left`
    }

    searchButtonColor: () => string = () => {
        const status = this.state.workerStatus;
        if (status === "IDLE") return 'green'
        if (status === "WORKING") {
            if (!this.state.searchProgress) return 'red';
            if (!this.state.searchProgress.foundPuzzles) return 'yellow';
            if (this.state.searchProgress.foundPuzzles > 0) return 'primary';
        }
        return 'primary';
    }

    searchButtonClickHandler = () => {
        const status = this.state.workerStatus;
        if (status === "IDLE") this.searchPuzzles();
        if (status === "WORKING" && this.state.searchProgress?.cancelFn)
            this.state.searchProgress.cancelFn();
    }

}

export default SearchControls;