import React from "react";

const development: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
const production: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'production';

export function isDev(): boolean {
    return development;
}

export function devLog(item: any) {
    if (isDev()) console.log(item);
}

export function isProd(): boolean {
    return production;
}

export function isApp(): boolean {
    return window.matchMedia('(display-mode: standalone)').matches;
}

export function isWeb(): boolean {
    return !window.matchMedia('(display-mode: standalone)').matches;
}



export function devOnly<T>(component: () => T): T | undefined {
    if (isDev()) return component();
    return undefined;
}

export function appOnly<T>(component: () => T): T | undefined {
    if (isApp()) return component();
    return undefined;
}

export function webOnly<T>(component: () => T): T | undefined {
    if (isWeb()) return component();
    return undefined;
}
