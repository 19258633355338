import React, {createRef} from "react";
import {World} from "../../puzzles/world";
import Puzzle from "../../puzzle/puzzle";
import Utility from "../../utility";

import '../../css/lab/worldBuilder.css'
import GetProgress from "../../progress/getProgress";

interface WorldBuilderProps {
    loadPuzzle: (puzzle: Puzzle) => void,
    currentDisplayedPuzzle: Puzzle,
    rowCount: number,
    worldToBuild: World,
    clearWorldFn: () => void,
    worldModFn: () => void,
}

interface WorldBuilderState {
    levelListSelection: number[] | undefined,
    lastBuiltWorldInitializer: string | null,
}

type LocalState = {
    levelListSelection: number[],
};

const doNothing = () => {
};

class WorldBuilder extends React.Component<WorldBuilderProps, WorldBuilderState> {
    private levelListSelectRef = createRef<HTMLSelectElement>();

    constructor(props: WorldBuilderProps) {
        super(props);
        this.state = {
            levelListSelection: undefined,
            lastBuiltWorldInitializer: localStorage.getItem("lastBuiltWorldInitializer"),
        }
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.copyPuzzles = this.copyPuzzles.bind(this);
    }

    keyBindings = new Map<string, (e: React.KeyboardEvent<HTMLSelectElement>) => void>([
        ['Enter', (e) => this.props.loadPuzzle(this.props.worldToBuild.puzzles[+e.currentTarget.value])],
        ['BracketLeft', (e) => this.shiftSelectedLevels(-1)],
        ['BracketRight', (e) => this.shiftSelectedLevels(1)],
        ['Backslash', (e) => {
            this.props.worldToBuild.puzzles[+e.currentTarget.value].toggleEnabled();
            this.props.worldModFn();
        }],
    ]);

    getStateFromLocalStorage(): LocalState | undefined {
        // let world = Utility.getFromStorage('WorldUnderConstruction', World.fromJSON);
        let levelListSelection = Utility.getFromStorage('WorldBuilder', j => j.levelListSelection);

        // Hack for old way we had this. deletable.
        if (typeof levelListSelection == 'number') levelListSelection = [levelListSelection];
        return {
            levelListSelection: levelListSelection,
        };
    }

    // React Life Cycle
    componentDidMount() {
        let v = this.getStateFromLocalStorage();
        if (v) this.setState({...v});
    }

    getSnapshotBeforeUpdate(nextProps: Readonly<WorldBuilderProps>, nextState: Readonly<WorldBuilderState>) {
        Utility.setInStorage('WorldBuilder', nextState);
        return null;
    }

    componentDidUpdate(prevProps: Readonly<WorldBuilderProps>, prevState: Readonly<WorldBuilderState>, snapshot?: any): void {
    }

    handleSelectChange(event: React.ChangeEvent<HTMLSelectElement>) {
        let options = event.target.options;
        let selectedOptions = [];

        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                selectedOptions.push(+options[i].value);
            }
        }

        this.setState({levelListSelection: selectedOptions}, this.props.worldModFn);

        // const target = event.target;
        // const value = target.value;
        // console.log(value)
        // if (target.name == 'levelListSelection')
        //     this.setState({
        //         levelListSelection: value,
        //     });
        // else {
        //     console.error("handleSelectChange fuckup")
        // }
    }

    copyPuzzles(useFancyAnnotation: boolean) {
        if (useFancyAnnotation) {

            // let puzInit = this.props.worldToBuild.puzzles.map(
            //     p => `${p.annotatedInitializationLines().join('\n')},`
            // ).join('\n');

            const worldInitLines = [
                `// ${this.props.worldToBuild.worldName} generated by WorldBuilder`,
                'import Puzzle from "../puzzle/puzzle";',
                'import {World} from "./world";',
                'import Location from "../puzzle/location";\n',
                '',
                `export let ${this.props.worldToBuild.worldName} = new World("${this.props.worldToBuild.worldName}", [`,
                // This is all of the puzzle initialization, flattened down right.
                ...this.props.worldToBuild.puzzles.map(puz => [...puz.annotatedInitializationLines(), ',']).flat(),
                ']);',
            ]

//             let worldInit = `// ${this.props.worldToBuild.worldName} generated by WorldBuilder
// import Puzzle from "../puzzle/puzzle";
// import {World} from "./world";
// import Location from "../puzzle/location";
//
// export let ${this.props.worldToBuild.worldName} = new World("${this.props.worldToBuild.worldName}", [
// ` + puzInit + '\n]);';

            const worldInit = worldInitLines.join('\n');

            this.setState({lastBuiltWorldInitializer: worldInit})
            Utility.setClipboard(worldInit);
        } else {
            const puzzles = this.props.worldToBuild.puzzles
                .map(p => Puzzle.initializationLines(p.toPuzzleSpec()).join('\n'))
                .join(",\n");
            Utility.setClipboard(puzzles);
        }
    }

    private shiftSelectedLevels(offset: number) {
        if (!this.state.levelListSelection || offset === 0) return;
        let moveOrder = this.state.levelListSelection.sort();
        if (offset > 0) moveOrder.reverse();
        let mods = moveOrder.map(loc => {
            return {
                from: loc,
                to: loc + offset,
            }
        })
        if (mods.some(mod => mod.to < 0 || mod.to >= this.props.worldToBuild.size)) {
            console.error("Can't move puzzle off the edge of the world.");
            return;
        }
        mods.forEach(mod => {
            // console.config(`Moving oldLocation, newLocation ${oldLocation} ${newLocation}`);
            this.props.worldToBuild.adjust(mod.from, mod.to);
        });
        this.setState({
            levelListSelection: mods.map(mod => mod.to),
        }, this.props.worldModFn);

    }

    render() {
        const {worldToBuild} = this.props;
        const levelListSelections = this.state.levelListSelection || [];
        const filteredWorld = worldToBuild.enabledPuzzles();
        const filteredPositionAnnotation = (p: Puzzle) => {
            let pos = filteredWorld.findIndex(pp => pp == p);
            return pos === -1 ? '' : `${pos + 1}: `;
        }
        const worldChangeNoteClasses = [
            'world-change-note',
            worldToBuild.sameAsWorldOnDisk() ? 'world-change-note--unchanged' : 'world-change-note--changed'
        ].join(' ');

        const levelListLevelClasses = (puz: Puzzle) => {
            const puzzleCommentText = GetProgress.getPuzzleCommentText(puz);
            const ret = [
                'level-list__level',
                puz.enabled ? 'level-list__level--enabled' : 'level-list__level--disabled',
                puzzleCommentText ?
                    'level-list__level--has-comment' :
                    'level-list__level--no-comment',
                ['//', '#'].some(commentSignal => puz.toString().includes(commentSignal)) ?
                    'level-list__level--definition-has-comment' :
                    'level-list__level--definition-has-no-comment',
            ];
            if (puz.toString() === this.props.currentDisplayedPuzzle.toString()) ret.push("level-list__level--active");
            if (puzzleCommentText?.includes('!')) ret.push('level-list__level--important-comment');

            return ret.join(' ');
        }

        return <div className={'world-builder'}>
            <h2 className={'panel-header'}>World Builder</h2>
            <p className={'info-text'}>{
                `${worldToBuild.worldName} World has ${Utility.optionalS(worldToBuild.enabledSize(), 'Level')} ` +
                `(${worldToBuild.size - worldToBuild.enabledSize()} disabled)`
            }</p>
            <p className={worldChangeNoteClasses}>
                {worldToBuild.sameAsWorldOnDisk() ? 'Unchanged' : 'Different from world in code'}
            </p>
            <select multiple={true}
                    ref={this.levelListSelectRef}
                    name={"levelListSelections"}
                    className={"level-list"}
                    value={levelListSelections.map(i => i.toString())}
                    size={this.props.rowCount}
                    onChange={this.handleSelectChange}
                    onKeyPress={(e) => {
                        let fn = this.keyBindings.get(e.code);
                        if (fn) {
                            e.preventDefault();
                            fn(e);
                        } else {
                            console.info(e.code)
                        }
                    }}>
                {worldToBuild.puzzles.map((puz: Puzzle, index: number) => {
                        return <option key={index}
                                       value={index}
                                       className={levelListLevelClasses(puz)}
                                       onDoubleClick={
                                           (e) => {
                                               this.props.loadPuzzle(worldToBuild.puzzles[+e.currentTarget.value]);
                                           }}>
                            {filteredPositionAnnotation(puz) + puz.shortAnnotation()}
                        </option>;
                    }
                )}
            </select>
            <br/>

            <input type={"submit"} value={"Insert Active"} onClick={() => {
                let newSelection = worldToBuild.insertPuzzle(
                    this.props.currentDisplayedPuzzle,
                    Math.max(...levelListSelections));
                // this.forceUpdate();
                this.props.worldModFn();
            }}/>
            <input type={"submit"}
                   className={'puzzle-lab__button'}
                   value={"Delete Selected"}
                   disabled={!levelListSelections || levelListSelections.length !== 1}
                   onClick={() => {
                       const singleSelection = levelListSelections[0];
                       worldToBuild.deleteLevel(singleSelection);
                       this.setState({
                           levelListSelection: singleSelection > 0 ? [singleSelection - 1] : [0],
                       })
                       this.props.worldModFn();
                   }}/>
            <br/>

            <input type={"submit"}
                   className={'puzzle-lab__button'}
                   value={"Copy World"}
                   onClick={e => this.copyPuzzles(true)}/>
            <input type={"submit"}
                   className={'puzzle-lab__button'}
                   value={"Clear"}
                   onClick={(e) => {
                       this.copyPuzzles(false);
                       if (this.props.clearWorldFn) this.props.clearWorldFn();
                       else console.error('You should really set clearWorldFn')
                       this.setState({
                           levelListSelection: undefined,
                       })
                       this.props.worldModFn();
                   }}/>
            <textarea
                className={'puzzle-lab__text-area'}
                value={this.state.lastBuiltWorldInitializer ? this.state.lastBuiltWorldInitializer : 'None made yet'}
                onClick={e => e.currentTarget.select()}
                // We can't edit this thing manually. This prevents an annoying js error from showing up in
                // the console continuously.
                onChange={doNothing}/>
            <br/>
            <p className={'info-text'}>Keys bound: {Array.from(this.keyBindings.keys()).join(", ")}</p>
        </div>
    }

    focusSelection(levelListSelection: number[]) {
        this.setState({levelListSelection: levelListSelection}, this.props.worldModFn);
        this.levelListSelectRef.current?.focus();
    }
}

export {WorldBuilder};