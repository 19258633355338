// liegeworld generated by WorldBuilder
import Puzzle from "../puzzle/puzzle";
import {World} from "./world";
import Location from "../puzzle/location";


export let liegeworld = new World("liegeworld", [
//
// l 4x3 unique
// Path: l:11  Entropy: 11.0
    Puzzle.fromString(`
111
1l1
111
111
`)
        .setCanonicalSolutionWithWitness(`1,1/2,2/3,1/3,2/2,1/1,2/0,2/0,1/0,0/1,0/2,0/3,0`.split('/').map(Location.fromString))
        .setComment(
            `nonunique`)
    ,
//
// l 3x4 unique
// Path: l:8  Entropy: 6.58
    Puzzle.fromString(`
0111
0011
11l1
`)
        .setCanonicalSolutionWithWitness(`2,2/2,3/1,3/0,3/0,2/0,1/1,2/2,1/2,0`.split('/').map(Location.fromString))
    ,
//
// l 5x3 unique
// Path: l:11  Entropy: 9.58
    Puzzle.fromString(`
111
110
11l
011
101
`)
        .setCanonicalSolutionWithWitness(`2,2/1,1/0,2/0,1/0,0/1,0/2,0/2,1/3,2/4,2/3,1/4,0`.split('/').map(Location.fromString))
    ,
//
// l 4x5 unique
// Path: l:14  Entropy: 12.7
    Puzzle.fromString(`
10101
l1111
11110
10101
`)
        .setCanonicalSolutionWithWitness(`1,0/0,0/1,1/2,0/3,0/2,1/3,2/2,2/1,2/0,2/1,3/0,4/1,4/2,3/3,4`.split('/').map(Location.fromString))
    ,
//
// ml 5x2 unique
// Path: l:4 m:3  Entropy: 5.00
    Puzzle.fromString(`
01
1l
m1
10
11
`)
        .setCanonicalSolutionWithWitness(`1,1/0,1/1,0/2,1/2,0/3,0/4,0/4,1`.split('/').map(Location.fromString))
    ,
//
// ml 4x3 unique
// Path: l:4 m:5  Entropy: 6.39
    Puzzle.fromString(`
10m
111
0l1
111
`)
        .setCanonicalSolutionWithWitness(`2,1/1,0/0,0/1,1/0,2/1,2/2,2/3,2/3,1/3,0`.split('/').map(Location.fromString))
    ,
//
// ld 5x3 unique
// Path: l:8 d:1  Entropy: 7.75
    Puzzle.fromString(`
001
l11
110
101
0d1
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`1,0/2,0/1,1/0,2/1,2/2,1/3,2/4,2/4,1/3,0`.split('/').map(Location.fromString))
    ,
//
// ld 5x3 unique
// Path: d:2 l:7  Entropy: 5.00
    Puzzle.fromString(`
001
110
l10
111
01d
`)
        .setCanonicalSolutionWithWitness(`4,2/3,1/2,0/3,0/4,1/3,2/2,1/1,0/1,1/0,2`.split('/').map(Location.fromString))
    ,
//
// l 4x6 unique
// Path: l:17  Entropy: 13.2
    Puzzle.fromString(`
110111
101101
110110
1l1101
`)
        .setCanonicalSolutionWithWitness(`3,1/3,0/2,0/1,0/0,0/0,1/1,2/2,1/3,2/3,3/2,3/1,3/0,3/0,4/0,5/1,5/2,4/3,5`.split('/').map(Location.fromString))
    ,
//
// lJ 4x3 unique
// Path: l:6 J:2  Entropy: 7.17
    Puzzle.fromString(`
111
11J
101
10l
`)
        .setCanonicalSolutionWithWitness(`3,2/2,2/1,1/0,0/0,1/0,2/1,2/1,0/3,0`.split('/').map(Location.fromString))
    ,
//
// lJ 3x5 unique
// Path: J:2 l:7  Entropy: 6.00
    Puzzle.fromString(`
J1111
11010
11l01
`)
        .setCanonicalSolutionWithWitness(`0,0/2,0/2,2/1,1/0,1/0,2/0,3/0,4/1,3/2,4`.split('/').map(Location.fromString))
    ,
//
// ld 4x4 unique
// Path: d:3 l:10  Entropy: 7.58
    Puzzle.fromString(`
1111
l111
001d
1111
`)
        .setCanonicalSolutionWithWitness(`2,3/1,2/0,1/1,0/0,0/1,1/0,2/0,3/1,3/2,2/3,3/3,2/3,1/3,0`.split('/').map(Location.fromString))
    ,
//
// ml 5x3 unique
// Path: m:6 l:6  Entropy: 9.17
    Puzzle.fromString(`
101
011
l1m
111
111
`)
        .setCanonicalSolutionWithWitness(`2,2/3,2/4,2/4,1/4,0/3,0/2,0/3,1/2,1/1,2/0,2/1,1/0,0`.split('/').map(Location.fromString))
    ,
//
// ml 4x5 unique
// Path: l:10 m:4  Entropy: 10.3
    Puzzle.fromString(`
10m01
11111
l1011
10101
`)
        .setCanonicalSolutionWithWitness(`2,0/3,0/2,1/3,2/2,3/3,4/2,4/1,4/0,4/1,3/0,2/1,2/1,1/1,0/0,0`.split('/').map(Location.fromString))
    ,
//
// ml 4x4 unique
// Path: m:3 l:9  Entropy: 7.32
    Puzzle.fromString(`
l101
1101
1011
m111
`)
        .setCanonicalSolutionWithWitness(`3,0/2,0/1,0/0,0/0,1/1,1/2,2/3,1/3,2/3,3/2,3/1,3/0,3`.split('/').map(Location.fromString))
    ,
//
// lJ 4x4 unique
// Path: l:10 J:2  Entropy: 12.9
    Puzzle.fromString(`
1101
1l10
1J01
1111
`)
        .setCanonicalSolutionWithWitness(`1,1/0,0/1,0/2,0/3,0/3,1/3,2/3,3/2,3/1,2/2,1/0,1/0,3`.split('/').map(Location.fromString))
    ,
//
// ld 4x4 unique
// Path: d:3 l:10  Entropy: 10.0
    Puzzle.fromString(`
111l
d111
1110
1101
`)
        .setCanonicalSolutionWithWitness(`1,0/2,1/1,2/0,3/1,3/0,2/0,1/0,0/1,1/2,0/3,0/3,1/2,2/3,3`.split('/').map(Location.fromString))
    ,
//
// lJ 7x4 unique
// Path: l:13 J:5  Entropy: 17.9
    Puzzle.fromString(`
1l11
1101
1101
0100
1100
1101
111J
`)
        .setCanonicalSolutionWithWitness(`0,1/0,2/1,1/0,0/1,0/2,0/3,1/4,0/5,0/6,0/5,1/6,2/5,3/6,3/6,1/4,1/2,1/2,3/0,3`.split('/').map(Location.fromString))
    ,
//
// lJ 5x5 unique
// Path: J:5 l:8  Entropy: 7.58
    Puzzle.fromString(`
J1111
11001
01111
01001
11l11
`)
        .setCanonicalSolutionWithWitness(`0,0/0,2/0,4/2,4/4,4/4,2/4,1/4,0/3,1/2,1/1,0/1,1/2,2/2,3`.split('/').map(Location.fromString))
    ,
//
// ldd 3x4 unique
// Path: d:3 l:6 d:2  Entropy: 9.17
    Puzzle.fromString(`
l111
111d
111d
`)
        .setCanonicalSolutionWithWitness(`1,3/0,2/1,1/0,0/0,1/1,0/2,0/2,1/2,2/2,3/1,2/0,3`.split('/').map(Location.fromString))
    ,
//
// ld 6x6 unique
// Path: l:8 d:8  Entropy: 9.58
    Puzzle.fromString(`
010l00
101010
010101
10d110
010101
100010
`)
        .setCanonicalSolutionWithWitness(`0,3/1,4/2,5/3,4/4,5/5,4/4,3/3,3/3,2/2,3/1,2/0,1/1,0/2,1/3,0/4,1/5,0`.split('/').map(Location.fromString))
    ,
//
// ld 6x6 unique
// Path: d:10 l:7  Entropy: 9.75
    Puzzle.fromString(`
001010
010101
001110
011l01
101010
01010d
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`5,5/4,4/3,5/2,4/1,5/0,4/1,3/0,2/1,1/2,2/3,3/2,3/3,2/3,1/4,0/5,1/4,2/5,3`.split('/').map(Location.fromString))
    ,
//
// ld 7x5 unique
// Path: d:7 l:11  Entropy: 11.2
    Puzzle.fromString(`
0d010
10101
01110
1101l
01110
00101
01010
`)
        .setCanonicalSolutionWithWitness(`0,1/1,0/2,1/1,2/0,3/1,4/2,3/3,4/3,3/2,2/3,1/3,0/4,1/4,2/4,3/5,4/6,3/5,2/6,1`.split('/').map(Location.fromString))
    ,
//
// ld 7x5 unique
// Path: l:11 d:7  Entropy: 16.6
    Puzzle.fromString(`
00010
l0101
01110
1111d
01110
10100
01010
`)
        .setCanonicalSolutionWithWitness(`1,0/2,1/3,0/3,1/4,2/3,3/2,2/1,2/0,3/1,4/2,3/3,4/4,3/3,2/4,1/5,0/6,1/5,2/6,3`.split('/').map(Location.fromString))
    ,
//
// ld 7x5 unique
// Path: l:9 d:9  Entropy: 11.8
    Puzzle.fromString(`
0d010
10101
01010
l1111
01100
10101
01010
`)
        .setCanonicalSolutionWithWitness(`3,0/3,1/4,2/3,3/3,4/2,3/1,4/0,3/1,2/0,1/1,0/2,1/3,2/4,1/5,0/6,1/5,2/6,3/5,4`.split('/').map(Location.fromString))
    ,
//
// ld 7x5 unique
// Path: d:7 l:11  Entropy: 9.17
    Puzzle.fromString(`
0d010
10101
01100
01111
01110
10l01
01010
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,1/1,0/2,1/3,2/4,1/5,0/6,1/5,2/6,3/5,4/4,3/3,4/3,3/4,2/3,1/2,2/1,2/0,3/1,4`.split('/').map(Location.fromString))
    ,
//
// ld 7x6 unique
// Path: l:6 d:14  Entropy: 13.0
    Puzzle.fromString(`
001010
010101
10d110
011101
101010
01010l
100010
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`5,5/6,4/5,3/4,2/3,2/2,3/2,2/3,3/4,4/3,5/2,4/1,5/0,4/1,3/0,2/1,1/2,0/3,1/4,0/5,1/6,0`.split('/').map(Location.fromString))
    ,
//
// ld 7x5 unique
// Path: d:10 l:7  Entropy: 9.17
    Puzzle.fromString(`
01010
d0101
01010
01111
01010
10l01
01010
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`1,0/0,1/1,2/0,3/1,4/2,3/3,4/4,3/5,4/6,3/5,2/6,1/5,0/4,1/3,1/2,1/3,2/3,3`.split('/').map(Location.fromString))
    ,
//
// ld 7x5 unique
// Path: l:5 d:13  Entropy: 14.5
    Puzzle.fromString(`
00010
10101
01010
1111l
0d110
10101
01010
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`3,4/3,3/4,2/3,1/3,0/4,1/5,0/6,1/5,2/6,3/5,4/4,3/3,2/2,3/1,4/0,3/1,2/2,1/1,0`.split('/').map(Location.fromString))
    ,
//
// mld 5x4 unique
// Path: m:7 d:5 l:4  Entropy: 9.17
    Puzzle.fromString(`
l111
110d
1011
m101
1111
`)
        .setCanonicalSolutionWithWitness(`3,0/4,0/4,1/4,2/4,3/3,3/2,3/1,3/2,2/3,1/2,0/1,1/0,0/1,0/0,1/0,2/0,3`.split('/').map(Location.fromString))
    ,
//
// mld 4x4 unique
// Path: m:10 l:2 d:2  Entropy: 10.8
    Puzzle.fromString(`
1m11
0111
1ld1
1111
`)
        .setCanonicalSolutionWithWitness(`0,1/0,2/0,3/1,3/2,3/3,3/3,2/3,1/3,0/2,0/2,1/1,2/2,2/1,1/0,0`.split('/').map(Location.fromString))
    ,
//
// mld 4x4 unique
// Path: m:2 l:8 d:3  Entropy: 14.4
    Puzzle.fromString(`
ld11
1111
m111
1001
`)
        .setCanonicalSolutionWithWitness(`2,0/1,0/0,0/1,1/2,2/3,3/2,3/1,3/0,3/0,2/0,1/1,2/2,1/3,0`.split('/').map(Location.fromString))
    ,
//
// mld 4x4 unique
// Path: l:8 d:3 m:3  Entropy: 15.7
    Puzzle.fromString(`
m111
11l0
1111
1d11
`)
        .setCanonicalSolutionWithWitness(`1,2/2,3/3,3/3,2/2,1/1,0/2,0/3,0/3,1/2,2/1,1/0,0/0,1/0,2/0,3`.split('/').map(Location.fromString))
    ,
//
// mld 4x4 unique
// Path: m:11 l:3 d:0  Entropy: 7.75
// Doesn't use end movementType
    Puzzle.fromString(`
1l11
1mod
1111
1111
`)
        .setCanonicalSolutionWithWitness(`1,1/2,1/2,2/2,3/3,3/3,2/3,1/3,0/2,0/1,0/0,0/0,1/0,2/0,3/1,3`.split('/').map(Location.fromString))
    ,
//
// ld 6x4 unique
// Path: l:14 d:4  Entropy: 14.8
    Puzzle.fromString(`
01l1
1001
111d
1111
1001
1111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,2/0,3/1,3/2,2/3,1/2,0/3,0/4,0/5,0/5,1/5,2/5,3/4,3/3,3/2,3/3,2/2,1/1,0/0,1`.split('/').map(Location.fromString))
    ,
//
// ml 5x4 unique
// Path: l:6 m:10  Entropy: 11.4
    Puzzle.fromString(`
1011
1m11
11l1
0011
1111
`)
        .setCanonicalSolutionWithWitness(`2,2/3,2/2,1/2,0/1,0/0,0/1,1/1,2/0,2/0,3/1,3/2,3/3,3/4,3/4,2/4,1/4,0`.split('/').map(Location.fromString))
    ,
//
// lw 4x4 unique
// Path: l:11  Entropy: 8.17
// Contraptions do 1 visits
    Puzzle.fromString(`
l111
1100
1111
1w10
`)
        .setCanonicalSolutionWithWitness(`0,0/1,1/2,2/2,3/3,2/3,1/3,0/2,1/1,0/0,1/0,2/0,3`.split('/').map(Location.fromString))
    ,
//
// lw 4x4 unique
// Path: l:9  Entropy: 5.17
// Contraptions do 4 visits
    Puzzle.fromString(`
1011
1l11
0w11
1111
`)
        .setCanonicalSolutionWithWitness(`1,1/0,0/1,0/2,1/3,1/2,2/3,3/2,3/1,3/0,2`.split('/').map(Location.fromString))
    ,
//
// leeww 3x5 unique
// Path: l:9  Entropy: 5.91
// Contraptions do 5 visits
    Puzzle.fromString(`
1lw11
1we11
11e11
`)
        .setCanonicalSolutionWithWitness(`0,1/0,2/0,3/0,4/1,4/2,3/1,2/2,1/1,0/0,0`.split('/').map(Location.fromString))
    ,
//
// leeww 3x5 unique
// Path: l:9  Entropy: 12.5
// Contraptions do 5 visits
    Puzzle.fromString(`
e1w11
11w1l
111e1
`)
        .setCanonicalSolutionWithWitness(`1,4/2,4/1,3/2,2/2,1/2,0/1,0/0,0/1,1/1,2`.split('/').map(Location.fromString))
    ,
//
// leeee 3x4 unique
// Path: l:6  Entropy: 6.91
// Contraptions do 5 visits
    Puzzle.fromString(`
ee11
1ee1
11l1
`)
        .setCanonicalSolutionWithWitness(`2,2/2,1/2,0/1,0/0,1/1,2/2,3`.split('/').map(Location.fromString))
    ,
//
// leeee 3x4 unique
// Path: l:8  Entropy: 11.2
// Contraptions do 3 visits
    Puzzle.fromString(`
e1e1
1le1
e111
`)
        .setCanonicalSolutionWithWitness(`1,1/2,1/2,2/2,3/1,3/0,3/1,2/0,1/0,0`.split('/').map(Location.fromString))
    ,
//
// lwww 4x4 unique
// Path: l:11  Entropy: 7.91
// Contraptions do 3 visits
    Puzzle.fromString(`
1lw1
1w10
111w
1111
`)
        .setCanonicalSolutionWithWitness(`0,1/0,2/0,3/1,2/2,3/3,3/2,2/3,1/3,0/2,1/1,0/0,0`.split('/').map(Location.fromString))
    ,
//
// lwwwww 4x4 unique
// Path: l:10  Entropy: 7.00
// Contraptions do 5 visits
    Puzzle.fromString(`
1ww1
1w11
wl11
1w11
`)
        .setCanonicalSolutionWithWitness(`2,1/3,0/3,1/3,2/3,3/2,3/1,2/0,3/0,2/0,1/0,0`.split('/').map(Location.fromString))
    ,
//
// lwwww 4x4 unique
// Path: l:9  Entropy: 9.34
// Contraptions do 5 visits
    Puzzle.fromString(`
l1w1
11w1
1101
w1w1
`)
        .setCanonicalSolutionWithWitness(`0,0/1,1/0,2/0,1/1,0/2,0/3,0/3,1/3,2/3,3`.split('/').map(Location.fromString))
    ,
//
// lwwwwwww 4x4 unique
// Path: l:11  Entropy: 9.58
// Contraptions do 4 visits
    Puzzle.fromString(`
1ww1
wl1w
111w
1ww1
`)
        .setCanonicalSolutionWithWitness(`1,1/0,2/0,3/1,2/2,3/3,3/2,2/3,1/3,0/2,1/1,0/0,0`.split('/').map(Location.fromString))
    ,
//
// lwwwwww 4x4 unique
// Path: l:9  Entropy: 10.3
// Contraptions do 6 visits
    Puzzle.fromString(`
ww11
1w11
1lww
1w11
`)
        .setCanonicalSolutionWithWitness(`2,1/3,0/2,0/1,0/0,1/0,2/0,3/1,3/2,2/3,2`.split('/').map(Location.fromString))
    ,
//
// leeee 3x4 unique
// Path: l:8  Entropy: 11.2
// Contraptions do 3 visits
    Puzzle.fromString(`
e1e1
1le1
e111
`)
        .setCanonicalSolutionWithWitness(`1,1/2,1/2,2/2,3/1,3/0,3/1,2/0,1/0,0`.split('/').map(Location.fromString))
    ,
//
// le 4x4 unique
// Path: l:10  Entropy: 7.00
// Contraptions do 2 visits
    Puzzle.fromString(`
11l1
1110
0e01
1111
`)
        .setCanonicalSolutionWithWitness(`0,2/0,3/1,2/2,3/3,3/3,2/3,1/3,0/2,1/1,0/0,0`.split('/').map(Location.fromString))
    ,
//
// mle 4x4 unique
// Path: m:10  Entropy: 6.00
// Contraptions do 3 visits
    Puzzle.fromString(`
111e
1111
110l
m101
`)
        .setCanonicalSolutionWithWitness(`3,0/3,1/2,1/2,0/1,0/0,0/0,1/1,1/1,2/0,2/0,3`.split('/').map(Location.fromString))
    ,
//
// leeee 3x4 unique
// Path: l:6  Entropy: 6.91
// Contraptions do 5 visits
    Puzzle.fromString(`
ee11
1ee1
11l1
`)
        .setCanonicalSolutionWithWitness(`2,2/2,1/2,0/1,0/0,1/1,2/2,3`.split('/').map(Location.fromString))
    ,
//
// le 3x4 unique
// Path: l:8  Entropy: 6.32
// Contraptions do 1 visits
    Puzzle.fromString(`
1l11
01e0
1111
`)
        .setCanonicalSolutionWithWitness(`0,1/0,0/1,1/2,0/2,1/2,2/2,3/1,2/0,3`.split('/').map(Location.fromString))
    ,
//
// mle 4x4 unique
// Path: m:6 l:5  Entropy: 5.00
// Contraptions do 2 visits
    Puzzle.fromString(`
le11
1m11
1101
1101
`)
        .setCanonicalSolutionWithWitness(`1,1/2,1/3,1/3,0/2,0/1,0/0,0/0,1/1,2/1,3/2,3/3,3`.split('/').map(Location.fromString))
    ,
//
// leeww 3x5 unique
// Path: l:9  Entropy: 12.5
// Contraptions do 5 visits
    Puzzle.fromString(`
e1w11
11w1l
111e1
`)
        .setCanonicalSolutionWithWitness(`1,4/2,4/1,3/2,2/2,1/2,0/1,0/0,0/1,1/1,2`.split('/').map(Location.fromString))
    ,
//
// leeww 3x5 unique
// Path: l:9  Entropy: 5.91
// Contraptions do 5 visits
    Puzzle.fromString(`
1lw11
1we11
11e11
`)
        .setCanonicalSolutionWithWitness(`0,1/0,2/0,3/0,4/1,4/2,3/1,2/2,1/1,0/0,0`.split('/').map(Location.fromString))
    ,
]);