// multitap_world generated by WorldBuilder
import Puzzle from "../puzzle/puzzle";
import {World} from "./world";
import Location from "../puzzle/location";

export let multitap_world = new World("multitap_world", [
//
// m 4x1 unique
// Path: m:4  Entropy: 0.00
    Puzzle.fromString(`
m
1
2
1
`)
        .setCanonicalSolutionWithWitness(`0,0/1,0/2,0/3,0/2,0`.split('/').map(Location.fromString)),
//
// m 4x4 unique
// Path: m:10  Entropy: 2.58
    Puzzle.fromString(`
1110
1010
1121
00m0
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`3,2/2,2/2,3/2,2/2,1/2,0/1,0/0,0/0,1/0,2/1,2`.split('/').map(Location.fromString)),
//
// d 5x5 unique
// Path: d:16  Entropy: 7.58
    Puzzle.fromString(`
10101
02020
10d01
02020
10101
`)
        .setCanonicalSolutionWithWitness(`2,2/3,3/4,4/3,3/2,4/1,3/0,4/1,3/0,2/1,1/0,0/1,1/2,0/3,1/4,2/3,1/4,0`.split('/').map(Location.fromString)),
//
// m 6x5 unique
// Path: m:20  Entropy: 9.34
    Puzzle.fromString(`
0m100
11110
11111
11121
01110
00100
`)
        .setCanonicalSolutionWithWitness(`0,1/0,2/1,2/1,3/2,3/2,4/3,4/3,3/4,3/3,3/3,2/2,2/2,1/1,1/1,0/2,0/3,0/3,1/4,1/4,2/5,2`.split('/').map(Location.fromString)),
//
// d 8x6 unique
// Path: d:23  Entropy: 10.5
    Puzzle.fromString(`
01010d
102010
010101
101010
010101
101010
010101
100010
`)
        .setCanonicalSolutionWithWitness(`0,5/1,4/2,5/3,4/4,5/5,4/6,5/7,4/6,3/5,2/4,3/3,2/2,3/1,2/0,3/1,2/0,1/1,0/2,1/3,0/4,1/5,0/6,1/7,0`.split('/').map(Location.fromString)),
//
// m 4x4 unique
// Path: m:15  Entropy: 7.58
    Puzzle.fromString(`
m112
1211
1111
0110
`)
        .setCanonicalSolutionWithWitness(`0,0/0,1/1,1/1,2/1,1/1,0/2,0/2,1/3,1/3,2/2,2/2,3/1,3/0,3/0,2/0,3`.split('/').map(Location.fromString)),
//
// m 3x3 unique
// Path: m:10  Entropy: 4.58
    Puzzle.fromString(`
m21
111
112
`)
        .setCanonicalSolutionWithWitness(`0,0/0,1/0,2/0,1/1,1/1,0/2,0/2,1/2,2/1,2/2,2`.split('/').map(Location.fromString)),
//
// m 4x3 unique
// Path: m:14  Entropy: 7.17
    Puzzle.fromString(`
211
112
211
1m1
`)
        .setCanonicalSolutionWithWitness(`3,1/3,2/2,2/1,2/0,2/1,2/1,1/2,1/2,0/3,0/2,0/1,0/0,0/0,1/0,0`.split('/').map(Location.fromString)),
//
// d 8x6 unique
// Path: d:23  Entropy: 11.3
    Puzzle.fromString(`
01010d
101010
010100
101010
020101
101010
010101
101010
`)
        .setCanonicalSolutionWithWitness(`0,5/1,4/0,3/1,2/0,1/1,0/2,1/3,0/4,1/5,0/4,1/5,2/4,3/3,2/2,3/3,4/4,5/5,4/6,5/7,4/6,3/7,2/6,1/7,0`.split('/').map(Location.fromString)),
//
// md 4x4 unique
// Path: m:11 d:3  Entropy: 10.2
    Puzzle.fromString(`
0d11
1121
11m1
1110
`)
        .setCanonicalSolutionWithWitness(`2,2/2,1/3,1/3,0/2,0/1,0/1,1/1,2/1,3/0,3/0,2/0,1/1,2/2,3/3,2`.split('/').map(Location.fromString)),
//
// md 4x4 unique
// Path: m:11 d:4  Entropy: 12.3
    Puzzle.fromString(`
0d12
1m11
1211
1110
`)
        .setCanonicalSolutionWithWitness(`1,1/2,1/2,0/3,0/3,1/3,2/2,2/2,3/1,3/0,3/0,2/0,1/1,0/2,1/1,2/0,3`.split('/').map(Location.fromString)),
//
// md 4x4 unique
// Path: m:11 d:4  Entropy: 10.8
    Puzzle.fromString(`
0212
1111
md11
1110
`)
        .setCanonicalSolutionWithWitness(`2,0/3,0/3,1/3,2/2,2/2,3/1,3/0,3/0,2/0,1/1,1/2,1/1,0/0,1/1,2/0,3`.split('/').map(Location.fromString)),
//
// md 4x4 unique
// Path: m:11 d:4  Entropy: 11.6
    Puzzle.fromString(`
0d11
2121
11m1
1110
`)
        .setCanonicalSolutionWithWitness(`2,2/3,2/3,1/3,0/2,0/1,0/1,1/1,2/1,3/0,3/0,2/0,1/1,0/2,1/1,2/2,3`.split('/').map(Location.fromString)),
//
// md 4x4 unique
// Path: m:11 d:5  Entropy: 13.9
    Puzzle.fromString(`
0d11
112m
1211
2110
`)
        .setCanonicalSolutionWithWitness(`1,3/0,3/0,2/1,2/2,2/2,1/3,1/3,0/2,0/1,0/1,1/0,1/1,2/2,3/3,2/2,1/3,0`.split('/').map(Location.fromString)),
//
// md 4x4 unique
// Path: m:11 d:6  Entropy: 13.8
    Puzzle.fromString(`
0d12
1121
12m1
1120
`)
        .setCanonicalSolutionWithWitness(`2,2/3,2/3,1/3,0/2,0/2,1/1,1/1,2/1,3/0,3/0,2/0,1/1,0/2,1/3,2/2,3/1,2/0,3`.split('/').map(Location.fromString)),
//
// md 4x4 unique
// Path: m:12 d:6  Entropy: 14.2
    Puzzle.fromString(`
0d12
2111
1212
m120
`),
//
// m 5x3 unique
// Path: m:18  Entropy: 10.2
    Puzzle.fromString(`
m21
111
112
211
112
`)
        .setCanonicalSolutionWithWitness(`0,0/0,1/0,2/0,1/1,1/1,0/2,0/3,0/4,0/3,0/3,1/2,1/2,2/1,2/2,2/3,2/4,2/4,1/4,2`.split('/').map(Location.fromString)),
//
// md 5x5 unique
// Path: d:5 m:8  Entropy: 6.75
    Puzzle.fromString(`
m1100
011d0
00311
00011
00001
`)
        .setCanonicalSolutionWithWitness(`1,3/2,2/3,3/2,2/1,1/0,0/0,1/0,2/1,2/2,2/2,3/2,4/3,4/4,4`.split('/').map(Location.fromString)),
//
// md 4x4 unique
// Path: m:15 d:2  Entropy: 11.2
    Puzzle.fromString(`
21dm
1112
1111
1111
`)
        .setCanonicalSolutionWithWitness(`0,3/1,3/1,2/1,3/2,3/3,3/3,2/2,2/2,1/3,1/3,0/2,0/1,0/0,0/0,1/0,2/1,1/0,0`.split('/').map(Location.fromString))
        .setComment(
            `//
// PUZZLES
//`),
//
// md 5x3 unique
// Path: m:13 d:3  Entropy: 12.8
    Puzzle.fromString(`
11d
m21
111
121
111
`)
        .setCanonicalSolutionWithWitness(`1,0/0,0/0,1/1,1/2,1/3,1/3,0/4,0/4,1/4,2/3,2/2,2/1,2/0,2/1,1/2,0/3,1`.split('/').map(Location.fromString)),
//
// mJ 5x5 unique
// Path: m:21 J:4  Entropy: 18.3
    Puzzle.fromString(`
J1112
11111
11111
11m21
11111
`)
        .setCanonicalSolutionWithWitness(`3,2/3,3/2,3/3,3/3,4/4,4/4,3/4,2/4,1/4,0/3,0/3,1/2,1/1,1/1,2/1,3/1,4/0,4/0,3/0,2/0,1/0,0/2,0/2,2/2,4/0,4`.split('/').map(Location.fromString)),
//
// md 5x4 unique
// Path: m:17 d:3  Entropy: 13.6
    Puzzle.fromString(`
m111
2111
110d
1121
1111
`)
        .setCanonicalSolutionWithWitness(`0,0/0,1/0,2/0,3/1,3/1,2/1,1/1,0/2,0/3,0/4,0/4,1/3,1/3,2/4,2/4,3/3,3/2,3/3,2/2,1/1,0`.split('/').map(Location.fromString)),
//
// mdd 4x3 unique
// Path: d:4 m:11 d:5  Entropy: 13.5
    Puzzle.fromString(`
22d
m22
222
d22
`)
        .setCanonicalSolutionWithWitness(`3,0/2,1/3,2/2,1/1,0/0,0/0,1/0,0/0,1/1,1/1,2/2,2/3,2/2,2/1,2/0,2/1,1/2,0/3,1/2,0/3,1`.split('/').map(Location.fromString)),
//
// mdd 5x4 unique
// Path: d:4 m:11 d:5  Entropy: 13.5
    Puzzle.fromString(`
d111
11d1
m111
1111
1112
`)
        .setCanonicalSolutionWithWitness(`0,0/1,1/2,2/3,1/2,0/3,0/4,0/4,1/4,2/4,3/3,3/2,3/1,3/0,3/0,2/1,2/0,1/1,0/2,1/3,2/4,3`.split('/').map(Location.fromString)),
//
// mmd 3x4 unique
// Path: m:9 d:3 m:1  Entropy: 8.17
    Puzzle.fromString(`
1mm1
d121
1211
`)
        .setCanonicalSolutionWithWitness(`0,2/0,3/1,3/2,3/2,2/1,2/1,1/2,1/2,0/1,0/2,1/1,2/0,1/0,0`.split('/').map(Location.fromString)),
//
// mmd 3x4 unique
// Path: m:9 d:3 m:1  Entropy: 9.75
    Puzzle.fromString(`
1m11
dm21
1211
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`1,1/1,2/0,2/0,3/1,3/2,3/2,2/2,1/2,0/1,0/2,1/1,2/0,1/0,0`.split('/').map(Location.fromString)),
//
// mdd 7x3 unique
// Path: d:6 m:13 d:1  Entropy: 9.75
    Puzzle.fromString(`
111
111
11m
030
111
111
dd1
`)
        .setCanonicalSolutionWithWitness(`6,0/5,1/4,0/3,1/2,0/3,1/2,2/1,2/0,2/0,1/0,0/1,0/1,1/2,1/3,1/4,1/4,2/5,2/6,2/6,1/5,0`.split('/').map(Location.fromString)),
//
// mdd 5x4 unique
// Path: d:6 m:9 d:6  Entropy: 15.3
    Puzzle.fromString(`
d111
1111
m11d
1221
1111
`)
        .setCanonicalSolutionWithWitness(`0,0/1,1/0,2/1,3/2,2/3,1/2,0/3,0/4,0/4,1/3,1/3,2/4,2/4,3/3,3/2,3/3,2/2,1/1,0/0,1/1,2/0,3`.split('/').map(Location.fromString)),
//
// mmdd 5x3 unique
// Path: d:3 m:5 d:3 m:4  Entropy: 7.17
    Puzzle.fromString(`
1dd
111
121
mm1
111
`)
        .setCanonicalSolutionWithWitness(`0,2/1,1/2,2/3,1/2,1/2,0/1,0/0,0/0,1/1,2/2,1/3,0/4,0/4,1/4,2/3,2`.split('/').map(Location.fromString)),
//
// mmd 4x4 unique
// Path: m:13 d:3 m:1  Entropy: 10.8
    Puzzle.fromString(`
1m11
d121
1211
1m11
`)
        .setCanonicalSolutionWithWitness(`3,1/3,0/2,0/2,1/2,2/3,2/3,3/2,3/1,3/0,3/0,2/1,2/1,1/1,0/2,1/1,2/0,1/0,0`.split('/').map(Location.fromString)),
//
// mdd 5x5 unique
// Path: d:6 m:3 d:10  Entropy: 11.2
    Puzzle.fromString(`
01110
1md11
01210
1d111
01110
`)
        .setCanonicalSolutionWithWitness(`3,1/4,2/3,3/2,2/1,3/2,2/1,1/0,1/0,2/1,2/0,3/1,4/2,3/3,4/4,3/3,2/4,1/3,0/2,1/1,0`.split('/').map(Location.fromString)),
//
// mdd 5x5 unique
// Path: d:4 m:15 d:9  Entropy: 22.9
    Puzzle.fromString(`
dm111
12121
111d1
12121
11111
`)
        .setCanonicalSolutionWithWitness(`2,3/1,4/0,3/1,2/0,1/1,1/2,1/3,1/3,2/3,3/3,4/4,4/4,3/4,2/4,1/4,0/3,0/2,0/1,0/0,0/1,1/0,2/1,3/0,4/1,3/2,4/3,3/2,2/3,1`.split('/').map(Location.fromString)),
//
// mdd 5x5 unique
// Path: d:7 m:9 d:12  Entropy: 17.3
    Puzzle.fromString(`
d11m1
12121
11111
d2121
11111
`)
        .setCanonicalSolutionWithWitness(`3,0/4,1/3,2/4,3/3,4/2,3/1,4/0,3/1,3/1,2/0,2/0,1/1,1/2,1/2,0/1,0/0,0/1,1/2,2/3,1/4,0/3,1/4,2/3,3/4,4/3,3/2,4/1,3/0,4`.split('/').map(Location.fromString)),
//
// mdd 4x4 unique
// Path: d:3 m:21 d:4  Entropy: 21.1
    Puzzle.fromString(`
m222
222d
2222
22d2
`)
        .setCanonicalSolutionWithWitness(`1,3/2,2/1,1/0,0/0,1/0,2/0,3/0,2/0,1/1,1/1,0/2,0/1,0/2,0/3,0/3,1/3,0/3,1/2,1/2,2/2,3/3,3/2,3/3,3/3,2/2,1/1,2/0,3/1,2`.split('/').map(Location.fromString)),
//
// mdd 4x4 unique
// Path: d:2 m:23 d:3  Entropy: 22.7
    Puzzle.fromString(`
2d22
2m22
2222
2d22
`)
        .setCanonicalSolutionWithWitness(`3,1/2,2/1,1/1,2/0,2/0,3/0,2/0,3/1,3/2,3/1,3/2,3/3,3/3,2/3,3/3,2/2,2/2,1/2,0/3,0/2,0/1,0/0,0/1,0/0,0/0,1/1,2/2,1/3,0`.split('/').map(Location.fromString)),
//
// meeed 5x5 unique
// Path: m:13 d:3  Entropy: 11.2
// Contraptions do 5 visits
    Puzzle.fromString(`
0d110
11e11
1e111
1m1e2
01110
`)
        .setCanonicalSolutionWithWitness(`3,1/4,1/4,2/3,2/2,2/2,3/3,3/3,4/2,4/1,4/1,3/0,3/0,2/0,1/1,2/2,1/3,0`.split('/').map(Location.fromString)),
//
// mmdd 6x6 unique
// Path: d:8 m:1 d:11 m:4  Entropy: 11.2
    Puzzle.fromString(`
d10m11
111011
011101
012100
m11110
d00100
`)
        .setCanonicalSolutionWithWitness(`0,0/1,1/2,2/3,3/4,4/5,3/4,2/3,1/4,0/5,0/4,1/3,2/4,3/3,2/2,1/1,0/0,1/1,2/2,3/1,4/0,3/0,4/0,5/1,5/2,5`.split('/').map(Location.fromString))
        .setComment(
            `// laura 2017-06-04`),
//
// md 4x4 unique
// Path: m:15 d:0  Entropy: 7.58
// Doesn't use end movementType
    Puzzle.fromString(`
d11m
1101
1112
1111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,3/0,2/0,1/1,1/2,1/2,2/2,3/1,3/2,3/3,3/3,2/3,1/3,0/2,0/1,0/0,0`.split('/').map(Location.fromString)),
//
// mdd 4x5 unique
// Path: d:4 m:15 d:1  Entropy: 12.3
    Puzzle.fromString(`
1d111
1d311
110m1
11111
`)
        .setCanonicalSolutionWithWitness(`0,1/1,2/2,1/1,2/2,3/1,3/1,2/0,2/0,3/0,4/1,4/2,4/3,4/3,3/3,2/3,1/3,0/2,0/1,0/1,1/0,0`.split('/').map(Location.fromString)),
//
// mdd 5x5 unique
// Path: d:6 m:17 d:1  Entropy: 13.8
    Puzzle.fromString(`
dd111
11111
1130m
11011
11111
`)
        .setCanonicalSolutionWithWitness(`0,0/1,1/2,2/3,1/2,2/3,3/2,4/3,4/4,4/4,3/4,2/4,1/4,0/3,0/2,0/2,1/2,2/1,2/1,3/1,4/0,4/0,3/0,2/0,1/1,0`.split('/').map(Location.fromString)),
//
// mdd 5x5 unique
// Path: d:7 m:13 d:3  Entropy: 10.3
    Puzzle.fromString(`
d11d1
11111
11201
1m011
11111
`)
        .setCanonicalSolutionWithWitness(`0,0/1,1/0,2/1,3/2,2/3,3/2,2/3,1/2,1/2,0/3,0/4,0/4,1/4,2/4,3/4,4/3,4/2,4/1,4/0,4/0,3/1,2/0,1/1,0`.split('/').map(Location.fromString)),
//
// mdd 3x4 unique
// Path: d:1 m:9 d:3  Entropy: 9.17
    Puzzle.fromString(`
1d11
m11d
1131
`)
        .setCanonicalSolutionWithWitness(`0,1/1,0/2,0/2,1/2,2/2,3/2,2/1,2/0,2/0,3/1,3/2,2/1,1/0,0`.split('/').map(Location.fromString)),
//
// mdd 5x5 unique
// Path: d:5 m:19 d:0  Entropy: 14.2
// Doesn't use end movementType
    Puzzle.fromString(`
1d111
1d111
11301
11011
11m11
`)
        .setCanonicalSolutionWithWitness(`1,1/2,2/1,3/2,2/3,3/4,2/4,3/4,4/3,4/2,4/1,4/0,4/0,3/0,2/1,2/2,2/2,1/3,1/4,1/4,0/3,0/2,0/1,0/0,0/0,1`.split('/').map(Location.fromString)),
//
// mdd 5x5 unique
// Path: d:1 m:17 d:5  Entropy: 14.3
    Puzzle.fromString(`
1d111
m11d1
11201
11011
11111
`)
        .setCanonicalSolutionWithWitness(`0,1/1,0/2,0/2,1/3,1/3,0/4,0/4,1/4,2/4,3/4,4/3,4/2,4/1,4/0,4/0,3/0,2/1,2/1,3/2,2/3,3/2,2/1,1/0,0`.split('/').map(Location.fromString)),
//
// mdd 5x5 unique
// Path: d:5 m:19 d:0  Entropy: 14.8
// Doesn't use end movementType
    Puzzle.fromString(`
1d111
111d1
m1301
11011
11111
`)
        .setCanonicalSolutionWithWitness(`1,3/2,2/3,3/2,2/3,1/2,0/3,0/4,0/4,1/4,2/4,3/4,4/3,4/2,4/1,4/0,4/0,3/0,2/1,2/2,2/2,1/1,1/1,0/0,0/0,1`.split('/').map(Location.fromString)),
//
// mdd 5x5 unique
// Path: d:1 m:17 d:6  Entropy: 15.2
    Puzzle.fromString(`
1d111
m1111
11301
11011
11d11
`)
        .setCanonicalSolutionWithWitness(`0,1/1,0/2,0/3,0/4,0/4,1/3,1/2,1/2,2/1,2/0,2/0,3/0,4/1,4/2,4/3,4/4,4/4,3/4,2/3,3/2,2/1,3/2,2/1,1/0,0`.split('/').map(Location.fromString)),
//
// mdd 5x4 unique
// Path: d:3 m:13 d:1  Entropy: 9.34
    Puzzle.fromString(`
1110
1d11
1011
1111
11md
`)
        .setCanonicalSolutionWithWitness(`1,1/2,2/3,1/4,2/4,1/4,0/3,0/2,0/1,0/0,0/0,1/0,2/1,2/1,3/2,3/3,3/4,3/3,2`.split('/').map(Location.fromString)),
//
// mdd 5x5 unique
// Path: d:4 m:15 d:4  Entropy: 14.9
    Puzzle.fromString(`
01111
11d11
11d11
13101
m1110
`)
        .setCanonicalSolutionWithWitness(`2,2/3,1/4,2/3,1/4,0/4,1/3,1/3,0/2,0/1,0/1,1/0,1/0,2/0,3/0,4/1,4/2,4/2,3/1,3/1,2/2,1/3,2/4,3/3,4`.split('/').map(Location.fromString)),
//
// mdd 5x5 unique
// Path: d:4 m:11 d:10  Entropy: 16.3
    Puzzle.fromString(`
d0111
13m11
11111
1112d
11101
`)
        .setCanonicalSolutionWithWitness(`3,4/2,3/1,4/0,3/1,2/1,1/2,1/3,1/3,2/4,2/4,1/4,0/3,0/2,0/1,0/0,0/1,1/0,2/1,1/2,2/3,3/4,4/3,3/2,4/1,3/0,4`.split('/').map(Location.fromString)),
//
// mdd 6x4 unique
// Path: d:8 m:9 d:9  Entropy: 15.9
    Puzzle.fromString(`
1111
121d
1111
11d1
1311
1m11
`)
        .setCanonicalSolutionWithWitness(`1,3/0,2/1,1/0,0/1,1/2,0/3,1/4,0/5,1/4,1/4,2/5,2/5,3/4,3/3,3/2,3/2,2/3,2/4,1/5,0/4,1/3,0/2,1/1,0/0,1/1,2/0,3`.split('/').map(Location.fromString)),
//
// mdd 7x6 unique
// Path: d:5 m:23 d:5  Entropy: 14.2
    Puzzle.fromString(`
011110
111111
101101
101211
d0110m
d11111
011110
`)
        .setCanonicalSolutionWithWitness(`5,0/6,1/5,2/6,3/5,4/4,5/3,5/2,5/1,5/1,4/0,4/0,3/1,3/2,3/3,3/3,4/3,3/4,3/4,2/3,2/2,2/1,2/0,2/0,1/1,1/1,0/2,0/3,0/4,0/5,1/6,2/5,3/6,4/5,5`.split('/').map(Location.fromString)),
//
// mdd 7x6 unique
// Path: d:3 m:29 d:2  Entropy: 14.9
    Puzzle.fromString(`
011110
111111
10m101
10d311
101101
111d11
011110
`)
        .setCanonicalSolutionWithWitness(`5,3/4,2/3,3/2,2/1,2/0,2/0,1/1,1/1,0/2,0/3,0/4,0/5,0/5,1/6,1/6,2/6,3/6,4/5,4/5,5/4,5/3,5/2,5/1,5/1,4/0,4/0,3/1,3/2,3/3,3/3,4/3,3/3,2/4,3/5,2`.split('/').map(Location.fromString)),
//
// mdd 5x5 unique
// Path: d:7 m:9 d:7  Entropy: 15.1
    Puzzle.fromString(`
01110
111d1
m1111
d3111
11110
`)
        .setCanonicalSolutionWithWitness(`1,3/2,4/3,3/4,2/3,1/4,0/3,1/2,0/1,0/1,1/0,1/0,2/1,2/2,2/2,1/3,1/3,0/4,1/3,2/4,3/3,4/2,3/1,4/0,3`.split('/').map(Location.fromString)),
//
// mdd 5x5 unique
// Path: d:3 m:11 d:6  Entropy: 11.9
    Puzzle.fromString(`
01m10
11111
1d111
101d1
11110
`)
        .setCanonicalSolutionWithWitness(`3,3/2,4/1,3/0,2/0,1/1,1/1,0/2,0/3,0/4,0/4,1/4,2/3,2/2,2/2,1/1,2/0,3/1,4/2,3/3,4/4,3`.split('/').map(Location.fromString)),
//
// md 4x5 unique
// Path: m:20 d:0  Entropy: 12.2
// Doesn't use end movementType
    Puzzle.fromString(`
11211
1m111
11111
111d1
`)
        .setCanonicalSolutionWithWitness(`1,1/2,1/2,2/3,2/3,1/3,0/2,0/1,0/0,0/0,1/0,2/1,2/0,2/0,3/0,4/1,4/1,3/2,3/2,4/3,4/3,3`.split('/').map(Location.fromString))
        .setComment(
            `// Good stuff. Hard for danny.`),
//
// mdd 5x5 unique
// Path: d:6 m:11 d:8  Entropy: 16.9
    Puzzle.fromString(`
1m111
11111
1d21d
11111
11111
`)
        .setCanonicalSolutionWithWitness(`2,1/3,2/2,3/1,4/0,3/1,2/0,1/0,0/1,0/2,0/3,0/4,0/4,1/4,2/4,3/4,4/3,4/2,4/3,3/2,2/3,1/2,2/1,1/0,2/1,3/0,4`.split('/').map(Location.fromString)),
//
// mdd 5x5 unique
// Path: d:2 m:19 d:4  Entropy: 17.3
    Puzzle.fromString(`
111m1
11111
112d1
11111
11d11
`)
        .setCanonicalSolutionWithWitness(`2,3/1,2/0,3/0,4/1,4/2,4/3,4/4,4/4,3/3,3/3,2/2,2/2,1/1,1/0,1/0,0/1,0/2,0/3,0/4,0/4,1/4,2/3,1/2,2/1,3/0,2`.split('/').map(Location.fromString)),
//
// mdd 5x5 unique
// Path: d:10 m:11 d:4  Entropy: 14.3
    Puzzle.fromString(`
m1111
12111
1010d
d2121
11111
`)
        .setCanonicalSolutionWithWitness(`2,4/3,3/4,4/3,3/4,2/3,1/4,0/3,1/2,2/1,1/0,0/0,1/0,2/0,3/0,4/1,4/1,3/1,2/1,1/1,0/2,0/3,0/4,1/3,2/4,3/3,4`.split('/').map(Location.fromString)),
//
// mdd 6x7 unique
// Path: d:10 m:13 d:11  Entropy: 22.9
    Puzzle.fromString(`
0111211
d111m11
1111d11
1111111
0111100
0111000
`)
        .setCanonicalSolutionWithWitness(`1,0/0,1/1,2/2,1/3,0/4,1/5,2/4,3/3,2/2,3/1,4/0,4/0,3/0,4/0,5/0,6/1,6/1,5/2,5/2,6/3,6/3,5/3,4/2,4/1,3/0,2/1,1/2,0/3,1/2,2/3,3/4,4/5,3/4,2/5,1`.split('/').map(Location.fromString))
        .setComment(
            `// laura 2017-06-03
// 0d1100
// 111111
// 111111
// 111111
// gmd110
// 111100
// 111100`),
//
// mdd 5x5 unique
// Path: d:10 m:15 d:2  Entropy: 16.3
    Puzzle.fromString(`
10111
d3111
1111d
11141
m1101
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`2,4/3,3/4,4/3,3/2,2/1,1/0,0/1,1/2,0/3,1/4,0/4,1/4,2/3,2/3,3/3,4/3,3/2,3/1,3/1,4/0,4/0,3/0,2/1,2/1,1/1,0/2,1/3,0`.split('/').map(Location.fromString)),
//
// Jd 5x5 unique
// Path: d:7 J:8  Entropy: 15.8
    Puzzle.fromString(`
11100
1d110
11511
01111
J1101
`)
        .setCanonicalSolutionWithWitness(`1,1/2,2/3,3/2,2/1,3/2,2/3,1/4,0/4,2/2,2/2,0/0,0/0,2/2,2/2,4/4,4`.split('/').map(Location.fromString))
        .setComment(
            `//
// PUZZLES
//
// danny 2017-06-02`),
]);