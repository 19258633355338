// fontworld generated by WorldBuilder
import Puzzle from "../puzzle/puzzle";
import {World} from "./world";
import Location from "../puzzle/location";


export let fontworld = new World("fontworld", [
//
// mJd 7x6 unique
// Path: d:3 m:21 J:3  Entropy: 11.8
    Puzzle.fromString(`
001100
011110
110011
1d0011
11111J
110011
m10011
`)
        .setCanonicalSolutionWithWitness(`3,1/4,2/5,1/6,0/5,0/4,0/3,0/2,0/2,1/1,1/1,2/0,2/0,3/1,3/1,4/2,4/2,5/3,5/3,4/4,4/5,4/6,4/6,5/5,5/4,5/4,3/4,1/6,1`.split('/').map(Location.fromString))
    ,
//
// mdd 6x5 unique
// Path: d:5 m:15 d:5  Entropy: 10.8
    Puzzle.fromString(`
111dd
11011
11110
11011
11011
111m1
`)
        .setCanonicalSolutionWithWitness(`0,4/1,3/2,2/3,3/4,4/5,3/5,2/5,1/5,0/4,0/4,1/3,1/3,0/2,0/2,1/1,1/1,0/0,0/0,1/0,2/0,3/1,4/2,3/3,4/4,3/5,4`.split('/').map(Location.fromString))
    ,
//
// mX 6x5 unique
// Path: X:5 m:16  Entropy: 8.00
    Puzzle.fromString(`
11111
1101X
11000
11000
1101m
11111
`)
        .setCanonicalSolutionWithWitness(`1,4/0,4/0,0/5,0/5,4/4,4/4,3/5,3/5,2/5,1/4,1/4,0/3,0/3,1/2,1/2,0/1,0/1,1/0,1/0,2/0,3/1,3`.split('/').map(Location.fromString))
    ,
//
// pS 6x5 unique
// Path: p:4 S:11  Entropy: 7.17
    Puzzle.fromString(`
11p10
11011
S1011
11011
11011
11110
`)
        .setCanonicalSolutionWithWitness(`0,2/1,4/3,3/4,1/2,0/0,0/0,1/3,1/3,0/5,0/5,3/4,3/4,4/2,4/2,3/0,3`.split('/').map(Location.fromString))
    ,
//
// mJS 6x5 unique
// Path: m:13 J:1 S:3  Entropy: 8.58
    Puzzle.fromString(`
m1111
11000
111S0
11000
110J1
11111
`)
        .setCanonicalSolutionWithWitness(`0,0/1,0/2,0/3,0/3,1/4,1/4,0/5,0/5,1/5,2/5,3/5,4/4,4/4,3/2,3/2,1/0,1/0,4`.split('/').map(Location.fromString))
    ,
//
// mewd 6x5 unique
// Path: m:10 d:4  Entropy: 5.58
// Contraptions do 5 visits
    Puzzle.fromString(`
d1111
w11e1
11000
111m0
11000
11000
`)
        .setCanonicalSolutionWithWitness(`3,3/3,2/3,1/4,1/5,1/5,0/4,0/3,0/2,0/1,0/0,0/1,1/0,2/1,3/0,4`.split('/').map(Location.fromString))
    ,
//
// mJJ 6x5 unique
// Path: J:4 m:9 J:5  Entropy: 7.17
    Puzzle.fromString(`
11111
1m01J
11000
11011
11001
1J111
`)
        .setCanonicalSolutionWithWitness(`5,1/3,1/3,3/1,3/1,1/2,1/2,0/1,0/0,0/0,1/0,2/0,3/0,4/1,4/3,4/5,4/5,2/5,0/3,0`.split('/').map(Location.fromString))
    ,
//
// meJ 6x5 unique
// Path: m:18 J:2  Entropy: 8.00
// Contraptions do 1 visits
    Puzzle.fromString(`
11111
110J1
11000
1e0m1
11001
11111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`3,3/3,4/4,4/5,4/5,3/5,2/5,1/5,0/4,0/3,0/2,0/1,0/0,0/0,1/0,2/0,3/0,4/1,4/1,3/1,1/3,1`.split('/').map(Location.fromString))
    ,
//
// X 6x5 unique
// Path: X:24  Entropy: 9.58
    Puzzle.fromString(`
1X011
11011
11111
11011
11011
11011
`)
        .setCanonicalSolutionWithWitness(`0,1/5,1/5,0/0,0/4,0/4,1/1,1/3,1/3,0/1,0/2,0/2,4/5,4/5,3/0,3/0,4/1,4/1,3/4,3/4,4/3,4/3,3/2,3/2,1/2,2`.split('/').map(Location.fromString))
    ,
//
// X 6x5 unique
// Path: X:24  Entropy: 9.17
    Puzzle.fromString(`
11011
11011
X1111
11011
11011
11011
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`2,0/5,0/5,1/0,1/0,0/1,0/1,1/4,1/4,0/3,0/3,1/2,1/2,4/5,4/5,3/0,3/0,4/1,4/1,3/4,3/4,4/3,4/3,3/2,3/2,2`.split('/').map(Location.fromString))
    ,
//
// mp 6x5 unique
// Path: p:20 m:1  Entropy: 12.8
    Puzzle.fromString(`
p1111
01110
01110
01110
01110
1m111
`)
        .setCanonicalSolutionWithWitness(`0,0/1,2/0,4/2,3/0,2/2,1/1,3/0,1/2,2/0,3/1,1/3,2/5,3/4,1/3,3/5,4/4,2/5,0/3,1/4,3/5,1/5,2`.split('/').map(Location.fromString))
    ,
//
// led 6x5 unique
// Path: d:4 l:10  Entropy: 4.58
// Contraptions do 2 visits
    Puzzle.fromString(`
000l1
00011
00011
00011
110d1
11e11
`)
        .setCanonicalSolutionWithWitness(`4,3/3,4/2,3/1,4/0,3/0,4/1,3/2,4/3,3/4,4/5,4/5,3/5,2/4,1/4,0`.split('/').map(Location.fromString))
    ,
//
// peee 6x5 unique
// Path: p:13  Entropy: 11.7
// Contraptions do 10 visits
    Puzzle.fromString(`
1p01e
11011
11110
e101e
11011
11011
`)
        .setCanonicalSolutionWithWitness(`0,1/1,3/2,1/3,3/4,1/5,3/3,4/2,2/4,3/5,1/3,0/1,1/2,3/0,4`.split('/').map(Location.fromString))
        .setComment(
            `totally unfair`)
    ,
//
// X 6x5 unique
// Path: X:23  Entropy: 7.58
    Puzzle.fromString(`
11011
1101X
11110
11011
11011
11011
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`1,4/0,4/0,3/5,3/5,4/3,4/4,4/4,3/1,3/3,3/2,3/2,0/5,0/5,1/0,1/0,0/1,0/1,1/4,1/4,0/3,0/3,1/2,1/2,2`.split('/').map(Location.fromString))
    ,
//
// mpw 6x5 unique
// Path: p:4 m:12  Entropy: 6.58
// Contraptions do 1 visits
    Puzzle.fromString(`
m1000
11000
11000
1p000
11111
11w11
`)
        .setCanonicalSolutionWithWitness(`3,1/5,0/4,2/2,1/0,0/0,1/1,1/1,0/2,0/3,0/4,0/4,1/5,1/5,2/5,3/5,4/4,4`.split('/').map(Location.fromString))
    ,
//
// L 5x7 unique
// Path: L:16  Entropy: 14.7
    Puzzle.fromString(`
1100011
L110111
1111111
1101011
1101011
`)
        .setCanonicalSolutionWithWitness(`1,0/2,1/2,0/4,0/4,1/3,1/2,2/0,0/0,1/2,3/0,5/0,6/4,6/4,5/1,5/3,3/4,3`.split('/').map(Location.fromString))
    ,
//
// X 6x6 unique
// Path: X:27  Entropy: 11.2
    Puzzle.fromString(`
110011
111011
11111X
110111
110011
110011
`)
        .setCanonicalSolutionWithWitness(`2,5/5,5/5,4/0,4/0,5/1,5/1,4/4,4/4,5/3,5/3,3/3,4/2,4/2,0/5,0/5,1/0,1/0,0/1,0/1,2/1,1/4,1/4,0/3,0/3,1/2,1/2,3/2,2`.split('/').map(Location.fromString))
    ,
//
// r 6x5 unique
// Path: r:25  Entropy: 5.00
    Puzzle.fromString(`
1r111
11011
11011
11011
11011
11111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,1/1,1/0,0/1,0/2,0/2,1/3,1/4,1/3,0/4,0/5,0/5,1/5,2/5,3/5,4/4,3/4,4/3,3/3,4/2,3/2,4/1,3/0,2/0,3/0,4/1,4`.split('/').map(Location.fromString))
    ,
//
// mwJ 6x5 unique
// Path: m:19 J:3  Entropy: 11.0
// Contraptions do 3 visits
    Puzzle.fromString(`
m1111
11011
w1011
11011
110J1
11111
`)
        .setCanonicalSolutionWithWitness(`0,0/1,0/2,0/3,0/4,0/5,0/5,1/5,2/5,3/5,4/4,4/3,4/2,4/1,4/0,4/0,3/1,3/2,3/3,3/4,3/4,1/2,1/0,1`.split('/').map(Location.fromString))
    ,
//
// mmJ 6x5 unique
// Path: m:2 J:2 m:19  Entropy: 10.2
    Puzzle.fromString(`
1m111
11011
110J1
11011
110m1
11111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`4,3/3,3/2,3/0,3/0,1/0,0/1,0/1,1/2,1/2,0/3,0/3,1/4,1/4,0/5,0/5,1/5,2/5,3/5,4/4,4/3,4/2,4/1,4/0,4`.split('/').map(Location.fromString))
    ,
//
// X 6x5 unique
// Path: X:21  Entropy: 8.17
    Puzzle.fromString(`
11111
110X1
11011
11111
11000
11000
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`1,3/0,3/0,4/3,4/1,4/2,4/2,3/3,3/3,0/0,0/0,2/0,1/5,1/5,0/4,0/4,1/1,1/1,0/2,0/2,1/3,1/3,2`.split('/').map(Location.fromString))
    ,
//
// mJd 6x5 unique
// Path: d:3 m:15 J:3  Entropy: 12.2
    Puzzle.fromString(`
11111
110J1
1d011
11111
11000
m1000
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`2,1/3,2/4,1/5,0/4,0/3,0/2,0/1,0/0,0/0,1/0,2/0,3/0,4/1,4/2,4/3,4/3,3/2,3/1,3/1,1/3,1/5,1`.split('/').map(Location.fromString))
    ,
//
// mmJ 6x5 unique
// Path: m:19 J:1  Entropy: 9.58
    Puzzle.fromString(`
11mJ1
110m1
11011
11111
11000
11000
`)
        .setCanonicalSolutionWithWitness(`0,2/0,1/0,0/1,0/1,1/2,1/2,0/3,0/4,0/5,0/5,1/4,1/3,1/3,2/3,3/3,4/2,4/1,4/0,4/0,3/2,3`.split('/').map(Location.fromString))
    ,
//
// mJ 6x6 unique
// Path: m:17 J:5  Entropy: 14.2
    Puzzle.fromString(`
111110
110J10
110110
1101m0
111110
000111
`)
        .setCanonicalSolutionWithWitness(`3,4/4,4/4,3/4,2/4,1/4,0/3,0/2,0/1,0/0,0/0,1/0,2/0,3/0,4/1,4/2,4/2,3/1,3/1,1/3,1/3,3/5,3/5,5`.split('/').map(Location.fromString))
    ,
//
// X 6x5 unique
// Path: X:24  Entropy: 8.17
    Puzzle.fromString(`
11111
11011
11011
11110
1101X
11011
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`4,4/5,4/5,3/0,3/0,4/2,4/1,4/1,3/4,3/2,3/3,3/3,0/0,0/0,2/0,1/5,1/5,0/4,0/4,1/1,1/1,0/2,0/2,1/3,1/3,2`.split('/').map(Location.fromString))
    ,
//
// mJd 6x5 unique
// Path: d:3 m:17 J:4  Entropy: 13.8
    Puzzle.fromString(`
11111
11011
1d011
11110
11011
m10J1
`)
        .setCanonicalSolutionWithWitness(`2,1/3,2/4,1/5,0/4,0/3,0/2,0/1,0/0,0/0,1/0,2/0,3/0,4/1,4/2,4/2,3/3,3/4,3/4,4/5,4/5,3/5,1/3,1/1,1/1,3`.split('/').map(Location.fromString))
    ,
//
// mJJ 6x5 unique
// Path: J:3 m:9 J:6  Entropy: 9.17
    Puzzle.fromString(`
1J111
11000
11111
00011
J10m1
11111
`)
        .setCanonicalSolutionWithWitness(`0,1/2,1/4,1/4,3/3,3/3,4/4,4/5,4/5,3/5,2/5,1/5,0/4,0/2,0/0,0/0,2/0,4/2,4/2,2`.split('/').map(Location.fromString))
    ,
//
// mdd 6x6 unique
// Path: d:4 m:11 d:4  Entropy: 7.75
    Puzzle.fromString(`
111111
1m11d1
001100
001100
001100
001d00
`)
        .setCanonicalSolutionWithWitness(`5,3/4,2/3,3/2,2/1,1/1,0/0,0/0,1/0,2/1,2/1,3/0,3/0,4/0,5/1,5/1,4/2,3/3,2/4,3/5,2`.split('/').map(Location.fromString))
    ,
//
// S 6x5 unique
// Path: S:9  Entropy: 1.58
    Puzzle.fromString(`
11011
11011
1S011
11011
11111
11111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`2,1/0,1/0,0/5,0/5,4/0,4/0,3/4,3/4,1/3,1`.split('/').map(Location.fromString))
    ,
//
// mewd 6x5 unique
// Path: m:16 d:4  Entropy: 8.58
// Contraptions do 5 visits
    Puzzle.fromString(`
1m0e1
11011
11011
11011
111d1
1w111
`)
        .setCanonicalSolutionWithWitness(`0,1/0,0/1,0/1,1/2,1/2,0/3,0/3,1/4,1/4,0/5,0/5,1/5,2/5,3/5,4/4,4/4,3/3,4/2,3/1,4/0,3`.split('/').map(Location.fromString))
    ,
//
// S 6x5 unique
// Path: S:11  Entropy: 2.00
    Puzzle.fromString(`
11011
11011
11011
011S1
00111
00011
`)
        .setCanonicalSolutionWithWitness(`3,3/0,3/0,4/5,4/5,3/4,3/4,2/3,2/3,1/0,1/0,0/2,0`.split('/').map(Location.fromString))
    ,
//
// X 6x7 unique
// Path: X:29  Entropy: 11.2
    Puzzle.fromString(`
1100011
1100011
1101011
X111111
1110111
1100011
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`3,0/0,0/0,1/5,1/5,0/4,0/4,2/4,1/1,1/1,0/2,0/2,1/3,1/3,6/0,6/0,5/5,5/5,6/4,6/4,4/4,5/1,5/1,6/2,6/2,5/3,5/3,2/3,4/3,3/2,3`.split('/').map(Location.fromString))
    ,
//
// mmJd 6x7 unique
// Path: m:12 d:2 m:13 J:2  Entropy: 15.2
    Puzzle.fromString(`
1100011
1m00011
11010J1
1111111
11d0m11
1100011
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`1,1/0,1/0,0/1,0/2,0/3,0/4,0/5,0/5,1/4,1/3,1/3,2/4,2/3,3/4,4/3,4/3,5/4,5/5,5/5,6/4,6/3,6/2,6/1,6/0,6/0,5/1,5/2,5/2,3/2,1`.split('/').map(Location.fromString))
    ,
//
// mmJd 6x7 unique
// Path: m:9 J:3 m:15 d:1  Entropy: 14.8
    Puzzle.fromString(`
1100011
1100011
110d01J
1111111
11m0111
110001m
`)
        .setCanonicalSolutionWithWitness(`5,6/5,5/4,5/3,5/2,5/1,5/0,5/0,6/1,6/2,6/4,6/4,4/4,2/4,1/5,1/5,0/4,0/3,0/2,0/1,0/0,0/0,1/1,1/2,1/3,1/3,2/3,3/2,3/3,4`.split('/').map(Location.fromString))
    ,
//
// mmeJ 7x5 unique
// Path: m:11 J:5 m:9  Entropy: 15.9
// Contraptions do 1 visits
    Puzzle.fromString(`
J1011
1101m
11111
01110
11111
e1011
1101m
`)
        .setCanonicalSolutionWithWitness(`1,4/0,4/0,3/1,3/2,3/3,3/3,2/3,1/2,1/1,1/0,1/0,0/2,0/2,2/2,4/4,4/6,4/6,3/5,3/4,3/4,2/4,1/5,1/6,1/6,0/5,0`.split('/').map(Location.fromString))
    ,
//
// X 5x5 unique
// Path: X:13  Entropy: 4.00
    Puzzle.fromString(`
1X011
11111
01110
00100
00100
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,1/0,0/1,0/1,4/0,4/0,3/2,3/2,1/1,1/1,3/1,2/4,2/2,2/3,2`.split('/').map(Location.fromString))
    ,
//
// lee 5x5 unique
// Path: l:12  Entropy: 12.5
// Contraptions do 1 visits
    Puzzle.fromString(`
11011
1eel1
01110
00100
00100
`)
        .setCanonicalSolutionWithWitness(`1,3/2,2/2,1/1,0/0,0/0,1/1,2/0,3/0,4/1,4/2,3/3,2/4,2`.split('/').map(Location.fromString))
        .setComment(
            `Nice to have the king back in a final puzzle`)
    ,
//
// med 5x5 unique
// Path: d:4 m:7  Entropy: 4.58
// Contraptions do 2 visits
    Puzzle.fromString(`
1e0m1
11111
0d110
00100
00100
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`2,1/1,0/0,1/1,2/0,3/0,4/1,4/1,3/2,3/2,2/3,2/4,2`.split('/').map(Location.fromString))
    ,
//
// L 5x5 unique
// Path: L:10  Entropy: 8.58
    Puzzle.fromString(`
11111
1L110
00100
01111
11111
`)
        .setCanonicalSolutionWithWitness(`1,1/0,0/1,0/0,1/1,2/0,2/0,4/4,0/4,4/3,4/3,2`.split('/').map(Location.fromString))
    ,
//
// X 5x5 unique
// Path: X:18  Entropy: 5.17
    Puzzle.fromString(`
11X11
11110
00100
01111
11111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,2/0,4/0,3/1,3/1,0/0,0/0,1/1,1/1,2/4,2/4,0/4,1/3,1/3,4/4,4/4,3/3,3/3,2/2,2`.split('/').map(Location.fromString))
    ,
//
// mp 6x5 unique
// Path: p:4 m:12  Entropy: 5.58
    Puzzle.fromString(`
11111
11000
11000
111p0
11000
1m000
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`3,3/4,1/2,0/3,2/5,1/5,0/4,0/3,0/3,1/2,1/1,1/1,0/0,0/0,1/0,2/0,3/0,4`.split('/').map(Location.fromString))
    ,
//
// mewd 6x5 unique
// Path: m:14  Entropy: 8.00
// Contraptions do 5 visits
    Puzzle.fromString(`
1111m
1d11w
11000
1e110
11000
11000
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,4/1,4/1,3/1,2/0,2/0,1/0,0/1,0/2,0/3,0/4,0/5,0/5,1/4,1/3,1`.split('/').map(Location.fromString))
    ,
//
// mmp 6x5 unique
// Path: m:8 p:2 m:7  Entropy: 7.58
    Puzzle.fromString(`
1111m
1p000
11000
11111
11000
1m000
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,4/0,3/0,2/0,1/0,0/1,0/2,0/2,1/1,1/3,0/5,1/5,0/4,0/4,1/3,1/3,2/3,3/3,4`.split('/').map(Location.fromString))
    ,
//
// mp 6x5 unique
// Path: p:6 m:13  Entropy: 11.8
    Puzzle.fromString(`
p1111
11111
11000
11110
m1000
11000
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,0/1,2/3,3/2,1/1,3/3,2/4,0/5,0/5,1/4,1/3,1/3,0/2,0/1,0/1,1/0,1/0,2/0,3/0,4/1,4`.split('/').map(Location.fromString))
    ,
//
// mmJJ 6x5 unique
// Path: m:8 J:2 m:7 J:2  Entropy: 9.00
    Puzzle.fromString(`
J111m
11011
11000
11000
m10J1
11111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,4/1,4/1,3/0,3/0,2/0,1/1,1/1,0/0,0/2,0/4,0/5,0/5,1/5,2/5,3/5,4/4,4/4,3/4,1/2,1`.split('/').map(Location.fromString))
    ,
//
// mmJ 6x5 unique
// Path: m:5 J:5 m:11  Entropy: 8.58
    Puzzle.fromString(`
11111
1J011
11011
11011
11011
m11m0
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`5,0/4,0/3,0/2,0/2,1/1,1/1,3/3,3/3,1/5,1/5,3/4,3/4,4/3,4/2,4/1,4/0,4/0,3/0,2/0,1/0,0/1,0`.split('/').map(Location.fromString))
    ,
//
// mmdd 6x5 unique
// Path: m:17 d:5 m:3 d:2  Entropy: 16.8
// Golds touched 3
    Puzzle.fromString(`
11111
11011
11g10
1d011
1m01m
11d11
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`4,1/5,1/5,0/4,0/3,0/2,0/1,0/0,0/0,1/0,2/0,3/0,4/1,4/1,3/2,3/2,2/2,1/3,1/2,2/1,1/2,2/3,3/4,4/5,4/5,3/5,2/4,3/3,4`.split('/').map(Location.fromString))
    ,
//
// mmdd 6x5 unique
// Path: d:4 m:17 d:3 m:3  Entropy: 14.8
// Golds touched 3
    Puzzle.fromString(`
11111
1d0m1
11g10
m1011
110d1
11111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`1,1/2,2/3,1/2,2/1,3/1,4/0,4/0,3/0,2/0,1/0,0/1,0/2,0/2,1/2,2/2,3/3,3/3,4/4,4/5,4/5,3/4,3/5,2/4,1/3,0/4,0/5,0/5,1`.split('/').map(Location.fromString))
    ,
//
// mmd 6x5 unique
// Path: m:2 d:2 m:21  Entropy: 12.8
    Puzzle.fromString(`
m1011
1d0m1
11211
11011
11011
11011
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,0/0,1/1,1/2,2/1,3/0,3/0,4/1,4/2,4/3,4/4,4/5,4/5,3/4,3/3,3/2,3/2,2/2,1/3,1/4,1/5,1/5,0/4,0/3,0/2,0/1,0`.split('/').map(Location.fromString))
    ,
//
// pX 6x5 unique
// Path: X:10 p:15  Entropy: 17.2
    Puzzle.fromString(`
11111
1101p
11110
11011
11011
X1111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`5,0/0,0/0,4/0,1/0,3/5,3/5,4/3,4/3,3/1,3/1,4/0,2/2,1/4,0/5,2/4,4/2,3/1,1/3,0/5,1/4,3/3,1/1,0/2,2/4,1/2,0`.split('/').map(Location.fromString))
    ,
//
// mewJ 6x5 unique
// Path: J:3 m:15  Entropy: 7.00
// Contraptions do 4 visits
    Puzzle.fromString(`
11111
1J0m1
11000
11011
11011
11w1e
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`1,1/3,1/3,3/1,3/1,4/0,4/0,3/0,2/0,1/0,0/1,0/2,0/3,0/4,0/5,0/5,1/5,2/5,3/5,4`.split('/').map(Location.fromString))
    ,
]);