import {VisitableThing, VisitableThingInfo} from "./movement";
import Location from "./location";
import Solver, {SolutionSet} from "./solver";
import {Square} from "./square";
import Utility from "../utility";
import {World} from "../puzzles/world";
import ActivePuzzle from "./activePuzzle";
import {devLog} from "../buildModeChecker";
import VisitCounter from "./visitCounter";
import GetProgress from "../progress/getProgress";
import {SolutionInfo} from "./solutionInfo";
import {MoveSequence} from "./move";

export enum PuzzleOrientation {
    default = 12,
    wide,
    tall,
}

export interface PuzzleOrientationHelper {
    // Did we swap everything around to write this?
    swapped: boolean,
    // What we should regard while laying out the puzzle as its width. This might be swapped with the height.
    width: number,
    // What we should regard while laying out the puzzle as its height. This might be swapped with the width.
    height: number,
    // The locations in the puzzle, from top left to the right then the other rows, in the order they should be laid
    // out for this (possibly transposed from the way it's written down as a puzzle) orientation.
    locationList: Location[],
}

export interface HintInfo {
    lastHintedMove: number,
    totalHintsAvailable: number,
}

export class VisitCountRequirements {
    constructor(public visitCount: number, public gold: boolean = false) {
        // Gold spots are never required to be visited.
        if (this.gold) this.visitCount = 0;
    }

    public toString() {
        if (this.gold) return 'g';
        return this.visitCount.toString();
    }

    isVoid() {
        return this.visitCount === 0 && !this.gold;
    }
}

// A clean representation of everything we might need to persist about a puzzle that can be taken to and from
// json freely and smoothly.
export interface PuzzleSpec {
    isPuzzleSpec: boolean,
    rawText: string,
    witness?: string[],  // These are stringified Location objects
    enabled: boolean,
    comment?: string,
}

class PuzzleInfo {
    constructor(private readonly puzzle: Puzzle) {
    }


}

/**
 * The Puzzle class represents a static untouched puzzle.
 */
class Puzzle {
    public readonly startingLocations: Location[];
    public readonly height: number;
    public readonly width: number;
    public readonly goldCount: number;
    public readonly nonGoldVisitsNeeded: number;
    public readonly visitableThingsList: VisitableThing[];
    public readonly contraptionLocations: Location[];
    public readonly multiTapSquareCount: number;
    private _hintInfo?: HintInfo[];
    private _firstHintCoveringMoveNum: number[] | undefined;
    private _comment: string | undefined;
    private allowedToRotateForLayout: boolean;
    public totalVisitableLocations: number;

    /**
     * Make a puzzle from an array of arrays of underlying squares.
     * @param grid A rectangular array of arrays of Squares.
     * @param rawText The text that generated this puzzle, if we want to remember any.
     */
    constructor(private grid: Array<Array<Square>>, private rawText?: string) {
        this.height = grid.length;
        this.width = grid[0].length;
        this.goldCount = 0;
        this.nonGoldVisitsNeeded = 0;
        this.totalVisitableLocations = 0;
        this.startingLocations = [];
        this.contraptionLocations = [];
        this._enabled = true;
        this.multiTapSquareCount = 0;
        this.allowedToRotateForLayout = true;
        this.visitableThingsList = new Array<VisitableThing>();
        this._indexInWorld = -1;

        for (let r = 0; r < this.height; r++) {
            let row = grid[r];
            if (row.length !== this.width) {
                console.error(grid);
                throw new Error(`Puzzle grid input must be rectangular.`);
            }
            for (let c = 0; c < row.length; c++) {
                let sq = row[c];
                // If it has a schema, we can start in it.
                if (VisitableThingInfo.isStartableThing(sq.thingType)) {
                    this.startingLocations.push(new Location(r, c));
                }
                if (VisitableThingInfo.isContraption(sq.thingType)) {
                    this.contraptionLocations.push(new Location(r, c));
                }
                if (sq.thingType !== VisitableThing.None) this.visitableThingsList.push(sq.thingType);
                if (sq.visits.gold) this.goldCount += 1;
                else {
                    this.nonGoldVisitsNeeded += sq.visits.visitCount;
                    this.totalVisitableLocations += 1;
                }
                if (sq.visits.visitCount > 1) this.multiTapSquareCount++;

            }
        }
        this.visitableThingsList.sort();
    }

    private _cleanRawText?: string;

    // This is only used for localStorage Keys for comments.
    get cleanRawText(): string {
        if (!this._cleanRawText) {
            const lines = this.toString().trim().split('\n');

            this._cleanRawText = lines
                // Drop comments out
                .filter(line => !(line.startsWith('#') || line.startsWith('//')))
                // Clean up, redundantly
                .map(line => line.trim())
                // Join with slashes instead of newlines, idiosyncratically
                .join('/');
        }
        return this._cleanRawText;
    }

    private _nextPuzzleInWorldOrder?: Puzzle;

    get nextPuzzleInWorldOrder(): Puzzle | undefined {
        return this._nextPuzzleInWorldOrder;
    }

    get totalHintCount(): number {
        return this._hintInfo?.length || 0;
    }

    private _enabled: boolean;

    get enabled(): boolean {
        return this._enabled;
    }

    private _label: string | undefined;

    get label(): string {
        // if (!this._label) {
        //     devLog(this.toString());
        //     devLog('indexInWorlds needs to be set during initialization');
        // }
        return this._label || this.toString();
    }

    private _interactingBombCount: number | undefined;

    get interactingBombCount(): number {
        if (this._interactingBombCount === undefined) {
            // Act like this puzzle hasn't been touched yet.
            const dummyCounter = new VisitCounter(this.height, this.width);
            this._interactingBombCount =
                this.contraptionLocations.filter(loc => {
                    const vis = this.visitableAtLocation(loc);
                    const effect = VisitableThingInfo.immediateEffect(vis);
                    if (!effect) throw new Error('Should not happen!');
                    let visits = effect(loc, this, dummyCounter);
                    return this.contraptionLocations.some(cl => visits.some(vl => cl.equals(vl)));
                }).length
        }
        return this._interactingBombCount;
    }

    private _sourceWorld: World | undefined;

    get sourceWorld(): World | undefined {
        return this._sourceWorld;
    }

    private _indexInWorld: number;

    get indexInWorld(): number {
        // if (this._indexInWorld === -1) {
        //     devLog(this.toString());
        //     devLog('indexInWorlds needs to be set during initialization');
        // }
        return this._indexInWorld;
    }

    private _analyticsName: string | undefined;

    get analyticsName(): string {
        if (this._analyticsName === undefined)
            this._analyticsName = `${this.label} ${Utility.simpleShortStringHash(this.toString())}`;
        return this._analyticsName;
    }

    // private _canonicalSolution: Solution | undefined;
    //
    // get canonicalSolution(): Solution | undefined {
    //     return this._canonicalSolution;
    // }

    private _canonicalSolutionMoves: MoveSequence | undefined;

    get canonicalSolutionMoves(): MoveSequence | undefined {
        return this._canonicalSolutionMoves;
    }

    /**
     * Get the relative prevalence of the odd- and even-parity diagonal movement types. The squares accessible to the
     * types are complementary, so it can be interesting to know this. If there are no diagonal movement types, this
     * just returns -1 so we don't have to screw around with type stuff.
     */
    get diagonalParityRatio(): number {
        let parities = this.startingLocations
            .filter(loc => this.visitableAtLocation(loc) === VisitableThing.Diagonal)
            .map(loc => (loc.column + loc.row) % 2);
        if (parities.length === 0) return -1;
        let evenCount = parities.filter(n => n === 0).length;
        let oddCount = parities.length - evenCount;
        return Math.min(evenCount / oddCount, oddCount / evenCount);
    }

    private _solutionInfo: SolutionInfo | undefined;

    get solutionInfo(): SolutionInfo | undefined {
        if (!this._solutionInfo) {
            if (this.canonicalSolutionMoves) {
                this._solutionInfo = new SolutionInfo(this, this.canonicalSolutionMoves)
            }
        }
        return this._solutionInfo;
    }

    /**
     * Construct a Puzzle from a text definition. Digits are used to specify the number of visitsMade required and
     * codes for each schema indicate their presence. You can additionally specify more characteristics by
     * putting everything you want a square to have inside braces.
     * @param rawText The text to parse into a Puzzle.
     */
    static fromString(rawText: string) {
        let grid: Array<Array<Square>> = [];
        let row: Array<Square> = [];
        let text = rawText.trim() + '\n';
        // Adding newline is cheap hack to push out the last row.
        // console.error("Making puzzle:\n" + def);
        let i = 0;
        while (i < text.length) {
            let squareDef = text[i];

            // Pull out all of the chars until the brace closes.
            if (squareDef === '{') {
                squareDef = '';
                i += 1;
                // This is bad overflow for some texts
                while (text[i] !== '}') {
                    squareDef += text[i];
                    i += 1;
                }
            } else if (squareDef === '/' || squareDef === '#') { // Skip comment lines
                // This is bad overflow for some texts
                while (text[i] !== '\n') {
                    i += 1;
                }
                i += 1;
                continue;
            } else if (squareDef === '\n') { // Newlines indicate the end of a row
                grid.push(row);
                row = [];
                i += 1;
                continue;
            }

            // If there's a schema on our square, we can only visit it the once.
            let thingType = VisitableThingInfo.parseVisitableThing(squareDef);
            let isGold = squareDef.match(/g/) != null;
            let visitCount = thingType === VisitableThing.None ? +Utility.justDigits(squareDef) : 1;
            let visits = new VisitCountRequirements(visitCount, isGold);
            row.push(new Square(visits, thingType));
            i += 1;
        }
        return new Puzzle(grid, text);
    }

    public static initializationLines(puz: PuzzleSpec): string[] {
        // Start string raw text literal definition.
        let lines: string[] = ['Puzzle.fromString(`'];
        // This might have some internal newlines. That's fine.
        lines.push(puz.rawText.trim());
        // End string raw text literal definition.
        lines.push('`)');
        // Handle enabled.
        if (!puz.enabled) {
            lines.push('.toggleEnabled()');
        }
        if (puz.witness) {
            const witnessString = puz.witness.join('/');
            lines.push(`.setCanonicalSolutionWithWitness(\`${witnessString}\`.split('/').map(Location.fromString))`);
        }
        if (puz.comment) {
            lines.push(`.setComment(\n\`${puz.comment}\`)`);
        }
        return lines;
    }

    public static fromJSON(json: any): Puzzle {
        let ret: Puzzle;
        if (!json.isPuzzleSpec) {
            console.error(`Puzzle.fromJSON for non-PuzzleSpec`)
            console.error(json);
        }
        return Puzzle.fromSpec(json as PuzzleSpec);


        // if (typeof json == 'string') {
        //     json = JSON.parse(json);
        // }
        // if (json.puzzleString) ret = Puzzle.fromString(json.puzzleString);
        // if (json.rawText) ret = Puzzle.fromString(json.rawText);
        // // @ts-ignore
        // if (!ret) {
        //     devLog(json);
        //     throw new Error();
        // }
        //
        // if (json.witness) {
        //     ret.setCanonicalSolutionWithWitness(json.witness.map(Location.fromString));
        // }
        // // In case we fuck this up somehow again.
        // if (json.enabled !== undefined) ret._enabled = json.enabled;
        // return ret;
    }

    public  static fromSpec(spec: PuzzleSpec) {
        if (!spec.isPuzzleSpec) throw new Error(`spec is not a PuzzleSpec: ${spec}`);
        const ret = Puzzle.fromString(spec.rawText.trim());
        if (!spec.enabled) ret.toggleEnabled();
        if (spec.witness) ret.setCanonicalSolutionWithWitness(spec.witness.map(Location.fromString));
        if (spec.comment) ret.setComment(spec.comment);
        return ret;
    }

    public setComment(value: string): Puzzle {
        this._comment = value;
        return this;
    }

    public toJSON() {
        return this.toPuzzleSpec();
    }

    public toPuzzleSpec(): PuzzleSpec {
        return {
            isPuzzleSpec: true,
            rawText: this.toString(),
            witness: this.canonicalSolutionMoves?.map(move => move.newLocation.toString()),
            enabled: this.enabled,
            comment: GetProgress.getPuzzleCommentText(this) || this._comment,
        }
    }

    setWorldNeighbourhood(world: World , indexInWorld: number, nextPuzzleInWorldOrder: Puzzle | undefined) {
        this._sourceWorld = world;
        this._indexInWorld = indexInWorld;
        this._nextPuzzleInWorldOrder = nextPuzzleInWorldOrder;
    }

    public setCannotRotate() {
        this.allowedToRotateForLayout = false;
    }

    public toggleEnabled(): Puzzle {
        this._enabled = !this._enabled;
        return this;
    }

    public setLabel(label: string) {
        this._label = label;
    }

    public setCanonicalSolutionWithMoves(moveSequence: MoveSequence, guaranteedUnique: boolean = false): Puzzle {
        this._canonicalSolutionMoves = moveSequence;
        // this._canonicalSolution = new Solution(this, moveSequence);
        // TODO: use guaranteedUnique

        return this;
    }

    public setCanonicalSolutionWithWitness(witness: Location[]): Puzzle {
        let activePuzzle = new ActivePuzzle(this);
        witness.forEach(loc => {
            let moves = activePuzzle.viableMoves();
            let move = moves.find(move => move.newLocation.equals(loc));
            if (!move) {
                devLog(this.toString());
                devLog(this);
                throw console.error('fromMoveList passed garbage sequence');
            }
            activePuzzle.addMove(move);
        })

        this.setCanonicalSolutionWithMoves(activePuzzle.moveList, true);
        return this;
    }

    public setCanonicalSolutionWithSolutionSet(solutionSet: SolutionSet): Puzzle {
        // Some sanity checking.
        console.assert(solutionSet.puzzleSpec.rawText === this.toPuzzleSpec().rawText);
        console.assert(solutionSet.solverConfig.maxSolutions > 1);
        console.assert(solutionSet.solutions.length === 1);
        // These are ok to be set, since they only cause rejection of the entire puzzle.
        // console.assert(!solutionSet.solverConfig.excludeUnusedMovementType);
        // console.assert(!solutionSet.solverConfig.excludeEndOnMovement);
        // console.assert(!solutionSet.solverConfig.excludeBoomlessBombPuzzles);
        // console.assert(!solutionSet.solverConfig.excludeBackTrackInSolution);

        const moveSequence = solutionSet.solutions[0];
        this.setCanonicalSolutionWithMoves(moveSequence, true);
        return this;
    }

    public toString() {
        if (this.rawText) return this.rawText;
        // TODO: This is untested, probably doesn't work, probably doesn't take into account lots of edge cases.
        let result = "";
        for (let r = 0; r < this.height; r++) {
            let row = this.grid[r];
            for (let c = 0; c < row.length; c++) {
                let sq = row[c];
                result += sq.toString();
            }
            result += "\n";
        }
        return result;
    }

    public visitableTypesString() {
        return this.visitableThingsList.map(v => VisitableThingInfo.keyCode(v)).join('');
    }

    public annotatedInitializationLines(): string[] {
        let lines = [''];
        {
            const solns = this.getSolutions();
            let solnCount: string;
            switch (solns.length) {
                case 0:
                    solnCount = "BUSTED";
                    break;
                case 1:
                    solnCount = 'unique';
                    break;
                case 2:
                    solnCount = '2-solution';
                    break;
                default:
                    solnCount = 'multisolution';
            }
            const movementTypes = this.visitableTypesString();
            lines.push(`${movementTypes} ${this.height}x${this.width} ${solnCount}`);
            // let ret = `${movementTypes} ${this.height}x${this.width} ${solnCount}` + '\n';
            if (solns.length > 0) {
                const solution = solns[0];
                const solutionInfo = new SolutionInfo(this, solution);
                const goldTouchCounts = solutionInfo.goldTouchCounts;
                lines.push(`Path: ${solutionInfo.describePath}  Entropy: ${solutionInfo.pathEntropy.toPrecision(3)}`);
                // ret += `Path: ${solution.describePath}  Entropy: ${solution.pathEntropy.toPrecision(3)}` + '\n';
                if (solutionInfo.hasUnusedEndSchema()) {
                    lines.push("Doesn't use end movementType");
                    // ret += "Doesn't use end movementType" + '\n';
                }
                if (solutionInfo.contraptionCausedVisitsCount !== 0)
                    lines.push(`Contraptions do ${solutionInfo.contraptionCausedVisitsCount} visits`);
                // ret += `Contraptions do ${solution.contraptionCausedVisitsCount} visits` + '\n';
                if (goldTouchCounts.length > 0) {
                    lines.push(`Golds touched ${goldTouchCounts.join(" ")}`);
                    // ret += `Golds touched ${goldTouchCounts.join(" ")}` + '\n';
                }
            }
        }

        return [
            ...lines.map(line => '// ' + line.trim()),
            ...Puzzle.initializationLines(this.toPuzzleSpec()),
        ];


        // return "//\n" + textCommentAnnotation.trim().split('\n').map(s => "// " + s).join('\n') + '\n' +
        //     this.initializationString();
    }

    public rotate(): Puzzle {
        let ret = [];
        for (let i = 0; i < this.width; i += 1) {
            let row = []
            for (let j = this.height - 1; j >= 0; j -= 1) {
                row.push(this.grid[j][i].rotated())
            }
            ret.push(row);
        }
        return new Puzzle(ret)
    }

    public hFlip(): Puzzle {
        let ret = [];
        for (let i = 0; i < this.height; i += 1) {
            let row = []
            for (let j = this.width - 1; j >= 0; j -= 1) {
                row.push(this.grid[i][j].hFlipped())
            }
            ret.push(row);
        }
        return new Puzzle(ret)
    }

    public vFlip(): Puzzle {
        let ret = [];
        for (let i = this.height - 1; i >= 0; i -= 1) {
            let row = []
            for (let j = 0; j < this.width; j += 1) {
                row.push(this.grid[i][j].vFlipped())
            }
            ret.push(row);
        }
        return new Puzzle(ret)
    }

    //  TODO: I might have fixed this already. Dunno.
    public transpose(): Puzzle {
        let ret = [];
        for (let i = 0; i < this.width; i += 1) {
            let row = []
            for (let j = 0; j < this.height; j += 1) {
                row.push(this.grid[j][i].transposed())
            }
            ret.push(row);
        }
        return new Puzzle(ret)
    }

    public isOnBoard(loc: Location) {
        return loc.row >= 0 && loc.row < this.height && loc.column >= 0 && loc.column < this.width;
    }

    public visitableAtLocation(location: Location): VisitableThing {
        return this.grid[location.row][location.column].thingType;
    }

    public visitsNeeded(location: Location): VisitCountRequirements {
        // Return dummy off the board since we know we don't need to visit it.
        if (!this.isOnBoard(location)) return new VisitCountRequirements(0)
        return this.grid[location.row][location.column].visits;
    }

    public isGoldSquare(loc: Location) {
        return this.visitsNeeded(loc).gold;
    }

    /**
     * Get all 16 variants of this puzzle obtainable by some combination of rotations and horizontal and vertical
     * flips. This includes performing the appropriate changes to the movesets of the underlying MovementTypes
     * available in the puzzle. This is normally not a big deal and is only important in the case of Wyes and any
     * other asymmetrical MovementTypes we come up with.
     */
    isomorphicVariants(): Puzzle[] {
        let ret: Puzzle[] = [];
        let alts = [this, this.hFlip(), this.vFlip(), this.transpose()];
        ret.push(...alts);
        for (let i = 0; i < 3; i++) {
            alts = alts.map(p => p.rotate());
            ret.push(...alts);
        }
        return ret;
    }

    // TODO: Remove this garbage function, or at least handle the return better.
    getSomeSolution(): MoveSequence {
        if (this.canonicalSolutionMoves) {
            return this.canonicalSolutionMoves;
        } else {
            let solver = new Solver({
                maxSolutions: 1,
                excludeBackTrackInSolution: false,
                excludeBoomlessBombPuzzles: false,
                excludeEndOnMovement: false,
                excludeUnusedMovementType: false,
                returnEmptyOnAnySkip: false,
            });
            const solutionSet = solver.solve(this);
            // We can return an undefined here. This whole thing sucks.
            return solutionSet.solutions[0];
        }
    }

    firstHintIncludingMoveNum(moveNum: number) {
        if (!this._firstHintCoveringMoveNum) return undefined;
        if (moveNum < 0 || moveNum >= this._firstHintCoveringMoveNum.length) return undefined;
        return this._firstHintCoveringMoveNum[moveNum];
    }

    getHintInfo(ownedHints: number): HintInfo {
        if (!this._hintInfo) {
            devLog(this.toString());
            devLog('_hintInfo needs to be set during initialization.')
            return {
                lastHintedMove: -1,
                totalHintsAvailable: 0,
            };
        }
        if (ownedHints === 0) return {
            lastHintedMove: -1,
            totalHintsAvailable: this._hintInfo.length,
        };

        if (ownedHints > this.totalHintCount)
            console.error(`Don't have hint ${ownedHints} for ${this.label}`)
        return this._hintInfo[ownedHints - 1];
    }

    public generateHintLocations() {
        const soln = this.getSomeSolution();
        if (!soln) {
            console.error(`No Solution found.`);
            throw new Error(`Puzzle ${this.label} has no solution. This is bad.`);
        }

        // This is only done here at gametime for the world puzzles so we have SOMETHING, ANYTHING to use as
        // the canonical solution.
        this.setCanonicalSolutionWithMoves(soln);

        this._hintInfo = new Array<HintInfo>();
        this._firstHintCoveringMoveNum = new Array<number>();
        let ap = new ActivePuzzle(this);
        let choiceMoves: number[] = [];
        soln.forEach((m, i) => {
            if (ap.viableMoves().length !== 1) choiceMoves.push(i);
            ap.addMove(m);
        });
        for (let i = 1; i < choiceMoves.length; i++) {
            this._hintInfo.push({
                lastHintedMove: choiceMoves[i] - 1,
                totalHintsAvailable: choiceMoves.length,
            });
        }
        this._hintInfo.push({
            lastHintedMove: soln.length - 1,
            totalHintsAvailable: choiceMoves.length,
        });

        this._firstHintCoveringMoveNum = Array.from(Array(soln.length).keys())
            .map((_, i) => (this._hintInfo!.findIndex(hi => hi.lastHintedMove > i)));

        this._firstHintCoveringMoveNum = new Array<number>();
        let ap2 = new ActivePuzzle(this);
        let tot = 0;
        soln.forEach((m, i) => {
            this._firstHintCoveringMoveNum!.push(Math.min(this._hintInfo!.length, tot + 1));
            if (ap2.viableMoves().length !== 1) tot += 1;
            ap2.addMove(m);
        });
    }

    public orientedLayoutConstants(layout: PuzzleOrientation): PuzzleOrientationHelper {
        // Square puzzles also rotate in tall mode. This is mostly so puzzles like the first-ever and the unfolded
        // version show in an ovelapping type of form.
        const needSwap = (this.allowedToRotateForLayout &&
            ((layout === PuzzleOrientation.tall && this.width >= this.height) ||
                (layout === PuzzleOrientation.wide && this.height > this.width)));

        const arr = new Array<Location>(this.height * this.width);
        for (let i = 0; i < arr.length; i++) {
            arr[i] = needSwap ?
                new Location(i % this.height, Math.floor(i / this.height)) :
                new Location(Math.floor(i / this.width), i % this.width);
        }
        if (needSwap) {
            return {
                height: this.width,
                width: this.height,
                swapped: needSwap,
                locationList: arr,
            }
        } else {
            return {
                height: this.height,
                width: this.width,
                swapped: needSwap,
                locationList: arr,
            }
        }
    }

    shortAnnotation() {
        // We can't memo this because we think it might change, use it as an indicator in the worldbuilder results.
        if (!this.solutionInfo)
            return `${this.visitableThingsList.map(VisitableThingInfo.keyCode).join('')} ` +
                `${this.height}x${this.width} ` +
                `${this.toString()}`;
        return this.solutionInfo.shortSolutionAnnotation;
    }

    private getSolutions(): MoveSequence[] {
        // If there's a single canonical solution implanted, we know it's a unique solution.
        if (this.canonicalSolutionMoves) {
            return [this.canonicalSolutionMoves];
        } else {
            // We can just use the default solver props
            let solver = new Solver();
            return solver.solve(this).solutions;
        }
    }

    /**
     * Make absolutely sure that the puzzle can be solved using this sequence of moves.
     * Returns true is everything's cool.
     * TODO: We should also be building the solutions from scratch to be sure.
     */
    public validateCanonicalSolutionMoves() {
        if (!this.canonicalSolutionMoves) return false;
        const ap = new ActivePuzzle(this);
        this.canonicalSolutionMoves.forEach(m => {
            const viableMoves = ap.viableMoves();
            if (!viableMoves.some(vm => m.equals(vm))) return false;
            ap.addMove(m);
        });
        return ap.isCompleted;
    }


     _orderInRollout : Map<string, number> | undefined;
    /**
     * This provides an order of the squares, user for us to run animations on them in order after puzzle
     * solution. We want the top right square to be first, and we then proceed along the row, then the columns,
     * or vice versa. it doesn't really matter. But we do like starting in the top right.
     * @param loc
     * @param swapped
     */
    orderInRollout(loc: Location, swapped: boolean) {
        // Build this thing only once.
        if (!this._orderInRollout) {
            this._orderInRollout = new Map<string, number>();
            let currentCount = 0;
            for (let row = 0; row < this.height; row++) {
                for (let col = this.width - 1; col >= 0; col--) {
                    const loc = new Location(row, col);
                    const includedInRollout = this.visitsNeeded(loc).isVoid();
                    if (includedInRollout) {
                        currentCount++;
                        this._orderInRollout.set(loc.toString(), currentCount);
                    }
                }
            }
        }
        return this._orderInRollout.get(loc.toString());
    }
}

export default Puzzle;