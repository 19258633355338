import {ReportHandler} from 'web-vitals';
import {devLog} from "./buildModeChecker";
import Constants from "./constants";

// Taken from https://github.com/GoogleChrome/web-vitals#using-gtagjs-google-analytics-4
// @ts-ignore
export function sendToGoogleAnalytics({name, delta, value, id}) {
    if (Constants.logReportVitals) devLog('sendToGoogleAnalytics called for reportWebVitals');
    // Assumes the global `gtag()` function exists, see:
    // https://developers.google.com/analytics/devguides/collection/ga4
    gtag('event', name, {
        // Built-in params:
        value: delta, // Use `delta` so the value can be summed.
        // Custom params:
        metric_id: id, // Needed to aggregate events.
        metric_value: value, // Optional.
        metric_delta: delta, // Optional.

        // OPTIONAL: any additional params or debug info here.
        // See: https://web.dev/debug-web-vitals-in-the-field/
        // metric_rating: 'good' | 'ni' | 'poor',
        // debug_info: '...',
        // ...
    });
}
const reportWebVitals = (onPerfEntry?: ReportHandler) => {
    if (onPerfEntry && onPerfEntry instanceof Function) {
        import('web-vitals').then(({getCLS, getFID, getFCP, getLCP, getTTFB}) => {
            getCLS(onPerfEntry);
            getFID(onPerfEntry);
            getFCP(onPerfEntry);
            getLCP(onPerfEntry);
            getTTFB(onPerfEntry);
        });
    }
};

export default reportWebVitals;
