import React from 'react';
import Puzzle from "../../puzzle/puzzle";
import GetProgress from "../../progress/getProgress";
import SetProgress from "../../progress/setProgress";
import Key from "../../progress/key";

import '../../css/lab/puzzleComment.css';
import Utility from "../../utility";

interface PuzzleCommentProps {
    puzzle?: Puzzle,
    rows: number,
}

interface PuzzleCommentState {
}

class PuzzleComment extends React.Component<PuzzleCommentProps, PuzzleCommentState> {
    static defaultProps = {
        rows: 7,
    }

    constructor(props: PuzzleCommentProps) {
        super(props);

        this.updateCommentHandler = this.updateCommentHandler.bind(this);
    }


    private updateCommentHandler(event: React.ChangeEvent<HTMLTextAreaElement>) {
        if (!this.props.puzzle) return;
        const target = event.target;
        const newText = target.value;

        // Either unset of fix the new value.
        // Perhaps it's overkill to be writing to local storace constantly like this but it's fine.
        if (newText.length === 0) {
            Utility.removeFromStorage(Key.keyForPuzzleComment(this.props.puzzle));
        } else {
            SetProgress.setPuzzleCommentText(this.props.puzzle, newText);
        }
        this.forceUpdate();
    }


    render() {
        const text = this.props.puzzle ? GetProgress.getPuzzleCommentText(this.props.puzzle) : 'No puzzle loaded';

        const classes = [
            'puzzle-comment-component',
            !!text ?
                'puzzle-comment-component--with-comment' :
                'puzzle-comment-component--no-comment',
        ].join(' ');


        return (
            <div className={classes}>
                <h2 className={'panel-header'}>Puzzle Comment</h2>
                <textarea value={text || ''}
                    // tabIndex={1}
                          className={"puzzle-lab__text-area puzzle-comment-component__comment-text"}
                    // spellCheck={"false"}
                          name={"searchText"}
                          rows={this.props.rows}
                          onChange={this.updateCommentHandler}
                          disabled={!this.props.puzzle}
                />
            </div>
        );
    }


}


export default PuzzleComment;