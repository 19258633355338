// intro generated by WorldBuilder
import Puzzle from "../puzzle/puzzle";
import {World} from "./world";
import Location from "../puzzle/location";


export let intro = new World("intro", [
//
// m 4x1 unique
// Path: m:3  Entropy: 0.00
        Puzzle.fromString(`
m
1
1
1
`)
            .setCanonicalSolutionWithWitness(`0,0/1,0/2,0/3,0`.split('/').map(Location.fromString))
            .setComment(
                `// MANHATTAN ONLY
First puzzle in the game.`)
        ,
//
// m 3x3 unique
// Path: m:6  Entropy: 0.00
        Puzzle.fromString(`
111
m01
011
`)
            .setCanonicalSolutionWithWitness(`1,0/0,0/0,1/0,2/1,2/2,2/2,1`.split('/').map(Location.fromString))
            .setComment(
                `Teaching you can move in different directions.`)
        ,
//
// m 3x3 unique
// Path: m:6  Entropy: 2.00
        Puzzle.fromString(`
111
m11
010
`)
            .setCanonicalSolutionWithWitness(`1,0/0,0/0,1/0,2/1,2/1,1/2,1`.split('/').map(Location.fromString))
            .setComment(
                `Now you're allowed to fail.`)
        ,
//
// m 5x3 unique
// Path: m:12  Entropy: 4.17
        Puzzle.fromString(`
011
111
0m1
111
111
`)
            .setCanonicalSolutionWithWitness(`2,1/3,1/3,0/4,0/4,1/4,2/3,2/2,2/1,2/0,2/0,1/1,1/1,0`.split('/').map(Location.fromString))
            .setComment(
                `Now you have to do something a little tricky.`)
        ,
//
// m 6x4 unique
// Path: m:21  Entropy: 9.17
        Puzzle.fromString(`
1111
0111
1m11
1011
1111
1111
`)
            .setCanonicalSolutionWithWitness(`2,1/2,0/3,0/4,0/5,0/5,1/4,1/4,2/5,2/5,3/4,3/3,3/3,2/2,2/2,3/1,3/0,3/0,2/1,2/1,1/0,1/0,0`.split('/').map(Location.fromString))
        ,
//
// d 4x4 unique
// Path: d:6  Entropy: 2.00
        Puzzle.fromString(`
0100
d010
0101
1010
`)
            .setCanonicalSolutionWithWitness(`1,0/0,1/1,2/2,3/3,2/2,1/3,0`.split('/').map(Location.fromString))
            .setComment(
                `// dave 2017-05-20 training puzzle`)
        ,
//
// d 8x4 unique
// Path: d:13  Entropy: 4.00
        Puzzle.fromString(`
d010
0101
0010
0101
1010
0100
1010
0101
`)
            .setCanonicalSolutionWithWitness(`0,0/1,1/0,2/1,3/2,2/3,3/4,2/3,1/4,0/5,1/6,0/7,1/6,2/7,3`.split('/').map(Location.fromString))
        ,
//
// d 6x5 unique
// Path: d:11  Entropy: 4.58
        Puzzle.fromString(`
00100
01010
1010d
01010
10100
01010
`)
            .setCanonicalSolutionWithWitness(`2,4/3,3/2,2/1,3/0,2/1,1/2,0/3,1/4,0/5,1/4,2/5,3`.split('/').map(Location.fromString))
        ,
//
// d 8x6 unique
// Path: d:21  Entropy: 8.75
        Puzzle.fromString(`
001010
010101
10d010
010101
101010
010100
101010
010101
`)
            .toggleEnabled()
            .setCanonicalSolutionWithWitness(`2,2/3,3/4,2/5,3/4,4/3,5/2,4/1,5/0,4/1,3/0,2/1,1/2,0/3,1/4,0/5,1/6,0/7,1/6,2/7,3/6,4/7,5`.split('/').map(Location.fromString))
        ,
//
// m 6x6 unique
// Path: m:21  Entropy: 8.75
        Puzzle.fromString(`
001110
011m11
111111
111110
011000
001000
`)
            .setCanonicalSolutionWithWitness(`1,3/2,3/2,2/3,2/3,3/3,4/2,4/2,5/1,5/1,4/0,4/0,3/0,2/1,2/1,1/2,1/2,0/3,0/3,1/4,1/4,2/5,2`.split('/').map(Location.fromString))
        ,
//
// md 5x3 unique
// Path: d:4 m:3  Entropy: 3.00
        Puzzle.fromString(`
m00
110
101
110
d00
`)
            .setCanonicalSolutionWithWitness(`4,0/3,1/2,2/1,1/0,0/1,0/2,0/3,0`.split('/').map(Location.fromString))
            .setComment(
                `First double-mover puzzle.
Potentially confusing for people.
Luckily it's impossible to fuck up.`)
        ,
//
// md 3x5 unique
// Path: d:4 m:4  Entropy: 3.58
        Puzzle.fromString(`
01110
d011m
01010
`)
            .setCanonicalSolutionWithWitness(`1,0/2,1/1,2/2,3/1,4/1,3/0,3/0,2/0,1`.split('/').map(Location.fromString))
        ,
//
// md 6x4 unique
// Path: m:11 d:2  Entropy: 4.00
        Puzzle.fromString(`
0111
110m
1110
11d0
0001
0010
`)
            .setCanonicalSolutionWithWitness(`1,3/0,3/0,2/0,1/1,1/1,0/2,0/3,0/3,1/2,1/2,2/3,2/4,3/5,2`.split('/').map(Location.fromString))
            .setComment(
                `Unfolding of the puzzle that started it all.`)
        ,
//
// md 4x4 unique
// Path: m:11 d:2  Entropy: 8.75
        Puzzle.fromString(`
0111
1111
111d
1m10
`)
            .setCanonicalSolutionWithWitness(`3,1/3,0/2,0/1,0/1,1/0,1/0,2/0,3/1,3/1,2/2,2/2,3/3,2/2,1`.split('/').map(Location.fromString))
            .setComment(
                `The puzzle that started it all.`)
        ,
//
// mdd 6x6 unique
// Path: d:6 m:7 d:6  Entropy: 5.58
        Puzzle.fromString(`
00m100
011110
110011
1100d1
0111d0
001100
`)
            .setCanonicalSolutionWithWitness(`4,4/5,3/4,2/3,1/2,0/1,1/0,2/0,3/1,3/1,4/2,4/2,5/3,5/3,4/4,3/5,2/4,1/3,0/2,1/1,2`.split('/').map(Location.fromString))
        ,
//
// md 5x4 unique
// Path: d:3 m:13  Entropy: 6.17
        Puzzle.fromString(`
0111
1111
1d01
11m1
0111
`)
            .setCanonicalSolutionWithWitness(`2,1/1,2/2,3/3,2/3,3/4,3/4,2/4,1/3,1/3,0/2,0/1,0/1,1/0,1/0,2/0,3/1,3`.split('/').map(Location.fromString))
        ,
//
// md 7x4 unique
// Path: d:4 m:19  Entropy: 9.75
        Puzzle.fromString(`
1110
1m11
1011
1111
1011
1d11
1110
`)
            .setCanonicalSolutionWithWitness(`5,1/4,2/3,1/2,0/1,1/1,0/0,0/0,1/0,2/1,2/1,3/2,3/2,2/3,2/3,3/4,3/5,3/5,2/6,2/6,1/6,0/5,0/4,0/3,0`.split('/').map(Location.fromString))
        ,
//
// md 5x5 unique
// Path: m:18 d:2  Entropy: 12.2
        Puzzle.fromString(`
0m111
d1111
11011
11111
01110
`)
            .setCanonicalSolutionWithWitness(`0,1/1,1/1,2/0,2/0,3/0,4/1,4/1,3/2,3/2,4/3,4/3,3/4,3/4,2/4,1/3,1/3,0/2,0/1,0/2,1/3,2`.split('/').map(Location.fromString))
        ,
//
// md 6x4 unique
// Path: m:17 d:3  Entropy: 13.3
        Puzzle.fromString(`
0111
11dm
1111
1110
1111
1110
`)
            .setCanonicalSolutionWithWitness(`1,3/0,3/0,2/0,1/1,1/1,0/2,0/3,0/4,0/5,0/5,1/5,2/4,2/4,1/3,1/2,1/2,2/1,2/2,3/3,2/4,3`.split('/').map(Location.fromString))
        ,
//
// md 5x4 unique
// Path: m:19 d:0  Entropy: 11.8
// Doesn't use end movementType
        Puzzle.fromString(`
1111
d11m
1111
1111
1111
`)
            .setCanonicalSolutionWithWitness(`1,3/0,3/0,2/1,2/2,2/2,3/3,3/4,3/4,2/3,2/3,1/4,1/4,0/3,0/2,0/2,1/1,1/0,1/0,0/1,0`.split('/').map(Location.fromString))
        ,
//
// md 5x5 unique
// Path: d:2 m:20  Entropy: 10.8
        Puzzle.fromString(`
1110m
11111
11d11
11111
10111
`)
            .setCanonicalSolutionWithWitness(`2,2/1,3/0,4/1,4/2,4/2,3/3,3/3,4/4,4/4,3/4,2/3,2/3,1/2,1/1,1/1,2/0,2/0,1/0,0/1,0/2,0/3,0/4,0`.split('/').map(Location.fromString))
        ,
//
// md 6x4 unique
// Path: d:3 m:17  Entropy: 10.6
        Puzzle.fromString(`
0d11
1011
1111
11m1
1111
1110
`)
            .setCanonicalSolutionWithWitness(`0,1/1,0/2,1/3,2/2,2/1,2/0,2/0,3/1,3/2,3/3,3/4,3/4,2/5,2/5,1/5,0/4,0/4,1/3,1/3,0/2,0`.split('/').map(Location.fromString))
        ,
//
// md 6x5 unique
// Path: m:25 d:2  Entropy: 18.1
        Puzzle.fromString(`
1m111
11111
d1111
11111
11111
01110
`)
            .toggleEnabled()
            .setCanonicalSolutionWithWitness(`0,1/0,0/1,0/1,1/2,1/2,2/3,2/3,3/2,3/1,3/1,2/0,2/0,3/0,4/1,4/2,4/3,4/4,4/4,3/5,3/5,2/5,1/4,1/4,0/3,0/2,0/3,1/4,2`.split('/').map(Location.fromString))
        ,
//
// mdd 5x3 unique
// Path: d:4 m:7 d:3  Entropy: 6.75
        Puzzle.fromString(`
m11
111
111
111
dd1
`)
            .setCanonicalSolutionWithWitness(`4,0/3,1/2,0/1,1/0,0/0,1/0,2/1,2/2,2/3,2/4,2/4,1/3,0/2,1/1,0`.split('/').map(Location.fromString))
        ,
//
// mdd 4x4 unique
// Path: d:4 m:7 d:1  Entropy: 4.75
        Puzzle.fromString(`
0111
11m1
d101
d110
`)
            .setCanonicalSolutionWithWitness(`3,0/2,1/3,2/2,3/1,2/1,3/0,3/0,2/0,1/1,1/1,0/2,0/3,1`.split('/').map(Location.fromString))
        ,
//
// mdd 4x4 unique
// Path: d:4 m:3 d:4  Entropy: 5.58
        Puzzle.fromString(`
0d10
1m1d
1111
0110
`)
            .setCanonicalSolutionWithWitness(`1,3/2,2/3,1/2,0/1,1/1,2/0,2/0,1/1,0/2,1/3,2/2,3`.split('/').map(Location.fromString))
        ,
//
// mdd 5x5 unique
// Path: d:1 m:23 d:0  Entropy: 14.9
// Doesn't use end movementType
        Puzzle.fromString(`
11111
d11d1
1111m
11111
11111
`)
            .toggleEnabled()
            .setCanonicalSolutionWithWitness(`1,3/2,4/1,4/0,4/0,3/0,2/1,2/2,2/2,3/3,3/3,4/4,4/4,3/4,2/3,2/3,1/4,1/4,0/3,0/2,0/2,1/1,1/0,1/0,0/1,0`.split('/').map(Location.fromString))
        ,
//
// mdd 5x5 unique
// Path: d:2 m:14 d:2  Entropy: 11.3
        Puzzle.fromString(`
01110
m1111
01110
d111d
01110
`)
            .setCanonicalSolutionWithWitness(`3,0/2,1/1,0/1,1/0,1/0,2/0,3/1,3/1,2/2,2/3,2/3,1/4,1/4,2/4,3/3,3/3,4/2,3/1,4`.split('/').map(Location.fromString))
        ,
//
// mdd 6x4 unique
// Path: d:6 m:7 d:6  Entropy: 8.58
        Puzzle.fromString(`
1111
md11
0110
0110
1111
d111
`)
            .setCanonicalSolutionWithWitness(`5,0/4,1/5,2/4,3/3,2/2,1/1,0/0,0/0,1/0,2/0,3/1,3/1,2/1,1/2,2/3,1/4,0/5,1/4,2/5,3`.split('/').map(Location.fromString))
        ,
//
// mdd 6x5 unique
// Path: d:3 m:23 d:3  Entropy: 20.3
        Puzzle.fromString(`
11111
1d111
1d111
11111
11111
11m11
`)
            .toggleEnabled()
            .setCanonicalSolutionWithWitness(`2,1/3,2/4,1/5,2/5,1/5,0/4,0/3,0/2,0/1,0/0,0/0,1/0,2/0,3/0,4/1,4/2,4/3,4/4,4/5,4/5,3/4,3/3,3/2,3/1,3/1,2/1,1/2,2/3,1/4,2`.split('/').map(Location.fromString))
        ,
//
// mdd 6x6 unique
// Path: d:2 m:15 d:2  Entropy: 9.75
        Puzzle.fromString(`
001100
00m100
11d111
d11111
001100
001100
`)
            .setCanonicalSolutionWithWitness(`3,0/2,1/1,2/0,2/0,3/1,3/2,3/2,4/2,5/3,5/3,4/3,3/4,3/5,3/5,2/4,2/3,2/2,2/3,1/2,0`.split('/').map(Location.fromString))
        ,
//
// mdd 5x7 unique
// Path: d:3 m:19 d:6  Entropy: 15.9
        Puzzle.fromString(`
0111110
1110111
1101111
101d011
111dm11
`)
            .setCanonicalSolutionWithWitness(`3,3/2,4/3,5/4,4/4,5/4,6/3,6/2,6/1,6/1,5/0,5/0,4/0,3/0,2/0,1/1,1/1,0/2,0/3,0/4,0/4,1/4,2/4,3/3,2/2,1/1,2/2,3/1,4/2,5`.split('/').map(Location.fromString))
        ,
//
// mdd 5x5 unique
// Path: d:6 m:13 d:5  Entropy: 13.9
        Puzzle.fromString(`
11m1d
11111
11111
1111d
11111
`)
            .setCanonicalSolutionWithWitness(`0,4/1,3/2,4/3,3/2,2/1,1/0,2/0,1/0,0/1,0/2,0/3,0/4,0/4,1/3,1/3,2/4,2/4,3/4,4/3,4/2,3/1,4/0,3/1,2/2,1`.split('/').map(Location.fromString))
            .setComment(
                `// dave 2017-05-18 (decent)`)
        ,
//
// mdd 5x5 unique
// Path: d:1 m:21 d:2  Entropy: 16.8
        Puzzle.fromString(`
11111
1dm11
111d1
11111
11111
`)
            .setCanonicalSolutionWithWitness(`2,3/1,2/0,2/0,3/0,4/1,4/2,4/3,4/4,4/4,3/3,3/3,2/4,2/4,1/4,0/3,0/3,1/2,1/2,0/1,0/0,0/0,1/1,1/2,2/1,3`.split('/').map(Location.fromString))
        ,
//
// mdd 6x5 unique
// Path: d:4 m:14 d:1  Entropy: 6.75
        Puzzle.fromString(`
01110
11111
1d011
11111
0m0d0
00100
`)
            .setCanonicalSolutionWithWitness(`2,1/1,2/2,3/3,2/4,1/3,1/3,0/2,0/1,0/1,1/0,1/0,2/0,3/1,3/1,4/2,4/3,4/3,3/4,3/5,2`.split('/').map(Location.fromString))
        ,
//
// mdd 5x5 unique
// Path: d:1 m:18 d:1  Entropy: 12.3
        Puzzle.fromString(`
0m110
11d11
111d1
11111
01110
`)
            .toggleEnabled()
            .setCanonicalSolutionWithWitness(`1,2/0,1/0,2/0,3/1,3/1,4/2,4/3,4/3,3/4,3/4,2/4,1/3,1/3,0/2,0/1,0/1,1/2,1/2,2/2,3/3,2`.split('/').map(Location.fromString))
        ,
//
// mdd 6x5 unique
// Path: d:3 m:14 d:2  Entropy: 11.3
        Puzzle.fromString(`
0d010
1111m
11011
d1111
01110
00100
`)
            .setCanonicalSolutionWithWitness(`0,1/1,2/0,3/1,4/1,3/2,3/2,4/3,4/3,3/4,3/4,2/3,2/3,1/2,1/1,1/1,0/2,0/3,0/4,1/5,2`.split('/').map(Location.fromString))
        ,
//
// mdd 7x4 unique
// Path: d:4 m:19 d:4  Entropy: 18.3
        Puzzle.fromString(`
m111
1111
1111
11d1
d111
1111
1111
`)
            .setCanonicalSolutionWithWitness(`4,0/3,1/2,0/1,1/0,0/0,1/0,2/0,3/1,3/1,2/2,2/2,3/3,3/4,3/5,3/6,3/6,2/6,1/6,0/5,0/5,1/5,2/4,2/3,2/4,1/3,0/2,1/1,0`.split('/').map(Location.fromString))
        ,
//
// mdd 6x5 unique
// Path: d:3 m:19 d:4  Entropy: 16.3
        Puzzle.fromString(`
11111
111d1
1d001
1111m
11111
01111
`)
            .setCanonicalSolutionWithWitness(`2,1/3,2/4,3/3,4/4,4/5,4/5,3/5,2/5,1/4,1/4,0/3,0/2,0/1,0/0,0/0,1/1,1/1,2/0,2/0,3/0,4/1,4/1,3/2,4/3,3/4,2/3,1`.split('/').map(Location.fromString))
        ,
//
// mdd 5x5 unique
// Path: d:5 m:9 d:6  Entropy: 11.8
        Puzzle.fromString(`
11d11
11d11
11111
01110
0m110
`)
            .setCanonicalSolutionWithWitness(`1,2/0,1/1,0/2,1/3,2/4,1/4,2/4,3/3,3/2,3/2,4/1,4/0,4/0,3/0,2/1,3/2,2/3,1/2,0/1,1/0,0`.split('/').map(Location.fromString))
            .setComment(
                `Classic early puzzle`)
        ,
//
// mmd 5x7 unique
// Path: m:8 d:4 m:16  Entropy: 10.8
        Puzzle.fromString(`
111d111
1010101
1m11111
1010m01
1111111
`)
            .setCanonicalSolutionWithWitness(`3,4/2,4/2,5/2,6/1,6/0,6/0,5/0,4/0,3/1,4/2,3/3,2/2,1/2,2/1,2/0,2/0,1/0,0/1,0/2,0/3,0/4,0/4,1/4,2/4,3/4,4/4,5/4,6/3,6`.split('/').map(Location.fromString))
        ,
//
// mmd 5x5 unique
// Path: m:11 d:4 m:5  Entropy: 11.9
        Puzzle.fromString(`
01111
11mm1
11011
d1111
01110
`)
            .setCanonicalSolutionWithWitness(`1,3/0,3/0,4/1,4/2,4/3,4/3,3/4,3/4,2/4,1/3,1/3,0/2,1/3,2/2,3/1,2/0,2/0,1/1,1/1,0/2,0`.split('/').map(Location.fromString))
        ,
//
// mmd 5x5 unique
// Path: m:8 d:4 m:7  Entropy: 12.3
        Puzzle.fromString(`
0m110
11111
110m1
11111
011d0
`)
            .toggleEnabled()
            .setCanonicalSolutionWithWitness(`0,1/1,1/1,0/2,0/3,0/3,1/4,1/4,2/4,3/3,2/2,1/1,2/2,3/3,3/3,4/2,4/1,4/1,3/0,3/0,2`.split('/').map(Location.fromString))
        ,
//
// mmd 5x5 unique
// Path: m:8 d:4 m:7  Entropy: 10.8
        Puzzle.fromString(`
0m110
11111
1d011
11111
011m0
`)
            .setCanonicalSolutionWithWitness(`4,3/4,2/4,1/3,1/3,0/2,0/1,0/1,1/2,1/3,2/2,3/1,2/0,1/0,2/0,3/1,3/1,4/2,4/3,4/3,3`.split('/').map(Location.fromString))
        ,
//
// mmd 7x4 unique
// Path: m:8 d:3 m:13  Entropy: 14.8
        Puzzle.fromString(`
0m11
d111
1111
1110
111m
1111
0111
`)
            .toggleEnabled()
            .setCanonicalSolutionWithWitness(`0,1/0,2/0,3/1,3/2,3/2,2/1,2/1,1/1,0/2,1/3,2/4,3/4,2/5,2/5,3/6,3/6,2/6,1/5,1/5,0/4,0/4,1/3,1/3,0/2,0`.split('/').map(Location.fromString))
        ,
//
// mmd 7x4 unique
// Path: m:11 d:3 m:7  Entropy: 10.2
        Puzzle.fromString(`
0111
11m1
11m1
1001
1d11
1110
0110
`)
            .toggleEnabled()
            .setCanonicalSolutionWithWitness(`2,2/2,3/3,3/4,3/4,2/5,2/6,2/6,1/5,1/5,0/4,0/4,1/3,0/2,1/1,2/1,3/0,3/0,2/0,1/1,1/1,0/2,0`.split('/').map(Location.fromString))
        ,
//
// mmd 7x4 unique
// Path: m:10 d:4 m:9  Entropy: 12.9
        Puzzle.fromString(`
1110
1111
m111
0101
m1d1
1111
1110
`)
            .toggleEnabled()
            .setCanonicalSolutionWithWitness(`4,0/4,1/5,1/5,0/6,0/6,1/6,2/5,2/5,3/4,3/4,2/3,3/2,2/3,1/2,0/2,1/1,1/1,0/0,0/0,1/0,2/1,2/1,3/2,3`.split('/').map(Location.fromString))
        ,
//
// mmd 7x4 unique
// Path: m:8 d:4 m:11  Entropy: 12.8
        Puzzle.fromString(`
0m11
d111
1111
1010
1111
11m1
0111
`)
            .setCanonicalSolutionWithWitness(`0,1/0,2/0,3/1,3/2,3/2,2/1,2/1,1/1,0/2,1/3,2/4,1/5,2/4,2/4,3/5,3/6,3/6,2/6,1/5,1/5,0/4,0/3,0/2,0`.split('/').map(Location.fromString))
        ,
//
// mmdd 4x4 unique
// Path: m:6 d:2 m:5 d:2  Entropy: 10.6
        Puzzle.fromString(`
m111
1m11
111d
111d
`)
            .toggleEnabled()
            .setCanonicalSolutionWithWitness(`0,0/1,0/2,0/3,0/3,1/3,2/3,3/2,2/1,1/0,1/0,2/0,3/1,3/2,3/1,2/2,1`.split('/').map(Location.fromString))
        ,
//
// mmdd 5x4 unique
// Path: m:12 d:1 m:5 d:1  Entropy: 13.6
        Puzzle.fromString(`
m111
111m
1dd1
1111
1111
`)
            .toggleEnabled()
            .setCanonicalSolutionWithWitness(`0,0/1,0/2,0/3,0/4,0/4,1/3,1/3,2/4,2/4,3/3,3/2,3/2,2/1,3/0,3/0,2/0,1/1,1/2,1/1,2`.split('/').map(Location.fromString))
        ,
//
// mmdd 5x5 unique
// Path: d:3 m:6 d:1 m:8  Entropy: 7.17
        Puzzle.fromString(`
0m110
11d11
1d011
1110m
01110
`)
            .setCanonicalSolutionWithWitness(`2,1/3,2/2,3/3,4/2,4/1,4/1,3/0,3/0,2/1,2/0,1/1,1/1,0/2,0/3,0/3,1/4,1/4,2/4,3`.split('/').map(Location.fromString))
        ,
//
// mmdd 5x5 unique
// Path: d:2 m:1 d:9 m:5  Entropy: 9.17
        Puzzle.fromString(`
0dm10
10111
1101d
11m01
01110
`)
            .setCanonicalSolutionWithWitness(`2,4/1,3/0,2/0,1/1,0/2,1/1,2/0,3/1,4/2,3/3,4/4,3/3,2/4,2/4,1/3,1/3,0/2,0`.split('/').map(Location.fromString))
        ,
//
// mmdd 5x5 unique
// Path: d:1 m:5 d:6 m:7  Entropy: 11.8
        Puzzle.fromString(`
01m11
m01d1
11011
1110d
11110
`)
            .toggleEnabled()
            .setCanonicalSolutionWithWitness(`1,3/0,2/0,3/0,4/1,4/2,4/3,4/4,3/3,2/2,3/1,2/0,1/1,0/2,0/2,1/3,1/3,0/4,0/4,1/4,2`.split('/').map(Location.fromString))
        ,
//
// mmdd 5x5 unique
// Path: m:9 d:2 m:4 d:3  Entropy: 12.9
        Puzzle.fromString(`
01m10
1111d
11011
11d0m
01110
`)
            .setCanonicalSolutionWithWitness(`0,2/0,1/1,1/1,0/2,0/3,0/3,1/4,1/4,2/3,2/4,3/3,4/2,4/2,3/1,3/1,4/0,3/1,2/2,1`.split('/').map(Location.fromString))
        ,
//
// mmdd 5x5 unique
// Path: d:2 m:1 d:9 m:5  Entropy: 9.17
        Puzzle.fromString(`
0m110
d0111
m1011
11101
01d10
`)
            .toggleEnabled()
            .setCanonicalSolutionWithWitness(`4,2/3,1/2,0/1,0/2,1/3,0/4,1/3,2/4,3/3,4/2,3/1,2/0,1/0,2/0,3/1,3/1,4/2,4`.split('/').map(Location.fromString))
        ,
//
// mmdd 7x4 unique
// Path: m:10 d:4 m:3 d:4  Entropy: 9.58
        Puzzle.fromString(`
1111
1111
100m
d111
1111
0dm0
0110
`)
            .toggleEnabled()
            .setCanonicalSolutionWithWitness(`2,3/1,3/0,3/0,2/1,2/1,1/0,1/0,0/1,0/2,0/3,0/4,1/3,2/4,3/5,2/6,2/6,1/5,1/4,0/3,1/4,2/3,3`.split('/').map(Location.fromString))
            .setComment(
                `A little too simple`)
        ,
//
// mmdd 6x6 unique
// Path: m:11 d:10 m:5 d:3  Entropy: 16.3
        Puzzle.fromString(`
0d1111
101111
d10111
11101m
1m1101
111110
`)
            .setCanonicalSolutionWithWitness(`3,5/2,5/2,4/1,4/1,5/0,5/0,4/0,3/1,3/1,2/0,2/0,1/1,0/2,1/3,2/2,3/3,4/4,5/5,4/4,3/5,2/4,1/5,1/5,0/4,0/3,0/2,0/3,1/4,2/5,3`.split('/').map(Location.fromString))
        ,
//
// mmdd 5x4 unique
// Path: m:10 d:2 m:5 d:2  Entropy: 13.6
        Puzzle.fromString(`
111d
111d
1m11
1111
111m
`)
            .toggleEnabled()
            .setCanonicalSolutionWithWitness(`4,3/4,2/4,1/4,0/3,0/2,0/1,0/0,0/0,1/0,2/0,3/1,2/2,1/3,1/3,2/3,3/2,3/1,3/2,2/1,1`.split('/').map(Location.fromString))
        ,
//
// mmdd 7x4 unique
// Path: m:6 d:4 m:7 d:4  Entropy: 9.75
        Puzzle.fromString(`
1111
1md1
1001
111m
1111
0d10
0110
`)
            .setCanonicalSolutionWithWitness(`3,3/4,3/4,2/5,2/6,2/6,1/5,1/4,0/3,1/2,0/1,1/1,0/0,0/0,1/0,2/0,3/1,3/1,2/2,3/3,2/4,1/3,0`.split('/').map(Location.fromString))
        ,
//
// mmdd 7x4 unique
// Path: d:5 m:9 d:6 m:7  Entropy: 19.5
        Puzzle.fromString(`
1mm1
1111
1111
1111
1111
11d1
11d1
`)
            .setCanonicalSolutionWithWitness(`5,2/4,1/3,2/2,1/1,2/0,1/0,0/1,0/2,0/3,0/4,0/5,0/6,0/6,1/6,2/5,1/4,2/3,1/2,2/1,1/0,2/0,3/1,3/2,3/3,3/4,3/5,3/6,3`.split('/').map(Location.fromString))
        ,
//
// mmdd 7x4 unique
// Path: m:14 d:1 m:11 d:1  Entropy: 19.8
        Puzzle.fromString(`
11m1
1d11
1111
1111
1111
11d1
11m1
`)
            .setCanonicalSolutionWithWitness(`6,2/6,1/6,0/5,0/5,1/4,1/4,0/3,0/3,1/2,1/2,0/1,0/0,0/0,1/1,1/0,2/0,3/1,3/1,2/2,2/2,3/3,3/3,2/4,2/4,3/5,3/5,2/6,3`.split('/').map(Location.fromString))
        ,
//
// mmdd 7x4 unique
// Path: d:5 m:13 d:5 m:4  Entropy: 16.3
        Puzzle.fromString(`
mm11
1111
1111
1d11
1111
11d1
1111
`)
            .setCanonicalSolutionWithWitness(`3,1/4,2/3,3/2,2/1,1/0,0/1,0/2,0/3,0/4,0/4,1/5,1/5,0/6,0/6,1/6,2/6,3/5,3/5,2/4,3/3,2/2,1/1,2/0,1/0,2/0,3/1,3/2,3`.split('/').map(Location.fromString))
        ,
//
// mmdd 7x4 unique
// Path: m:4 d:3 m:11 d:3  Entropy: 11.2
        Puzzle.fromString(`
11d1
111m
m111
d001
1111
0110
0110
`)
            .setCanonicalSolutionWithWitness(`2,0/1,0/0,0/0,1/0,2/1,1/2,2/1,3/2,3/3,3/4,3/4,2/5,2/6,2/6,1/5,1/4,1/4,0/3,0/2,1/1,2/0,3`.split('/').map(Location.fromString))
        ,
//
// mmdd 7x4 unique
// Path: m:7 d:7 m:7 d:6  Entropy: 15.8
        Puzzle.fromString(`
11m1
11d1
1111
1111
d11m
1111
1111
`)
            .toggleEnabled()
            .setCanonicalSolutionWithWitness(`0,2/0,3/1,3/2,3/3,3/3,2/2,2/1,2/0,1/1,0/2,1/3,0/4,1/5,2/4,3/5,3/6,3/6,2/6,1/6,0/5,0/4,0/5,1/4,2/3,1/2,0/1,1/0,0`.split('/').map(Location.fromString))
        ,
//
// mmdd 5x5 unique
// Path: m:8 d:5 m:5 d:6  Entropy: 14.8
        Puzzle.fromString(`
1m111
11m11
1111d
11111
1d111
`)
            .toggleEnabled()
            .setCanonicalSolutionWithWitness(`0,1/0,0/1,0/1,1/2,1/2,0/3,0/4,0/4,1/3,2/4,3/3,4/2,3/1,2/0,2/0,3/0,4/1,4/2,4/1,3/2,2/3,1/4,2/3,3/4,4`.split('/').map(Location.fromString))
        ,
//
// mmdd 5x5 unique
// Path: d:6 m:5 d:6 m:7  Entropy: 15.3
        Puzzle.fromString(`
1d111
11111
1111m
11111
1m11d
`)
            .setCanonicalSolutionWithWitness(`4,4/3,3/4,2/3,1/2,2/1,3/2,4/1,4/0,4/0,3/0,2/0,1/1,2/2,3/3,4/4,3/3,2/4,1/4,0/3,0/2,0/2,1/1,1/1,0/0,0`.split('/').map(Location.fromString))
        ,
//
// mdd 6x6 unique
// Path: d:5 m:25 d:3  Entropy: 21.5
        Puzzle.fromString(`
111m11
1d11d1
110111
111011
111111
111111
`)
            .setCanonicalSolutionWithWitness(`1,4/2,3/3,2/2,1/1,2/0,3/0,4/0,5/1,5/2,5/3,5/3,4/4,4/4,5/5,5/5,4/5,3/4,3/4,2/5,2/5,1/5,0/4,0/4,1/3,1/3,0/2,0/1,0/0,0/0,1/1,1/0,2/1,3/2,4`.split('/').map(Location.fromString))
        ,
//
// mdd 6x6 unique
// Path: d:6 m:23 d:6  Entropy: 23.7
        Puzzle.fromString(`
1m1111
111111
d11111
111111
1d1111
111111
`)
            .setCanonicalSolutionWithWitness(`4,1/3,2/2,3/1,2/2,1/1,0/0,1/0,2/0,3/1,3/1,4/0,4/0,5/1,5/2,5/2,4/3,4/3,5/4,5/5,5/5,4/4,4/4,3/5,3/5,2/5,1/5,0/4,0/3,0/2,0/3,1/4,2/3,3/2,2/1,1/0,0`.split('/').map(Location.fromString))
            .setComment(
                `# A perfect little miracle. the only 6x6 mdd unique
`)
        ,
//
// mdd 6x6 unique
// Path: d:10 m:15 d:10  Entropy: 21.3
        Puzzle.fromString(`
d1m111
111111
111111
111111
111111
11d111
`)
            .setCanonicalSolutionWithWitness(`0,0/1,1/2,0/3,1/4,0/5,1/4,2/3,3/2,2/1,3/0,2/0,3/0,4/0,5/1,5/1,4/2,4/2,5/3,5/3,4/4,4/4,5/5,5/5,4/5,3/5,2/4,3/3,2/2,3/1,2/0,1/1,0/2,1/3,0/4,1/5,0`.split('/').map(Location.fromString))
        ,
//
// mdd 7x6 unique
// Path: d:6 m:29 d:6  Entropy: 27.5
        Puzzle.fromString(`
111111
1111d1
111111
111111
111111
1m1d11
111111
`)
            .setCanonicalSolutionWithWitness(`5,3/4,2/3,3/2,2/3,1/4,0/5,1/5,0/6,0/6,1/6,2/6,3/6,4/6,5/5,5/5,4/4,4/4,5/3,5/3,4/2,4/2,5/1,5/0,5/0,4/0,3/0,2/0,1/0,0/1,0/2,0/2,1/1,1/1,2/1,3/1,4/2,3/3,2/4,3/5,2/4,1/3,0`.split('/').map(Location.fromString))
        ,
]);