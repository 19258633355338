import appInfo from '../package.json';
import {isDev} from "./buildModeChecker";
import {WorldTheme} from "./puzzles/world";

export type DisplayTheme = '3d-theme' | 'original-theme';

class Constants {
    static readonly designMode = false;

    // Some constants to control initialization.
    static initSolveAllFromScratch = false;

    // The color schemes for our worlds.
    static readonly explicitWorldThemes = new Map<number, WorldTheme>([
        [0, {majorHue: 105, minorHue: 216}], /* 0 intro, */
        [1, {majorHue: 19, minorHue: 70}],   /* 1 jumpworld, Aussie outback colors */
        [2, {majorHue: 355, minorHue: 235}], /* 2 bomb_world, */
        [3, {majorHue: 235, minorHue: 150}], /* 3 liegeworld, Royal purple */
        [4, {majorHue: 205, minorHue: 180}], /* 4 slipperyWorld, Frosty ice */
        [5, {majorHue: 148, minorHue: 40}],  /* 5 bulletWorld, */
        [6, {majorHue: 238, minorHue: 118}], /* 6 fontworld, book colors or whatever */
    ]);

    // Analytics initialization
    static readonly reportToGameAnalytics = true;
    static readonly reportToGoogleAnalytics = true;
    static readonly logReportVitals = false;

    // The version we should report. Incremented manually
    static readonly showBuildNumber = false; // Updates eventually get installed without user intervention.
    static readonly versionNumber = appInfo.version;
    static readonly serviceWorkerUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

    // Default global values.
    static readonly overrideEliminateThemeOptionsEntirely = true;
    static readonly themeDefaultValue: DisplayTheme = 'original-theme';
    static readonly soundEnabledDefaultValue = true;
    static readonly animationEnabledDefaultValue = true;
    static readonly lastPlayedWorldDefault = 0;

    // A bit of a hack. Some layout-oriented constants.
    static readonly gameSectionWindowHeightProportion = .85;
    static readonly puzzleSelectorMinimumPermissibleGapProportionOfSquare = .10;
    static readonly pathStartingCircleRadiusProportionOfSquareSize = .11;
    static readonly pathContraptionVisitRadiusProportionOfSquareSize = .33;

    // Let the user into the next world if they've solved this many puzzles or half of the previous world, whichever
    // comes first.
    // TODO: This should be something like 10 for release to incentivize giving me some money.
    static readonly solvesNeededToSeeNextWorld = isDev() ? 0 : 5;
    static readonly overrideEliminateHintsEntirely = false;
    static readonly infiniteHintsMode = true;
    static readonly showQuackCount = false;
    static readonly solvesPerFreeHint = 5;
    // This is for picking theme colors, and can be dispensed with once the true final number of worlds is set.
    static readonly totalNumberOfWorlds = 7;

    // This manually sets the maximum for our .puzzle-layout--completed-<number> classes defining
    // success animations.
    static readonly numCompletionAnimations = 6;

    // Don't let the buttons stay down too long. Click release triggers unpress, but this is for if they're
    // left hanging.
    static readonly buttonUnpressDelayMs = 300;
    // This is hardcoded to the carousel, we're just reproducing it here.
    // static readonly worldMoveTransitionTimeMs: number = 500;
    static readonly worldMoveTransitionTimeMs = 1000;


    // Some puzzley constants for the lab only.
    static readonly keyboardPuzzleText =
        "1234000000\n" +
        "qwertyuiop\n" +
        "asdfghjkl0\n" +
        "zxcvbnm000\n" +
        "0000000000\n" +
        "QWERTYUIOP\n" +
        "ASDFGHJKL0\n" +
        "ZXCVBNM000\n" +
        "0000000000\n" +
        "0000000000\n" +
        "0000000000\n";

    // ...000
    // ......
    // .0.0..
    // ...0..
    // ......
    // ...0..
    // .0.0..
    // ......
    // ...000
    // konigsberg

    static readonly movementTypesThanCanSoloAPuzzle = 'XCxcSmlLpjMrtyfhvbn'.split('').join(',');
    static worldViewerTransitionTimeMs = 600;


}


export default Constants;
