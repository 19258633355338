// world_whatever generated by WorldBuilder
import Puzzle from "../puzzle/puzzle";
import {World} from "./world";
import Location from "../puzzle/location";

export let world_whatever = new World("world_whatever", [
//
// mHY 5x5 unique
// Path: Y:4 H:4 m:8  Entropy: 8.49
    Puzzle.fromString(`
Y111H
11111
11111
1m111
11111
`)
        .setCanonicalSolutionWithWitness(`0,0/2,1/3,3/1,2/0,4/2,3/0,2/1,0/3,1/3,0/4,0/4,1/4,2/4,3/4,4/3,4/2,4`.split('/').map(Location.fromString)),
//
// H 4x5 unique
// Path: H:7  Entropy: 2.58
    Puzzle.fromString(`
01110
011H1
01111
11110
`)
        .setCanonicalSolutionWithWitness(`1,3/3,2/2,4/0,3/1,1/3,0/2,2/0,1`.split('/').map(Location.fromString)),
//
// mpd 4x9 unique
// Path: d:2 m:12 p:5  Entropy: 13.6
    Puzzle.fromString(`
m1111111p
111111111
d00000000
010000000
`)
        .setCanonicalSolutionWithWitness(`2,0/1,1/0,0/0,1/0,2/0,3/1,3/1,4/1,5/0,5/0,6/0,7/1,7/1,8/0,8/1,6/0,4/1,2/3,1/1,0`.split('/').map(Location.fromString))
        .setComment(
            `// dave 2017-05-16
// actually has 2 solns`),
//
// mpd 4x9 unique
// Path: d:2 m:12 p:4  Entropy: 12.6
    Puzzle.fromString(`
m1111111p
011111111
d00000000
010000000
`)
        .setCanonicalSolutionWithWitness(`2,0/1,1/0,0/0,1/0,2/0,3/1,3/1,4/1,5/0,5/0,6/0,7/1,7/1,8/0,8/1,6/0,4/1,2/3,1`.split('/').map(Location.fromString)),
//
// pd 5x5 unique
// Path: p:20 d:2  Entropy: 18.2
    Puzzle.fromString(`
p1101
11111
11d11
11111
10111
`)
        .setCanonicalSolutionWithWitness(`0,0/2,1/4,0/3,2/2,0/0,1/1,3/3,4/4,2/3,0/1,1/2,3/0,4/1,2/2,4/4,3/3,1/1,0/0,2/1,4/2,2/3,3/4,4`.split('/').map(Location.fromString)),
//
// pd 5x5 unique
// Path: p:20 d:2  Entropy: 17.9
    Puzzle.fromString(`
p1101
11111
d1111
11111
10111
`)
        .setCanonicalSolutionWithWitness(`0,0/2,1/3,3/1,4/0,2/1,0/2,2/4,3/2,4/1,2/0,4/2,3/4,4/3,2/1,1/3,0/4,2/3,4/1,3/0,1/2,0/3,1/4,0`.split('/').map(Location.fromString)),
//
// pd 5x5 unique
// Path: p:15 d:4  Entropy: 14.1
    Puzzle.fromString(`
11pd0
11101
11111
10111
01110
`)
        .setCanonicalSolutionWithWitness(`0,2/1,0/2,2/0,1/2,0/4,1/3,3/1,2/0,0/2,1/4,2/3,0/1,1/3,2/2,4/0,3/1,4/2,3/3,4/4,3`.split('/').map(Location.fromString)),
//
// pd 5x5 unique
// Path: p:15 d:4  Entropy: 14.5
    Puzzle.fromString(`
0d110
11111
11011
11111
01p10
`)
        .setCanonicalSolutionWithWitness(`4,2/3,4/1,3/3,2/1,1/0,3/2,4/4,3/3,1/2,3/0,2/1,4/3,3/1,2/2,0/0,1/1,0/2,1/3,0/4,1`.split('/').map(Location.fromString)),
//
// mp 5x6 unique
// Path: m:11 p:8  Entropy: 7.00
    Puzzle.fromString(`
p11100
100111
011110
110011
001m11
`)
        .setCanonicalSolutionWithWitness(`4,3/4,4/4,5/3,5/3,4/2,4/1,4/1,3/0,3/0,2/0,1/0,0/2,1/4,2/3,0/2,2/1,0/3,1/2,3/1,5`.split('/').map(Location.fromString)),
//
// yf 4x5 unique
// Path: f:5 y:14  Entropy: 8.58
    Puzzle.fromString(`
11111
1yf11
11111
11111
`)
        .setCanonicalSolutionWithWitness(`1,2/0,3/0,2/0,1/0,0/1,1/1,0/2,0/3,0/2,1/3,1/2,2/1,3/0,4/1,4/2,4/3,4/3,3/3,2/2,3`.split('/').map(Location.fromString)),
//
// pf 4x7 unique
// Path: p:16 f:1  Entropy: 7.58
    Puzzle.fromString(`
f1p0111
1110101
1110111
0001000
`)
        .setCanonicalSolutionWithWitness(`0,2/1,0/2,2/0,1/2,0/1,2/0,4/1,6/2,4/0,5/2,6/1,4/0,6/2,5/3,3/2,1/0,0/1,1`.split('/').map(Location.fromString))
        .setComment(
            `// dave 2017-05-18
`),
//
// yf 5x4 unique
// Path: y:3 f:14  Entropy: 4.58
    Puzzle.fromString(`
y111
1f11
0110
1111
1111
`)
        .setCanonicalSolutionWithWitness(`0,0/1,0/0,1/1,1/2,2/3,3/3,2/4,3/4,2/4,1/4,0/3,1/3,0/2,1/1,2/0,3/0,2/1,3`.split('/').map(Location.fromString)),
//
// mmmmmmmmddddddd 4x4 unique
// Path: m:1 d:1 m:1 d:1 m:1 d:1 m:1 d:1 m:1 d:1 m:1 d:1 m:1 d:1 m:1  Entropy: 7.91
    Puzzle.fromString(`
mmdd
ddmm
mmd1
ddmm
`)
        .setCanonicalSolutionWithWitness(`3,2/3,1/2,0/3,0/2,1/1,1/0,0/1,0/0,1/0,2/1,3/0,3/1,2/2,2/3,3/2,3`.split('/').map(Location.fromString)),
//
// mmmmmmmmdddddddd 4x4 unique
// Path: m:1 d:1 m:1 d:1 m:1 d:1 m:1 d:1 m:1 d:1 m:1 d:1 m:1 d:1 m:1 d:0  Entropy: 9.00
// Doesn't use end movementType
    Puzzle.fromString(`
mmdd
ddmm
mmdd
ddmm
`)
        .setCanonicalSolutionWithWitness(`3,3/2,3/3,2/3,1/2,0/3,0/2,1/2,2/1,3/0,3/1,2/1,1/0,0/1,0/0,1/0,2`.split('/').map(Location.fromString)),
//
// mlpd 4x4 unique
// Path: d:2 l:5 m:6 p:2  Entropy: 14.5
    Puzzle.fromString(`
1l11
1m11
111d
111p
`)
        .setCanonicalSolutionWithWitness(`2,3/1,2/0,1/0,2/0,3/1,3/2,2/1,1/1,0/2,0/3,0/3,1/3,2/3,3/2,1/0,0`.split('/').map(Location.fromString)),
//
// mlpd 4x4 unique
// Path: l:7 p:3 d:2 m:3  Entropy: 15.5
    Puzzle.fromString(`
1l11
1m11
1p11
1d11
`)
        .setCanonicalSolutionWithWitness(`0,1/0,2/0,3/1,3/2,3/3,3/3,2/2,1/0,0/1,2/3,1/2,2/1,1/1,0/2,0/3,0`.split('/').map(Location.fromString)),
//
// mlpd 4x4 unique
// Path: p:2 l:6 m:7 d:0  Entropy: 14.3
// Doesn't use end movementType
    Puzzle.fromString(`
1d11
1111
l1m1
1p11
`)
        .setCanonicalSolutionWithWitness(`3,1/1,2/2,0/3,0/2,1/1,0/0,0/1,1/2,2/3,2/3,3/2,3/1,3/0,3/0,2/0,1`.split('/').map(Location.fromString)),
//
// mlpd 4x4 unique
// Path: m:11 d:1 l:2 p:1  Entropy: 11.2
    Puzzle.fromString(`
11m1
1111
11pl
11d1
`)
        .setCanonicalSolutionWithWitness(`0,2/0,3/1,3/1,2/1,1/0,1/0,0/1,0/2,0/2,1/3,1/3,2/2,3/3,3/2,2/3,0`.split('/').map(Location.fromString)),
//
// mmldd 4x4 unique
// Path: l:1 m:2 d:2 m:3 d:3  Entropy: 8.91
    Puzzle.fromString(`
0dl0
111m
m1d1
0110
`)
        .setCanonicalSolutionWithWitness(`0,2/1,3/2,3/2,2/3,1/2,0/1,0/1,1/0,1/1,2/2,1/3,2`.split('/').map(Location.fromString)),
//
// pJ 5x5 unique
// Path: p:8 J:6  Entropy: 13.6
    Puzzle.fromString(`
11111
10001
11111
1p011
1111J
`)
        .setCanonicalSolutionWithWitness(`3,1/4,3/2,2/4,1/3,3/2,1/4,2/2,3/4,4/2,4/0,4/0,2/0,0/2,0/4,0`.split('/').map(Location.fromString)),

//
// mpJ 5x5 unique
// Path: p:11 J:4 m:5  Entropy: 18.1
    Puzzle.fromString(`
pJ110
11101
11111
10111
0m111
`)
        .setCanonicalSolutionWithWitness(`0,0/1,2/3,3/1,4/0,2/1,0/2,2/3,0/1,1/3,2/2,0/0,1/0,3/2,3/2,1/4,1/4,2/4,3/4,4/3,4/2,4`.split('/').map(Location.fromString))
        .setComment(
            `absurd. bad.
Piece out of order`)
    ,
]);