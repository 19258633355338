import Key from "./key";
import {World} from "../puzzles/world";
import Constants, {DisplayTheme} from "../constants";
import {EventCounter} from "./userEventTracker";
import Utility from "../utility";
import Puzzle from "../puzzle/puzzle";
import Progress, {LevelProgress, PuzzleHintState, WorldProgress} from "./progress";

class GetProgress {

    static getUserID(): string {
        const key = Key.keyForUserID();
        let id = Utility.getFromStorage(key, (val: string) => val);
        if (!id) {
            id = Progress.generateRandomUserID();
            // console.log(`New ID generated: ${id}`);

            // SetProgress.setUserID(id);
            Utility.setInStorage(key, id);

            // localStorage.setItem(key, id);
        }
        return id;
    }

    static getPuzzleCommentText(puzzle: Puzzle) {
        const key = Key.keyForPuzzleComment(puzzle);
        return Utility.getFromStorage(key, (str: string) => str);
    }

    static getUserHasSolved(world: World): string[] {
        const key = Key.keyForUserHasSolved(world);
        const current = Utility.getFromStorage(key, (solved: string[]) => solved);
        // let val = localStorage.getItem(key);
        // let current: string[] = val ? JSON.parse(val) : [];
        return current || [];
    }

    static getHintsEnabled(): boolean {
        if (Constants.overrideEliminateHintsEntirely) {
            return false;
        }
        const key = Key.keyForHintsEnabled();
        const enabled = Utility.getFromStorage(key, f => !!f);
        return enabled || false;
    }

    //
    // static getTheme(): DisplayTheme {
    //     const fromLS = Utility.getFromStorage('displayTheme', (s: DisplayTheme) => s);
    //     return fromLS || Constants.themeDefaultValue;
    // }

    static getSoundEnabled(): boolean {
        const key = Key.keyForSoundEnabled();
        const enabled = Utility.getFromStorage(key, f => !!f);
        // TODO: Set this true once we're feeling good about sound on mobile.
        if (enabled === undefined) return Constants.soundEnabledDefaultValue;
        return enabled;
    }

    static getAnimationEnabled(): boolean {
        const key = Key.keyForAnimationEnabled();
        const enabled = Utility.getFromStorage(key, f => !!f);
        // TODO: Set this true once we're feeling good about animation on mobile.
        if (enabled === undefined) return Constants.animationEnabledDefaultValue;
        return enabled;
    }

    /**
     * Get the WorldProgress for a world from LocalStorage for puzzles in the world.
     */
    static getWorldProgress(world: World): WorldProgress {
        const worldProgressCache = Progress.worldProgressCache();
        if (!worldProgressCache.has(world.worldName)) {
            let played: string[] = GetProgress.getUserHasPlayed(world);
            let solved: string[] = GetProgress.getUserHasSolved(world);
            // {
            //     const playedKey = Key.keyForUserHasPlayed(world);
            //     let playedVal = localStorage.getItem(playedKey);
            //     played = playedVal ? JSON.parse(playedVal) : [];
            // }
            // {
            //     const solvedKey = Key.keyForUserHasSolved(world);
            //     let solvedVal = localStorage.getItem(solvedKey);
            //     solved = solvedVal ? JSON.parse(solvedVal) : [];
            // }

            const progress = world.puzzles.map(puzzle => {
                return {
                    puzzle: puzzle,
                    played: played.includes(puzzle.toString()),
                    solved: solved.includes(puzzle.toString()),
                    puzzleHintState: GetProgress.getPuzzleHintState(puzzle),
                } as LevelProgress;
            });

            let solvedCount = 0;
            let playedCount = 0;
            let worldHintState: PuzzleHintState = {ownedHints: 0, hintPurchases: 0};
            for (let i = 0; i < world.size; i++) {
                if (progress[i].solved) solvedCount++;
                if (progress[i].played) playedCount++;
                if (progress[i].puzzleHintState) {
                    worldHintState.hintPurchases += progress[i].puzzleHintState.hintPurchases;
                    worldHintState.ownedHints += progress[i].puzzleHintState.ownedHints;
                }
            }
            const worldProgress: WorldProgress = {
                levelProgress: progress,
                totalPuzzleCount: world.size,
                playedCount: playedCount,
                solvedCount: solvedCount,
                worldHintState: worldHintState,
                lastPlayable: solvedCount > 0 ? 2 * solvedCount - 1 : 0,
                nextWorldAccessible: world.nextWorld !== undefined &&
                    (solvedCount >= world.size / 2 || solvedCount >= Constants.solvesNeededToSeeNextWorld),
                infiniteLevelAccessible: false,
            };
            worldProgressCache.set(world.worldName, worldProgress);
        }
        return worldProgressCache.get(world.worldName)!;
    }

    static getEventCounter(counterName: string): EventCounter | undefined {
        const key = Key.keyForEventCounter(counterName);
        const data = Utility.getFromStorage(key, EventCounter.fromParsedJSON);
        return data;

        // const data = localStorage.getItem(key);
        // if (!data) return undefined;
        // return EventCounter.fromParsedJSON(JSON.parse(data));

    }

    static getGlobalEventCounter(): EventCounter {
        const key = Key.keyForGlobalEventCounter();
        const counter = Utility.getFromStorage(key, EventCounter.fromParsedJSON);
        // devLog(counter);
        return counter || new EventCounter();
    }

    static getTheme(): DisplayTheme {
        if (Constants.overrideEliminateThemeOptionsEntirely) {
            return Constants.themeDefaultValue;
        }
        const fromLS = Utility.getFromStorage('displayTheme', (s: DisplayTheme) => s);
        return fromLS || Constants.themeDefaultValue;
    }

    static getUnallocatedHints(): number {
        const key = Key.keyForUnallocatedHints()
        return Utility.getFromStorage(key, (n) => +n) || 0;
    }

    static getLevelProgress(puzzle: Puzzle): LevelProgress {
        const prog = GetProgress.getWorldProgress(puzzle.sourceWorld!);
        return prog.levelProgress[puzzle.indexInWorld];
    }

    static getPuzzleHintState(puzzle: Puzzle): PuzzleHintState {
        const key = Key.keyForPuzzleHintState(puzzle);
        const hintState = Utility.getFromStorage(key, hs => hs);
        return hintState || {hintPurchases: 0, ownedHints: 0};
    }

    static getUserHasPlayed(world: World) {
        const key = Key.keyForUserHasPlayed(world);
        const val = Utility.getFromStorage(key, (val: string[]) => val);
        return val || [];
        // let val = localStorage.getItem(key);
        // let current: string[] = val ? JSON.parse(val) : [];
        // return current;
    }

    // Get the index of the last played world.
    static getLastPlayedWorld() {
        const key = Key.keyForLastPlayedWorld();
        let val = Utility.getFromStorage(key, (val) => +val);
        if (val === undefined) return Constants.lastPlayedWorldDefault;
        return val;
    }

    static getSolvesUntilNextFreeHint() {
        /// If we're configured to allow infinite hints, don't bother to report a changing value.
        if (Constants.infiniteHintsMode) return 2;

        const totalFirstSolves = GetProgress.getGlobalEventCounter().get('completion') || Constants.solvesPerFreeHint;
        const solvesUntilHint = Constants.solvesPerFreeHint - (totalFirstSolves % Constants.solvesPerFreeHint);
        return solvesUntilHint;
    }
}

export default GetProgress;