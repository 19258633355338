import GetProgress from "../progress/getProgress";
import Location from '../puzzle/location'
import React from "react";
import Utility, {ScreenDimensions} from "../utility";
import VisitableRenderer, {ImgRenderTechnique} from "./visitableRenderer";
import ActivePuzzle, {Hint} from "../puzzle/activePuzzle";
import {CumbersomeCssConstantsDeliveredByJs} from "../layoutConstants";
import {GameView} from "./userFacingGame";
import Puzzle, {PuzzleOrientation} from "../puzzle/puzzle";
import {VisitableThing, VisitableThingInfo} from "../puzzle/movement";
import {devLog} from "../buildModeChecker";
import {WorldTheme} from "../puzzles/world";
import SolutionPath from "./solutionPath";

import '../css/animations.css'
import '../css/puzzleLayout.css';
import '../css/puzzleTheme3D.css';
import '../css/puzzleThemeOriginal.css';
import '../css/successAnimations.css';

interface PuzzleLayoutFunctions {
    // Just functions to call whenever the user does something. These collect stats for us.
    completionFn: () => void,
    moveFn: () => void,
    rewindFn: () => void,
    deadEndFn: () => void,
}

interface PuzzleLayoutProps {
    // A few props just for the lab, so we can layout as we please.
    fixedSize?: ScreenDimensions,
    showSolutionPath?: boolean,
    injectedColorScheme?: WorldTheme,

    currentView: GameView,
    puzzleOrientation?: PuzzleOrientation,

    constantsToPutInCss: CumbersomeCssConstantsDeliveredByJs,
    howToRenderTheVisitableThings?: ImgRenderTechnique,
    previouslySolved?: Boolean,

    // hintCount?: number,
    activePuzzle?: ActivePuzzle,
    currentHint?: Hint,

    puzzleFunctions?: PuzzleLayoutFunctions,
}

interface PuzzleLayoutState {
    // activePuzzle?: ActivePuzzle;
    // solution?: Solution;
    // visibilityAnimation?: VisibilityAnimation;
    latestMoveWasByUser: boolean;
}


class PuzzleLayout extends React.Component<PuzzleLayoutProps, PuzzleLayoutState> {
    private forceRenderAfterMove: boolean = true;

    constructor(props: PuzzleLayoutProps) {
        super(props);
        this.state = {
            latestMoveWasByUser: true,
        };
    }

    // React Life Cycle
    componentDidMount() {
    }

    componentDidUpdate(prevProps: Readonly<PuzzleLayoutProps>,
                       prevState: Readonly<PuzzleLayoutState>) {
    }

    //
    // public hide = () => this.setState({
    //     visible: 'hidden',
    //     visibilityAnimation: "animate-hide",
    // });
    //
    //
    // public show = () => this.setState({
    //     visible: 'visible',
    //     visibilityAnimation: "animate-show",
    // });

    //
    // public loadPuzzle(puzzle: Puzzle) {
    //     // Either get the canonical solution or find one from scratch.
    //     let soln = puzzle.canonicalSolution;
    //     if (!soln) {
    //         const solns = new Solver().solve(puzzle);
    //         if (solns.length > 0) soln = solns[0];
    //     }
    //
    //     this.setState({
    //         activePuzzle: new ActivePuzzle(puzzle),
    //         solution: soln,
    //     });
    // }
    //
    // public loadSolution = (solution: Solution) => {
    //     return this.setState(
    //         {
    //             solution: solution,
    //             activePuzzle: solution.getSolvedActivePuzzle().undoAllFn(),
    //         });
    // };

    private isVisible = () => [
        'URL_PUZZLE', 'URL_PUZZLE_COMPLETE', 'PUZZLE', 'PUZZLE_COMPLETE'
    ].includes(this.props.currentView.view);

    public undoMove = () => {
        this.props.activePuzzle?.undoMove();
        this.setState({latestMoveWasByUser: false});
    }

    public undoAll = () => {
        this.props.activePuzzle?.undoAll();
        this.setState({latestMoveWasByUser: false});
    }

    public redoMove = () => {
        this.props.activePuzzle?.redoMove();
        this.setState({latestMoveWasByUser: false});
    }

    private attemptRewind(loc: Location): boolean {
        if (!this.props.activePuzzle || this.props.activePuzzle.isCompleted) return false;
        // If you really want to allow undo here, you also should rewrite the css for the relevant locations.
        const success = this.props.activePuzzle.attemptRewind(loc, false, true);

        if (success) {
            if (this.forceRenderAfterMove) this.setState({latestMoveWasByUser: false,}, this.forceUpdate);
            else this.setState({latestMoveWasByUser: false,});

            if (this.props.puzzleFunctions) this.props.puzzleFunctions.rewindFn();

        }
        return success;
    }

    private attemptMove(loc: Location): boolean {
        if (!this.props.activePuzzle) return false;
        const viableMoves = this.props.activePuzzle.viableMoves();
        const moveForThisSquare = viableMoves.find(m => m.newLocation.equals(loc));
        if (moveForThisSquare) {
            this.props.activePuzzle.addMove(moveForThisSquare);

            if (this.forceRenderAfterMove) this.setState({latestMoveWasByUser: true,}, this.forceUpdate);
            else this.setState({latestMoveWasByUser: true,});

            // Here we can run our tracker functions.
            if (this.props.puzzleFunctions) {
                this.props.puzzleFunctions.moveFn();
                if (this.props.activePuzzle.isCompleted)
                    this.props.puzzleFunctions.completionFn();
                if (this.props.activePuzzle.atDeadEnd())
                    this.props.puzzleFunctions.deadEndFn();
            }

            return true;
        }
        return false;
    }

    private activePuzzleReduxClasses() {
        const activePuzzle = this.props.activePuzzle;
        if (!activePuzzle) return '';

        let ret = [
            'puzzle-layout',
            `puzzle-layout--${this.isVisible() ? 'visible' : 'hidden'}`,
            // `puzzle-layout--${this.state.visibilityAnimation}`,
            activePuzzle.moveCount === 0 ?
                'puzzle-layout--no-moves-made' :
                'puzzle-layout--some-moves-made',
            `puzzle-layout--${GetProgress.getTheme()}`,
        ];
        if (this.state.latestMoveWasByUser)
            ret.push('puzzle-layout--user-performed-last-move');
        if (activePuzzle.isCompleted) {
            ret.push('puzzle-layout--completed');
            ret.push(`puzzle-layout--completed-${Utility.pseudoRandomCompletionAnimationNumber(activePuzzle.puzzle)}`);
        }
        if (!activePuzzle.isCompleted) {
            if (activePuzzle.viableMoves().length === 0)
                ret.push('puzzle-layout--dead-end');
            else ret.push('puzzle-layout--incomplete');
        }
        return ret.join(' ');
    }

    private generatePuzzleSpotModifiers() {
        const {activePuzzle} = this.props;
        const currentHint = this.props.currentHint || {hintBuyable: false, ownedCount: 0};

        let puzzleSpotModifiers = new Map<string, Set<string>>();
        if (!activePuzzle) return puzzleSpotModifiers;
        const lastMove = activePuzzle.lastMove();
        const viableMoves = activePuzzle.viableMoves();

        // We process out a lot of properties for the squares on the basis of the moves made. They're stored here
        // to be easily grabber by each square as it's loc comes up. This makes is Tricky for us to extract squares
        // into their own component, which would be better organization. This is more efficient than getting each
        // square to parse the old move list, and since we're stuck doing all of this processing centrally, we don't
        // even cut many lines out of this render function if we made a square component. Alas.
        const insert = (loc: Location, classSuffix: string) => {
            const key = loc.toString();
            const set = puzzleSpotModifiers.get(key) || puzzleSpotModifiers.set(key, new Set<string>()).get(key)!;
            set.add(classSuffix);
        }

        if (currentHint.highlightedLocation) insert(currentHint.highlightedLocation, 'hinted');
        lastMove?.requiredVisits.forEach(loc => insert(loc, 'required-visit-last-move'));
        lastMove?.optionalVisits.forEach(loc => insert(loc, 'optional-visit-last-move'));
        lastMove?.contraptionVisits.forEach(loc => insert(loc, 'contraption-visit-last-move'));
        if (lastMove) {
            insert(lastMove.newLocation, 'current-location');
        }
        if (lastMove?.oldLocation) insert(lastMove?.oldLocation, 'previous-location');
        viableMoves.forEach(m => {
            insert(m.newLocation, 'possible-destination');
            m.contraptionVisits.forEach(loc => insert(loc, 'contraption-visit-of-possible-next-move'))
            m.requiredVisits.forEach(loc => insert(loc, 'required-visit-of-possible-next-move'))
            m.optionalVisits.forEach(loc => insert(loc, 'optional-visit-of-possible-next-move'))
        })
        // Maybe we want to track the reason for every visit previously made to a spot?
        activePuzzle.moveList.forEach(m => {
            insert(m.newLocation, 'has-been-destination');
        });
        activePuzzle.redoMoveListStack.forEach(m => {
            // Only old moves are redoable, not currently viable ones, which will purge the redo stack.
            if (!viableMoves.find(vm => m.newLocation.equals(vm.newLocation)))
                insert(m.newLocation, 'possible-redo-location');
        });

        return puzzleSpotModifiers;
    }

    private puzzleSpotClasses(elementBlockType: string, loc: Location, puzzleSpotModifiers: Map<string, Set<string>>) {
        const {activePuzzle} = this.props;
        if (!activePuzzle) return '';
        const puzzle = activePuzzle.puzzle;

        const requiredVisits = puzzle.visitsNeeded(loc);
        const remainingVisits = activePuzzle.remainingRequiredVisits(loc);
        let ret = [
            elementBlockType,
            ...Array.from(puzzleSpotModifiers.get(loc.toString()) || [])
                .map(modifier => `${elementBlockType}--${modifier}`)
        ];
        if (requiredVisits.isVoid()) ret.push(`${elementBlockType}--void`);
        if (requiredVisits.gold) {
            ret.push(`${elementBlockType}--gold`);
        } else {
            ret.push(`${elementBlockType}--required-visits-${requiredVisits.visitCount}`);
            ret.push(`${elementBlockType}--remaining-visits-${remainingVisits}`);
        }
        if (VisitableThingInfo.isContraption(puzzle.visitableAtLocation(loc))) ret.push(`${elementBlockType}--contraption`);
        if (VisitableThingInfo.isMovementType(puzzle.visitableAtLocation(loc))) ret.push(`${elementBlockType}--movement-type`);

        return ret.join(' ');
    }

    private puzzleSpotStyle(puzzle: Puzzle, loc: Location, swapped: boolean) {
        if (swapped) loc = loc.transpose();
        let randomDirection = Utility.randomDirection(loc, puzzle);
        return {
            "--loc-column": loc.column,
            "--loc-row": loc.row,
            // TODO: What happens when this comes out as undefined? Does a style get implanted or is it smart?
            "--order-in-rollout": puzzle.orderInRollout(loc, swapped),
            //    These can be used as deterministic random components of a unit-length vector for animating things.
            "--random-dir-x": randomDirection.randomDirX,
            "--random-dir-y": randomDirection.randomDirY,
        } as React.CSSProperties


    }

    // Figure out which visitable should be displayed at a given location.
    private relevantVisitableAtLoc(loc: Location) {
        const {activePuzzle} = this.props;
        // This can't happen.
        if (!activePuzzle) return VisitableThing.None;
        const puzzle = activePuzzle.puzzle;
        const lastMove = activePuzzle.lastMove();

        const isCurrentLocation = lastMove && lastMove.newLocation.equals(loc);
        if (!isCurrentLocation && activePuzzle.remainingRequiredVisits(loc) === 0) {
            return VisitableThing.None;
        }
        let relevantVisitable = puzzle.visitableAtLocation(loc);
        // This is needed if we stepped on a contraption.
        if (isCurrentLocation) {
            relevantVisitable = lastMove!.newMovementType;
        }

        return relevantVisitable;
    }

    render() {
        const {constantsToPutInCss, activePuzzle} = this.props;
        if (!activePuzzle)
            return <div className={'puzzle-layout puzzle-layout--no-puzzle'}>
                {/*<p>No puzzle loaded.</p>*/}
            </div>

        const {puzzle} = activePuzzle;

        const visitableRenderMethod = this.props.howToRenderTheVisitableThings || 'background';

        // TODO: All this shit is too much work to be doing in render(). There shouldn't be any need to regenerate
        //  this all the time.
        const layout = this.props.puzzleOrientation ||
            (constantsToPutInCss.gameBodyAspectRatio >= 1 ? PuzzleOrientation.tall : PuzzleOrientation.wide);
        const orientationHelper = puzzle.orientedLayoutConstants(layout);
        const layoutConstants = Utility.calculateActivePuzzleLayoutConstants(orientationHelper, this.props.fixedSize);
        const swapped = orientationHelper.swapped;

        let style = {
            "--num-cols": orientationHelper.width,
            "--num-rows": orientationHelper.height,
            "--puzzle-spots": orientationHelper.height * orientationHelper.width,
            "--puzzle-visitable-spots": puzzle.totalVisitableLocations,
            "--square-size": `${layoutConstants.squareSizePx}px`,
            // "--grid-gap": `${gridGapPx}px`,
            "--row-gap": `${layoutConstants.rowGapPx}px`,
            "--col-gap": `${layoutConstants.colGapPx}px`,
        } as React.CSSProperties;
        if (this.props.injectedColorScheme) {
            style = {
                ...style,
                '--theme-major-hue': this.props.injectedColorScheme.majorHue,
                '--theme-minor-hue': this.props.injectedColorScheme.minorHue,
            } as React.CSSProperties;
        }

        // We do this for all the spots at once for efficiency.
        const puzzleSpotModifiers = this.generatePuzzleSpotModifiers();

        const puzzleSpotOuterClasses = (loc: Location) => [
            `puzzle-spot__outer`,
            // This is a hack, and is used in Location.fromClassName
            `puzzle-spot__outer--loc-${loc.row}-${loc.column}`,
        ].join(' ');

        return (
            <div className={this.activePuzzleReduxClasses()} style={style}>
                <div className={'puzzle-layout_group'}>

                    {/* Shadows for the visible squares that don't overlap the playable squares. */}
                    <div className={'puzzle-layout__shadow-grid'} key={'puzzle-layout__shadows'}>
                        {orientationHelper.locationList.map((loc, i) => {
                                if (puzzle.visitsNeeded(loc).isVoid()) {
                                    return <div className={'puzzle-layout__filler'} key={loc.toString()}/>
                                } else {
                                    // return <div className={shadowClasses(loc)} key={loc.toString()}/>
                                    return <div className={this.puzzleSpotClasses(
                                        'puzzle-layout__shadow', loc, puzzleSpotModifiers)}
                                                key={loc.toString()}/>
                                }
                            }
                        )}
                    </div>

                    <div className={'puzzle-layout__grid'}>
                        {orientationHelper.locationList.map(loc =>
                            <div className={this.puzzleSpotClasses('puzzle-spot', loc, puzzleSpotModifiers)}
                                 key={puzzle.label + loc.toString()}
                                 style={this.puzzleSpotStyle(puzzle, loc, swapped)}>
                                <div className={puzzleSpotOuterClasses(loc)}
                                     onMouseDown={e => {
                                         // devLog(`onMouseDown in ${loc.toString()}`)
                                         this.attemptMove(loc);
                                         e.preventDefault();
                                     }}
                                     onMouseEnter={e => {
                                         e.preventDefault();
                                         if (e.buttons === 1) this.attemptMove(loc);
                                     }}
                                     onTouchStart={(moveEvent) => {
                                         // preventDefault doesn't work here.
                                         this.attemptMove(loc);
                                         // devLog(`onTouchStart in ${loc.toString()}`)
                                     }}
                                     onClick={(e) => {
                                         devLog(`click in ${loc.toString()}`)

                                         e.preventDefault();
                                         if (!this.attemptMove(loc)) this.attemptRewind(loc);
                                     }}
                                     onTouchMove={moveEvent => {
                                         // preventDefault doesn't work here since the first touched element always receives these.
                                         // devLog(`onTouchMove in ${loc.toString()}`)

                                         // Good doc here https://mcalus.dev/posts/how-to-handle-touch-move
                                         let currentElementTouched = document.elementFromPoint(
                                             moveEvent.touches[0].pageX,
                                             moveEvent.touches[0].pageY
                                         );

                                         // This is a good way of checking for moves that avoids firing off events.
                                         if (currentElementTouched?.classList.contains('puzzle-spot__outer')) {
                                             // devLog(currentElementTouched);
                                             // devLog('attemptMove');
                                             currentElementTouched.classList.forEach(outerClass => {
                                                 const overLoc = Location.fromClassName(outerClass);
                                                 // devLog(`${outerClass} => ${overLoc}`)
                                                 if (overLoc) this.attemptMove(overLoc);
                                             })
                                         }
                                         // // This is a good way of checking for moves and firing off new clicks.
                                         // if (currentElementTouched?.classList.contains('puzzle-spot__outer')) {
                                         //     // devLog(currentElementTouched);
                                         //     devLog('Firing event');
                                         //     currentElementTouched.dispatchEvent(
                                         //         new MouseEvent("click", {
                                         //             view: window,
                                         //             bubbles: true,
                                         //             cancelable: true
                                         //         })
                                         //     );
                                         // } else {
                                         //     devLog(currentElementTouched?.classList);
                                         // }
                                     }}>
                                    <div className={'puzzle-spot__middle'}>
                                        <VisitableRenderer
                                            defaultClassName={'puzzle-spot__inner'}
                                            renderMethod={visitableRenderMethod}
                                            visitable={this.relevantVisitableAtLoc(loc)}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <SolutionPath
                        showSolutionPath={!!this.props.showSolutionPath}
                        layoutConstants={layoutConstants}
                        canonicalSolutionMoves={puzzle.canonicalSolutionMoves}
                        orientationSwapped={orientationHelper.swapped}
                    />

                </div>
            </div>)
    }
}

export default PuzzleLayout;