import Offset from "./offset";
import {devLog} from "../buildModeChecker";

class Location {
    constructor(public row: number, public column: number) {
    }

    public static fromString(s: string): Location {
        let l = s.split(",").map(Number)
        if (l.length !== 2) console.error(`Can't parse Location from "${s}"`)
        return new Location(l[0], l[1])
    }

    transpose(): Location {
        return new Location(this.column, this.row);
    }

    /**
     * Returns the current location modified by an offset, not guaranteed to be on the board.
     * @param offset
     * @constructor
     */
    public move(offset: Offset) {
        return new Location(this.row + offset.verticalOffset, this.column + offset.horizontalOffset)
    }

    /**
     * Compare whether this is the same as another location.
     * @param location
     */
    public equals(location: Location | undefined): boolean {
        if (!location) return false;
        return this.row === location.row && this.column === location.column;
    }

    public toJSON() {
        return this.toString();
    }

    public toString(): string {
        return `${this.row},${this.column}`;
    }

    private static classMatcher = new RegExp(`^puzzle-spot__outer--loc-([0-9]+)-([0-9]+)$`);

    public static fromClassName(cssClass: string): Location | undefined {
            let test = Location.classMatcher.exec(cssClass);
            if (test) {
                // devLog(test)
                const row = test[1], col = test[2];
                return new Location(+row, +col);
            }
    }

}

export default Location;