import React from "react";
import {SearchResults} from "../../puzzle/search";
import Utility from "../../utility";

import '../../css/lab/searchResultsPanel.css'
import Puzzle from "../../puzzle/puzzle";

interface SearchResultsPanelProps {
    // loadSolutionOLD: (solution: Solution) => void,
    loadSolvedPuzzle: (puzzle: Puzzle) => void,
    resetFilters: () => void,
    searchResultsHeight: number,
    solutionsHeight: number,
}

interface SearchResultsPanelState {
    unfilteredSolvedPuzzles: Puzzle[],
    solvedPuzzleListSelection: number | undefined,
    searchResultsList: SearchResults[],
    filterFn: (sp: Puzzle) => boolean,
    filteredSolvedPuzzles: Puzzle[],
    searchResultsListSelection: number | undefined,
}

class SearchResultsPanel extends React.Component<SearchResultsPanelProps, SearchResultsPanelState> {
    private readonly solvedPuzzleListRef: React.RefObject<HTMLSelectElement>;

    constructor(props: SearchResultsPanelProps) {
        super(props);
        this.state = {
            unfilteredSolvedPuzzles: [],
            solvedPuzzleListSelection: undefined,
            searchResultsList: [],
            filterFn: (sp: Puzzle) => true,
            filteredSolvedPuzzles: [],
            searchResultsListSelection: undefined,
        }
        this.solvedPuzzleListRef = React.createRef();
    }

    // React Life Cycle
    componentDidMount() {
        let searchResultsList = Utility.getFromStorage('searchResultsList',
            obj => obj.map(SearchResults.fromJSON))

        this.setState({
            searchResultsList: searchResultsList || [],
            searchResultsListSelection: undefined,
        })
    }

    // getSnapshotBeforeUpdate(nextProps: Readonly<SearchResultsPanelProps>, nextState: Readonly<SearchResultsPanelState>) {
    //     console.log(`Saving searchResultsList length ${nextState.searchResultsList.length}`);
    //     Utility.setInStorage('searchResultsList', nextState.searchResultsList);
    //     return null;
    // }

    componentDidUpdate(prevProps: Readonly<SearchResultsPanelProps>, prevState: Readonly<SearchResultsPanelState>, snapshot?: any): void {
    }

    public selectedSolvedPuzzle() {
        if (this.state.solvedPuzzleListSelection === undefined) return undefined;
        return this.state.filteredSolvedPuzzles[this.state.solvedPuzzleListSelection];
    }

    private filteredOut() {
        return this.state.unfilteredSolvedPuzzles.length - this.state.filteredSolvedPuzzles.length;
    }

    setSolvedPuzzles(solvedPuzzles: Puzzle[]) {
        this.props.resetFilters();
        let newSolvedPuzzleListSelection = (solvedPuzzles.length > 0) ? 0 : undefined;
        if (newSolvedPuzzleListSelection !== undefined) this.props.loadSolvedPuzzle(solvedPuzzles[newSolvedPuzzleListSelection]);
        this.setState({
            filterFn: (s: Puzzle) => true,
            unfilteredSolvedPuzzles: solvedPuzzles,
            filteredSolvedPuzzles: solvedPuzzles,
            solvedPuzzleListSelection: newSolvedPuzzleListSelection,
        });
    }

    private setSolvedPuzzleListSelection(num: number) {
        if (num < 0 || num >= this.state.filteredSolvedPuzzles.length)
            return;
        const solvedPuzzle = this.state.filteredSolvedPuzzles[num];
        this.props.loadSolvedPuzzle(solvedPuzzle);
        this.setState({
            solvedPuzzleListSelection: num,
        });
    }

    appendSolvedPuzzles(solvedPuzzles: Puzzle[]) {
        let newSolvedPuzzles = this.state.unfilteredSolvedPuzzles.concat(solvedPuzzles);
        this.setState({
            unfilteredSolvedPuzzles: newSolvedPuzzles,
            filteredSolvedPuzzles: newSolvedPuzzles.filter(this.state.filterFn),
        });
    }

    public registerSearchResult(results: SearchResults) {
        let newLength = this.state.searchResultsList.length + 1;
        // Immediately open the new SearchResults.
        this.setState(prev => ({
                searchResultsListSelection: newLength - 1,
                searchResultsList: [...prev.searchResultsList, results],
            }),
            () => {
                console.log(`Saving searchResultsList length ${this.state.searchResultsList.length}`);
                // Save the new stuff to LocalStorage.
                Utility.setInStorage('searchResultsList', this.state.searchResultsList);
                this.setSolvedPuzzles(this.state.searchResultsList[newLength - 1].results);
            });

    }

    public setFilter(filterFn: (s: Puzzle) => boolean) {
        let oldSelection = this.selectedSolvedPuzzle();
        let filteredSolvedPuzzles = this.state.unfilteredSolvedPuzzles.filter(filterFn);
        let newSelection = oldSelection ? filteredSolvedPuzzles.indexOf(oldSelection) : 0;
        this.setState({
            filterFn: filterFn,
            filteredSolvedPuzzles: filteredSolvedPuzzles,
            solvedPuzzleListSelection: newSelection,
        })
    }

    public sortSolvedPuzzles(compareFn: (a: Puzzle, b: Puzzle) => number) {
        let oldSelection = this.selectedSolvedPuzzle();
        this.state.filteredSolvedPuzzles.sort(compareFn);
        let newSelection = oldSelection ? this.state.filteredSolvedPuzzles.indexOf(oldSelection) : 0;
        this.setState({
            solvedPuzzleListSelection: newSelection,
        });
    }

    public focusSearchResults() {
        if (this.solvedPuzzleListRef.current)
            this.solvedPuzzleListRef.current.focus();
    }

    render() {
        return <>
            <h2 className={'panel-header'}>Search Results</h2>
            <select name={"searchResultsList"}
                    className={"solution-list"}
                    value={this.state.searchResultsListSelection}
                    size={this.props.searchResultsHeight}
                    onChange={e => {
                        this.setState({searchResultsListSelection: +e.target.value});
                    }}>
                {this.state.searchResultsList.map((s, index) =>
                    <option className={'solution-list__solution'}
                            key={index}
                            value={index}
                            onDoubleClick={
                                (e) => {
                                    this.setSolvedPuzzles(this.state.searchResultsList[+e.currentTarget.value].results);
                                }}
                    >{s.shortDescription()}</option>)}
            </select>
            <input type={"submit"}
                   className={'puzzle-lab__button'}
                   value={"Remove Result"}
                   disabled={this.state.searchResultsListSelection === undefined}
                   onClick={() => {
                       let ls = this.state.searchResultsListSelection;
                       let m = this.state.searchResultsList;
                       if (ls === undefined) return;
                       // Shift all up then pop
                       for (let i = ls; i < m.length - 1; i += 1) m[i] = m[i + 1]
                       m.pop();
                       this.setState({
                           searchResultsListSelection: ls > 0 ? ls - 1 : 0,
                       })
                   }}/>
            &nbsp;
            <input type={"submit"}
                   className={'puzzle-lab__button'}
                   value={"Remove Empties"}
                   onClick={() => {
                       this.setState(prev => ({
                           searchResultsList: prev.searchResultsList.filter(sr => sr.results.length > 0),
                       }))
                   }}/>

            <p className={'info-text'}>Solutions</p>
            <select name={"solutionList"}
                    className={"level-list"}
                    ref={this.solvedPuzzleListRef}
                    value={this.state.solvedPuzzleListSelection}
                    size={this.props.solutionsHeight}
                    onChange={(e) => {
                        const num = +e.target.value;
                        this.setSolvedPuzzleListSelection(num);
                    }}>
                {this.state.filteredSolvedPuzzles.map((s, index) =>
                    <option className={'level-list__level'}
                            key={`solution${index}`}
                            value={index}
                            onClick={(e) => this.setSolvedPuzzleListSelection(index)}>
                        {s.solutionInfo?.shortSolutionAnnotation}
                        {/*{`${s.describePath} ${s.puzzle.toString()} ${s.pathEntropy.toFixed(1)}`}*/}
                    </option>
                )}
            </select>
            <br/>
            <input type={"submit"}
                   className={'puzzle-lab__button'}
                   value={"Clear"}
                   onClick={() => this.setState({
                       unfilteredSolvedPuzzles: [],
                       filteredSolvedPuzzles: [],
                       solvedPuzzleListSelection: undefined,
                   })}/>
            &nbsp;
            {`${Utility.optionalS(this.state.filteredSolvedPuzzles.length, 'solution')} shown, `}
            {`${Utility.optionalS(this.filteredOut(), 'solution')} hidden`}
        </>
    }

}

export default SearchResultsPanel;