import {World} from "../puzzles/world";
import Puzzle from "../puzzle/puzzle";
import {devLog} from "../buildModeChecker";

class Key {

    static keyForUserID() {
        return `dukx-userID`;
    }

    static keyForHintsEnabled() {
        return `HintsEnabled`;
    }

    static keyForSoundEnabled() {
        return `SoundEnabled`;
    }

    static keyForPuzzleComment(puzzle: Puzzle): string {
        // devLog(`puzzle.cleanRawText ${puzzle.cleanRawText}`);
        return `PuzzleComment ${puzzle.cleanRawText}`;
    }

    static keyForAnimationEnabled() {
        return `AnimationEnabled`;
    }

    static keyForUserHasPlayed(world: World): string {
        return `UserHasPlayed ${world.worldName}`;
    }

    static keyForLastPlayedWorld(): string {
        return `LastPlayedWorld`;
    }

    static keyForUserHasSolved(world: World): string {
        return `UserHasSolved ${world.worldName}`;
    }

    static keyForUnallocatedHints(): string {
        return `HintsPurchased`;
    }

    static keyForPuzzleHintState(puzzle: Puzzle): string {
        return `HintsAllocated ${puzzle.toString()}`;
    }

    // This does triple duty for worlds and puzzles and global out of some small convenience. It's a hack.
    static keyForEventCounter(name: string) {
        return `EventCounter ${name}`;
    }

    static keyForGlobalEventCounter() {
        return this.keyForEventCounter('global');
    }

}

export default Key;