import PuzzleLayout from "./puzzleLayout";
import ButtonsPanel, {ButtonsFunctions, ReturnFromPopupOptions} from "./buttonsPanel";
import GetProgress from "../progress/getProgress";
import LayoutConstants from "../layoutConstants";
import Puzzle, {PuzzleOrientation} from "../puzzle/puzzle";
import React, {createRef, ReactNode} from "react";
import SetProgress from "../progress/setProgress";
import UserEventTracker from '../progress/userEventTracker'
import Utility, {ScreenDimensions} from "../utility";
import ActivePuzzle, {Hint} from "../puzzle/activePuzzle";
import {Analytics} from "../progress/analytics";
import {World, WorldTheme} from "../puzzles/world";
import {devLog, devOnly, isDev} from "../buildModeChecker";
import {playSound} from "../audio/sounds";
import {Worlds} from "../puzzles/worlds";
import Progress from "../progress/progress";
import {initializeServiceWorker} from "../initializeServiceWorker";
import Constants from "../constants";
import WorldViewerNicest from "./worldViewerNicest";
import PopupScreen from "./popupScreen";
import OptionsMenu from "./optionsMenu";
import GameInstructions from "./gameInstructions";
import PuzzleComment from "./lab/puzzleComment";
import {Rnd} from "react-rnd";
import '../css/userFacingGame.css'
import '../css/userFacingGameDevStuff.css'

export type GameBodyViewType =
    'POPUP_SCREEN'
    | 'WORLD'
    | 'PUZZLE'
    | 'PUZZLE_COMPLETE'
    | 'URL_PUZZLE'
    | 'URL_PUZZLE_COMPLETE' ;

export interface GameView {
    view: GameBodyViewType,

    worldNumber?: number,
}

export function gameViewEq(first?: GameView, second?: GameView) {
    if (!first || !second) return false;
    if (first.view !== second.view) return false;
    if (first.worldNumber !== second.worldNumber) return false;
    return true;
}

export type LoadPuzzleFn = (world: World, puzzle: Puzzle) => void;

interface UserFacingGameProps {
}

interface UserFacingGameState {
    currentView: GameView,

    currentWorld: World,
    currentActivePuzzle?: ActivePuzzle,
    mostRecentlyPlayedPuzzle?: Puzzle,
    currentHint?: Hint,
    hintJustAllocated: boolean,

    unallocatedHints: number,

    puzzleOrientation: PuzzleOrientation,

    popupEverVisited: boolean,
    // We only need to track this, and update it automatically, so we can fade the buttons from the previous view.
    previousView?: GameView,
    popupContents: () => ReactNode,
    popupTitle?: string,
    returnFromPopupOptions: ReturnFromPopupOptions,

    atDeadEnd: boolean,
    screenSize: ScreenDimensions,

    showSolution: boolean,
    quackCounter: number,

    showNewServiceWorkerMessage: boolean,
    restarting: boolean,
}


class UserFacingGame extends React.Component<UserFacingGameProps, UserFacingGameState> {
    private puzzleLayoutRef = createRef<PuzzleLayout>();
    private readonly initialWorld: World;
    private readonly startedInSinglePuzzleMode: boolean;
    private buttonsFunctions: ButtonsFunctions;

    constructor(props: UserFacingGameProps) {
        super(props);

        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('puzzle');
        const singlePlayerMode = !!myParam;
        let puzzle: ActivePuzzle | undefined;
        if (singlePlayerMode) puzzle = new ActivePuzzle(Puzzle.fromString(myParam!));
        const layoutConstants = LayoutConstants.layoutConstantsToPutInCss();

        this.initialWorld = Worlds.worlds[GetProgress.getLastPlayedWorld()];
        this.startedInSinglePuzzleMode = singlePlayerMode;

        devLog('initial world' + this.initialWorld.indexInWorlds);

        this.buttonsFunctions = {
            allocateHint: this.allocateHint,
            openOptionsMenuFn: this.openOptionsMenuFn,
            openInstructionsFn: this.openInstructionsFn,
            quack: this.quack,
            restartLevel: this.restartLevel,
            returnFromPopup: this.returnFromOptionsMenu,
            shiftToNextWorld: this.shiftToNextWorld,
            shiftToPreviousWorld: this.shiftToPreviousWorld,
            showWorld: this.showWorld,
            startNextLevel: this.startNextLevel,
        };

        this.state = {
            currentView: singlePlayerMode ?
                {view: "URL_PUZZLE"} :
                {view: "WORLD", worldNumber: this.initialWorld.indexInWorlds},
            currentWorld: this.initialWorld,
            currentActivePuzzle: puzzle,
            atDeadEnd: false,
            currentHint: undefined,
            hintJustAllocated: false,
            screenSize: layoutConstants.effectiveScreenSize,
            quackCounter: 0,
            puzzleOrientation: layoutConstants.preferredPuzzleOrientation,
            showSolution: false,
            unallocatedHints: GetProgress.getUnallocatedHints(),
            popupEverVisited: false,

            popupContents: (() => <></>),
            // This shouldn't ever be used since it's set when a popup happens. Maybe should be undefined.
            returnFromPopupOptions: {returnGameView: {view: 'WORLD', worldNumber: this.initialWorld.indexInWorlds}},
            showNewServiceWorkerMessage: false,
            restarting: false,
        };

        // TODO: Listen for service worker changes
        //  https://deanhume.com/displaying-a-new-version-available-progressive-web-app/

        this.openOptionsMenuFn = this.openOptionsMenuFn.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);

        // This sets up our service worker and puts in a situation where we will be prompted to update it when the
        // site's been updated.
        initializeServiceWorker(this.showSkipWaitingPrompt);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    currentHint(): Hint {
        const currentPuzzle = this.state.currentActivePuzzle;
        if (!currentPuzzle) return {
            hintBuyable: false,
            ownedCount: 0,
        };
        const progress = GetProgress.getLevelProgress(currentPuzzle.puzzle);
        return currentPuzzle.currentHint(progress.puzzleHintState.ownedHints);
    }

    loadPuzzle = (world: World, puzzle: Puzzle) => {
        // Just in case, we don't let anything happen in single puzzle mode.
        if (this.state.currentView.view === 'URL_PUZZLE') return;

        SetProgress.setUserHasPlayed(world, puzzle);

        playSound('puzzle-start');

        this.setState(prev => ({
            currentActivePuzzle: new ActivePuzzle(puzzle),
            mostRecentlyPlayedPuzzle: puzzle,
            currentWorld: world,
            currentView: {view: "PUZZLE"},
            previousView: prev.currentView,
            atDeadEnd: false,
            quackCounter: 0,
        }), () => {
            this.updateHintState();
        });

        Analytics.puzzleStart(puzzle);
        UserEventTracker.RegisterEvent('puzzle-start', {
            world: world,
            puzzle: puzzle,
        });
    }

    showSkipWaitingPrompt = () => {
        this.setState({showNewServiceWorkerMessage: true});
        UserEventTracker.RegisterEvent('update-available');
    };

    installNewServiceWorkerAndReload = () => {
        const wb = Progress.getServiceWorkerWorkbox();
        if (!wb) {
            console.error(`Can't find service worker.`)
            return;
        }

        playSound('restart initiated');
        UserEventTracker.RegisterEvent('update-accepted');

        // Assuming the user accepted the update, set up a listener
        // that will reload the page as soon as the previously waiting
        // service worker has taken control.
        wb.addEventListener('controlling', (event) => {
            window.location.reload();
        });
        wb.messageSkipWaiting();
    };

    registerCurrentPuzzleCompleted = () => {

        // If this is a single puzzle, we don't do much here since we don't want to fill LocalStorage with junk.
        if (this.state.currentView.view === 'URL_PUZZLE') {
            this.setState(prev => ({
                currentView: {view: 'URL_PUZZLE_COMPLETE'},
                previousView: prev.currentView,
            }));
            UserEventTracker.RegisterEvent('url-load-puzzle-completion');
            return;
        }

        this.setState(prev => ({
            currentView: {view: 'PUZZLE_COMPLETE'},
            previousView: prev.currentView,
        }));

        const world = this.state.currentWorld;
        const puzzle = this.state.currentActivePuzzle?.puzzle;

        if (!world || !puzzle)
            console.error('registerCurrentPuzzleCompleted called with puzzle or world not known.');
        else {
            const firstSolve = SetProgress.setUserHasSolved(world, puzzle);
            const eventName = firstSolve ? 'completion' : 're-solve';

            if (firstSolve) {
                Analytics.puzzleSolve(world, puzzle);
            } else {
                Analytics.puzzleReSolve(world, puzzle);
            }

            UserEventTracker.RegisterEvent(eventName, {
                world: world,
                puzzle: puzzle,
            });

            // Distribute a free hint if we just ticked over the line.
            if (!Constants.infiniteHintsMode &&
                firstSolve &&
                GetProgress.getSolvesUntilNextFreeHint() % Constants.solvesPerFreeHint === 0) {
                this.getFreeHint();
                playSound('completion-with-earned-hint');
            } else {
                playSound('completion');
            }
        }
    }

    registerMove = () => {
        this.updateHintState();
        const ap = this.state.currentActivePuzzle;
        if (ap && ap.puzzle.canonicalSolutionMoves) {
            // playMoveSound(ap.moveCount, ap.puzzle.canonicalSolution.moves.length);
            playSound('move');
        } else {
            playSound('move');
        }
        this.setState({hintJustAllocated: false,});

        let attribution =
            {
                world: this.state.currentWorld,
                puzzle: this.state.currentActivePuzzle?.puzzle,
            };
        UserEventTracker.RegisterEvent('move', attribution);
        Analytics.puzzleMove(this.state.currentWorld, this.state.currentActivePuzzle?.puzzle);
    }

    registerRewind = () => {
        this.updateHintState();
        playSound('move');
        let attribution =
            {
                world: this.state.currentWorld,
                puzzle: this.state.currentActivePuzzle?.puzzle,
                hintJustAllocated: false,
            };
        UserEventTracker.RegisterEvent('rewind', attribution);
        // TODO: This could be a different thing.
        Analytics.puzzleMove(this.state.currentWorld, this.state.currentActivePuzzle?.puzzle);
    }

    registerDeadEnd = () => {
        playSound('deadend');
        this.setState({atDeadEnd: true,});
        let attribution =
            {
                world: this.state.currentWorld,
                puzzle: this.state.currentActivePuzzle?.puzzle,
            };
        UserEventTracker.RegisterEvent('deadend', attribution);
        Analytics.puzzleDeadend(this.state.currentWorld, this.state.currentActivePuzzle?.puzzle);
    }

    getFreeHint = () => {
        console.log('Free hint earned!');
        playSound('acquire-free-hint');
        UserEventTracker.RegisterEvent('acquire-free-hint');
        Analytics.acquireHint();
        SetProgress.incrementUnallocatedHints();
        this.updateHintState();
    }
    //
    // private worldViewerRight: VoidFunction = () => {
    //     this.worldViewerRef.current?.goNext();
    // };
    //
    // private worldViewerLeft: VoidFunction = () => {
    //     this.worldViewerRef.current?.goPrev();
    // };

    // This is from a nice tip: https://stackoverflow.com/questions/19014250/rerender-view-on-browser-resize-with-react
    private updateDimensions = () => {
        const layoutConstants = LayoutConstants.layoutConstantsToPutInCss();
        this.setState({
            screenSize: layoutConstants.effectiveScreenSize,
            puzzleOrientation: layoutConstants.preferredPuzzleOrientation,
        });
    }

    private restartLevel = () => {
        this.puzzleLayoutRef.current?.undoAll();

        this.updateHintState();
        this.setState({
            atDeadEnd: false,
            hintJustAllocated: false,
        });

        let attribution = {
            world: this.state.currentWorld,
            puzzle: this.state.currentActivePuzzle?.puzzle,
        };
        UserEventTracker.RegisterEvent('restart', attribution);
        Analytics.puzzleRestart(this.state.currentWorld, this.state.currentActivePuzzle?.puzzle!);
    }

    private startNextLevel = () => {
        const nextPuzzleInWorldOrder = this.state.currentActivePuzzle?.puzzle.nextPuzzleInWorldOrder;
        if (!nextPuzzleInWorldOrder || !nextPuzzleInWorldOrder.sourceWorld) {
            console.error("Shouldn't be able to call startNextLevel without a known next level");
            return;
        }
        this.loadPuzzle(nextPuzzleInWorldOrder.sourceWorld, nextPuzzleInWorldOrder);
    }

    private returnFromOptionsMenu = () => {
        this.setState(prev => ({
            currentView: prev.returnFromPopupOptions.returnGameView,
            // This should certainly be 'OPTIONS', but we do it like this for consistency.
            previousView: prev.currentView,
        }));
    }

    private showWorld = () => {
        devLog('showWorld')
        devLog(this.state.currentWorld)
        devLog(this.state.currentWorld.indexInWorlds)
        this.setState(prev => ({
            // currentActivePuzzle: undefined,
            currentView: {view: "WORLD", worldNumber: prev.currentWorld.indexInWorlds},
            previousView: prev.currentView,
            hintJustAllocated: false,
        }));
    }

    private shiftToPreviousWorld = () => {
        const newWorld = this.state.currentWorld.prevWorld;
        if (!newWorld) return;
        playSound('world-previous');
        UserEventTracker.RegisterEvent('world-previous',
            {
                world: this.state.currentWorld,
                puzzle: this.state.currentActivePuzzle?.puzzle,
            });
        SetProgress.setLastPlayedWorld(newWorld);

        // this.worldViewerRef.current?.switchTo(newWorld.indexInWorlds)

        this.setState(prev => ({
            currentWorld: newWorld,
            currentView: {view: "WORLD", worldNumber: newWorld.indexInWorlds},
            previousView: prev.currentView,
        }));

    }

    private shiftToNextWorld = () => {
        const newWorld = this.state.currentWorld.nextWorld;
        if (!newWorld) return;
        playSound('world-next');
        UserEventTracker.RegisterEvent('world-next',
            {
                world: this.state.currentWorld,
                puzzle: this.state.currentActivePuzzle?.puzzle,
            });
        SetProgress.setLastPlayedWorld(newWorld);

        // this.worldViewerRef.current?.switchTo(newWorld.indexInWorlds);

        this.setState(prev => ({
            currentWorld: newWorld,
            currentView: {view: "WORLD", worldNumber: newWorld.indexInWorlds},
            previousView: prev.currentView,
        }));
    }

    private quack = () => {
        this.setState(prev => ({quackCounter: prev.quackCounter + 1,}));
        Utility.vibrate(100);
        playSound('quack');

        const attribution = {
            world: this.state.currentWorld,
            puzzle: this.state.currentActivePuzzle?.puzzle,
        };
        UserEventTracker.RegisterEvent('quack', attribution);
    }

    private allocateHint = () => {
        if (this.state.currentView.view === 'URL_PUZZLE') {
            devLog("This shouldn't happen. No hints in URL_PUZZLE mode.")
            return;
        }

        const currentActivePuzzle = this.state.currentActivePuzzle;
        if (!currentActivePuzzle) return;

        const currentPuzzle = currentActivePuzzle.puzzle;
        if (!currentPuzzle || this.state.currentHint?.ownedCountIfBought === undefined) return;

        // In infiniteHintsMode we always successfully allocate a hint, and don't even get charged for it.
        const success = SetProgress.trySpendUnallocatedHint(currentPuzzle, this.state.currentHint.ownedCountIfBought);
        console.log(`success ${success}`)
        if (success) {
            playSound('allocate-hint');
            this.setState({hintJustAllocated: true});
        }
        currentActivePuzzle.clearRedoStack();
        this.updateHintState();

        const attribution = {
            world: this.state.currentWorld,
            puzzle: currentActivePuzzle.puzzle,
        };
        UserEventTracker.RegisterEvent('allocate-hint', attribution);
        Analytics.allocateHint(this.state.currentWorld, currentActivePuzzle.puzzle!);
    }

    private updateHintState() {
        if (this.state.currentView.view === 'URL_PUZZLE') return;
        // console.log(this.currentHint())
        this.setState({
            currentHint: this.currentHint(),
            unallocatedHints: GetProgress.getUnallocatedHints(),
        })
    }

    private openPopupFn = (title: string, returnOptions: ReturnFromPopupOptions, contentsFn: () => ReactNode) =>
        (() => {
            this.setState(prev => ({
                currentView: {view: "POPUP_SCREEN"},
                popupContents: contentsFn,
                popupTitle: title,
                previousView: prev.currentView,
                popupEverVisited: true,
                returnFromPopupOptions: returnOptions,
            }));
        })


    private readonly openOptionsMenuFn = (returnOptions: ReturnFromPopupOptions) =>
        this.openPopupFn('Options Menu', returnOptions,
            () =>
                <OptionsMenu
                    unallocatedHints={this.state.unallocatedHints}
                    getHintFn={this.getFreeHint}
                    quackFn={this.quack}
                />);


    private readonly openInstructionsFn = (returnOptions: ReturnFromPopupOptions) =>
        this.openPopupFn('Instructions', returnOptions, () => <GameInstructions/>);


    private wipNote() {
        if (this.state.restarting) {
            return <div>Restarting...</div>;
        }
        if (this.state.showNewServiceWorkerMessage) {
            return <div>Update available!&nbsp;
                <strong className={'update-link'} onClick={this.installNewServiceWorkerAndReload}>
                    Restart
                </strong>
            </div>
        }
        return <div>WIP Build {Constants.versionNumber}</div>;
    }

    private headerText() {
        switch (this.state.currentView.view) {
            case "URL_PUZZLE_COMPLETE":
                return 'Well done!';
            case "URL_PUZZLE":
                return 'Enjoy this puzzle!';
            case "POPUP_SCREEN":
                return this.state.popupTitle;
            case "PUZZLE_COMPLETE":
            case "PUZZLE":
                if (!this.state.currentActivePuzzle?.puzzle) {
                    console.error("In puzzle view with no this.state.currentPuzzle");
                    return "Work in Progress. :)";
                }
                // const cnt = Progress.getEventCounter(this.state.currentPuzzle.toString())?.toString();
                // return cnt?.toString();
                const solvedIndicator = GetProgress.getLevelProgress(this.state.currentActivePuzzle.puzzle).solved ?
                    '☑' : '☐';
                return `${this.state.currentActivePuzzle.puzzle.label} ${solvedIndicator}`;
            case "WORLD":
                // Maybe should use this.state.currentView.worldNumber
                const progress = GetProgress.getWorldProgress(this.state.currentWorld);
                const worldDisplayName = this.state.currentWorld.indexInWorlds + 1;
                return `(${worldDisplayName}) ${progress.solvedCount} / ${progress.totalPuzzleCount}`
        }
    }

    render(): React.ReactNode {

        const layoutConstants = LayoutConstants.layoutConstantsToPutInCss();
        const gameLayout = LayoutConstants.gameLayout;
        const worldTheme: WorldTheme = this.state.currentWorld.getColorSchemeTheme();

        let style = {
            // We need to write these in px, not vh. https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
            '--button-bar-height': `${layoutConstants.buttonHeightPx}px`,
            '--text-overlay-height': `${layoutConstants.textOverlayHeightPx}px`,
            '--game-body-height': `${layoutConstants.gameBodySlideOutPx}px`,
            '--world-move-transition-time': `${layoutConstants.worldMoveTransitionTime}ms`,
            '--theme-major-hue': worldTheme.majorHue,
            '--theme-minor-hue': worldTheme.minorHue,
            // '--theme-complement-hue': worldTheme.complementHue,
            // '--theme-random-number-1': worldTheme.randomNumber1,
            // '--theme-random-number-2': worldTheme.randomNumber2,
            // '--theme-major-hue': this.state.themeLow,
            // '--theme-minor-hue': this.state.themeHigh,
        } as React.CSSProperties;

        let hintsEnabled = GetProgress.getHintsEnabled();
        let hintsClass = hintsEnabled ? 'user-facing-game--hints-enabled' : 'user-facing-game--hints-disabled';
        let getAnimationEnabled = GetProgress.getAnimationEnabled();
        let animationsClass = getAnimationEnabled ?
            'user-facing-game--animations-enabled' :
            'user-facing-game--animations-disabled';

        return <div className={`user-facing-game ${hintsClass} ${animationsClass}`} style={style}>
            <div className={`game-body game-body--${gameLayout.gameBodyOrientation}`} key={'GameMainSection'}>
                <div className={'game-body__component-view'}>
                    {this.startedInSinglePuzzleMode ?
                        <></> :
                        <WorldViewerNicest
                            currentView={this.state.currentView}
                            previousView={this.state.previousView}
                            puzzleOrientation={this.state.puzzleOrientation}
                            worlds={Worlds.worlds}
                            currentWorld={this.state.currentWorld}
                            disallowVisibilityAnimation={
                                this.startedInSinglePuzzleMode
                                // ||
                                // this.state.currentView === 'POPUP_SCREEN' ||
                                // this.state.previousView === 'POPUP_SCREEN'
                            }
                            mostRecentlyPlayedPuzzle={this.state.mostRecentlyPlayedPuzzle}
                            loadPuzzleFn={this.loadPuzzle}
                        />
                    }

                    <PuzzleLayout
                        ref={this.puzzleLayoutRef}
                        puzzleFunctions={{
                            moveFn: this.registerMove,
                            completionFn: this.registerCurrentPuzzleCompleted,
                            rewindFn: this.registerRewind,
                            deadEndFn: this.registerDeadEnd,
                        }}
                        activePuzzle={this.state.currentActivePuzzle}
                        currentView={this.state.currentView}
                        constantsToPutInCss={layoutConstants}
                        puzzleOrientation={this.state.puzzleOrientation}
                        currentHint={this.state.currentHint}
                        showSolutionPath={this.state.showSolution}
                    />

                    <PopupScreen
                        currentView={this.state.currentView}
                        previousView={this.state.previousView}
                        allowVisibilityAnimation={this.state.popupEverVisited}
                        contentsFn={this.state.popupContents}
                    />


                </div>
            </div>

            <ButtonsPanel
                buttonsFunctions={this.buttonsFunctions}
                atDeadEnd={this.state.atDeadEnd}
                nextPuzzleInWorldOrderExists={!!this.state.currentActivePuzzle?.puzzle.nextPuzzleInWorldOrder}
                currentView={this.state.currentView}
                previousView={this.state.previousView}
                currentWorld={this.state.currentWorld}
                gameButtonsOrientation={gameLayout.gameButtonsOrientation}
                unallocatedHints={this.state.unallocatedHints}
                quackCounter={this.state.quackCounter}
                currentHint={this.state.currentHint}
                hintJustAllocated={this.state.hintJustAllocated}
                showHints={hintsEnabled}
                noMovesMade={this.state.currentActivePuzzle?.moveCount === 0}
            />

            <div className={`game-text-overlay game-text-overlay--${gameLayout.levelDisplayOrientation}`}
                 key={'level-display'}
                 onClick={() => {
                     if (isDev()) {
                         SetProgress.toggleTheme();

                         console.log('Secret click.');
                         this.setState(prev => {
                             return {showSolution: !prev.showSolution}
                         }, () => {
                             this.forceUpdate();
                         });
                     }
                 }}>
                {this.headerText()}

            </div>

            {Constants.showBuildNumber ?
                <div className={`game-text-overlay game-text-overlay--${gameLayout.workInProgressOrientation}`}
                     key={'WIP-note'}>
                    {this.wipNote()}
                </div> :
                <></>
            }
            {/* So we can write comments on puzzles */}
            {devOnly(() => <Rnd
                default={{
                    x: 345, y: 920,
                    width: 215, height: 120,
                }}
            >
                <PuzzleComment puzzle={this.state.currentActivePuzzle?.puzzle} rows={5}/>
            </Rnd>)}
        </div>
    }

}

export default UserFacingGame;