import React from "react";

import '../css/puzzleThemeOriginal.css';
import '../css/puzzleTheme3D.css';

import '../css/popupScreen.css';


interface GameInstructionsProps {
}

interface GameInstructionsState {
}


class GameInstructions extends React.PureComponent<GameInstructionsProps, GameInstructionsState> {
    // private pwaAuthRef = createRef<PwaAuth>();

    constructor(props: GameInstructionsProps) {
        super(props);
    }

    render(): React.ReactNode {
        return (
            <>
                    <div className={'instruction-group'}>
                        <div className={'instruction-heading'}>What's Going On?</div>
                        <div className={'instruction-text'}>You control a symbol moving around a grid.</div>
                    </div>
                    <div className={'instruction-group'}>
                        <div className={'instruction-heading'}>What Should I Do?</div>
                        <div className={'instruction-text'}>
                            You have to visit every square in the grid.
                        </div>
                    </div>
                    <div className={'instruction-group'}>
                        <div className={'instruction-heading'}>Sounds Easy</div>
                        <div className={'instruction-text'}>
                            There are limits on how you can move.
                        </div>
                    </div>
                    <div className={'instruction-group'}>
                        <div className={'instruction-heading'}>What sort of limits?</div>
                        <li className={'instruction-text'}>
                            Some squares have a mover in them.
                        </li>
                        <li className={'instruction-text'}>
                            It sets how you move until you find a different one.
                        </li>
                        <li className={'instruction-text'}>
                            You can only visit each square once.
                        </li>
                    </div>
                    <div className={'instruction-group'}>
                        <div className={'instruction-heading'}>OK</div>
                        <div className={'instruction-text'}>
                            Also there's bombs and stuff later on.
                        </div>
                    </div>
                    <div className={'instruction-group'}>
                        <div className={'instruction-heading'}>Right. Fine. </div>
                        <div className={'instruction-text'}>
                            Learn by playing. Enjoy!
                        </div>
                    </div>

            </>
        );
    }

}

export default GameInstructions;