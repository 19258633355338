// of puzzles.
import Puzzle, {PuzzleOrientation} from "./puzzle";
import {Move, MoveSequence} from "./move";
import {VisitableThing, VisitableThingInfo} from "./movement";
import Utility from "../utility";
import ActivePuzzle from "./activePuzzle";

export type MovementTypeUse = {
    movementType: VisitableThing,
    count: number
}

export interface VisitableVisitOrder {
    visited: VisitableThing[],
    unvisited: VisitableThing[],
}

export class SolutionInfo {
    constructor(private puzzle: Puzzle, private moveSequence: MoveSequence) {
    }

    private _describePath: string | undefined;

    get describePath() {
        if (this._describePath === undefined) {
            this._describePath = this.movementTypeSequence
                .map(su => `${VisitableThingInfo.keyCode(su.movementType)}:${su.count}`)
                .join(" ");
        }
        return this._describePath;
    }

    private _pathIntersections: number | undefined;

    get pathIntersections(): number {
        if (this._pathIntersections === undefined) {
            this._pathIntersections = 0;
            type LineSegment = [number, number, number, number];
            // Each move is a line segment
            let segment: (m: Move) => LineSegment = (m: Move) =>
                [m.oldLocation!.column, m.oldLocation!.row, m.newLocation.column, m.newLocation.row];
            let intersects = (a: LineSegment, b: LineSegment) =>
                Utility.lineSegmentsIntersect(a[0], a[1], a[2], a[3], b[0], b[1], b[2], b[3]);
            // Ignore the first move, which cannot always comes out of nowhere and so represents a point instead of a line segment.
            let segments = this.moveSequence.slice(1).map(m => segment(m));

            let count = 0;
            for (let i = 0; i < segments.length; i++) {
                // Offset by 2 since we know consecutive moves always intersect and this doesn't interest us.
                for (let j = i + 2; j < segments.length; j++) {
                    if (intersects(segments[i], segments[j])) this._pathIntersections++;
                }
            }
        }
        return this._pathIntersections;
    }

    private _pathReuse: number[] | undefined;

    /**
     * For every path that is used more than once, getEventCount the number of reuses that it gets. This is
     * independent of direction, so a move from a gold and then right back to that gold registers as 1.
     */
    get pathReuse(): number[] {
        if (this._pathReuse === undefined) {
            // Location.toString here is lame, but it lets us make a canonical order easily.
            let segments = this.moveSequence.slice(1)
                .map(m => [m.oldLocation!.toString(), m.newLocation.toString()])
                .map(seg => seg.sort().join()) // Canonicalize the line segments by lexicographic order.
            // Simple counter from https://stackoverflow.com/a/19395302/6998241
            let counter = new Map<string, number>();
            segments.forEach(function (x) {
                counter.set(x, (counter.get(x) || 0) + 1);
            });
            let counts = Array.from(counter.values())
            this._pathReuse = counts.map(n => n - 1).filter(x => x > 0).sort().reverse();
        }
        return this._pathReuse;
    }

    private _movementTypeSequence: MovementTypeUse[] | undefined;

    get movementTypeSequence(): MovementTypeUse[] {
        if (this._movementTypeSequence === undefined) {
            this._movementTypeSequence = [];
            let cnt = 1;
            for (let m of this.moveSequence) {
                if (m.newMovementType === m.oldMovementType) {
                    cnt += 1;
                } else {
                    // This lets us ignore the first movement onto a MovementType that itself has no MovementType.
                    if (m.oldMovementType) {
                        this._movementTypeSequence.push({movementType: m.oldMovementType, count: cnt});
                    }
                    cnt = 1;
                }
            }
            // We have to count the final sequence, which isn't pushed because it doesn't end.
            this._movementTypeSequence.push({
                movementType: this.moveSequence[this.moveSequence.length - 1].newMovementType,
                count: cnt - 1
            });
        }
        return this._movementTypeSequence;
    }

    private _shortestMovementTypeUse: number | undefined;

    // The shortest number of moves taken by one of the movement types utilized in the solution.
    get shortestMovementTypeUse(): number {
        if (this._shortestMovementTypeUse === undefined) {
            this._shortestMovementTypeUse = Math.min(...this.movementTypeSequence.map(mt => mt.count));
        }
        return this._shortestMovementTypeUse;
    }

    private _pathEntropy = -1;

    //
    // public goldTouchCount() {
    //     return this.moves.reduce((tot, m) =>
    //         tot + (this.puzzle.isGoldSquare(m.newLocation) ? 1 : 0), 0);

    // }

    /**
     * Log of the product of the alternatives available along the route. This is meant to approximate the complexity
     * of the path, or the difficulty of the underlying puzzle. In reality, a lot of the moves are super obvious, even
     * long sequences sometimes.
     */
    get pathEntropy(): number {
        if (this._pathEntropy === -1) {
            this._pathEntropy = 0.0;
            let i = new ActivePuzzle(this.puzzle);
            for (let m of this.moveSequence) {
                let moves = i.viableMoves();
                this._pathEntropy += Math.log(moves.length);
                i.addMove(m)
            }
        }
        return this._pathEntropy / Math.log(2);
    }

    private _goldTouchCounts: number[] | undefined;

    //
    // static fromStructuredJSON(s: any) : Solution{
    //     // We are lazy and not incorporating
    //     let puzzle = Puzzle.fromString(s.puzzle.rawText);
    //     let moves = s.moves.map(parseMoveFromStructuredJSON);
    //
    //     return new Solution(puzzle, moves);
    // }
    //
    // public static fromStructuredJSON(json: any): Solution {
    //     const puzzle = Puzzle.fromStructuredJSON(json.puzzle);
    //     let moves: Move[] = json.moves.map(Move.FromAnyJSON);
    //     return new Solution(puzzle, moves);
    // }

    get goldTouchCounts() {
        if (this.puzzle.goldCount === 0) return [];
        if (this._goldTouchCounts === undefined) {
            let visits = this.moveSequence.map(m => m.allVisits()).flat();
            this._goldTouchCounts = this.puzzle.orientedLayoutConstants(PuzzleOrientation.default).locationList
                .filter(loc => this.puzzle.isGoldSquare(loc))
                .map(gloc => visits.filter(loc => loc.equals(gloc)).length).sort();
        }
        return this._goldTouchCounts;
    }

    private _movementTypeChanges: number | undefined;

    get movementTypeChanges() {
        if (this._movementTypeChanges === undefined) {
            this._movementTypeChanges = this.moveSequence.reduce((tot, m) =>
                tot + (m.newMovementType !== m.oldMovementType ? 1 : 0), 0);
        }
        return this._movementTypeChanges;
    }

    // Get an array with the number of touches that each gold square gets over the course of the solution.

    private _contraptionCausedVisitsCount: number | undefined;

    get contraptionCausedVisitsCount() {
        if (this._contraptionCausedVisitsCount === undefined) {
            this._contraptionCausedVisitsCount = Utility.sum(this.moveSequence.map(m => m.contraptionVisits.length));
        }
        return this._contraptionCausedVisitsCount;
    }

    private _visitableVisitOrder: VisitableVisitOrder | undefined;

    get visitableVisitOrder(): VisitableVisitOrder {
        if (!this._visitableVisitOrder) {
            const unvisited = [...this.puzzle.visitableThingsList];
            const drop = (v: VisitableThing) => unvisited.splice(unvisited.indexOf(v), 1);
            const visited: VisitableThing[] = [];
            this.moveSequence.forEach(m => {
                const visitableAtLocation = this.puzzle.visitableAtLocation(m.newLocation);
                if (visitableAtLocation !== VisitableThing.None) {
                    visited.push(visitableAtLocation);
                    drop(visitableAtLocation);
                }
            })

            this._visitableVisitOrder = {
                visited: visited,
                unvisited: unvisited,
            }
        }
        return this._visitableVisitOrder;
    }

    private _shortSolutionAnnotation: string | undefined;

    get shortSolutionAnnotation(): string {
        if (!this._shortSolutionAnnotation) {
            const visitableVisitOrder = this.visitableVisitOrder;
            const visited = visitableVisitOrder.visited.map(VisitableThingInfo.keyCode).join('');
            const unvisited = visitableVisitOrder.unvisited.map(VisitableThingInfo.keyCode).join('');
            const seperator = visitableVisitOrder.unvisited.length == 0 ? '!' : '?';
            this._shortSolutionAnnotation = `${visited}${seperator}${unvisited} ` +
                `${this.puzzle.height}x${this.puzzle.width} ` +
                `${this.puzzle.toString()}`;
        }
        return this._shortSolutionAnnotation;
    }

    private _untouchedGolds: number | undefined;

    get untouchedGolds(): number {
        if (!this._untouchedGolds) {
            this._untouchedGolds = this.goldTouchCounts.filter(c => c === 0).length;
        }
        return this._untouchedGolds;
    }

    private _unusedMovementTypes: number | undefined;

    get unusedMovementTypes(): number {
        if (!this._unusedMovementTypes) {
            this._unusedMovementTypes = this.puzzle.startingLocations.length - this.movementTypeChanges;
        }
        return this._unusedMovementTypes;
    }

    // public unforcedMoveCount() {
    //     let activePuzzle = new ActivePuzzle(this.puzzle);
    //     let unforcedCount = 0;
    //     let forcedCount = 0;
    //     this.moves.forEach(m => {
    //         if (activePuzzle.viableMoves().length !== 1) unforcedCount++;
    //         else forcedCount++;
    //         activePuzzle.addMove(m);
    //     })
    //     return unforcedCount;

    public movesReusingAPath() {
        return Utility.sum(this.pathReuse);
    }

    public hasUnusedEndSchema(): boolean {
        const lastLoc = this.moveSequence[this.moveSequence.length - 1].newLocation;
        const mt = this.puzzle.visitableAtLocation(lastLoc);
        return mt !== VisitableThing.None && VisitableThingInfo.isMovementType(mt);
    }

    public getSolvedActivePuzzle() {
        let ret = new ActivePuzzle(this.puzzle);
        this.moveSequence.forEach(m => ret.addMove(m));
        if (!ret.isCompleted)
            console.error("You have a malformed Solution. Loading it results in an unsolved state.");
        return ret;
    }

    public moveOfFirstContraptionCausedVisit() {
        return this.moveSequence.findIndex(m => m.contraptionVisits.length > 0);
    }

}