import React from "react";

import '../css/visitableRenderer.css';
import {VisitableThing, VisitableThingInfo} from "../puzzle/movement";

export type ImgRenderTechnique = 'img' | 'background';

interface VisitableRendererProps {
    defaultClassName: string,
    renderMethod: ImgRenderTechnique,
    visitable: VisitableThing,
    isCurrentLocation?: boolean,
}

type VisitableRendererState = {}

class VisitableRenderer extends React.PureComponent<VisitableRendererProps, VisitableRendererState> {
    constructor(props: VisitableRendererProps) {
        super(props);
        this.state = {}
    }

    imageClasses = () => {
        const imgClasses = ['puzzle-spot__img',];
        const svgClasses = VisitableThingInfo.additionalImageClassSuffixes(this.props.visitable);
        svgClasses.forEach(suffix => imgClasses.push(`puzzle-spot__img--${suffix}`));
        if (this.props.isCurrentLocation) imgClasses.push('puzzle-spot__img--active-mover');
        return imgClasses.join(' ');
    }

    render(): React.ReactNode {
        const {visitable, defaultClassName} = this.props;
        if (visitable === VisitableThing.None)
            return <div className={this.props.defaultClassName}/>;

        const {renderMethod} = this.props;

        if (renderMethod === 'background') {
            const classes = [
                defaultClassName,
                `${defaultClassName}--background-container`,
                `${defaultClassName}--visitable-${VisitableThingInfo.cssName(visitable)}`,
                ...VisitableThingInfo.additionalImageClassSuffixes(visitable)
                    .map(suffix => (`${defaultClassName}--background-${suffix}`)),
            ].join(' ');

            return <div className={classes}>
            </div>;
        }

        // TODO: Consider doing this: https://create-react-app.dev/docs/adding-images-fonts-and-files/#adding-svgs
        return <div className={this.props.defaultClassName}>
            <img src={VisitableThingInfo.imageSrc(visitable)}
                 className={this.imageClasses()}/>
        </div>;
    }
}

export default VisitableRenderer;