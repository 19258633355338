import {VisitableThing, VisitableThingInfo} from "../puzzle/movement";
import React from "react";
import {DisplayTheme} from "../constants";
import GetProgress from "../progress/getProgress";

import '../css/puzzleTheme3D.css';
import '../css/puzzleThemeOriginal.css';
import '../css/optionsMenu.css';

interface IsolatedMoverProps {
    onClick: (themeName: DisplayTheme) => void,
    theme: DisplayTheme,
}

type IsolatedMoverState = {}

class IsolatedMover extends React.Component<IsolatedMoverProps, IsolatedMoverState> {
    constructor(props: IsolatedMoverProps) {
        super(props);
        this.state = {}
    }

    themeClasses = () => {
        return [
            'theme-choice',
            GetProgress.getTheme() === this.props.theme ? 'theme-choice--active' : 'theme-choice--inactive',
            `puzzle-layout--${this.props.theme}`,
        ].join(' ');
    };

    render(): React.ReactNode {

        return (
            <div className={this.themeClasses()}
                 onClick={() => this.props.onClick(this.props.theme)}>
                <div
                    className="puzzle-spot puzzle-spot--possible-destination puzzle-spot--required-visits-1 puzzle-spot--remaining-visits-1 puzzle-spot--movement-type"
                    style={{'--square-size': '40px'} as React.CSSProperties}>
                    <div className="puzzle-spot__outer">
                        <div className="puzzle-spot__middle">
                            <div className="puzzle-spot__inner">
                                <img
                                    alt={'Puzzle Theme Example'}
                                    src={VisitableThingInfo.imageSrc(VisitableThing.Manhattan)}
                                    className={"puzzle-spot__img puzzle-spot__img--slightly-smaller"}/></div>
                        </div>
                    </div>
                </div>
            </div>)
    }
}

export default IsolatedMover;