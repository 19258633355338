import Location from "./location";
import Utility from "../utility";

class VisitCounter {
    private totalVisits: number = 0;
    private goldVisits: number = 0;
    private readonly counts: number[][];

    constructor(public readonly height: number,
                public readonly width: number,
                copyCounts: number[][] | undefined = undefined) {
        if (copyCounts === undefined) {
            this.counts = Utility.squareArray(height, width, 0);
        } else {
            console.assert(copyCounts.length === height);
            copyCounts.forEach(row => console.assert(row.length === width))
            this.counts = copyCounts.map(row => [...row]);
        }
    }

    get totalNonGoldVisits() {
        return this.totalVisits - this.goldVisits;
    }

    public validLoc(loc: Location) {
        return loc.row >= 0 && loc.row < this.height && loc.column >= 0 && loc.column < this.width;
    }

    public visit(loc: Location, isGold: boolean) {
        console.assert(this.validLoc(loc));
        this.totalVisits += 1;
        if (isGold) this.goldVisits += 1;
        this.counts[loc.row][loc.column] += 1;
    }

    public getVisitCount(loc: Location): number {
        if (!this.validLoc(loc)) return 0;
        return this.counts[loc.row][loc.column];
    }

    public unvisit(loc: Location, isGold: boolean) {
        console.assert(this.validLoc(loc));
        if (--this.counts[loc.row][loc.column] < 0)
            console.error("Unvisited a location that's never been visited.")

        this.totalVisits -= 1;
        if (isGold) this.goldVisits -= 1;
    }

    public debugString() {
        return this.counts.map(row => row.join(",")).join('\n');
    }

    /**
     * Copy this VisitCounter into a new one and add in the passed requiredVisits
     * @param newLocs Some locations to visit
     */
    public withVisits(newLocs: [Location, boolean][]): VisitCounter {
        let newVisitCounter = new VisitCounter(this.height, this.width, this.counts);
        newLocs.forEach(pair => {
            console.assert(this.validLoc(pair[0]));
            newVisitCounter.visit(pair[0], pair[1]);
        })
        return newVisitCounter;
    }

}

export default VisitCounter;
