// mddj_world generated by WorldBuilder
import Puzzle from "../puzzle/puzzle";
import {World} from "./world";
import Location from "../puzzle/location";


export let mddj_world = new World("mddj_world", [
//
// mjdd 5x5 unique
// Path: d:4 m:11 d:5 j:2  Entropy: 13.1
// Golds touched 0
    Puzzle.fromString(`
jm111
11111
1g111
111d1
1d111
`).toggleEnabled().setCanonicalSolutionWithWitness(JSON.parse('["4,1","3,2","2,1","1,2","0,1","0,2","0,3","0,4","1,4","1,3","2,3","2,4","3,4","4,4","4,3","3,3","4,2","3,1","2,2","1,1","0,0","2,0","4,0"]').map(Location.fromString)),
//
// mjdd 4x4 unique
// Path: d:5 j:2 m:5 d:3  Entropy: 8.44
// Golds touched 0
    Puzzle.fromString(`
11m1
1111
j1gd
1d11
`).toggleEnabled().setCanonicalSolutionWithWitness(JSON.parse('["3,1","2,2","3,3","2,2","1,1","2,0","0,0","0,2","0,3","1,3","1,2","2,2","2,3","3,2","2,1","3,0"]').map(Location.fromString)),
//
// mjdd 4x4 unique
// Path: d:3 m:5 d:5 j:2  Entropy: 8.84
// Golds touched 0
    Puzzle.fromString(`
11jm
1111
11g1
d11d
`).toggleEnabled().setCanonicalSolutionWithWitness(JSON.parse('["3,0","2,1","1,2","0,3","1,3","2,3","2,2","3,2","3,3","2,2","3,1","2,2","1,1","0,2","0,0","2,0"]').map(Location.fromString)),
//
// mjdd 4x4 unique
// Path: d:4 j:1 m:5 d:5  Entropy: 4.56
// Golds touched 0
    Puzzle.fromString(`
1111
m1jd
11g1
d111
`).toggleEnabled().setCanonicalSolutionWithWitness(JSON.parse('["3,0","2,1","3,2","2,3","1,2","1,0","0,0","0,1","0,2","0,3","1,3","2,2","3,3","2,2","3,1","2,0"]').map(Location.fromString)),
//
// mjdd 4x4 unique
// Path: d:4 m:11 j:2  Entropy: 8.44
// Golds touched 1
    Puzzle.fromString(`
1111
1djm
11g1
1d11
`).toggleEnabled().setCanonicalSolutionWithWitness(JSON.parse('["1,1","2,2","3,3","2,2","1,3","0,3","0,2","0,1","0,0","1,0","2,0","2,1","2,2","2,3","2,2","1,2","3,2","3,0"]').map(Location.fromString)),
//
// mjdd 4x4 unique
// Path: d:3 j:2 m:5 d:5  Entropy: 7.74
// Golds touched 0
    Puzzle.fromString(`
1j11
1111
11gm
d11d
`).toggleEnabled().setCanonicalSolutionWithWitness(JSON.parse('["3,0","2,1","1,0","0,1","0,3","2,3","2,2","1,2","2,2","3,2","3,3","2,2","3,1","2,0","1,1","0,0"]').map(Location.fromString)),
//
// mjdd 4x4 unique
// Path: j:2 d:5 m:5 d:3  Entropy: 7.74
// Golds touched 0
    Puzzle.fromString(`
11j1
1111
d1g1
d11m
`).toggleEnabled().setCanonicalSolutionWithWitness(JSON.parse('["0,2","0,0","2,0","1,1","2,2","1,3","2,2","3,3","2,3","2,2","3,2","3,1","3,0","2,1","1,2","0,3"]').map(Location.fromString)),
//
// mddj 5x5 unique
// Path: j:8 m:8  Entropy: 3.47
    Puzzle.fromString(`
//
// Start 5x5 mjdd puzzles
//
md111
11111
11j11
d1111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["2,2","2,0","4,0","4,2","4,4","2,4","0,4","0,2","0,0","1,0","1,1","1,2","1,3","2,3","3,3","3,2","3,1"]').map(Location.fromString)),
//
// mddj 5x5 unique
// Path: m:14 j:5 d:0  Entropy: 11.3
// Doesn't use end movementType
    Puzzle.fromString(`
m1dd1
11111
11111
11111
j1111
`).setCanonicalSolutionWithWitness(JSON.parse('["0,0","0,1","1,1","1,0","2,0","2,1","2,2","1,2","1,3","2,3","3,3","3,2","3,1","3,0","4,0","4,2","4,4","2,4","0,4","0,2"]').map(Location.fromString)),
//
// mddj 5x5 unique
// Path: j:5 d:2 m:11 d:1  Entropy: 8.44
    Puzzle.fromString(`
m1d11
11d11
11111
11111
j1111
`).setCanonicalSolutionWithWitness(JSON.parse('["4,0","4,2","4,4","2,4","0,4","0,2","1,1","0,0","1,0","2,0","3,0","3,1","2,1","2,2","3,2","3,3","2,3","1,3","1,2","0,1"]').map(Location.fromString)),
//
// mddj 5x5 unique
// Path: j:3 d:4 m:11 d:3  Entropy: 9.13
    Puzzle.fromString(`
m1d11
11111
11111
11d11
1111j
`).setCanonicalSolutionWithWitness(JSON.parse('["4,4","2,4","0,4","0,2","1,3","2,2","1,1","0,0","1,0","2,0","2,1","3,1","3,0","4,0","4,1","4,2","4,3","3,3","3,2","2,3","1,2","0,1"]').map(Location.fromString)),
//
// mddj 5x5 unique
// Path: d:4 j:5 m:7 d:3  Entropy: 6.07
    Puzzle.fromString(`
m111d
11111
1111j
11d11
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["0,4","1,3","2,2","3,3","2,4","4,4","4,2","4,0","2,0","0,0","0,1","0,2","1,2","1,1","2,1","3,1","3,2","2,3","1,4","0,3"]').map(Location.fromString)),
//
// mddj 5x5 unique
// Path: d:6 j:1 m:11 d:5  Entropy: 8.84
    Puzzle.fromString(`
m1j1d
11111
11111
1111d
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["0,4","1,3","2,4","3,3","2,2","1,1","0,2","0,0","1,0","2,0","3,0","4,0","4,1","3,1","3,2","4,2","4,3","4,4","3,4","2,3","1,4","0,3","1,2","2,1"]').map(Location.fromString)),
//
// mddj 5x5 unique
// Path: m:11 j:6 d:1  Entropy: 10.8
    Puzzle.fromString(`
dm11j
1111d
11111
11111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["0,1","0,2","1,2","2,2","2,1","3,1","3,2","3,3","2,3","1,3","0,3","0,4","2,4","4,4","4,2","4,0","2,0","0,0","1,1"]').map(Location.fromString)),
//
// mddj 5x5 unique
// Path: m:11 j:6 d:1  Entropy: 10.1
    Puzzle.fromString(`
1mdd1
11111
j1111
11111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["0,1","0,0","1,0","1,1","1,2","2,2","2,3","3,3","3,2","3,1","2,1","2,0","4,0","4,2","4,4","2,4","0,4","0,2","1,3"]').map(Location.fromString)),
//
// mddj 5x5 unique
// Path: j:2 m:20 d:0  Entropy: 9.70
// Doesn't use end movementType
    Puzzle.fromString(`
// tricoy
md11d
11111
11j11
11111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["2,2","0,2","0,0","1,0","1,1","2,1","2,0","3,0","4,0","4,1","3,1","3,2","4,2","4,3","4,4","3,4","3,3","2,3","2,4","1,4","1,3","0,3","0,4"]').map(Location.fromString)),
//
// mddj 5x5 unique
// Path: m:10 j:7  Entropy: 10.1
    Puzzle.fromString(`
1d111
1md11
1111j
11111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["1,1","2,1","3,1","3,2","3,3","2,3","1,3","0,3","0,4","1,4","2,4","4,4","4,2","4,0","2,0","0,0","0,2","2,2"]').map(Location.fromString)),
//
// mddj 5x5 unique
// Path: m:20 j:2 d:0  Entropy: 11.3
// Doesn't use end movementType
    Puzzle.fromString(`
11m11
11111
1dd11
11111
j1111
`).setCanonicalSolutionWithWitness(JSON.parse('["0,2","0,1","0,0","1,0","1,1","1,2","1,3","0,3","0,4","1,4","2,4","2,3","3,3","3,4","4,4","4,3","4,2","3,2","3,1","4,1","4,0","2,0","2,2"]').map(Location.fromString)),
//
// mddj 5x5 unique
// Path: m:17 j:3 d:1  Entropy: 10.5
    Puzzle.fromString(`
1md11
1111d
11j11
11111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["0,1","0,0","1,0","2,0","3,0","4,0","4,1","4,2","4,3","4,4","3,4","3,3","3,2","3,1","2,1","1,1","1,2","2,2","2,4","0,4","0,2","1,3"]').map(Location.fromString)),
//
// mddj 5x5 unique
// Path: m:13 d:1 j:5  Entropy: 11.4
    Puzzle.fromString(`
1m1d1
11111
11111
1d111
j1111
`).setCanonicalSolutionWithWitness(JSON.parse('["0,1","0,0","1,0","1,1","1,2","1,3","2,3","3,3","3,2","2,2","2,1","2,0","3,0","3,1","4,0","4,2","4,4","2,4","0,4","0,2"]').map(Location.fromString)),
//
// mddj 5x5 unique
// Path: d:1 m:19 d:2 j:1  Entropy: 12.0
    Puzzle.fromString(`
// hard
1m111
11d11
j1d11
11111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["1,2","0,1","0,2","0,3","0,4","1,4","1,3","2,3","2,4","3,4","4,4","4,3","3,3","3,2","4,2","4,1","4,0","3,0","3,1","2,1","2,2","1,1","2,0","0,0"]').map(Location.fromString)),
//
// mddj 5x5 unique
// Path: j:1 d:1 m:21 d:0  Entropy: 9.53
// Doesn't use end movementType
    Puzzle.fromString(`
11d11
1m11d
11j11
11111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["2,2","0,2","1,1","0,1","0,0","1,0","2,0","2,1","3,1","3,0","4,0","4,1","4,2","3,2","3,3","4,3","4,4","3,4","2,4","2,3","1,3","0,3","0,4","1,4"]').map(Location.fromString)),
//
// mddj 5x5 unique
// Path: j:7 d:3 m:5 d:2  Entropy: 6.36
    Puzzle.fromString(`
11j1d
1m111
111d1
11111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["0,2","0,0","2,0","4,0","4,2","4,4","2,4","0,4","1,3","2,2","1,1","2,1","3,1","3,2","3,3","2,3","1,2","0,3"]').map(Location.fromString)),
//
// mddj 5x5 unique
// Path: d:2 m:15 j:2 d:3  Entropy: 10.1
    Puzzle.fromString(`
// good shit
11111
d1md1
11111
1j111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["1,0","0,1","1,2","0,2","0,3","0,4","1,4","2,4","3,4","4,4","4,3","4,2","4,1","4,0","3,0","2,0","2,1","3,1","3,3","1,3","2,2","1,1","0,0"]').map(Location.fromString)),
//
// mddj 5x5 unique
// Path: m:9 j:7 d:1  Entropy: 9.65
    Puzzle.fromString(`
11111
d1m11
d1111
11111
j1111
`).setCanonicalSolutionWithWitness(JSON.parse('["1,2","1,3","2,3","3,3","3,2","2,2","2,1","3,1","3,0","4,0","4,2","4,4","2,4","0,4","0,2","0,0","2,0","1,1"]').map(Location.fromString)),
//
// mddj 5x5 unique
// Path: j:6 m:12 d:0  Entropy: 7.05
// Doesn't use end movementType
    Puzzle.fromString(`
11111
1dd11
11m11
11111
1111j
`).setCanonicalSolutionWithWitness(JSON.parse('["4,4","4,2","4,0","2,0","0,0","0,2","2,2","2,3","1,3","0,3","0,4","1,4","2,4","3,4","3,3","3,2","3,1","2,1","1,1"]').map(Location.fromString)),
//
// mddj 5x5 unique
// Path: d:5 m:7 j:3 d:6  Entropy: 10.2
    Puzzle.fromString(`
1m111
11111
1111d
11d11
j1111
`).setCanonicalSolutionWithWitness(JSON.parse('["3,2","2,3","1,4","0,3","1,2","0,1","0,0","1,0","2,0","2,1","3,1","3,0","4,0","4,2","4,4","2,4","3,3","2,2","1,1","0,2","1,3","0,4"]').map(Location.fromString)),
//
// mddj 5x5 unique
// Path: d:3 m:9 j:4 d:4  Entropy: 10.3
    Puzzle.fromString(`
1m11j
11111
11111
d1111
d1111
`).setCanonicalSolutionWithWitness(JSON.parse('["3,0","2,1","1,0","0,1","0,2","1,2","2,2","3,2","3,3","2,3","1,3","0,3","0,4","2,4","4,4","4,2","4,0","3,1","2,0","1,1","0,0"]').map(Location.fromString)),
//
// mddj 5x5 unique
// Path: d:4 m:9 d:5 j:3  Entropy: 12.5
    Puzzle.fromString(`
// Good as heck
1mj11
11111
11111
1d111
111d1
`).setCanonicalSolutionWithWitness(JSON.parse('["4,3","3,2","2,3","1,2","0,1","0,0","1,0","1,1","2,1","2,0","3,0","4,0","4,1","3,1","4,2","3,3","2,2","1,3","0,2","0,4","2,4","4,4"]').map(Location.fromString)),
//
// mddj 5x5 unique
// Path: j:5 d:2 m:11 d:1  Entropy: 8.44
    Puzzle.fromString(`
d1m11
11d11
1111j
11111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["2,4","4,4","4,2","4,0","2,0","0,0","1,1","0,2","0,3","0,4","1,4","1,3","2,3","3,3","3,2","3,1","2,1","2,2","1,2","0,1"]').map(Location.fromString)),
//
// mddj 5x5 unique
// Path: j:2 d:3 m:15 d:2  Entropy: 6.64
    Puzzle.fromString(`
md111
111d1
11111
1j111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["3,1","3,3","1,3","2,2","1,1","0,0","1,0","2,0","3,0","4,0","4,1","4,2","4,3","4,4","3,4","2,4","1,4","0,4","0,3","0,2","0,1","1,2","2,1"]').map(Location.fromString)),
]);