import React from "react";
import {GameBodyViewType, GameView} from "./userFacingGame";

import '../css/popupScreen.css'

interface PopupScreenProps {
    currentView: GameView,
    previousView?: GameView,
    contentsFn: () => React.ReactNode,
    allowVisibilityAnimation: boolean,
}

interface PopupScreenState {
}


class PopupScreen extends React.Component<PopupScreenProps, PopupScreenState> {
    constructor(props: PopupScreenProps) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render(): React.ReactNode {
        const myView = 'POPUP_SCREEN';
        const PopupScreenClasses = [
            'popup-screen',
            this.props.currentView.view === myView ? 'popup-screen--active' : 'popup-screen--inactive',
            this.props.currentView.view === myView && this.props.allowVisibilityAnimation ? 'popup-screen--fade-in' : '',
            this.props.currentView.view !== myView && this.props.allowVisibilityAnimation ? 'popup-screen--fade-out' : '',
        ].join(' ');

        return (
            <div className={'popup-screen-outer'}>
                <div className={PopupScreenClasses}>
                    {this.props.currentView.view === myView || this.props.previousView?.view === myView ?
                        this.props.contentsFn() :
                        <></>}
                </div>
            </div>
        );


    }

}

export default PopupScreen;