import React, {PureComponent} from 'react';

interface MoveControlsPanelProps {
    undoMoveFn: VoidFunction,
    redoMoveFn: VoidFunction,
    solveFn: VoidFunction,
    undoAllFn: VoidFunction,
}

type MoveControlsPanelState = {}

class MoveControlsPanel extends PureComponent<MoveControlsPanelProps, MoveControlsPanelState> {
    constructor(props: MoveControlsPanelProps) {
        super(props);
    }

    render() {
        return (<>
                <h2 className={'panel-header'}>Move Controls</h2>
                <input type={"submit"}
                       className={'puzzle-lab__button'}
                       value={"Undo"}
                       onClick={this.props.undoMoveFn}/>
                <input type={"submit"}
                       className={'puzzle-lab__button'}
                       value={"Redo"}
                       onClick={this.props.redoMoveFn}/>
                &nbsp;
                <input type={"submit"}
                       className={'puzzle-lab__button'}
                       value={"Solve"}
                       onClick={this.props.solveFn}/>
                <input type={"submit"}
                       className={'puzzle-lab__button'}
                       value={"Undo All"}
                       onClick={this.props.undoAllFn}/>
            </>
        );
    }
}

export default MoveControlsPanel;