// slipperyWorld generated by WorldBuilder
import Puzzle from "../puzzle/puzzle";
import {World} from "./world";
import Location from "../puzzle/location";

export let slipperyMultitapWorld = new World("slipperyMultitapWorld", [
//
// S 7x5 unique
// Path: S:15  Entropy: 3.87

Puzzle.fromString(`
11111
01211
11111
11S11
11111
11211
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["3,2","0,2","0,4","6,4","6,0","2,0","2,1","5,1","5,3","5,2","4,2","4,3","1,3","1,1","0,1","0,0"]').map(Location.fromString)),
//
// S 7x5 unique
// Path: S:16  Entropy: 7.45
    Puzzle.fromString(`
13111
13111
11121
11S11
11111
11111
12111
`).setCanonicalSolutionWithWitness(JSON.parse('["3,2","6,2","6,0","6,1","0,1","1,1","0,1","0,0","0,4","6,4","6,3","1,3","2,3","2,2","1,2","1,0","5,0"]').map(Location.fromString)),
//
// mS 5x4 unique
// Path: S:6 m:10  Entropy: 5.26
    Puzzle.fromString(`
1121
1111
1S01
111m
1111
`).setCanonicalSolutionWithWitness(JSON.parse('["2,1","0,1","0,3","0,2","1,2","1,3","3,3","4,3","4,2","3,2","3,1","4,1","4,0","3,0","2,0","1,0","0,0"]').map(Location.fromString)),
//
// S 7x5 unique
// Path: S:17  Entropy: 5.38
    Puzzle.fromString(`
11211
12311
11111
11S11
13111
11111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["3,2","0,2","1,2","1,0","0,0","0,4","6,4","6,0","2,0","2,1","1,1","1,3","5,3","5,1","3,1","4,1","4,2","4,1"]').map(Location.fromString)),
//
// S 7x5 unique
// Path: S:15  Entropy: 4.56
    Puzzle.fromString(`
13311
11211
11111
11S11
11111
11111
12111
`).setCanonicalSolutionWithWitness(JSON.parse('["3,2","6,2","6,0","0,0","0,4","6,4","6,3","1,3","1,1","0,1","0,2","0,1","0,2","2,2","2,1","6,1"]').map(Location.fromString)),
//
// S 7x5 unique
// Path: S:16  Entropy: 4.56
    Puzzle.fromString(`
11211
11311
11111
01S11
12111
11111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["3,2","0,2","0,0","2,0","2,1","6,1","6,4","0,4","0,2","1,2","1,1","1,3","5,3","5,2","4,2","4,0","6,0"]').map(Location.fromString)),
//
// S 7x5 unique
// Path: S:17  Entropy: 4.16
    Puzzle.fromString(`
11111
21011
11111
11S11
11111
11211
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["3,2","2,2","2,0","0,0","1,0","1,1","0,1","0,4","6,4","6,0","3,0","3,1","5,1","5,3","5,2","4,2","4,3","1,3"]').map(Location.fromString)),
//
// S 7x5 unique
// Path: S:12  Entropy: 3.18
    Puzzle.fromString(`
12111
11111
11011
12S11
11011
11111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["3,2","3,0","6,0","6,4","0,4","0,0","2,0","2,1","5,1","5,3","1,3","1,1","0,1"]').map(Location.fromString)),
//
// S 7x5 unique
// Path: S:15  Entropy: 5.55
    Puzzle.fromString(`
01211
11211
112S1
11211
11211
11201
11211
`).setCanonicalSolutionWithWitness(JSON.parse('["2,3","2,0","1,0","1,4","6,4","6,0","3,0","3,3","4,3","4,1","5,1","5,2","6,2","0,2","0,1","0,4"]').map(Location.fromString)),
//
// S 6x5 unique
// Path: S:15  Entropy: 4.56
    Puzzle.fromString(`
11211
1S211
11211
11211
11201
11211
`).setCanonicalSolutionWithWitness(JSON.parse('["1,1","1,0","0,0","0,4","5,4","5,0","2,0","2,3","3,3","3,1","4,1","4,2","5,2","0,2","1,2","1,3"]').map(Location.fromString)),
//
// S 6x5 unique
// Path: S:14  Entropy: 4.16
    Puzzle.fromString(`
11211
1S201
11211
11211
11201
11211
`).setCanonicalSolutionWithWitness(JSON.parse('["1,1","1,0","0,0","0,4","5,4","5,0","2,0","2,3","3,3","3,1","4,1","4,2","5,2","0,2","1,2"]').map(Location.fromString)),
//
// S 7x5 unique
// Path: S:14  Entropy: 2.48
    Puzzle.fromString(`
11111
10111
11011
12S21
11010
11111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["3,2","3,0","6,0","6,4","5,4","5,1","2,1","2,0","0,0","0,4","3,4","3,3","4,3","1,3","1,2"]').map(Location.fromString)),
//
// S 6x5 unique
// Path: S:16  Entropy: 3.47
    Puzzle.fromString(`
11011
11111
11211
1S111
11211
10111
`).setCanonicalSolutionWithWitness(JSON.parse('["3,1","3,0","0,0","0,1","2,1","2,4","0,4","0,3","1,3","1,2","5,2","5,4","3,4","3,3","4,3","4,0","5,0"]').map(Location.fromString)),
//
// S 5x5 unique
// Path: S:10  Entropy: 1.79
    Puzzle.fromString(`
11111
1111S
11121
11121
11110
`).setCanonicalSolutionWithWitness(JSON.parse('["1,4","0,4","0,0","4,0","4,3","1,3","1,1","3,1","3,4","2,4","2,2"]').map(Location.fromString)),
//
// S 5x5 unique
// Path: S:13  Entropy: 3.99
    Puzzle.fromString(`
1S111
11111
11121
10121
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["0,1","2,1","2,4","0,4","0,2","1,2","1,3","4,3","4,4","3,4","3,2","4,2","4,0","0,0"]').map(Location.fromString)),
//
// S 6x5 unique
// Path: S:14  Entropy: 4.97
    Puzzle.fromString(`
11211
0S201
11211
11211
11211
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["1,1","1,2","0,2","0,4","5,4","5,0","2,0","2,3","4,3","4,1","3,1","3,2","4,2","0,2","0,0"]').map(Location.fromString)),
//
// L 6x5 unique
// Path: L:14  Entropy: 9.64
    Puzzle.fromString(`
11111
001L1
11111
11101
11111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["1,3","0,2","0,4","5,4","2,1","2,0","5,0","4,1","5,1","5,3","3,1","2,2","2,3","0,1","0,0"]').map(Location.fromString)),
//
// S 7x5 unique
// Path: S:12  Entropy: 2.08
    Puzzle.fromString(`
11111
1S111
11111
11101
11111
11111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["1,1","1,4","0,4","0,0","6,0","6,4","2,4","2,1","5,1","5,3","4,3","4,2","3,2"]').map(Location.fromString)),
//
// S 7x5 unique
// Path: S:14  Entropy: 2.08
    Puzzle.fromString(`
11111
1S111
11111
11111
01111
11111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["1,1","1,0","0,0","0,4","6,4","6,0","5,0","5,3","1,3","1,2","4,2","4,1","2,1","2,0","3,0"]').map(Location.fromString)),
//
// S 7x5 unique
// Path: S:11  Entropy: 2.08
    Puzzle.fromString(`
11111
10111
11111
11S11
11111
11111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["3,2","6,2","6,0","0,0","0,4","6,4","6,3","1,3","1,2","2,2","2,1","5,1"]').map(Location.fromString)),
//
// S 7x5 unique
// Path: S:11  Entropy: 2.77
    Puzzle.fromString(`
12111
11111
11111
11S11
11111
10111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["3,2","0,2","0,0","6,0","6,4","0,4","0,3","5,3","5,2","4,2","4,1","0,1"]').map(Location.fromString)),
//
// S 7x5 unique
// Path: S:14  Entropy: 3.87
    Puzzle.fromString(`
01211
11211
11111
11S11
11111
11111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["3,2","3,0","6,0","6,4","0,4","0,1","0,2","2,2","2,0","1,0","1,3","5,3","5,1","4,1","4,2"]').map(Location.fromString)),
//
// S 7x5 unique
// Path: S:14  Entropy: 3.18
    Puzzle.fromString(`
11111
10111
11111
11S21
11121
11111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["3,2","3,4","0,4","0,0","6,0","6,4","4,4","4,1","5,1","5,3","1,3","1,2","2,2","2,1","3,1"]').map(Location.fromString)),
//
// S 7x5 unique
// Path: S:13  Entropy: 4.97
    Puzzle.fromString(`
01111
11111
11111
11S11
11121
10121
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["3,2","0,2","0,1","4,1","4,4","0,4","0,3","6,3","6,4","5,4","5,2","6,2","6,0","1,0"]').map(Location.fromString)),
//
// S 7x5 unique
// Path: S:17  Entropy: 4.16
    Puzzle.fromString(`
12111
11101
11112
11S11
11110
11111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["3,2","3,0","6,0","6,4","5,4","5,1","4,1","4,3","2,3","2,4","3,4","0,4","0,0","2,0","2,2","1,2","1,1","0,1"]').map(Location.fromString)),
//
// S 7x5 unique
// Path: S:12  Entropy: 3.99
    Puzzle.fromString(`
11011
11111
12211
11S11
11011
11111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["3,2","1,2","2,2","2,0","6,0","6,4","0,4","0,3","5,3","5,1","0,1","0,0","1,0"]').map(Location.fromString)),
//
// mS 7x5 unique
// Path: S:5 m:21  Entropy: 7.74
    Puzzle.fromString(`
12111
11111
11111
1111S
11m11
10111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["3,4","0,4","0,0","0,1","4,1","4,2","3,2","2,2","1,2","1,3","2,3","3,3","4,3","4,4","5,4","6,4","6,3","5,3","5,2","6,2","6,1","6,0","5,0","4,0","3,0","2,0","1,0"]').map(Location.fromString)),
]);