import React, {PureComponent} from 'react';
import NiceButton from "./niceButton";
import InfoImg from "../img/info-2.svg";
import WorldLeftImg from "../img/left-5.svg";
import OptionsImg from "../img/options-2.svg";
import WorldRightImg from "../img/right-6.svg";
import WorldImg from "../img/world-1.svg";
import ReturnImg from "../img/return-3.svg";
import RestartImg from "../img/restart-8.svg";
import HintImg from "../img/hint-1.svg";
import NextPuzzleImg from "../img/right-5.svg";
import DuckImg from "../img/logo256.png";
import {GameView, gameViewEq} from "./userFacingGame";
import {GamePanelOrientation} from "../layoutConstants";
import {World} from "../puzzles/world";
import GetProgress from "../progress/getProgress";
import {Hint} from "../puzzle/activePuzzle";

import '../css/buttonsPanel.css'
import Constants from "../constants";

export interface ReturnFromPopupOptions {
    returnGameView: GameView,
}

export interface ButtonsFunctions {
    allocateHint: VoidFunction,
    openOptionsMenuFn: (returnOptions: ReturnFromPopupOptions) => VoidFunction,
    openInstructionsFn: (returnOptions: ReturnFromPopupOptions) => VoidFunction,
    quack: VoidFunction,
    restartLevel: VoidFunction,
    returnFromPopup: VoidFunction,
    shiftToNextWorld: VoidFunction,
    shiftToPreviousWorld: VoidFunction,
    showWorld: VoidFunction,
    startNextLevel: VoidFunction,

}

interface ButtonsPanelProps {
    buttonsFunctions: ButtonsFunctions,

    currentView: GameView,
    previousView?: GameView,
    gameButtonsOrientation: GamePanelOrientation,
    unallocatedHints: number,
    currentWorld: World,
    atDeadEnd: boolean,
    noMovesMade: boolean,
    currentHint?: Hint,
    quackCounter: number,
    nextPuzzleInWorldOrderExists: boolean,
    hintJustAllocated: boolean,
    showHints: boolean,
}

interface ButtonsPanelState {
}

class ButtonsPanel extends PureComponent<ButtonsPanelProps, ButtonsPanelState> {
    constructor(props: ButtonsPanelProps) {
        super(props);
        this.state = {};
    }


    private buttonsPanelClasses = (view?: GameView) => {
        return [
            'buttons-panel__row',
            'buttons-panel__row--' + (gameViewEq(this.props.currentView, view) ? 'active' : 'inactive'),
        ].join(' ');
    }

    getPanel(view: GameView, oldAndInactive: boolean = false) {
        const {buttonsFunctions} = this.props;

        const worldProgress = GetProgress.getWorldProgress(this.props.currentWorld);
        const nextWorld = this.props.currentWorld.nextWorld;
        const nextWorldProgress = nextWorld ? GetProgress.getWorldProgress(nextWorld) : undefined;

        // const showPrev = this.props.currentView === 'WORLD' && this.props.currentWorld.prevWorld !== undefined;
        const showNext = worldProgress.nextWorldAccessible || (!!nextWorldProgress && nextWorldProgress.playedCount > 0)

        let children: React.ReactNode;

        switch (view.view) {
            // Any changes here should probably also be reflected in "WORLD_FIRST" below.
            case "WORLD":
                const leftButton =
                    view.worldNumber === 0 ?
                        // The first world has the instructions button.
                        <NiceButton name={"Instructions"}
                                    display={{image: InfoImg}}
                                    action={buttonsFunctions.openInstructionsFn({returnGameView: view})}
                                    active={!oldAndInactive}
                        /> :
                        <NiceButton name={"WorldLeft"}
                                    display={{image: WorldLeftImg}}
                                    action={buttonsFunctions.shiftToPreviousWorld}
                                    active={!oldAndInactive}
                            // disableFadeAnimation={remainInWorldView}
                        />;

                children = <>
                    {leftButton}
                    <NiceButton name={"Options"}
                                display={{image: OptionsImg}}
                                shape={'squarelike'}
                                action={buttonsFunctions.openOptionsMenuFn({returnGameView: view})}
                                active={!oldAndInactive}
                        // disableFadeAnimation={remainInWorldView}
                    />
                    <NiceButton name={"WorldRight"}
                                display={{image: WorldRightImg}}
                                action={buttonsFunctions.shiftToNextWorld}
                                visible={!!this.props.currentWorld.nextWorld}
                                active={!oldAndInactive && showNext}
                                encouraged={nextWorldProgress?.playedCount === 0}
                        // disableFadeAnimation={remainInWorldView}
                    />
                </>;
                break;
            case "POPUP_SCREEN":
                children = <>
                    <NiceButton name={"Return"}
                        // TODO: This can be a component showing the current puzzle if we came from singlepuzzle view.
                                display={{image: ReturnImg}}
                                shape={'squarelike'}
                                action={buttonsFunctions.returnFromPopup}
                                active={!oldAndInactive}
                    />
                </>;
                break;
            case "PUZZLE":
                children = <>
                    <NiceButton name={"Restart"}
                                display={{image: RestartImg}}
                                action={buttonsFunctions.restartLevel}
                                encouraged={this.props.atDeadEnd || this.props.currentHint?.suggestRestart}
                                highlighted={this.props.currentHint?.suggestRestart && this.props.hintJustAllocated}
                                active={!oldAndInactive && !this.props.noMovesMade}
                    />
                    <NiceButton name={"World"}
                                display={{image: WorldImg}}
                                shape={'squarelike'}
                                action={buttonsFunctions.showWorld}
                                active={!oldAndInactive}
                    />
                    {this.props.showHints ?
                        <NiceButton name={"AllocateHint"}
                                    display={{image: HintImg}}
                                    insetNumber={Constants.infiniteHintsMode ? undefined : this.props.unallocatedHints}
                                    highlighted={this.props.hintJustAllocated}
                                    action={buttonsFunctions.allocateHint}
                                    active={
                                        !this.props.atDeadEnd &&
                                        !!this.props.currentHint?.hintBuyable &&
                                        !oldAndInactive
                                    }/>
                        :
                        // We can't quack when during puzzle solving.
                        <NiceButton name={"Quack"}
                                    display={{image: DuckImg}}
                                    insetNumber={Constants.showQuackCount ? this.props.quackCounter : undefined}
                                    action={buttonsFunctions.quack}
                                    active={false}
                        />
                    }
                </>;
                break;
            case "PUZZLE_COMPLETE":
                children = <>
                    <NiceButton name={"NextPuzzle"}
                                display={{image: NextPuzzleImg}}
                                action={buttonsFunctions.startNextLevel}
                                visible={this.props.currentView.view === 'PUZZLE_COMPLETE'}
                                active={!oldAndInactive && this.props.nextPuzzleInWorldOrderExists}
                                encouraged
                    />
                    <NiceButton name={"World"}
                        // TODO: Make this better than the trash image displayed now.
                                display={{image: WorldImg}}
                                shape={'squarelike'}
                                action={buttonsFunctions.showWorld}
                                active={!oldAndInactive}
                                encouraged
                    />
                    <NiceButton name={"Quack"}
                                display={{image: DuckImg}}
                                insetNumber={Constants.showQuackCount ? this.props.quackCounter : undefined}
                                action={buttonsFunctions.quack}
                                active={!oldAndInactive}
                    /></>;
                break;
            case "URL_PUZZLE":
                children = <>
                    <NiceButton name={"Restart"}
                                display={{image: RestartImg}}
                                action={buttonsFunctions.restartLevel}
                                encouraged={this.props.atDeadEnd}
                                active={!oldAndInactive}
                    />
                    <NiceButton name={"Options"}
                                display={{image: OptionsImg}}
                                shape={'squarelike'}
                                action={buttonsFunctions.openOptionsMenuFn(
                                    {returnGameView: {view: 'URL_PUZZLE'}})}
                                active={!oldAndInactive}
                    />
                    <NiceButton name={"Quack"}
                                display={{image: DuckImg}}
                                insetNumber={this.props.quackCounter}
                                action={buttonsFunctions.quack}
                                active={false}
                    /></>;
                break;
            case "URL_PUZZLE_COMPLETE":
                children = <>
                    {/* TODO: This could be a 'show solution' button just to have something here instead of nothing. */}
                    <NiceButton name={"Placeholder"}
                                display={{}}
                                visible={false}
                                active={false}
                    />
                    <NiceButton name={"Options"}
                                display={{image: OptionsImg}}
                                shape={'squarelike'}
                                action={buttonsFunctions.openOptionsMenuFn({returnGameView: view})}
                                active={!oldAndInactive}
                    />
                    <NiceButton name={"Quack"}
                                display={{image: DuckImg}}
                                insetNumber={this.props.quackCounter}
                                action={buttonsFunctions.quack}
                                active={!oldAndInactive}
                    /></>;
                break;
            default:
                return <></>;
        }
        return <div className={this.buttonsPanelClasses(view)}
                    key={view.view}
                    children={children}/>

    }

    render() {
        const showPreviousForFade = (this.props.previousView?.view !== this.props.currentView.view);

        return (
            <div className={`buttons-panel buttons-panel--${this.props.gameButtonsOrientation}`}>
                {showPreviousForFade && this.props.previousView ?
                    this.getPanel(this.props.previousView, true)
                    : <></>}
                {this.getPanel(this.props.currentView, false)}
            </div>
        );
    }
}

export default ButtonsPanel;