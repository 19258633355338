import React, {Component} from 'react';
import Puzzle from "../../puzzle/puzzle";
import Utility from "../../utility";


interface PuzzleDefinitionControlsProps {
    currentlyDisplayedPuzzle: Puzzle,
    loadPuzzleWithSolutionFn: (puzzle: Puzzle) => void,
    searchSmallerFn: VoidFunction,
    searchDropOneFn: VoidFunction,
    toggleThemeFn: VoidFunction,
    toggleshowSolutionPathFn: VoidFunction,
    showSolutionPathChecked: boolean,
}

interface PuzzleDefinitionControlsState {
    puzzleTextBoxContent: string,
}

class PuzzleDefinitionControls extends Component<PuzzleDefinitionControlsProps, PuzzleDefinitionControlsState> {
    constructor(props: PuzzleDefinitionControlsProps) {
        super(props);
        this.state = {
            puzzleTextBoxContent: this.props.currentlyDisplayedPuzzle.toString(),
        }
    }

    // shouldComponentUpdate(nextProps: Readonly<PuzzleDefinitionControlsProps>,
    //                       nextState: Readonly<PuzzleDefinitionControlsState>,
    //                       nextContext: any): boolean {
    //     if (this.props.currentlyDisplayedPuzzle !== nextProps.currentlyDisplayedPuzzle) {
    //
    //     }
    // }

    componentWillReceiveProps(nextProps: PuzzleDefinitionControlsProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.currentlyDisplayedPuzzle !== this.props.currentlyDisplayedPuzzle) {
            this.setState({ puzzleTextBoxContent: nextProps.currentlyDisplayedPuzzle.toString(), });
        }
    }

    private updateTextAreaHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const target = event.target;
        const newText = target.value;
        const name = target.name;
        // @ts-ignore
        this.setState({[name]: newText,});
    }

    render() {
        const puzzleTextBoxContent = this.state.puzzleTextBoxContent;
        let likePuzzleEntryBox = puzzleTextBoxContent === this.props.currentlyDisplayedPuzzle.toString() ?
            'puzzle-lab__text-area--same-as-puzzle' : 'puzzle-lab__text-area--different-from-puzzle';

        return (

            <div key={"PuzzleDefinitionControls"}
                 className={"panel puzzle-definition-controls-panel"}>
                <h2 className={'panel-header'}>Puzzle Definition</h2>
                <textarea value={puzzleTextBoxContent}
                          className={`puzzle-lab__text-area puzzle-lab__text-area--puzzle-text ${likePuzzleEntryBox}`}
                          spellCheck={"false"}
                          name={"puzzleTextBoxContent"}
                          key={"puzzleTextBoxContent"}
                          rows={7}
                          onChange={this.updateTextAreaHandler}/>
                <br/>

                <input type={"submit"}
                       className={'puzzle-lab__button'}
                       value={"Reload"} onClick={() => {
                    this.props.loadPuzzleWithSolutionFn(Puzzle.fromString(puzzleTextBoxContent));
                }}/>
                <input type={"submit"}
                       className={'puzzle-lab__button'}
                       value={"Copy"}
                       onClick={(e) => {
                           Utility.setClipboard(puzzleTextBoxContent);
                       }}/>
                &nbsp;
                <input type={"submit"}
                       className={'puzzle-lab__button'}
                       value={"Spin"}
                       onClick={(e) => {
                           this.props.loadPuzzleWithSolutionFn(this.props.currentlyDisplayedPuzzle.rotate());
                       }}/>
                <input type={"submit"}
                       className={'puzzle-lab__button'}
                       value={"T/pose"}
                       onClick={(e) => {
                           this.props.loadPuzzleWithSolutionFn(this.props.currentlyDisplayedPuzzle.transpose());
                       }}/>
                <br/>

                <input type={"submit"}
                       className={'puzzle-lab__button'}
                       value={"Search Smaller"}
                       onClick={this.props.searchSmallerFn}/>
                <input type={"submit"}
                       className={'puzzle-lab__button'}
                       value={"Drop One"}
                       onClick={this.props.searchDropOneFn}/>
                <br/>

                <input type={"submit"}
                       className={'puzzle-lab__button'}
                       value={"Copy URL"}
                       onClick={e => {
                           Utility.setClipboard(Utility.puzzleUrl(this.props.currentlyDisplayedPuzzle));
                       }}/>
                <input type={"submit"}
                       className={'puzzle-lab__button'}
                       value={"Theme"}
                       onClick={this.props.toggleThemeFn}/>
                <br/>

                <label>
                    <input type="checkbox"
                           className={'puzzle-lab__checkbox'}
                           name="showSolutionPath"
                           checked={this.props.showSolutionPathChecked}
                           onChange={this.props.toggleshowSolutionPathFn}/>
                    Show path of solution
                </label>
            </div>
        );
    }
}

export default PuzzleDefinitionControls;