// jumpworld generated by WorldBuilder
import Puzzle from "../puzzle/puzzle";
import {World} from "./world";
import Location from "../puzzle/location";


export let jumpworld = new World("jumpworld", [
//
// J 5x5 unique
// Path: J:8  Entropy: 3.58
    Puzzle.fromString(`
J0111
10101
11101
00001
11111
`)
        .setCanonicalSolutionWithWitness(`0,0/2,0/2,2/0,2/0,4/2,4/4,4/4,2/4,0`.split('/').map(Location.fromString))
        .setComment(
            `Nice clean, obvious`)
    ,
//
// J 5x5 unique
// Path: J:8  Entropy: 4.00
    Puzzle.fromString(`
J0111
10101
10101
00101
11101
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,0/2,0/4,0/4,2/2,2/0,2/0,4/2,4/4,4`.split('/').map(Location.fromString))
    ,
//
// J 5x5 unique
// Path: J:8  Entropy: 4.00
    Puzzle.fromString(`
J0111
00001
11101
10001
11111
`)
        .setCanonicalSolutionWithWitness(`0,0/0,2/0,4/2,4/4,4/4,2/4,0/2,0/2,2`.split('/').map(Location.fromString))
    ,
//
// J 5x5 unique
// Path: J:8  Entropy: 4.00
    Puzzle.fromString(`
J0101
10101
10101
10101
10101
`)
        .setCanonicalSolutionWithWitness(`0,0/2,0/4,0/4,2/2,2/0,2/0,4/2,4/4,4`.split('/').map(Location.fromString))
    ,
//
// J 5x5 unique
// Path: J:8  Entropy: 4.00
    Puzzle.fromString(`
J0101
00001
10111
00000
11111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,0/0,2/0,4/2,4/2,2/2,0/4,0/4,2/4,4`.split('/').map(Location.fromString))
    ,
//
// J 5x5 unique
// Path: J:8  Entropy: 4.00
    Puzzle.fromString(`
J0101
00100
10111
10000
10101
`)
        .setCanonicalSolutionWithWitness(`0,0/2,0/4,0/4,2/4,4/2,4/2,2/0,2/0,4`.split('/').map(Location.fromString))
        .setComment(
            `Possible to fuck up, but unlikely`)
    ,
//
// J 5x5 unique
// Path: J:8  Entropy: 4.00
    Puzzle.fromString(`
J0101
00000
10101
00100
10111
`)
        .setCanonicalSolutionWithWitness(`0,0/0,2/0,4/2,4/4,4/4,2/2,2/2,0/4,0`.split('/').map(Location.fromString))
    ,
//
// J 7x5 unique
// Path: J:11  Entropy: 6.00
    Puzzle.fromString(`
J0101
00000
10111
00000
10101
00101
10101
`)
        .setCanonicalSolutionWithWitness(`0,0/0,2/0,4/2,4/2,2/2,0/4,0/6,0/6,2/4,2/4,4/6,4`.split('/').map(Location.fromString))
    ,
//
// J 7x5 unique
// Path: J:11  Entropy: 5.58
    Puzzle.fromString(`
J0101
00000
10101
00000
11101
00100
10101
`)
        .setCanonicalSolutionWithWitness(`0,0/2,0/2,2/0,2/0,4/2,4/4,4/6,4/6,2/4,2/4,0/6,0`.split('/').map(Location.fromString))
    ,
//
// J 7x7 unique
// Path: J:14  Entropy: 7.00
    Puzzle.fromString(`
1010100
0000000
10J0101
0000000
1110101
0000100
1010101
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`2,2/2,0/0,0/0,2/0,4/2,4/2,6/4,6/6,6/6,4/4,4/4,2/4,0/6,0/6,2`.split('/').map(Location.fromString))
    ,
//
// J 9x7 unique
// Path: J:19  Entropy: 10.6
    Puzzle.fromString(`
1010101
0000000
1010101
0000001
11J0101
0000000
1010101
0000100
1010101
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`4,2/4,0/6,0/8,0/8,2/6,2/6,4/8,4/8,6/6,6/4,6/2,6/0,6/0,4/0,2/0,0/2,0/2,2/2,4/4,4`.split('/').map(Location.fromString))
    ,
//
// J 7x7 unique
// Path: J:15  Entropy: 9.00
    Puzzle.fromString(`
J010101
0000000
1010101
0000000
1110101
0000100
1010101
`)
        .setCanonicalSolutionWithWitness(`0,0/0,2/0,4/0,6/2,6/4,6/6,6/6,4/4,4/2,4/2,2/2,0/4,0/4,2/6,2/6,0`.split('/').map(Location.fromString))
        .setComment(
            `# gorgeous. unique on pattern`)
    ,
//
// J 7x7 unique
// Path: J:15  Entropy: 7.17
    Puzzle.fromString(`
1010101
0000000
10J0101
0000000
1110101
0000100
1010101
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`2,2/2,4/4,4/6,4/6,6/4,6/2,6/0,6/0,4/0,2/0,0/2,0/4,0/4,2/6,2/6,0`.split('/').map(Location.fromString))
    ,
//
// J 9x7 unique
// Path: J:19  Entropy: 11.2
    Puzzle.fromString(`
J010101
0000100
1010101
0000000
1010101
0000000
1010111
0010000
1010101
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,0/0,2/2,2/2,0/4,0/6,0/8,0/8,2/6,2/4,2/4,4/2,4/0,4/0,6/2,6/4,6/6,6/6,4/8,4/8,6`.split('/').map(Location.fromString))
    ,
//
// J 9x7 unique
// Path: J:19  Entropy: 10.2
    Puzzle.fromString(`
1010101
0000100
10J0101
0000000
1010111
0000000
1010101
0000100
1010101
`)
        .setCanonicalSolutionWithWitness(`2,2/4,2/4,4/4,6/6,6/8,6/8,4/6,4/6,2/8,2/8,0/6,0/4,0/2,0/0,0/0,2/0,4/2,4/2,6/0,6`.split('/').map(Location.fromString))
    ,
//
// J 9x7 unique
// Path: J:19  Entropy: 10.6
    Puzzle.fromString(`
1010101
0000100
10J0101
0000000
1010101
0000000
1010111
0010000
1010101
`)
        .setCanonicalSolutionWithWitness(`2,2/0,2/0,0/2,0/4,0/6,0/8,0/8,2/6,2/4,2/4,4/2,4/0,4/0,6/2,6/4,6/6,6/6,4/8,4/8,6`.split('/').map(Location.fromString))
    ,
//
// J 9x7 unique
// Path: J:19  Entropy: 9.75
    Puzzle.fromString(`
1010101
0000000
1010111
0000000
10J0101
0000000
1110101
0000000
1010101
`)
        .setCanonicalSolutionWithWitness(`4,2/2,2/2,4/2,6/0,6/0,4/0,2/0,0/2,0/4,0/6,0/6,2/6,4/4,4/4,6/6,6/8,6/8,4/8,2/8,0`.split('/').map(Location.fromString))
    ,
//
// mJ 4x4 unique
// Path: J:1 m:13  Entropy: 6.17
    Puzzle.fromString(`
1J11
1111
1m11
1111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,1/2,1/2,2/1,2/0,2/0,3/1,3/2,3/3,3/3,2/3,1/3,0/2,0/1,0/0,0`.split('/').map(Location.fromString))
    ,
//
// mJ 4x4 unique
// Path: m:7 J:3  Entropy: 7.00
    Puzzle.fromString(`
m111
1100
111J
1111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,0/1,0/2,0/3,0/3,1/3,2/3,3/2,3/2,1/0,1/0,3`.split('/').map(Location.fromString))
    ,
//
// mJJ 7x3 unique
// Path: J:3 m:5 J:5  Entropy: 3.58
    Puzzle.fromString(`
1J1
111
11J
101
111
111
1m1
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,1/2,1/4,1/6,1/6,2/5,2/4,2/3,2/2,2/0,2/0,0/2,0/4,0/6,0`.split('/').map(Location.fromString))
    ,
//
// mJ 4x4 unique
// Path: J:3 m:7  Entropy: 3.58
    Puzzle.fromString(`
0J11
1101
1m11
1111
`)
        .setCanonicalSolutionWithWitness(`0,1/0,3/2,3/2,1/1,1/1,0/2,0/3,0/3,1/3,2/3,3`.split('/').map(Location.fromString))
    ,
//
// mJ 4x4 unique
// Path: m:9 J:3  Entropy: 8.75
    Puzzle.fromString(`
0101
1111
111J
1m11
`)
        .setCanonicalSolutionWithWitness(`3,1/3,0/2,0/1,0/1,1/1,2/2,2/3,2/3,3/2,3/0,3/0,1/2,1`.split('/').map(Location.fromString))
    ,
//
// mJ 5x5 unique
// Path: J:5 m:13  Entropy: 10.0
    Puzzle.fromString(`
m1110
11111
1111J
11111
11111
`)
        .setCanonicalSolutionWithWitness(`2,4/4,4/4,2/4,0/2,0/0,0/0,1/1,1/2,1/3,1/3,2/3,3/2,3/2,2/1,2/0,2/0,3/1,3/1,4`.split('/').map(Location.fromString))
    ,
//
// mJ 5x5 unique
// Path: J:8 m:8  Entropy: 4.00
    Puzzle.fromString(`
m0111
11111
10J11
11111
11111
`)
        .setCanonicalSolutionWithWitness(`2,2/2,0/4,0/4,2/4,4/2,4/0,4/0,2/0,0/1,0/1,1/1,2/1,3/2,3/3,3/3,2/3,1`.split('/').map(Location.fromString))
    ,
//
// mmJ 5x5 unique
// Path: m:9 J:3 m:9  Entropy: 11.2
    Puzzle.fromString(`
mm111
11111
11111
11111
111J1
`)
        .setCanonicalSolutionWithWitness(`0,0/1,0/2,0/3,0/4,0/4,1/3,1/3,2/4,2/4,3/2,3/2,1/0,1/0,2/1,2/1,3/0,3/0,4/1,4/2,4/3,4/4,4`.split('/').map(Location.fromString))
    ,
//
// mJJ 5x5 unique
// Path: J:2 m:17 J:2  Entropy: 13.2
    Puzzle.fromString(`
1m111
11111
11JJ1
11111
11111
`)
        .setCanonicalSolutionWithWitness(`2,3/0,3/0,1/0,0/1,0/2,0/3,0/4,0/4,1/4,2/4,3/4,4/3,4/3,3/3,2/3,1/2,1/1,1/1,2/2,2/2,4/0,4`.split('/').map(Location.fromString))
    ,
//
// mJ 5x4 unique
// Path: J:2 m:15  Entropy: 5.17
    Puzzle.fromString(`
1111
1m11
1111
111J
1111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`3,3/3,1/1,1/1,2/2,2/2,3/1,3/0,3/0,2/0,1/0,0/1,0/2,0/3,0/4,0/4,1/4,2/4,3`.split('/').map(Location.fromString))
    ,
//
// mJ 5x5 unique
// Path: m:13 J:6  Entropy: 14.9
    Puzzle.fromString(`
1m111
11111
10J11
11111
11101
`)
        .setCanonicalSolutionWithWitness(`0,1/1,1/1,0/2,0/3,0/4,0/4,1/3,1/3,2/3,3/2,3/1,3/1,2/2,2/4,2/4,4/2,4/0,4/0,2/0,0`.split('/').map(Location.fromString))
    ,
//
// mJJ 7x3 unique
// Path: J:7 m:4 J:1  Entropy: 4.58
    Puzzle.fromString(`
J1m
111
111
1J1
111
011
111
`)
        .setCanonicalSolutionWithWitness(`0,0/2,0/4,0/6,0/6,2/4,2/2,2/0,2/0,1/1,1/2,1/3,1/5,1`.split('/').map(Location.fromString))
    ,
//
// mmJ 5x7 unique
// Path: m:12 J:4 m:10  Entropy: 6.58
    Puzzle.fromString(`
1111111
J010m01
1110111
101010m
1111111
`)
        .setCanonicalSolutionWithWitness(`1,4/2,4/2,5/2,6/1,6/0,6/0,5/0,4/0,3/0,2/0,1/0,0/1,0/1,2/3,2/3,4/3,6/4,6/4,5/4,4/4,3/4,2/4,1/4,0/3,0/2,0/2,1`.split('/').map(Location.fromString))
    ,

//
// mJJ 7x3 unique
// Path: J:3 m:9 J:3  Entropy: 8.58
    Puzzle.fromString(`
111
J11
101
111
J11
11m
111
`)
        .setCanonicalSolutionWithWitness(`1,0/1,2/3,2/5,2/6,2/6,1/6,0/5,0/5,1/4,1/3,1/3,0/4,0/2,0/0,0/0,2`.split('/').map(Location.fromString))
    ,
//
// mJd 6x4 unique
// Path: m:17 J:3 d:0  Entropy: 14.9
// Doesn't use end movementType
    Puzzle.fromString(`
1111
11d1
m111
1111
1111
J111
`)
        .setCanonicalSolutionWithWitness(`2,0/2,1/1,1/1,0/0,0/0,1/0,2/0,3/1,3/2,3/3,3/4,3/5,3/5,2/4,2/4,1/5,1/5,0/3,0/3,2/1,2`.split('/').map(Location.fromString))
    ,
//
// mmJ 5x5 unique
// Path: m:2 J:3 m:16  Entropy: 7.75
    Puzzle.fromString(`
10111
1J1m1
11m11
11111
11111
`)
        .setCanonicalSolutionWithWitness(`2,2/2,1/1,1/3,1/3,3/1,3/1,2/0,2/0,3/0,4/1,4/2,4/3,4/4,4/4,3/4,2/4,1/4,0/3,0/2,0/1,0/0,0`.split('/').map(Location.fromString))
    ,
//
// mmJ 5x5 unique
// Path: m:21 J:1  Entropy: 11.8
    Puzzle.fromString(`
11m11
10111
11m11
11J11
11111
`)
        .setCanonicalSolutionWithWitness(`0,2/0,1/0,0/1,0/2,0/2,1/3,1/3,0/4,0/4,1/4,2/4,3/4,4/3,4/2,4/1,4/0,4/0,3/1,3/2,3/3,3/3,2/1,2`.split('/').map(Location.fromString))
        .setComment(
            `// First unused movementtype`)
    ,
//
// mmmJ 7x3 unique
// Path: m:9 J:6  Entropy: 12.6
    Puzzle.fromString(`
Jm1
111
111
111
1m1
111
1m1
`)
        .setCanonicalSolutionWithWitness(`4,1/5,1/5,2/4,2/3,2/3,1/2,1/1,1/1,0/0,0/0,2/2,2/2,0/4,0/6,0/6,2`.split('/').map(Location.fromString))
    ,
//
// mJJ 7x5 unique
// Path: m:28 J:3  Entropy: 24.8
    Puzzle.fromString(`
J1111
11111
m1111
11J11
11111
11111
11111
`)
        .setCanonicalSolutionWithWitness(`2,0/3,0/4,0/5,0/6,0/6,1/6,2/6,3/6,4/5,4/4,4/3,4/2,4/1,4/0,4/0,3/1,3/2,3/3,3/4,3/5,3/5,2/5,1/4,1/3,1/2,1/1,1/1,0/0,0/0,2/2,2/4,2`.split('/').map(Location.fromString))
    ,
//
// mmJ 5x5 unique
// Path: m:13 J:3 m:5  Entropy: 9.75
    Puzzle.fromString(`
1m111
11111
11m11
10111
111J1
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`2,2/3,2/3,3/2,3/1,3/1,2/0,2/0,3/0,4/1,4/2,4/3,4/4,4/4,3/4,1/2,1/0,1/0,0/1,0/2,0/3,0/4,0`.split('/').map(Location.fromString))
    ,
//
// mJd 5x5 unique
// Path: d:2 m:19 J:2  Entropy: 14.3
    Puzzle.fromString(`
1m111
11111
1dJ11
11111
11011
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`2,1/1,2/0,1/1,1/1,0/2,0/3,0/4,0/4,1/3,1/3,2/3,3/4,3/4,4/3,4/2,4/1,4/0,4/0,3/1,3/2,3/2,2/0,2/0,0`.split('/').map(Location.fromString))
    ,
//
// mmJ 6x6 unique
// Path: m:15 J:3 m:3  Entropy: 12.3
    Puzzle.fromString(`
00m100
0011m0
111111
1111J1
011110
001100
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,2/0,3/1,3/2,3/2,2/2,1/2,0/3,0/3,1/4,1/4,2/5,2/5,3/4,3/4,4/3,4/3,2/1,2/1,4/2,4/2,5/3,5`.split('/').map(Location.fromString))
    ,
//
// mmJ 6x6 unique
// Path: m:7 J:3 m:11  Entropy: 11.9
    Puzzle.fromString(`
00m100
011110
111111
11m1J1
011100
001100
`)
        .setCanonicalSolutionWithWitness(`0,2/0,3/1,3/2,3/2,4/2,5/3,5/3,4/1,4/1,2/3,2/3,3/4,3/5,3/5,2/4,2/4,1/3,1/3,0/2,0/2,1/1,1`.split('/').map(Location.fromString))
    ,
//
// mJdd 5x5 unique
// Path: d:6 m:7 J:3 d:5  Entropy: 11.6
    Puzzle.fromString(`
dJ110
11111
11011
11111
011dm
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,0/1,1/2,0/3,1/4,2/3,3/4,4/3,4/2,4/1,4/1,3/1,2/0,2/0,1/0,3/2,3/4,3/3,2/4,1/3,0/2,1/1,0`.split('/').map(Location.fromString))
    ,
//
// mJdd 5x5 unique
// Path: d:4 m:9 J:3 d:4  Entropy: 13.2
    Puzzle.fromString(`
m1110
11111
110dd
11111
011J1
`)
        .setCanonicalSolutionWithWitness(`2,4/1,3/0,2/1,1/0,0/1,0/2,0/3,0/3,1/3,2/3,3/3,4/4,4/4,3/4,1/2,1/2,3/1,4/0,3/1,2/0,1`.split('/').map(Location.fromString))
    ,
//
// mJdd 5x5 unique
// Path: m:10 J:7  Entropy: 17.5
    Puzzle.fromString(`
1d111
1md11
1111J
11111
11111
`)
        .setCanonicalSolutionWithWitness(`1,1/2,1/3,1/3,2/3,3/2,3/1,3/0,3/0,4/1,4/2,4/4,4/4,2/4,0/2,0/0,0/0,2/2,2`.split('/').map(Location.fromString))
        .setComment(
            `Very nice`)
    ,
//
// mJdd 5x5 unique
// Path: J:8 m:8  Entropy: 5.00
    Puzzle.fromString(`
md111
11111
11J11
d1111
11111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`2,2/2,0/4,0/4,2/4,4/2,4/0,4/0,2/0,0/1,0/1,1/1,2/1,3/2,3/3,3/3,2/3,1`.split('/').map(Location.fromString))
        .setComment(
            `Good. double-diagonal is a nice hint here. easy once you see in.
But samey`)
    ,
//
// mJdd 5x5 unique
// Path: m:20 J:2 d:0  Entropy: 16.3
// Doesn't use end movementType
    Puzzle.fromString(`
11m11
11111
1dd11
11111
J1111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,2/0,1/0,0/1,0/1,1/1,2/1,3/0,3/0,4/1,4/2,4/2,3/3,3/3,4/4,4/4,3/4,2/3,2/3,1/4,1/4,0/2,0/2,2`.split('/').map(Location.fromString))
    ,
//
// mJdd 5x5 unique
// Path: J:6 m:12 d:0  Entropy: 10.2
// Doesn't use end movementType
    Puzzle.fromString(`
11111
1dd11
11m11
11111
1111J
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`4,4/4,2/4,0/2,0/0,0/0,2/2,2/2,3/1,3/0,3/0,4/1,4/2,4/3,4/3,3/3,2/3,1/2,1/1,1`.split('/').map(Location.fromString))
    ,
//
// mJdd 5x5 unique
// Path: J:2 m:20 d:0  Entropy: 14.0
// Doesn't use end movementType
    Puzzle.fromString(`
md11d
11111
11J11
11111
11111
`)
        .setCanonicalSolutionWithWitness(`2,2/0,2/0,0/1,0/1,1/2,1/2,0/3,0/4,0/4,1/3,1/3,2/4,2/4,3/4,4/3,4/3,3/2,3/2,4/1,4/1,3/0,3/0,4`.split('/').map(Location.fromString))
        .setComment(
            `// tricoy`)
    ,
//
// mJd 6x6 unique
// Path: d:3 m:17 J:3  Entropy: 13.9
    Puzzle.fromString(`
00m100
011110
111111
J11d11
011110
001100
`)
        .setCanonicalSolutionWithWitness(`3,3/2,2/1,1/0,2/0,3/1,3/2,3/2,4/2,5/3,5/3,4/4,4/4,3/5,3/5,2/4,2/4,1/3,1/2,1/2,0/3,0/3,2/1,2/1,4`.split('/').map(Location.fromString))
    ,
//
// mJdd 5x5 unique
// Path: J:5 d:2 m:11 d:1  Entropy: 12.2
    Puzzle.fromString(`
m1d11
11d11
11111
11111
J1111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`4,0/4,2/4,4/2,4/0,4/0,2/1,1/0,0/1,0/2,0/3,0/3,1/2,1/2,2/3,2/3,3/2,3/1,3/1,2/0,1`.split('/').map(Location.fromString))
    ,
//
// mJdd 5x5 unique
// Path: J:7 d:3 m:5 d:2  Entropy: 9.17
    Puzzle.fromString(`
11J1d
1m111
111d1
11111
11111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,2/0,0/2,0/4,0/4,2/4,4/2,4/0,4/1,3/2,2/1,1/2,1/3,1/3,2/3,3/2,3/1,2/0,3`.split('/').map(Location.fromString))
    ,
//
// mJd 5x5 unique
// Path: m:13 J:3  Entropy: 11.8
    Puzzle.fromString(`
11111
1m111
01110
01110
01dJ0
`)
        .setCanonicalSolutionWithWitness(`1,1/1,0/0,0/0,1/0,2/0,3/0,4/1,4/1,3/1,2/2,2/3,2/3,3/4,3/4,1/2,1/2,3`.split('/').map(Location.fromString))
        .setComment(
            `I like it`)
    ,
//
// mJd 7x4 unique
// Path: d:5 m:15 J:5  Entropy: 13.8
    Puzzle.fromString(`
111J
1d11
1111
1001
1111
1111
11m1
`)
        .setCanonicalSolutionWithWitness(`1,1/2,2/3,3/4,2/5,3/6,2/5,2/5,1/6,1/6,0/5,0/4,0/3,0/2,0/1,0/0,0/0,1/0,2/1,2/1,3/0,3/2,3/2,1/4,1/4,3/6,3`.split('/').map(Location.fromString))
    ,
//
// mJ 7x5 unique
// Path: m:21 J:6  Entropy: 17.3
    Puzzle.fromString(`
01110
J1111
11111
10101
1101m
11111
01110
`)
        .setCanonicalSolutionWithWitness(`4,4/4,3/5,3/6,3/6,2/6,1/5,1/4,1/4,0/3,0/2,0/2,1/2,2/2,3/2,4/1,4/1,3/0,3/0,2/0,1/1,1/1,0/1,2/3,2/3,4/5,4/5,2/5,0`.split('/').map(Location.fromString))
        .setComment(
            `Really nice`)
    ,
//
// mJJ 7x5 unique
// Path: J:5 m:12 J:5  Entropy: 11.2
    Puzzle.fromString(`
0m110
11111
11011
10101
110J1
1111J
01110
`)
        .setCanonicalSolutionWithWitness(`4,3/4,1/2,1/2,3/0,3/0,1/1,1/1,0/2,0/3,0/4,0/5,0/5,1/6,1/6,2/6,3/5,3/5,4/3,4/1,4/1,2/3,2/5,2`.split('/').map(Location.fromString))
        .setComment(
            `Samey`)
    ,
//
// mJJ 7x5 unique
// Path: J:6 m:6 J:7  Entropy: 10.8
    Puzzle.fromString(`
0J110
11J11
111m1
10101
11011
11111
01110
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,1/2,1/4,1/6,1/6,3/4,3/2,3/2,4/1,4/1,3/0,3/0,2/1,2/3,2/3,4/5,4/5,2/5,0/3,0/1,0`.split('/').map(Location.fromString))
        .setComment(
            `Cool, a little harder`)
    ,
//
// mJJ 7x5 unique
// Path: J:4 m:10 J:7  Entropy: 12.3
    Puzzle.fromString(`
01110
11111
1m011
1010J
110J1
11111
01110
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`4,3/6,3/6,1/4,1/2,1/2,0/1,0/1,1/0,1/0,2/0,3/1,3/2,3/2,4/3,4/5,4/5,2/5,0/3,0/3,2/1,2/1,4`.split('/').map(Location.fromString))
    ,
//
// mJJ 7x5 unique
// Path: J:5 m:14 J:4  Entropy: 10.2
    Puzzle.fromString(`
0m110
11111
1J011
1010J
11011
11111
01110
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`2,1/4,1/4,3/2,3/0,3/0,1/1,1/1,0/2,0/3,0/4,0/5,0/5,1/6,1/6,2/6,3/5,3/5,4/4,4/3,4/1,4/1,2/3,2/5,2`.split('/').map(Location.fromString))
        .setComment(
            `Very samey`)
    ,
//
// mJJ 7x5 unique
// Path: J:4 m:10 J:7  Entropy: 12.8
    Puzzle.fromString(`
0J110
11111
11011
1010J
1m011
11111
01110
`)
        .setCanonicalSolutionWithWitness(`0,1/0,3/2,3/2,1/4,1/4,0/5,0/5,1/6,1/6,2/6,3/5,3/4,3/4,4/3,4/1,4/1,2/1,0/3,0/3,2/5,2/5,4`.split('/').map(Location.fromString))
    ,
//
// mJd 7x4 unique
// Path: m:19 J:3 d:0  Entropy: 14.8
// Doesn't use end movementType
    Puzzle.fromString(`
1111
1111
11J1
1001
11d1
m111
1111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`5,0/6,0/6,1/5,1/5,2/6,2/6,3/5,3/4,3/3,3/2,3/1,3/0,3/0,2/0,1/0,0/1,0/1,1/1,2/2,2/2,0/4,0/4,2`.split('/').map(Location.fromString))
    ,
//
// mJdd 8x4 unique
// Path: d:2 m:17 J:3 d:2  Entropy: 14.3
    Puzzle.fromString(`
11J1
1111
1111
1001
d001
m1d1
1111
1111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`5,2/6,1/5,0/6,0/7,0/7,1/7,2/7,3/6,3/5,3/4,3/3,3/2,3/2,2/2,1/1,1/1,2/1,3/0,3/0,2/0,0/2,0/4,0/5,1/6,2`.split('/').map(Location.fromString))
    ,
//
// mJdd 6x4 unique
// Path: d:3 m:13 d:2 J:1  Entropy: 8.00
    Puzzle.fromString(`
m11J
111d
100d
1001
1111
1111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`1,3/0,2/1,1/0,0/1,0/2,0/3,0/4,0/5,0/5,1/4,1/4,2/5,2/5,3/4,3/3,3/2,3/1,2/0,3/0,1`.split('/').map(Location.fromString))
    ,
//
// mJd 7x4 unique
// Path: m:15 J:5 d:0  Entropy: 13.3
// Doesn't use end movementType
    Puzzle.fromString(`
11J1
1111
1111
1001
1111
1111
11dm
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`6,3/5,3/5,2/5,1/4,1/4,2/4,3/3,3/2,3/2,2/2,1/1,1/1,2/1,3/0,3/0,2/0,0/2,0/4,0/6,0/6,2`.split('/').map(Location.fromString))
    ,
//
// mJd 6x4 unique
// Path: d:2 m:17 J:3  Entropy: 14.2
    Puzzle.fromString(`
1111
1d11
1111
1m11
1111
J011
`)
        .setCanonicalSolutionWithWitness(`1,1/2,2/3,1/2,1/2,0/1,0/0,0/0,1/0,2/0,3/1,3/2,3/3,3/4,3/5,3/5,2/4,2/4,1/4,0/5,0/3,0/3,2/1,2`.split('/').map(Location.fromString))
        .setComment(
            `pretty ok 6.5/10`)
    ,
//
// mJd 6x4 unique
// Path: J:4 d:1 m:13  Entropy: 6.58
    Puzzle.fromString(`
1111
1111
1m11
11d1
1111
J011
`)
        .setCanonicalSolutionWithWitness(`5,0/3,0/1,0/1,2/3,2/2,1/3,1/4,1/4,2/5,2/5,3/4,3/3,3/2,3/1,3/0,3/0,2/0,1/0,0`.split('/').map(Location.fromString))
    ,
//
// mJdd 5x5 unique
// Path: d:2 m:15 J:2 d:3  Entropy: 15.6
    Puzzle.fromString(`
11111
d1md1
11111
1J111
11111
`)
        .setCanonicalSolutionWithWitness(`1,0/0,1/1,2/0,2/0,3/0,4/1,4/2,4/3,4/4,4/4,3/4,2/4,1/4,0/3,0/2,0/2,1/3,1/3,3/1,3/2,2/1,1/0,0`.split('/').map(Location.fromString))
        .setComment(
            `// good shit`)
    ,
//
// mJdd 5x5 unique
// Path: d:4 m:9 d:5 J:3  Entropy: 17.5
    Puzzle.fromString(`
1mJ11
11110
11111
1d111
111d1
`)
        .setCanonicalSolutionWithWitness(`4,3/3,2/2,3/1,2/0,1/0,0/1,0/1,1/2,1/2,0/3,0/4,0/4,1/3,1/4,2/3,3/2,2/1,3/0,2/0,4/2,4/4,4`.split('/').map(Location.fromString))
        .setComment(
            `// Good as heck`)
    ,
//
// mJdd 5x5 unique
// Path: d:4 J:5 m:7 d:3  Entropy: 8.75
    Puzzle.fromString(`
m111d
11111
1111J
11d11
11111
`)
        .setCanonicalSolutionWithWitness(`0,4/1,3/2,2/3,3/2,4/4,4/4,2/4,0/2,0/0,0/0,1/0,2/1,2/1,1/2,1/3,1/3,2/2,3/1,4/0,3`.split('/').map(Location.fromString))
    ,
//
// mJdd 5x5 unique
// Path: d:6 J:1 m:11 d:5  Entropy: 12.8
    Puzzle.fromString(`
m1J1d
11111
11111
1111d
11111
`)
        .setCanonicalSolutionWithWitness(`0,4/1,3/2,4/3,3/2,2/1,1/0,2/0,0/1,0/2,0/3,0/4,0/4,1/3,1/3,2/4,2/4,3/4,4/3,4/2,3/1,4/0,3/1,2/2,1`.split('/').map(Location.fromString))
    ,
//
// mJdd 5x5 unique
// Path: J:5 d:2 m:11 d:1  Entropy: 12.2
    Puzzle.fromString(`
d1m11
11d11
1111J
11111
11111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`2,4/4,4/4,2/4,0/2,0/0,0/1,1/0,2/0,3/0,4/1,4/1,3/2,3/3,3/3,2/3,1/2,1/2,2/1,2/0,1`.split('/').map(Location.fromString))
    ,
//
// mJdd 5x5 unique
// Path: J:2 d:3 m:15 d:2  Entropy: 9.58
    Puzzle.fromString(`
md111
111d1
11111
1J111
11111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`3,1/3,3/1,3/2,2/1,1/0,0/1,0/2,0/3,0/4,0/4,1/4,2/4,3/4,4/3,4/2,4/1,4/0,4/0,3/0,2/0,1/1,2/2,1`.split('/').map(Location.fromString))
    ,
//
// mJdd 5x5 unique
// Path: m:11 J:6 d:1  Entropy: 15.5
    Puzzle.fromString(`
dm11J
1111d
11111
11111
11111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,1/0,2/1,2/2,2/2,1/3,1/3,2/3,3/2,3/1,3/0,3/0,4/2,4/4,4/4,2/4,0/2,0/0,0/1,1`.split('/').map(Location.fromString))
    ,
//
// mJdd 5x5 unique
// Path: J:1 d:1 m:21 d:0  Entropy: 13.8
// Doesn't use end movementType
    Puzzle.fromString(`
11d11
1m11d
11J11
11111
11111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`2,2/0,2/1,1/0,1/0,0/1,0/2,0/2,1/3,1/3,0/4,0/4,1/4,2/3,2/3,3/4,3/4,4/3,4/2,4/2,3/1,3/0,3/0,4/1,4`.split('/').map(Location.fromString))
    ,
]);