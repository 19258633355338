// bulletWorld generated by WorldBuilder
import Puzzle from "../puzzle/puzzle";
import {World} from "./world";
import Location from "../puzzle/location";


export let bulletWorld = new World("bulletWorld", [
//
// X 3x4 unique
// Path: X:11  Entropy: 5.58
    Puzzle.fromString(`
1111
1111
11X1
`)
        .setCanonicalSolutionWithWitness(`2,2/2,3/0,3/0,0/0,2/0,1/2,1/2,0/1,0/1,3/1,1/1,2`.split('/').map(Location.fromString))
        .setComment(
            `2 solns`)
    ,
//
// X 2x4 unique
// Path: X:6  Entropy: 1.58
    Puzzle.fromString(`
01X1
1111
`)
        .setCanonicalSolutionWithWitness(`0,2/0,3/1,3/1,0/1,2/1,1/0,1`.split('/').map(Location.fromString))
    ,
//
// X 4x4 unique
// Path: X:10  Entropy: 3.58
    Puzzle.fromString(`
0X10
1110
1111
0110
`)
        .setCanonicalSolutionWithWitness(`0,1/0,2/3,2/3,1/1,1/1,0/2,0/2,3/2,1/2,2/1,2`.split('/').map(Location.fromString))
    ,
//
// X 4x4 unique
// Path: X:14  Entropy: 5.58
    Puzzle.fromString(`
1011
1111
1X11
1111
`)
        .setCanonicalSolutionWithWitness(`2,1/3,1/3,0/0,0/2,0/1,0/1,3/0,3/0,2/3,2/3,3/2,3/2,2/1,2/1,1`.split('/').map(Location.fromString))
    ,
//
// X 4x4 unique
// Path: X:13  Entropy: 5.00
    Puzzle.fromString(`
1111
1111
110X
1110
`)
        .setCanonicalSolutionWithWitness(`2,3/0,3/0,0/3,0/3,2/3,1/0,1/2,1/2,0/1,0/1,3/1,1/1,2/0,2`.split('/').map(Location.fromString))
    ,
//
// X 4x4 unique
// Path: X:14  Entropy: 6.58
    Puzzle.fromString(`
X101
1111
1111
1111
`)
        .setCanonicalSolutionWithWitness(`0,0/3,0/3,3/0,3/2,3/1,3/1,0/2,0/2,2/3,2/3,1/0,1/2,1/1,1/1,2`.split('/').map(Location.fromString))
    ,
//
// X 5x5 unique
// Path: X:19  Entropy: 4.58
    Puzzle.fromString(`
1X111
11011
10001
11011
11111
`)
        .setCanonicalSolutionWithWitness(`0,1/0,4/0,2/0,3/1,3/1,4/4,4/2,4/3,4/3,3/4,3/4,0/4,2/4,1/3,1/3,0/0,0/2,0/1,0/1,1`.split('/').map(Location.fromString))
    ,
//
// X 5x5 unique
// Path: X:16  Entropy: 6.58
    Puzzle.fromString(`
01100
11X10
11111
01111
00110
`)
        .setCanonicalSolutionWithWitness(`1,2/0,2/0,1/3,1/1,1/1,0/2,0/2,4/3,4/3,2/4,2/4,3/1,3/3,3/2,3/2,1/2,2`.split('/').map(Location.fromString))
    ,
//
// X 5x4 unique
// Path: X:18  Entropy: 8.17
    Puzzle.fromString(`
1X11
1111
1011
1111
1111
`)
        .setCanonicalSolutionWithWitness(`0,1/0,3/0,2/4,2/4,0/4,1/3,1/3,0/0,0/2,0/1,0/1,3/1,1/1,2/3,2/2,2/2,3/4,3/3,3`.split('/').map(Location.fromString))
    ,
//
// X 5x5 unique
// Path: X:19  Entropy: 9.58
    Puzzle.fromString(`
00110
11111
111X1
11111
01110
`)
        .setCanonicalSolutionWithWitness(`2,3/2,4/1,4/1,0/3,0/3,4/3,1/3,3/4,3/4,1/4,2/0,2/0,3/1,3/1,1/1,2/3,2/2,2/2,0/2,1`.split('/').map(Location.fromString))
    ,
//
// X 6x4 unique
// Path: X:22  Entropy: 10.8
    Puzzle.fromString(`
1111
1111
X111
1011
1111
1111
`)
        .setCanonicalSolutionWithWitness(`2,0/2,3/5,3/5,0/3,0/4,0/4,3/4,1/5,1/5,2/0,2/0,3/1,3/1,0/0,0/0,1/2,1/1,1/1,2/4,2/2,2/3,2/3,3`.split('/').map(Location.fromString))
    ,
//
// X 5x5 unique
// Path: X:23  Entropy: 12.6
    Puzzle.fromString(`
10111
11111
11X11
11111
11111
`)
        .setCanonicalSolutionWithWitness(`2,2/0,2/0,4/4,4/4,0/0,0/3,0/3,4/3,1/4,1/4,3/4,2/3,2/3,3/0,3/2,3/2,4/1,4/1,0/2,0/2,1/1,1/1,3/1,2`.split('/').map(Location.fromString))
    ,
//
// X 5x5 unique
// Path: X:22  Entropy: 10.6
    Puzzle.fromString(`
10X11
11111
11011
11111
11111
`)
        .setCanonicalSolutionWithWitness(`0,2/0,4/4,4/4,0/0,0/3,0/3,4/3,1/4,1/4,3/4,2/3,2/3,3/0,3/2,3/2,4/1,4/1,0/2,0/2,1/1,1/1,3/1,2`.split('/').map(Location.fromString))
    ,
//
// X 5x5 unique
// Path: X:23  Entropy: 11.8
    Puzzle.fromString(`
1X111
11111
11111
11011
11111
`)
        .setCanonicalSolutionWithWitness(`0,1/0,0/4,0/1,0/3,0/3,1/4,1/4,4/4,2/4,3/0,3/0,4/3,4/3,3/1,3/1,4/2,4/2,0/2,3/2,1/2,2/0,2/1,2/1,1`.split('/').map(Location.fromString))
    ,
//
// X 4x6 unique
// Path: X:21  Entropy: 8.58
    Puzzle.fromString(`
111111
110111
10111X
111111
`)
        .setCanonicalSolutionWithWitness(`2,5/3,5/3,0/0,0/2,0/1,0/1,1/0,1/0,5/0,2/0,4/3,4/3,1/3,3/3,2/2,2/2,4/2,3/0,3/1,3/1,5/1,4`.split('/').map(Location.fromString))
    ,
//
// eX 5x4 unique
// Path: X:14  Entropy: 10.6
// Contraptions do 5 visits
    Puzzle.fromString(`
1111
e111
1X11
1111
1111
`)
        .setCanonicalSolutionWithWitness(`2,1/0,1/0,3/4,3/4,0/4,2/4,1/3,1/3,3/3,2/0,2/2,2/2,3/1,3/1,0`.split('/').map(Location.fromString))
    ,
//
// wX 5x4 unique
// Path: X:18  Entropy: 12.2
// Contraptions do 1 visits
    Puzzle.fromString(`
X111
1111
1111
1111
w111
`)
        .setCanonicalSolutionWithWitness(`0,0/0,3/0,1/0,2/4,2/4,3/1,3/3,3/2,3/2,0/1,0/1,2/1,1/4,1/2,1/2,2/3,2/3,0/4,0`.split('/').map(Location.fromString))
        .setComment(
            `unique on schema`)
    ,
//
// eX 5x4 unique
// Path: X:15  Entropy: 10.6
// Contraptions do 4 visits
    Puzzle.fromString(`
1e11
11X1
1111
1111
1111
`)
        .setCanonicalSolutionWithWitness(`1,2/1,0/0,0/0,3/4,3/1,3/3,3/2,3/2,0/2,2/4,2/3,2/3,0/4,0/4,1/0,1`.split('/').map(Location.fromString))
    ,
//
// mX 5x4 unique
// Path: m:11 X:6  Entropy: 7.58
    Puzzle.fromString(`
1X11
1111
1001
1111
11m1
`)
        .setCanonicalSolutionWithWitness(`4,2/4,3/3,3/3,2/3,1/4,1/4,0/3,0/2,0/1,0/0,0/0,1/0,3/2,3/1,3/1,1/1,2/0,2`.split('/').map(Location.fromString))
    ,
//
// ewX 5x4 unique
// Path: X:14  Entropy: 10.8
// Contraptions do 5 visits
    Puzzle.fromString(`
1e11
1111
X111
1111
1w11
`)
        .setCanonicalSolutionWithWitness(`2,0/0,0/0,3/4,3/1,3/1,0/1,2/4,2/2,2/2,3/3,3/3,0/4,0/4,1/0,1`.split('/').map(Location.fromString))
    ,
//
// ewX 5x4 unique
// Path: X:14  Entropy: 9.34
// Contraptions do 5 visits
    Puzzle.fromString(`
1Xw1
1111
111e
1111
1111
`)
        .setCanonicalSolutionWithWitness(`0,1/4,1/4,0/0,0/3,0/1,0/1,3/0,3/0,2/4,2/1,2/3,2/3,1/2,1/2,3`.split('/').map(Location.fromString))
    ,
//
// Xd 5x4 unique
// Path: X:16 d:3  Entropy: 13.8
    Puzzle.fromString(`
1111
d1X1
1111
1111
1111
`)
        .setCanonicalSolutionWithWitness(`1,2/1,3/0,3/0,0/0,2/0,1/4,1/4,3/4,2/2,2/2,0/4,0/3,0/3,3/3,1/1,1/1,0/2,1/3,2/2,3`.split('/').map(Location.fromString))
    ,
//
// mX 3x4 unique
// Path: m:10 X:1  Entropy: 6.58
    Puzzle.fromString(`
1m11
11X1
1111
`)
        .setCanonicalSolutionWithWitness(`0,1/0,0/1,0/2,0/2,1/2,2/2,3/1,3/0,3/0,2/1,2/1,1`.split('/').map(Location.fromString))
    ,
//
// Xd 4x6 unique
// Path: X:19 d:4  Entropy: 17.1
    Puzzle.fromString(`
dX1111
111111
111111
111111
`)
        .setCanonicalSolutionWithWitness(`0,1/0,5/0,2/0,4/3,4/1,4/1,5/3,5/2,5/2,0/3,0/3,3/0,3/2,3/2,1/3,1/3,2/1,2/1,0/0,0/1,1/2,2/1,3/2,4`.split('/').map(Location.fromString))
    ,
//
// SX 3x5 unique
// Path: X:5 S:6  Entropy: 7.58
    Puzzle.fromString(`
1S111
111X1
11111
`)
        .setCanonicalSolutionWithWitness(`1,3/2,3/2,0/2,2/2,1/0,1/0,0/1,0/1,2/0,2/0,4/2,4`.split('/').map(Location.fromString))
    ,
//
// SX 4x6 unique
// Path: X:10 S:10  Entropy: 11.2
    Puzzle.fromString(`
110111
1S1111
11X111
111111
`)
        .setCanonicalSolutionWithWitness(`2,2/1,2/1,5/0,5/0,3/0,4/3,4/3,0/0,0/0,1/1,1/1,0/2,0/2,1/3,1/3,3/1,3/1,4/2,4/2,5/3,5`.split('/').map(Location.fromString))
    ,
//
// SX 6x5 unique
// Path: X:11 S:12  Entropy: 13.2
    Puzzle.fromString(`
01110
11111
1S111
11X11
11111
01110
`)
        .setCanonicalSolutionWithWitness(`3,2/0,2/0,3/5,3/1,3/1,0/4,0/4,4/1,4/3,4/2,4/2,1/2,0/3,0/3,1/5,1/5,2/4,2/4,3/2,3/2,2/1,2/1,1/0,1`.split('/').map(Location.fromString))
    ,
//
// JX 5x5 unique
// Path: X:16 J:5  Entropy: 19.3
    Puzzle.fromString(`
1JX11
11111
11111
11111
11111
`)
        .setCanonicalSolutionWithWitness(`0,2/0,4/4,4/4,0/0,0/3,0/3,4/3,1/3,3/3,2/1,2/1,4/2,4/2,0/1,0/1,1/0,1/0,3/2,3/2,1/4,1/4,3`.split('/').map(Location.fromString))
        .setComment(
            `Probably impossible to solve`)
    ,
//
// JX 5x5 unique
// Path: X:17 J:5  Entropy: 18.9
    Puzzle.fromString(`
11111
1X111
111J1
11111
11111
`)
        .setCanonicalSolutionWithWitness(`1,1/1,0/0,0/0,4/4,4/4,0/2,0/3,0/3,4/3,1/3,3/3,2/0,2/2,2/1,2/1,4/2,4/2,3/0,3/0,1/2,1/4,1/4,3`.split('/').map(Location.fromString))
    ,
//
// JX 6x6 unique
// Path: X:31 J:3  Entropy: 24.5
    Puzzle.fromString(`
1J1111
1X1111
111111
111111
111111
111111
`)
        .setCanonicalSolutionWithWitness(`1,1/5,1/5,0/0,0/4,0/1,0/3,0/3,5/3,1/4,1/4,5/4,2/5,2/5,5/5,3/0,3/0,5/0,4/5,4/1,4/1,5/2,5/2,0/2,4/4,4/3,4/3,2/3,3/1,3/1,2/0,2/0,1/2,1/2,3/4,3`.split('/').map(Location.fromString))
    ,
//
// JX 6x6 unique
// Path: X:30 J:3  Entropy: 24.1
    Puzzle.fromString(`
11J111
111X11
111111
111111
111111
111111
`)
        .setCanonicalSolutionWithWitness(`1,3/0,3/0,5/0,4/5,4/5,0/5,3/2,3/4,3/4,0/4,2/5,2/5,1/0,1/4,1/1,1/1,0/3,0/3,5/5,5/4,5/4,4/1,4/1,5/2,5/2,4/3,4/3,1/3,3/3,2/0,2/2,2/2,0/0,0`.split('/').map(Location.fromString))
    ,
//
// X 3x8 unique
// Path: X:22  Entropy: 11.0
    Puzzle.fromString(`
11011111
111111X1
11111111
`)
        .setCanonicalSolutionWithWitness(`1,6/1,7/2,7/2,0/2,6/2,1/2,5/2,2/2,4/2,3/0,3/0,7/0,4/0,6/0,5/1,5/1,0/0,0/0,1/1,1/1,4/1,2/1,3`.split('/').map(Location.fromString))
    ,
//
// X 7x4 unique
// Path: X:26  Entropy: 13.8
    Puzzle.fromString(`
1111
0111
11X1
1111
1111
1111
1111
`)
        .setCanonicalSolutionWithWitness(`2,2/6,2/6,3/0,3/0,0/0,2/1,2/1,3/5,3/2,3/4,3/4,0/4,2/5,2/5,0/6,0/6,1/0,1/5,1/1,1/4,1/2,1/2,0/3,0/3,3/3,1/3,2`.split('/').map(Location.fromString))
        .setComment(
            `These 4 are pretty stupid. Too hard to solve`)
    ,
//
// X 8x4 unique
// Path: X:30  Entropy: 16.3
    Puzzle.fromString(`
1111
1011
1111
X111
1111
1111
1111
1111
`)
        .setCanonicalSolutionWithWitness(`3,0/0,0/0,3/0,1/0,2/7,2/1,2/1,3/7,3/2,3/6,3/3,3/5,3/5,0/4,0/4,3/4,1/7,1/7,0/6,0/6,2/6,1/5,1/5,2/2,2/4,2/3,2/3,1/2,1/2,0/1,0`.split('/').map(Location.fromString))
    ,
//
// X 8x4 unique
// Path: X:30  Entropy: 16.2
    Puzzle.fromString(`
1111
1011
1111
1111
11X1
1111
1111
1111
`)
        .setCanonicalSolutionWithWitness(`4,2/7,2/7,0/0,0/0,3/0,1/0,2/3,2/3,3/7,3/4,3/6,3/6,0/1,0/5,0/2,0/2,3/1,3/1,2/2,2/2,1/7,1/3,1/3,0/4,0/4,1/6,1/5,1/5,3/5,2/6,2`.split('/').map(Location.fromString))
    ,
//
// X 8x4 unique
// Path: X:30  Entropy: 15.8
    Puzzle.fromString(`
1111
1011
1111
11X1
1111
1111
1111
1111
`)
        .setCanonicalSolutionWithWitness(`3,2/7,2/7,0/7,1/2,1/6,1/6,0/0,0/0,3/0,1/0,2/2,2/1,2/1,3/7,3/2,3/6,3/3,3/5,3/5,0/1,0/4,0/2,0/3,0/3,1/5,1/4,1/4,3/4,2/6,2/5,2`.split('/').map(Location.fromString))
    ,
//
// Z 5x4 unique
// Path: Z:15  Entropy: 11.6
    Puzzle.fromString(`
1011
1111
1101
1Z00
1111
`)
        .setCanonicalSolutionWithWitness(`3,1/2,0/0,0/1,0/1,3/2,3/1,2/0,3/0,2/1,1/2,1/3,0/4,0/4,3/4,1/4,2`.split('/').map(Location.fromString))
    ,
//
// Z 3x4 unique
// Path: Z:8  Entropy: 4.58
    Puzzle.fromString(`
1011
01Z0
1111
`)
        .setCanonicalSolutionWithWitness(`1,2/0,3/0,2/2,0/2,3/2,1/2,2/0,0/1,1`.split('/').map(Location.fromString))
    ,
//
// Z 5x4 unique
// Path: Z:15  Entropy: 10.7
    Puzzle.fromString(`
1011
1011
1011
1Z10
1111
`)
        .setCanonicalSolutionWithWitness(`3,1/1,3/0,2/0,3/1,2/2,3/2,2/4,2/4,3/3,2/4,1/4,0/0,0/3,0/1,0/2,0`.split('/').map(Location.fromString))
    ,
]);