import Puzzle, {HintInfo} from "./puzzle";
import {MoveEffect, VisitableThing, VisitableThingInfo,} from "./movement";
import VisitCounter from "./visitCounter";
import Location from "./location";
import {Move} from "./move";
import {devLog} from "../buildModeChecker";

export interface Hint {
    highlightedLocation?: Location,
    suggestRestart?: boolean,
    insistRestart?: boolean,
    hintBuyable: boolean,
    ownedCount: number,
    ownedCountIfBought?: number,
}

class ActivePuzzle {
    public moveList: Move[];
    public redoMoveListStack: Move[];
    _viableMoves: Array<Move> | undefined;
    private visitCounter: VisitCounter;

    constructor(public readonly puzzle: Puzzle) {
        this.moveList = [];
        this.redoMoveListStack = [];
        this._viableMoves = undefined;
        this.visitCounter = new VisitCounter(puzzle.height, puzzle.width);
    }

    get moveCount() {
        return this.moveList.length;
    }

    // Make a copy of the moves, including the redo stack.
    public currentMoveSequence() {
        return this.moveList.concat(this.redoMoveListStack.map(e => e).reverse());
    }

    public restart(puzzle: Puzzle) {
        this.moveList = [];
        this.redoMoveListStack = [];
        this.visitCounter = new VisitCounter(puzzle.height, puzzle.width);
    }

    get isCompleted() {
        return this.visitCounter.totalNonGoldVisits === this.puzzle.nonGoldVisitsNeeded;
    }

    public atDeadEnd(): boolean {
        return !this.isCompleted && this.viableMoves().length === 0;
    }

    public lastMove() {
        const moveCount = this.moveList.length;
        if (moveCount === 0) return null;
        return this.moveList[moveCount - 1];
    }

    public currentLocation() {
        let currentMove = this.lastMove();
        if (!currentMove) return undefined;
        return currentMove.newLocation;
    }

    public currentMovementType(): VisitableThing {
        let currentMove = this.lastMove();
        if (!currentMove) return VisitableThing.None;
        return currentMove.newMovementType;
    }

    public remainingRequiredVisits(loc: Location) {
        const needed = this.puzzle.visitsNeeded(loc).visitCount;
        const completed = this.visitCounter.getVisitCount(loc);
        return needed - completed;
    }

    public viableMoves(): Move[] {
        if (this.isCompleted) return [];
        if (!this._viableMoves) {
            // If no moves have been made, only the starting movement types are available.
            if (!this.lastMove()) {
                this._viableMoves = this.puzzle.startingLocations.map(loc =>
                    new Move({
                        newLocation: loc,
                        newMovementType: this.puzzle.visitableAtLocation(loc),
                        requiredVisits: [],
                        optionalVisits: [],
                        contraptionVisits: [],
                    })
                );
                return this._viableMoves;
            }
            const currentMovementType = this.currentMovementType();
            const currentLocation = this.currentLocation()!;

            let moveEffects: MoveEffect[] = VisitableThingInfo.moveEffects(currentMovementType, this);
            // let effectSchemes = VisitableThingInfo.moveEffectSchemes(currentMovementType);
            if (!moveEffects) return [];
            let moves: Move[] = [];

            // We do a little bit of processing on the moves here. Calculate the results of stuff we land on, fix
            // optional visits, make sure everything's sane.
            moveEffects.forEach(effect => {
                const newLocation = effect.destination;
                // Make sure the destination and the required side effects are on the board.
                let requiredVisits = effect.requiredSideEffectVisits;
                // Add in the optional requiredVisits, if they're visitable, which is what makes them optional;.
                let optionalVisits = effect.optionalSideEffects.filter(loc => this.canBeVisited(loc));

                // Shortcut out if we can't visit any of the requirements.
                if (!this.canBeVisited(newLocation)) return;
                if (!requiredVisits.every(loc => this.canBeVisited(loc))) return;

                const destinationVisitable = this.puzzle.visitableAtLocation(newLocation);

                // Shortcut out if we would be picking up the same movement type as we were using. This is not allowed.
                if (destinationVisitable === currentMovementType) return;

                let newMovementType = destinationVisitable;
                if (VisitableThingInfo.isContraption(destinationVisitable) ||
                    destinationVisitable === VisitableThing.None)
                    newMovementType = currentMovementType;

                let contraptionVisits: Location[] = [];

                // Get the (possibly undefined) contraption we're going to.
                const immediateEffect = VisitableThingInfo.immediateEffect(destinationVisitable);
                // We have to simulate the contraption's requiredVisits as if all of the MoveEffect's
                // requiredVisits are already done.
                if (immediateEffect) {
                    // TODO: Copying out the visit counter is bad and lazy, probably need to restructure
                    //  a bunch of stuff.
                    contraptionVisits = immediateEffect(
                        newLocation,
                        this.puzzle,
                        this.visitCounter.withVisits([newLocation, ...requiredVisits, ...optionalVisits]
                            .map(l => [l, this.puzzle.isGoldSquare(l)]))
                    );
                }

                moves.push(new Move({
                    oldLocation: currentLocation,
                    newLocation: newLocation,
                    oldMovementType: currentMovementType,
                    newMovementType: newMovementType,
                    requiredVisits: requiredVisits,
                    optionalVisits: optionalVisits,
                    contraptionVisits: contraptionVisits,
                }));
            })
            this._viableMoves = moves;
        }
        return this._viableMoves;
    }

    public currentHint(ownedCount: number): Hint {
        let hintInfo: HintInfo = this.puzzle.getHintInfo(ownedCount);
        const includeUndoneMoves = this.moveList.length === 0 && ownedCount === 0;
        let mistake = this.depthOfFirstMistake(includeUndoneMoves);
        const hintOwnedForDepth = this.moveCount <= hintInfo.lastHintedMove;

        let hintloc: Location | undefined;
        if (mistake === undefined && hintOwnedForDepth) {
            hintloc = this.puzzle.canonicalSolutionMoves![this.moveCount].newLocation;
        }

        // devLog(hintInfo)

        const suggestRestart = (mistake !== undefined) && (mistake <= hintInfo.lastHintedMove);
        const hint: Hint = {
            highlightedLocation: hintloc,
            ownedCount: ownedCount,
            suggestRestart: suggestRestart,
            hintBuyable: !suggestRestart &&
                !hintOwnedForDepth &&
                !this.atDeadEnd() &&
                ownedCount < hintInfo.totalHintsAvailable &&
                this.moveCount > hintInfo.lastHintedMove,
            ownedCountIfBought: this.puzzle.firstHintIncludingMoveNum(mistake !== undefined ? mistake : this.moveCount),
        };

        // devLog(hint);
        return hint;
    }


    public showDebug() {
        let moves = this.viableMoves();
        let moveOptions = moves.map(m => m.newLocation.toString()).join(" ");
        console.log("MoveData options:" + moveOptions);
    }

    // is needed to convert to the heavier object.
    public addMove(move: Move, purgeRedoStack: boolean = true) {
        // Don't disturb the redo list if we try to do the shiftRight move on it.
        // const noPurge = (this.redoMoveListStack.length > 0 && this.redoMoveListStack.pop() === move);
        this.moveList.push(move);
        this._viableMoves = undefined;
        // if (move.newMovementType != move.oldMovementType)
        //     console.log("VisitableThing changed to " + fullName(move.newMovementType))
        move.allVisits().forEach(loc => this.visitCounter.visit(loc, this.puzzle.isGoldSquare(loc)));
        if (purgeRedoStack) this.redoMoveListStack.length = 0;
    }

    // MoveEffect doesn't give us enough config to easily undo moves, so we track Moves instead of the lighter-weight
    // MoveEffect, which we can produce in viableMoves() knowing just the current location and movement type. This

    public redoMove() {
        const move = this.redoMoveListStack.pop();
        if (!move) {
            console.error("WTF, asked to redoMoveFn that doesn't exist");
            return;
        }
        this.addMove(move, false);
        this._viableMoves = undefined;
    }

    public undoMove() {
        const move = this.moveList.pop();
        if (!move) {
            console.error("WTF, asked to undoMoveFn that doesn't exist");
            return;
        }
        move.allVisits().forEach((loc: Location) => this.visitCounter.unvisit(loc, this.puzzle.isGoldSquare(loc)));
        this.redoMoveListStack.push(move);
        this._viableMoves = undefined;
    }

    public undoAll(): ActivePuzzle {
        while (this.moveList.length > 0) {
            this.undoMove();
        }
        return this;
    }

    /**
     * Undo moves until we're at the requested location. If that doesn't work, maybe we had already undone some moves
     * and can redo enough to find that location.
     * @param loc Our hoped for destination.
     * @param allowUndo Can we jump to one of the previously made moves?
     * @param allowRedo Can we jump to one of the moves made later, this only being accessible after a restart.
     */
    public attemptRewind(loc: Location, allowUndo = true, allowRedo = true): boolean {
        if (allowUndo) {
            for (let i = this.moveList.length - 1; i >= 0; i--) {
                if (loc.equals(this.moveList[i].newLocation)) {
                    // console.log(`found redomove at ${i}`)
                    while (this.moveList.length > i + 1) this.undoMove();
                    return true;
                }
            }
        }
        if (allowRedo) {
            for (let i = this.redoMoveListStack.length - 1; i >= 0; i--) {
                if (loc.equals(this.redoMoveListStack[i].newLocation)) {
                    do {
                        this.redoMove();
                    } while (this.redoMoveListStack.length > i);
                    return true;
                }
            }
        }
        return false;
    }

    public canBeVisited(loc: Location): boolean {
        const needed = this.puzzle.visitsNeeded(loc);
        if (needed.gold) return true;
        const completed = this.visitCounter.getVisitCount(loc);
        return needed.visitCount - completed > 0;
    }

    // We look for the first known wrong move.
    private depthOfFirstMistake(includeUndoneMoves: boolean = false) {
        const soln = this.puzzle.canonicalSolutionMoves;
        if (!soln) return;
        const moves = includeUndoneMoves ? this.currentMoveSequence() : this.moveList;
        for (let i = 0; i < moves.length && i < soln.length; i++) {
            if (!moves[i].equals(soln[i])) return i;
        }
    }

    clearRedoStack() {
        this.redoMoveListStack = [];
    }
}

export default ActivePuzzle;