// import {EGAProgressionStatus, EGAResourceFlowType, GameAnalytics} from "gameanalytics";
import Puzzle from "../puzzle/puzzle";
import {World} from "../puzzles/world";
import {Worlds} from "../puzzles/worlds";
import GetProgress from "./getProgress";
import Constants from "../constants";


// Firebase stuff might be good:
// https://firebase.google.com/docs/android/integrate-play-games
// Do stuff like:
// Bundle bundle = new Bundle();
// bundle.putString(FirebaseAnalytics.Param.ACHIEVEMENT_ID, achievementId);
// mFirebaseAnalytics.logEvent(FirebaseAnalytics.Event.UNLOCK_ACHIEVEMENT, bundle);


/**
 * Helper functions for doing analytics stuff.
 * Google event types for games: https://support.google.com/analytics/answer/9267565?hl=en
 * Game Analytics event info: https://gameanalytics.com/docs/s/article/Javascript-SDK-Event-Tracking
 */
class Analytics {
    // Some vars denoting whether we have done the initialization yet.
    private static  gameAnalyticsInitializationComplete = false;
    private static  googleAnalyticsInitializationComplete = false;
    // Don't bother reporting from dev config.
    // const reportToGameAnalytics = isProd();
    // const reportToGoogleAnalytics = isProd();

    public static initialize(buildIdentifier: string) {
        // Don't bother reporting from dev config.
        // if (Constants.reportToGameAnalytics && !Analytics.gameAnalyticsInitializationComplete) {
        //     const gameKey = '422f6dd1af765cc371174d7eedcea843';
        //     const secretKey = 'd82069604fe554b5c93ea5e3cb1a6aa03c043ab1';
        //     GameAnalytics.configureBuild(buildIdentifier);
        //     GameAnalytics.configureUserId(GetProgress.getUserID());
        //     GameAnalytics.configureAvailableResourceCurrencies(["hint"]);
        //     GameAnalytics.configureAvailableResourceItemTypes(
        //         ['hint', ...Worlds.worlds.map(w => `${w.worldName}-hint`)]);
        //     GameAnalytics.initialize(gameKey, secretKey);
        //     Analytics.gameAnalyticsInitializationComplete = true;
        // }
        if (Constants.reportToGoogleAnalytics && !Analytics.googleAnalyticsInitializationComplete) {
            gtag('js', new Date());
            gtag('config', 'G-ZW1WX4NV89');
            Analytics.googleAnalyticsInitializationComplete = true;
        }


    }

    public static puzzleStart(puzzle: Puzzle) {
        const levelName = puzzle.analyticsName;
        let worldName = puzzle.sourceWorld?.worldName || 'unknown';
        // if (Constants.reportToGameAnalytics) {
        //     GameAnalytics.addProgressionEvent(
        //         EGAProgressionStatus.Start,
        //         worldName,
        //         levelName);
        // }
        if (Constants.reportToGoogleAnalytics) {
            gtag('event', 'level_start',
                {
                    'level_name': levelName,
                });
        }
    }

    public static puzzleSolve(world: World, puzzle: Puzzle) {
        const levelName = puzzle.analyticsName;
        // if (Constants.reportToGameAnalytics) {
        //     GameAnalytics.addProgressionEvent(
        //         EGAProgressionStatus.Complete,
        //         world.worldName,
        //         levelName);
        // }

        if (Constants.reportToGoogleAnalytics) {
            gtag('event', 'level_end',
                {
                    'level_name': levelName,
                    'success': true,
                });
        }
    }

    public static puzzleReSolve(world: World, puzzle: Puzzle) {
        const levelName = puzzle.analyticsName;
        if (Constants.reportToGoogleAnalytics) {
            gtag('event', 'level_end',
                {
                    'level_name': levelName,
                    'success': 'reSolve',
                });
        }
    }

    public static puzzleDeadend(world: World, puzzle: Puzzle | undefined) {

    }

    public static puzzleUndo(world: World, puzzle: Puzzle) {

    }

    public static puzzleRestart(world: World, puzzle: Puzzle) {
        const levelName = puzzle.analyticsName;
        // if (Constants.reportToGameAnalytics) {
        //     GameAnalytics.addProgressionEvent(
        //         EGAProgressionStatus.Fail,
        //         world.worldName,
        //         levelName);
        // }

        if (Constants.reportToGoogleAnalytics) {
            gtag('event', 'level_end',
                {
                    'level_name': levelName,
                    'success': false,
                });
        }
    }

    static puzzleMove(world: World, puzzle: Puzzle | undefined) {

    }

    static allocateHint(world: World, puzzle: Puzzle) {
        const levelName = puzzle.analyticsName;
        // if (Constants.reportToGameAnalytics) {
        //     GameAnalytics.addResourceEvent(
        //         EGAResourceFlowType.Sink,
        //         "hint", 1, `${world.worldName}-hint`, `${levelName}-hint`);
        // }

        if (Constants.reportToGoogleAnalytics) {
            gtag('event', 'spend_virtual_currency',
                {
                    // item_name, virtual_currency_name, value
                    'item_name': `${levelName}-hint`,
                    'virtual_currency_name': 'hint',
                    'value': 1,
                });
        }
    }

    static acquireHint(numHints: number = 1) {
        // if (Constants.reportToGameAnalytics) {
        //     GameAnalytics.addResourceEvent(
        //         EGAResourceFlowType.Source,
        //         "hint", numHints, `hint`, 'hint');
        // }

        if (Constants.reportToGoogleAnalytics) {
            gtag('event', 'earn_virtual_currency',
                {
                    // item_name, virtual_currency_name, value
                    'item_name': `hint`,
                    'virtual_currency_name': 'hint',
                });
        }
    }
}

export {Analytics};