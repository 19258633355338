import Utility from "./utility";

import {rawWorlds, Worlds} from "./puzzles/worlds";
import {Analytics} from "./progress/analytics";
import {EventCounter, EventName} from "./progress/userEventTracker";
import {World} from "./puzzles/world";
import {devLog, isDev, isProd} from "./buildModeChecker";
import GetProgress from "./progress/getProgress";
import SetProgress from "./progress/setProgress";
import {globalMute, initializeSounds} from "./audio/sounds";
import Constants from "./constants";
import reportWebVitals, {sendToGoogleAnalytics} from "./reportWebVitals";
import Progress from "./progress/progress";

class Initialization {

    public static rewriteThenPurgeOldSessionData(dryRun: boolean = true) {
        type EventStats = {
            occurrences: number,
            firstTime: number,
            lastTime: number,
            meanTimeBetween?: number,
            smallestGap?: number,
            biggestGap?: number,
        }
        type ActivityReport = ReportItem[];
        type ReportItem = {
            eventName: EventName,
            eventStats: EventStats,
        }

        {
            let playSessionCount = 0;
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i)!;
                if (key.startsWith('playSession: ')) playSessionCount++;
            }
            if (playSessionCount === 0) return;
            else console.log(`Found ${playSessionCount} entries to purge.`)
        }

        let worldCounters = Worlds.worlds.map(w => GetProgress.getEventCounter(w.worldName) || new EventCounter());
        let getWorldCounter = (puzzle: string) => {
            let index = Worlds.worlds.findIndex(w => w.puzzles.some(p => p.toString() === puzzle));
            if (index < 0 || index >= worldCounters.length) return undefined;
            return worldCounters[index];
        }

        let globalCounter = GetProgress.getEventCounter('global') || new EventCounter();
        console.log(globalCounter.toString())
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i)!;
            if (key.startsWith('playSession: ')) {
                let puzzle = key.substr(13)
                let newstyle = GetProgress.getEventCounter(puzzle)

                let val = localStorage.getItem(key);
                let current: ActivityReport[] = val ? JSON.parse(val) : [];
                let newcounter = new EventCounter();

                current.forEach(v => {
                    let tempc = new EventCounter();
                    v.forEach(r => {
                        if (!r.eventStats || !r.eventStats.occurrences) console.log(r)
                        else {
                            tempc.counter.set(r.eventName, r.eventStats.occurrences);
                        }
                    })
                    newcounter.registerEvents(tempc)
                })

                getWorldCounter(puzzle)?.registerEvents(newcounter);
                globalCounter.registerEvents(newcounter);
                if (newstyle) {
                    newcounter.registerEvents(newstyle);
                }

                // write newcounter
                console.log(`Going to write to ${puzzle}`);
                console.log(newcounter.toString());

                if (!dryRun) SetProgress.setEventCounter(puzzle, newcounter);

                // purge key
                console.log(`Going to purge row ${key}`);
                if (!dryRun) localStorage.removeItem(key)
            }
        }

        // write worldcounters
        Worlds.worlds.forEach((w, i) => {
            let counter = worldCounters[i];
            let worldname = w.worldName;
            console.log(`Going to write to ${worldname}`);
            console.log(counter.toString())
            if (!dryRun) SetProgress.setEventCounter(worldname, counter);
        });

        // Write global
        devLog(`Going to write to global`);
        devLog(globalCounter.toString());
        if (!dryRun) SetProgress.setEventCounter('global', globalCounter);
    }

    public static purgeAllHints(devOnly: boolean) {
        if (devOnly && isProd()) return;

        // This uses the old way of talking to the worlds for completeness.
        rawWorlds.forEach(w => w.puzzles.forEach(p => SetProgress.purgeAllocatedHints(p)));

        // Always start off with 10 free hints in dev mode.
        if (isDev()) SetProgress.setUnallocatedHints(10);
        if (isProd()) SetProgress.setUnallocatedHints(2);
    }

    public static maybeMute() {
        globalMute(GetProgress.getSoundEnabled());
    }

    public static disableRightClick() {
        document.addEventListener("contextmenu", function (event) {
            event.preventDefault();
        });
    }

    public static showEnvironmentInfo() {
        console.log(`isDev() ${isDev()}`);
        console.log(`isProd() ${isProd()}`);
        console.log(`process.env:`, process.env);
    }

    public static showGameSizeInfo() {
        if (isProd()) return;
        const worldStats = (worlds : World[]) => {
            const worldHintCount = (w: World) => w.puzzles.map(p => p.totalHintCount).reduce((n, t) => n + t, 0)
            console.log(worlds.map(w => `${w.worldName}: ${w.size} -- ${worldHintCount(w)} hints`).join('\n'));

            const totalPuzzles = worlds.map(w => w.size).reduce((n, t) => n + t, 0)
            const totalHints = worlds.map(w => worldHintCount(w)).reduce((n, t) => n + t, 0)
            return `${totalPuzzles} puzzles -- ${totalHints} hints`;
        }
        console.log(`In-game Worlds: ${worldStats(Worlds.worlds)}`);
        // This reports the wrong number of hints because we don't even generate them on the unused worlds.
        console.log(`All Worlds: ${worldStats(rawWorlds)}`);
    }

    public static setSpecialPropertiesOnPuzzles() {
        Worlds.worlds.find(w => w.worldName === 'fontworld')!.puzzles.forEach(p => p.setCannotRotate());
    }

    // TODO: initialize firebase stuff
    static firebaseInitialization() {
        // firebase.auth().onAuthStateChanged(function(user) {
        //     if (user) {
        //         // User is signed in.
        //     } else {
        //         // No user is signed in.
        //     }
        // });

    }
}

//
// // This is handled in userFacingGame componentDidMount instead of here with the other initialization stuff.
// // TODO: Consider putting this in lab somehow for when we fuck up and push lab to pages
// function initializeServiceWorker(showSkipWaitingPrompt: VoidFunction) {
//     if (isDev()) {
//         devLog('Not doing service worker stuff in dev environment.');
//         return;
//     }
//
//     // // If you want your app to work offline and load faster, you can change
//     // // unregister() to register() below. Note this comes with some pitfalls.
//     // // Learn more about service workers: https://cra.link/PWA
//     const wb = Progress.getServiceWorkerWorkbox();
//     if (!wb) {
//         console.error(`Couldn't find the service worker.`);
//         return;
//     }
//
//     wb.addEventListener('activated', (event) => {
//         console.log('WorkBox activated event fired.')
//         // `event.isUpdate` will be true if another version of the service
//         // worker was controlling the page when this version was registered.
//         if (!event.isUpdate) {
//             console.log('Service worker activated for the first time!');
//
//             // If your service worker is configured to precache assets, those
//             // assets should all be available now.
//         }
//     });
//
//     wb.addEventListener('controlling', (event) => {
//         console.log('WorkBox controlling event fired.')
//     });
//
//     wb.addEventListener('waiting', (event) => {
//         console.log(`A new service worker has installed, but it can't activate` +
//             `until all tabs running the current version have fully unloaded.`);
//     });
//
//     wb.addEventListener('message', (event) => {
//         if (event.data.type === 'CACHE_UPDATED') {
//             const {updatedURL} = event.data.payload;
//             console.log(`A newer version of ${updatedURL} is available!`);
//         }
//     });
//
//     // Add an event listener to detect when the registered
//     // service worker has installed but is waiting to activate.
//     wb.addEventListener('waiting', showSkipWaitingPrompt);
//
//     // This is a fancy way to do it.
//     // serviceWorkerRegistration.register();
//
//     wb.register().then(registration => {
//
//         console.log('wb.register finished...');
//         console.log(registration);
//         // @ts-ignore
//         console.log(registration?.paymentManager);
//
//     })
//
// }

// Initialize World things.
// This is called before anything is rendered in the page, so we can set up some data structures or finalize
// some html behaviors we need.
function initializeGame() {

    Initialization.disableRightClick();
    Initialization.maybeMute();
    initializeSounds();

    Initialization.firebaseInitialization();

    Initialization.rewriteThenPurgeOldSessionData(false);
    // purgeAllHints(true);

    // This does a lot of work rewriting our worlds and validating them.
    Worlds.initialize();

    // The fontworld puzzles can't rotate.
    Initialization.setSpecialPropertiesOnPuzzles();

    Analytics.initialize(`DUKX.app ${Constants.versionNumber}`);

    Initialization.showEnvironmentInfo();
    Initialization.showGameSizeInfo();
    Utility.showStorageUsage(false);
    Utility.initializeStorage();

    // If you want to start measuring performance in your app, pass a public static
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals(sendToGoogleAnalytics);

}


export {initializeGame};