import {playSound} from "../../audio/sounds";

export function letterGrid(letterInput: string): string | undefined {
    console.log(`Trying to find letter grid for ${letterInput}`);
    if (letterInput.length > 2) {
        console.error(`Don't ask for so much of letterGrid`);
        return undefined;
    }
    let letter = letterInput[0].toUpperCase();
    let variant = 1;
    if (letterInput.length === 2) {
        variant = +letterInput[1];
    }
    const grids = letters.find(po => po[0] === letter);
    if (!grids) {
        console.error(`Couldn't find lettergrid ${letterInput}`);
        return undefined;
    }
    const results = grids[1];
    if (results.length > 1 && letterInput.length === 1) {
        console.log(`Surprise, there are multiple options for letter ${letterInput}`);
        playSound('acquire-hint');
    }
    if (variant > results.length) console.error(`There are only ${results.length} variants for ${letter}, you asked for ${variant}`)
    const ret = results[variant - 1].trim();
    console.log(`Here's your letter grid:
${ret}`);
    return ret;
}

let letters: [string, string[]][] =
    [
        ['A',
            [`
.....
..0..
..0..
.....
..0..
..0..
`],],
        ['B',
            [`
.....
..0..
....0
..0..
..0..
.....
`],],
        ['C',
            [`
.....
..0..
..000
..000
..0..
.....
`],],
        ['D',
            [`
.....
..0..
..0..
..0..
..0..
....0
`,
                `
....0
..0..
..0..
..0..
..0..
....0
`],],
        ['E',
            [`
.....
..000
....0
..000
..0..
.....
`],],
        ['F',
            [`
.....
..000
..000
.....
..000
..000
`,
                `
.....
.....
..000
....0
..000
..000
`],],
        ['G',
            [`
.....
..0..
..000
..0..
..0..
...22
`,
                `
.....
..0..
..000
..0..
..0..
.....
`],],
        ['H',
            [`
..0..
..0..
.....
..0..
..0..
..0..
`],],
        ['I',
            [`
.....
0...0
0...0
0...0
0...0
.....
`],],
        ['J',
            [`
000..
000..
000..
000..
..0..
.....
`],],
        ['K',
            [`
..0..
..0..
....0
..0..
..0..
..0..
`],],
        ['L',
            [`
..000
..000
..000
..000
..000
.....
`],],
        ['M',
            [`
.....
.....
.0.0.
.0.0.
.0.0.
`,
                `
..000..
...0...
.......
..0.0..
..0.0..
`],],
        ['N',
            [`
..00..
...0..
......
..0...
..00..
..00..
`],],
        ['O',
            [`
.....
..0..
..0..
..0..
..0..
.....
`],],
        ['P',
            [`
.....
..0..
..0..
.....
..000
..000
`],],
        ['Q',
            [`
.....0
..0..0
..0..0
..0..0
.....0
000...
`,
                `
.....
..0..
..0..
..0..
..02.
....2
`,
                `
.....
..0..
..0..
..0..
..0e.
....e
`],],
        ['R',
            [`
.....
..0..
..0..
....0
..0..
..0..
`],],
        ['S',
            [`
.....
..000
.....
000..
..0..
.....
`],],
        ['T',
            [`
......
......
00..00
00..00
00..00
00..00
`],],
        ['U',
            [`
..0..
..0..
..0..
..0..
.....
.....
`],],
        ['V',
            [`
..0..
..0..
..0..
0....
00...
000..
`],],
        ['W',
            [`
..000..
..000..
..0.0..
.......
...0...
..000..
`,
                `
.0.0.
.0.0.
.0.0.
.....
.....
`],],
        ['X',
            [`
..0..
..0..
0...0
0...0
..0..
..0..
`],],
        ['Y',
            [`
..0..
.....
0...0
00.00
00.00
`,
                `
..00..
......
00..00
00..00
00..00
`],],
        ['Z',
            [`
.....
....0
00.00
0....
.....
`],],
    ];
