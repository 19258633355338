import Puzzle, {PuzzleSpec} from "./puzzle";
import {VisitableThingInfo} from "./movement";
import ActivePuzzle from "./activePuzzle"
import {PuzzleGeneratorConfig} from "./puzzleGenerator";
import {Move, MoveSequence} from "./move";
import {SolutionInfo} from "./solutionInfo";

export type SolverConfig = {
    maxSolutions: number;
    excludeBackTrackInSolution: boolean;
    excludeBoomlessBombPuzzles: boolean;
    excludeEndOnMovement: boolean;
    excludeUnusedMovementType: boolean;
    returnEmptyOnAnySkip: boolean;
}

let defaultSolverConfig: SolverConfig = {
    maxSolutions: 3,
    excludeBackTrackInSolution: false,
    excludeBoomlessBombPuzzles: false,
    excludeEndOnMovement: false,
    excludeUnusedMovementType: false,
    returnEmptyOnAnySkip: false,
}

export type SearchConfig = {
    excludeMultiSolutionPuzzles: boolean,
    solverConfig: SolverConfig,
    puzzleGeneratorProps: PuzzleGeneratorConfig,
};

export interface SolutionSet {
    readonly puzzleSpec: PuzzleSpec,
    readonly solutions: MoveSequence[],
    readonly solverConfig: SolverConfig,
}

export function puzzlesFromSolutionSet(sr: SolutionSet): Puzzle[] {
    return sr.solutions.map(
        ml => {
            const p = Puzzle.fromSpec(sr.puzzleSpec);
            p.setCanonicalSolutionWithMoves(ml, sr.solutions.length === 1);
            return p;
        }
    );
}

export function solutionSetFromJSON(json: any): SolutionSet | undefined {
    if (!json.puzzleSpec || !json.solutions || !json.solverConfig) {
        console.error(`Malformed input to solutionSetFromJSON`);
        console.error(json);
    } else {
        return {
            puzzleSpec: json.puzzleSpec,
            solverConfig: json.solverConfig,
            solutions: json.solutions.map((ml: any[]) => ml.map(Move.FromAnyJSON)),
        }
    }
}

class Solver {
    constructor(public solverConfig: SolverConfig = defaultSolverConfig) {
    }

    //
    // // This takes into account the current location and moves left for the different places.
    // // Other state for puzzle variations will need to be incorporated later.
    // // Eg. exploded or unexploded bomb_world and stuff like that.
    // static stateString(isolver: InteractiveSolver): string {
    //     let result = isolver.getVisitsNeededMask();
    //     result += isolver.currentLoc();
    //     return result;
    // }


    // returnEmptyOnAnySkip lets us stop searching for rawSolutions for this puzzle when any found solution yields true for
    // skipSolution. This is to speed up unique puzzle finding, essentially.
    solve(puzzle: Puzzle): SolutionSet {
        const useLoopDetection = puzzle.goldCount > 1;
        let activePuzzle = new ActivePuzzle(puzzle);
        let solutions: MoveSequence[] = [];
        let moveStack: Array<Array<Move>> = [activePuzzle.viableMoves()];

        /*
        The movestack looks like this:
        [[untried initial moves],
         [untried shiftRight move],
         ...
         [moves to investigate immediately]]
         */
        while (moveStack.length > 0 && solutions.length < this.solverConfig.maxSolutions) {
            // TODO: Look into making this more efficient.
            const viableMoves = moveStack[moveStack.length - 1];
            const currentLocation = activePuzzle.currentLocation();
            const viableMove = viableMoves.pop()!;
            if (!viableMove) {
                // If there are no moves in our activePuzzle, we're about to finish.
                if (activePuzzle.moveCount !== 0) {
                    activePuzzle.undoMove();
                }
                moveStack.pop();
                continue;
            }
            activePuzzle.addMove(viableMove);

            // Don't bother detecting loops unless it's like this.
            if (useLoopDetection && currentLocation &&
                puzzle.isGoldSquare(currentLocation) &&
                activePuzzle.moveCount > puzzle.goldCount + 2) {
                let loopDetected = true;

                // TODO: Some reasoning about loops needs to be done. If a loop is possible, then we know that the
                //  puzzle does not strictly have a unique solution. Do we want to use these? Or what? Damn.
                // The loop detection we do now is pretty blunt. We just check for if we've been on golds for longer
                // than is possible without looping. We don't find the particular loop used, which would be better.
                // strict = false, generates more rawSolutions.
                const strict = false;
                for (let i = 0; i < puzzle.goldCount + (strict ? 1 : 0); i++) {
                    let index = activePuzzle.moveList.length - i - 2;
                    if (!puzzle.isGoldSquare(activePuzzle.moveList[index].newLocation)) {
                        loopDetected = false;
                        break;
                    }
                }

                // What we do here is tantamount to saying "There's no solution down here so we can stop looking." And
                // indeed, if there is a solution that uses the detected loop, we will find one that doesn't use the
                // detected loop. The problem arises in finding uniquely solvable puzzles. Right now, we can return
                // non-uniquely solvable4 puzzles because we don't consider anything using a loop to be a solution.
                //
                // We are also not able to control which loops we detect precisely. We can also fail to find "uniques"
                // because we produce too many rawSolutions by going through small loops.
                if (loopDetected) {
                    activePuzzle.undoMove();
                    continue;
                }
            }

            if (activePuzzle.isCompleted) {
                const candidateSolution = activePuzzle.currentMoveSequence();
                if (this.skipPuzzleFn(puzzle, candidateSolution)) {
                    // We always return empty if we're told to quit on skip. This speeds up unique finding.
                    if (this.solverConfig.returnEmptyOnAnySkip) {
                        // Return empty.
                        return {
                            puzzleSpec: puzzle.toPuzzleSpec(),
                            solutions: [],
                            solverConfig: this.solverConfig,
                        };
                    }
                } else {
                    // console.log("Found solution." + solution);
                    solutions.push(candidateSolution);
                }
            }
            const validMoves = activePuzzle.viableMoves();
            moveStack.push(validMoves);
        }
        // console.log(`Found ${rawSolutions.length} rawSolutions.`);
        return {
            solverConfig: this.solverConfig,
            puzzleSpec: puzzle.toPuzzleSpec(),
            solutions: solutions,
        };
    }

    private skipPuzzleFn(puzzle: Puzzle, moves: MoveSequence) {
        const lastMove = moves[moves.length - 1];
        if (this.solverConfig.excludeEndOnMovement) {
            if (VisitableThingInfo.isMovementType(puzzle.visitableAtLocation(lastMove.newLocation)))
                return true;
        }
        if (this.solverConfig.excludeUnusedMovementType) {
            const solutionInfo = new SolutionInfo(puzzle, moves);
            if (solutionInfo.unusedMovementTypes > 0) return true;
        }
        if (this.solverConfig.excludeBackTrackInSolution) {
            for (let i = 0; i < moves.length - 1; i++) {
                let m1 = moves[i];
                let m2 = moves[i + 1];
                if (m1.newLocation.equals(m2.oldLocation) && m1.oldLocation?.equals(m2.newLocation))
                    return true;
            }
        }
        if (this.solverConfig.excludeBoomlessBombPuzzles) {
            const bomLocs = puzzle.contraptionLocations;
            return moves.filter(m => bomLocs.some(loc => loc.equals(m.newLocation)))
                .some(m => m.contraptionVisits.length === 0);

            // if (solution.puzzle.visitableThingsList.some(vt => VisitableThingInfo.isContraption(vt)) &&
            //     solution.contraptionCausedVisitsCount == 0) return true;
        }
        return false;
    }
}


export default Solver;