// bomb_world generated by WorldBuilder
import Puzzle from "../puzzle/puzzle";
import {World} from "./world";
import Location from "../puzzle/location";


export let bomb_world = new World("bomb_world", [
//
// mw 6x5 unique
// Path: m:3  Entropy: 1.58
// Contraptions do 8 visits
    Puzzle.fromString(`
00m00
10101
01110
00w00
01010
10001
`)
        .setCanonicalSolutionWithWitness(`0,2/1,2/2,2/3,2`.split('/').map(Location.fromString))
        .setComment(
            `Easiest demo of the model`)
    ,
//
// ed 6x6 unique
// Path: d:3  Entropy: 1.58
// Contraptions do 10 visits
    Puzzle.fromString(`
d00100
010100
001100
111e11
000100
000100
`)
        .setCanonicalSolutionWithWitness(`0,0/1,1/2,2/3,3`.split('/').map(Location.fromString))
    ,
//
// ms 4x4 unique
// Path: m:7  Entropy: 2.00
// Contraptions do 3 visits
    Puzzle.fromString(`
11m0
s111
1110
0010
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,2/0,1/0,0/1,0/2,0/2,1/2,2/3,2`.split('/').map(Location.fromString))
        .setComment(
            `We don't want to use this sort of bomb any more.`)
    ,
//
// eed 5x3 unique
// Path: d:5  Entropy: 1.00
// Contraptions do 7 visits
    Puzzle.fromString(`
110
e11
111
d1e
011
`)
        .setCanonicalSolutionWithWitness(`3,0/4,1/3,2/2,1/1,0/0,1`.split('/').map(Location.fromString))
    ,
//
// mw 4x4 unique
// Path: m:12  Entropy: 6.00
// Contraptions do 2 visits
    Puzzle.fromString(`
1111
1m11
110w
1111
`)
        .setCanonicalSolutionWithWitness(`1,1/2,1/3,1/3,0/2,0/1,0/0,0/0,1/0,2/0,3/1,3/2,3/3,3`.split('/').map(Location.fromString))
        .setComment(
            `tricky for a first puzzle off the tutorial`)
    ,
//
// mww 5x4 unique
// Path: m:14  Entropy: 7.58
// Contraptions do 2 visits
    Puzzle.fromString(`
1111
1w11
1.01
m11w
111.
`)
        .setCanonicalSolutionWithWitness(`3,0/4,0/4,1/3,1/3,2/3,3/2,3/1,3/0,3/0,2/0,1/0,0/1,0/1,1/1,2`.split('/').map(Location.fromString))
    ,
//
// mew 4x4 unique
// Path: m:9  Entropy: 3.58
// Contraptions do 4 visits
    Puzzle.fromString(`
m1.1
1111
111e
w11.
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,0/0,1/1,1/1,0/2,0/3,0/3,1/3,2/2,2/2,3`.split('/').map(Location.fromString))
    ,
//
// mew 4x4 unique
// Path: m:7  Entropy: 3.58
// Contraptions do 6 visits
    Puzzle.fromString(`
1m11
111e
w111
.1.1
`)
        .setCanonicalSolutionWithWitness(`0,1/0,0/1,0/2,0/2,1/2,2/1,2/1,3`.split('/').map(Location.fromString))
    ,
//
// me 4x4 unique
// Path: m:13  Entropy: 7.00
// Contraptions do 1 visits
    Puzzle.fromString(`
1110
1m11
1e11
1111
`)
        .setCanonicalSolutionWithWitness(`1,1/1,0/0,0/0,1/0,2/1,2/1,3/2,3/3,3/3,2/3,1/3,0/2,0/2,1`.split('/').map(Location.fromString))
    ,
//
// mew 4x4 unique
// Path: m:12  Entropy: 7.75
// Contraptions do 2 visits
    Puzzle.fromString(`
e1m1
1111
1111
11w.
`)
        .setCanonicalSolutionWithWitness(`0,2/0,3/1,3/1,2/1,1/2,1/2,2/3,2/3,1/3,0/2,0/1,0/0,0`.split('/').map(Location.fromString))
    ,
//
// mew 5x4 unique
// Path: m:16  Entropy: 9.34
// Contraptions do 3 visits
    Puzzle.fromString(`
1e11
m111
1111
11w1
1111
`)
        .setCanonicalSolutionWithWitness(`1,0/2,0/2,1/3,1/3,0/4,0/4,1/4,2/4,3/3,3/3,2/2,2/1,2/1,3/0,3/0,2/0,1`.split('/').map(Location.fromString))
    ,
//
// mew 5x5 unique
// Path: m:21  Entropy: 12.8
// Contraptions do 3 visits
    Puzzle.fromString(`
w1111
111e1
11111
1111m
11111
`)
        .setCanonicalSolutionWithWitness(`3,4/4,4/4,3/3,3/3,2/4,2/4,1/4,0/3,0/3,1/2,1/2,0/1,0/0,0/0,1/0,2/0,3/0,4/1,4/2,4/2,3/1,3`.split('/').map(Location.fromString))
        .setComment(
            `Good puzzle. Tricky but you can get a handle on it`)
    ,
//
// mew 5x5 unique
// Path: m:22  Entropy: 15.8
// Contraptions do 2 visits
    Puzzle.fromString(`
1m111
11111
11w11
11111
111e1
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,1/0,0/1,0/2,0/3,0/4,0/4,1/3,1/2,1/1,1/1,2/0,2/0,3/0,4/1,4/2,4/3,4/3,3/2,3/2,2/3,2/4,2/4,3`.split('/').map(Location.fromString))
    ,
//
// mew 4x5 unique
// Path: m:17  Entropy: 11.8
// Contraptions do 2 visits
    Puzzle.fromString(`
1mw11
11111
1111e
11111
`)
        .setCanonicalSolutionWithWitness(`0,1/0,0/1,0/2,0/3,0/3,1/2,1/2,2/3,2/3,3/2,3/1,3/1,2/0,2/0,3/0,4/1,4/2,4`.split('/').map(Location.fromString))
    ,
//
// mew 6x4 unique
// Path: m:21  Entropy: 13.9
// Contraptions do 2 visits
    Puzzle.fromString(`
1111
1e11
1111
m11w
1111
1111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`3,0/2,0/2,1/2,2/3,2/3,1/4,1/4,0/5,0/5,1/5,2/5,3/4,3/3,3/2,3/1,3/0,3/0,2/0,1/0,0/1,0/1,1`.split('/').map(Location.fromString))
    ,
//
// mew 6x4 unique
// Path: m:19  Entropy: 13.8
// Contraptions do 4 visits
    Puzzle.fromString(`
1me1
1111
1111
11w1
1111
1111
`)
        .setCanonicalSolutionWithWitness(`0,1/0,0/1,0/1,1/2,1/2,0/3,0/3,1/4,1/4,0/5,0/5,1/5,2/5,3/4,3/3,3/2,3/1,3/0,3/0,2`.split('/').map(Location.fromString))
    ,
//
// mewww 6x4 unique
// Path: m:11  Entropy: 5.17
// Contraptions do 12 visits
    Puzzle.fromString(`
1w11
1w1m
1w11
1111
1111
11e1
`)
        .setCanonicalSolutionWithWitness(`1,3/0,3/0,2/0,1/1,1/2,1/3,1/4,1/4,0/5,0/5,1/5,2`.split('/').map(Location.fromString))
    ,
//
// mewww 6x4 unique
// Path: m:18  Entropy: 9.75
// Contraptions do 5 visits
    Puzzle.fromString(`
1w11
1wm1
1w11
1111
1111
11e1
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`1,2/2,2/3,2/4,2/4,3/3,3/2,3/1,3/0,3/0,2/0,1/1,1/2,1/3,1/4,1/4,0/5,0/5,1/5,2`.split('/').map(Location.fromString))
    ,
//
// meww 6x4 unique
// Path: m:18  Entropy: 12.2
// Contraptions do 5 visits
    Puzzle.fromString(`
1m11
1w11
1w11
1111
1111
11e1
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,1/0,2/0,3/1,3/2,3/3,3/4,3/4,2/3,2/2,2/1,2/1,1/2,1/3,1/4,1/4,0/5,0/5,1/5,2`.split('/').map(Location.fromString))
    ,
//
// meew 6x4 unique
// Path: m:18  Entropy: 13.8
// Contraptions do 5 visits
    Puzzle.fromString(`
e1e1
1111
11w1
1111
1111
1m11
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`5,1/5,0/4,0/3,0/2,0/1,0/1,1/2,1/3,1/4,1/4,2/5,2/5,3/4,3/3,3/2,3/1,3/0,3/0,2`.split('/').map(Location.fromString))
        .setComment(
            `tricky, but good when you figure it out`)
    ,
//
// meew 6x4 unique
// Path: m:18  Entropy: 14.6
// Contraptions do 5 visits
    Puzzle.fromString(`
m111
1111
1111
1w11
1111
1e1e
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,0/0,1/0,2/0,3/1,3/2,3/3,3/4,3/4,2/3,2/2,2/1,2/1,1/1,0/2,0/3,0/4,0/5,0/5,1`.split('/').map(Location.fromString))
    ,
//
// meew 6x4 unique
// Path: m:18  Entropy: 13.8
// Contraptions do 5 visits
    Puzzle.fromString(`
w1e1
1111
11e1
1111
1111
1m11
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`5,1/5,0/4,0/3,0/2,0/1,0/1,1/2,1/3,1/4,1/4,2/5,2/5,3/4,3/3,3/2,3/1,3/0,3/0,2`.split('/').map(Location.fromString))
    ,
//
// mww 5x4 unique
// Path: m:17  Entropy: 10.3
// Contraptions do 2 visits
    Puzzle.fromString(`
111w
111m
1111
1w11
1111
`)
        .setCanonicalSolutionWithWitness(`1,3/2,3/2,2/3,2/3,3/4,3/4,2/4,1/4,0/3,0/3,1/2,1/1,1/1,0/0,0/0,1/0,2/0,3`.split('/').map(Location.fromString))
    ,
//
// mww 6x5 unique
// Path: m:23  Entropy: 13.9
// Contraptions do 5 visits
    Puzzle.fromString(`
11111
ww111
11m11
11111
01111
11111
`)
        .setCanonicalSolutionWithWitness(`2,2/2,3/3,3/4,3/4,2/4,1/3,1/3,0/2,0/1,0/1,1/1,2/1,3/0,3/0,4/1,4/2,4/3,4/4,4/5,4/5,3/5,2/5,1/5,0`.split('/').map(Location.fromString))
    ,
//
// mww 6x5 unique
// Path: m:24  Entropy: 13.9
// Contraptions do 4 visits
    Puzzle.fromString(`
11111
1w111
11m11
11w11
11110
11111
`)
        .setCanonicalSolutionWithWitness(`2,2/2,1/3,1/4,1/5,1/5,0/4,0/3,0/2,0/1,0/0,0/0,1/1,1/1,2/1,3/0,3/0,4/1,4/2,4/3,4/3,3/3,2/4,2/5,2/5,3`.split('/').map(Location.fromString))
    ,
//
// mww 5x5 unique
// Path: m:22  Entropy: 10.8
// Contraptions do 1 visits
    Puzzle.fromString(`
10111
11111
11m11
1w111
11w11
`)
        .setCanonicalSolutionWithWitness(`2,2/2,1/2,0/3,0/4,0/4,1/3,1/3,2/3,3/4,3/4,4/3,4/2,4/2,3/1,3/1,4/0,4/0,3/0,2/1,2/1,1/1,0/0,0`.split('/').map(Location.fromString))
    ,
//
// mww 7x5 unique
// Path: m:28  Entropy: 16.8
// Contraptions do 5 visits
    Puzzle.fromString(`
w1111
11111
11111
11m1w
11111
11011
11111
`)
        .setCanonicalSolutionWithWitness(`3,2/4,2/4,3/5,3/5,4/6,4/6,3/6,2/6,1/6,0/5,0/5,1/4,1/4,0/3,0/3,1/2,1/2,0/1,0/0,0/0,1/0,2/1,2/1,3/0,3/0,4/1,4/2,4/3,4`.split('/').map(Location.fromString))
        .setComment(
            `maybe don't need both`)
    ,
//
// eeeeeeeeeeeeeeeeed 5x4 unique
// Path: d:6  Entropy: 1.00
// Contraptions do 11 visits
    Puzzle.fromString(`
eeee
deee
ee0e
e0ee
eeee
`)
        .setCanonicalSolutionWithWitness(`1,0/0,1/1,2/2,3/3,2/4,1/3,0`.split('/').map(Location.fromString))
        .setComment(
            `unique on schema`)
    ,
//
// mww 7x5 unique
// Path: m:28  Entropy: 17.9
// Contraptions do 5 visits
    Puzzle.fromString(`
11111
11111
w01w1
11m11
11111
11111
11111
`)
        .setCanonicalSolutionWithWitness(`3,2/2,2/1,2/1,3/0,3/0,4/1,4/2,4/2,3/3,3/4,3/4,4/5,4/6,4/6,3/5,3/5,2/6,2/6,1/6,0/5,0/5,1/4,1/4,0/3,0/2,0/1,0/0,0/0,1`.split('/').map(Location.fromString))
    ,
//
// mww 7x5 unique
// Path: m:28  Entropy: 15.5
// Contraptions do 5 visits
    Puzzle.fromString(`
11111
w11w1
11111
11m10
11111
11111
11111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`3,2/4,2/4,1/5,1/5,0/6,0/6,1/6,2/5,2/5,3/6,3/6,4/5,4/4,4/4,3/3,3/2,3/2,4/1,4/0,4/0,3/1,3/1,2/1,1/0,1/0,0/1,0/2,0/3,0`.split('/').map(Location.fromString))
    ,
//
// meww 6x4 unique
// Path: m:17  Entropy: 11.3
// Contraptions do 6 visits
    Puzzle.fromString(`
e111
11m1
1111
1111
w1w1
1111
`)
        .setCanonicalSolutionWithWitness(`1,2/1,1/2,1/3,1/4,1/5,1/5,2/5,3/4,3/4,2/3,2/2,2/2,3/1,3/0,3/0,2/0,1/0,0`.split('/').map(Location.fromString))
    ,
//
// meww 5x4 unique
// Path: m:14  Entropy: 10.8
// Contraptions do 5 visits
    Puzzle.fromString(`
w1e1
1111
m1w1
1111
1111
`)
        .setCanonicalSolutionWithWitness(`2,0/1,0/1,1/2,1/3,1/3,0/4,0/4,1/4,2/4,3/3,3/2,3/1,3/0,3/0,2`.split('/').map(Location.fromString))
    ,
//
// mwww 5x5 unique
// Path: m:17  Entropy: 7.75
// Contraptions do 6 visits
    Puzzle.fromString(`
m1111
1w111
1101w
1111w
11111
`)
        .setCanonicalSolutionWithWitness(`0,0/1,0/1,1/2,1/3,1/3,0/4,0/4,1/4,2/3,2/3,3/4,3/4,4/3,4/2,4/1,4/0,4/0,3`.split('/').map(Location.fromString))
    ,
//
// mwww 5x5 unique
// Path: m:20  Entropy: 12.3
// Contraptions do 4 visits
    Puzzle.fromString(`
1w111
11w11
111mw
11111
11111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`2,3/2,2/3,2/3,1/4,1/4,0/3,0/2,0/2,1/1,1/1,0/0,0/0,1/0,2/0,3/0,4/1,4/2,4/3,4/4,4/4,3`.split('/').map(Location.fromString))
    ,
//
// mwww 5x5 unique
// Path: m:18  Entropy: 9.17
// Contraptions do 5 visits
    Puzzle.fromString(`
11111
11m11
1w011
11111
11ww1
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`1,2/1,1/1,0/0,0/0,1/0,2/0,3/0,4/1,4/1,3/2,3/2,4/3,4/4,4/4,3/4,2/4,1/4,0/3,0`.split('/').map(Location.fromString))
    ,
//
// mwww 6x4 unique
// Path: m:19  Entropy: 12.8
// Contraptions do 4 visits
    Puzzle.fromString(`
m111
1111
1111
11w1
111w
1w11
`)
        .setCanonicalSolutionWithWitness(`0,0/0,1/0,2/0,3/1,3/2,3/2,2/1,2/1,1/1,0/2,0/3,0/3,1/4,1/4,0/5,0/5,1/5,2/5,3/4,3`.split('/').map(Location.fromString))
    ,
//
// mwdd 5x5 unique
// Path: m:18  Entropy: 12.8
// Contraptions do 4 visits
    Puzzle.fromString(`
1m111
11111
10w01
1d1d1
11111
`)
        .setCanonicalSolutionWithWitness(`0,1/0,0/1,0/2,0/3,0/4,0/4,1/4,2/4,3/4,4/3,4/2,4/1,4/0,4/0,3/0,2/1,2/2,2/3,2`.split('/').map(Location.fromString))
    ,
//
// med 6x5 unique
// Path: m:12 d:4  Entropy: 10.8
// Contraptions do 6 visits
    Puzzle.fromString(`
10100
011m1
11e11
01111
01d11
01111
`)
        .setCanonicalSolutionWithWitness(`1,3/1,4/2,4/3,4/3,3/4,3/4,4/5,4/5,3/5,2/5,1/4,1/4,2/3,1/2,2/1,1/0,0`.split('/').map(Location.fromString))
    ,
//
// meed 5x5 unique
// Path: m:9 d:6  Entropy: 10.8
// Contraptions do 5 visits
    Puzzle.fromString(`
0d110
11111
1e111
e1111
01m10
`)
        .setCanonicalSolutionWithWitness(`4,2/4,3/3,3/3,4/2,4/1,4/1,3/0,3/0,2/0,1/1,2/2,3/3,2/4,1/3,0/2,1`.split('/').map(Location.fromString))
    ,
//
// meed 5x5 unique
// Path: d:5 m:9  Entropy: 5.58
// Contraptions do 6 visits
    Puzzle.fromString(`
01110
11d11
111m1
11e11
0e110
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`1,2/2,1/3,0/4,1/3,2/2,3/2,4/1,4/1,3/0,3/0,2/0,1/1,1/1,0/2,0`.split('/').map(Location.fromString))
    ,
//
// mmw 5x5 unique
// Path: m:21  Entropy: 10.6
// Contraptions do 1 visits
    Puzzle.fromString(`
11111
1111m
110w1
11111
1m110
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`4,1/4,0/3,0/2,0/1,0/0,0/0,1/0,2/1,2/1,1/2,1/3,1/3,2/4,2/4,3/3,3/3,4/2,4/2,3/1,3/0,3/0,4`.split('/').map(Location.fromString))
    ,
//
// mmw 5x5 unique
// Path: m:22  Entropy: 12.8
// Contraptions do 2 visits
    Puzzle.fromString(`
1m111
11111
w1111
11111
11m11
`)
        .setCanonicalSolutionWithWitness(`0,1/0,0/1,0/1,1/1,2/0,2/0,3/0,4/1,4/1,3/2,3/2,4/3,4/4,4/4,3/3,3/3,2/2,2/2,1/2,0/3,0/4,0/4,1`.split('/').map(Location.fromString))
        .setComment(
            `// Unique on schema`)
    ,
//
// mwwwww 7x5 unique
// Path: m:24  Entropy: 12.8
// Contraptions do 5 visits
    Puzzle.fromString(`
01110
w1111
1wm11
11w11
110w1
1111w
01110
`)
        .setCanonicalSolutionWithWitness(`2,2/1,2/1,1/0,1/0,2/0,3/1,3/1,4/2,4/2,3/3,3/3,4/4,4/5,4/5,3/5,2/6,2/6,1/5,1/5,0/4,0/4,1/3,1/3,0/2,0`.split('/').map(Location.fromString))
    ,
//
// med 5x5 unique
// Path: m:17  Entropy: 10.6
// Contraptions do 5 visits
    Puzzle.fromString(`
11e11
m0101
11111
11d11
11111
`)
        .setCanonicalSolutionWithWitness(`1,0/2,0/2,1/3,1/3,0/4,0/4,1/4,2/4,3/4,4/3,4/3,3/2,3/2,4/1,4/0,4/0,3/0,2`.split('/').map(Location.fromString))
    ,
//
// meJ 7x4 unique
// Path: m:19  Entropy: 14.8
// Contraptions do 5 visits
    Puzzle.fromString(`
0100
1em1
1111
1111
1J11
1111
1111
`)
        .setCanonicalSolutionWithWitness(`1,2/1,3/2,3/2,2/3,2/3,3/4,3/4,2/5,2/5,3/6,3/6,2/6,1/6,0/5,0/4,0/3,0/2,0/1,0/1,1`.split('/').map(Location.fromString))
    ,
//
// mwJ 7x4 unique
// Path: m:24 J:1  Entropy: 16.3
// Contraptions do 2 visits
    Puzzle.fromString(`
1m11
1111
1111
11J1
w111
1111
1111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,1/0,0/1,0/2,0/2,1/1,1/1,2/0,2/0,3/1,3/2,3/3,3/4,3/5,3/6,3/6,2/5,2/5,1/6,1/6,0/5,0/4,0/4,1/4,2/3,2/3,0`.split('/').map(Location.fromString))
    ,
//
// mwwwwJ 7x5 unique
// Path: m:28 J:2  Entropy: 22.6
// Contraptions do 3 visits
    Puzzle.fromString(`
11111
11J11
1mw11
1w1w1
11w11
11111
11111
`)
        .setCanonicalSolutionWithWitness(`2,1/2,0/1,0/0,0/0,1/0,2/0,3/0,4/1,4/2,4/3,4/4,4/5,4/6,4/6,3/6,2/6,1/6,0/5,0/4,0/4,1/5,1/5,2/5,3/4,3/3,3/2,3/1,3/1,2/3,2/3,0`.split('/').map(Location.fromString))
    ,
//
// mwd 5x5 unique
// Path: m:19 d:2  Entropy: 12.6
// Contraptions do 1 visits
    Puzzle.fromString(`
11111
11m11
d0w01
11111
11111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`1,2/1,1/1,0/0,0/0,1/0,2/0,3/0,4/1,4/2,4/3,4/4,4/4,3/3,3/3,2/4,2/4,1/4,0/3,0/2,0/3,1/2,2`.split('/').map(Location.fromString))
    ,
//
// mwwwd 5x5 unique
// Path: m:17 d:3  Entropy: 15.2
// Contraptions do 4 visits
    Puzzle.fromString(`
1d11m
1w111
11w11
111w1
11111
`)
        .setCanonicalSolutionWithWitness(`0,4/0,3/1,3/1,4/2,4/3,4/4,4/4,3/4,2/4,1/4,0/3,0/3,1/2,1/2,0/1,0/1,1/0,1/1,2/2,3/3,2`.split('/').map(Location.fromString))
    ,
//
// mwdd 5x5 unique
// Path: d:1 m:20 d:1  Entropy: 10.8
    Puzzle.fromString(`
11111
1m111
d0w01
1d111
11111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`2,0/1,1/1,0/0,0/0,1/0,2/1,2/1,3/0,3/0,4/1,4/2,4/3,4/4,4/4,3/3,3/3,2/4,2/4,1/4,0/3,0/3,1/2,2`.split('/').map(Location.fromString))
    ,
//
// med 5x5 unique
// Path: m:19  Entropy: 10.6
// Contraptions do 3 visits
    Puzzle.fromString(`
11111
10101
11e11
m1d11
11111
`)
        .setCanonicalSolutionWithWitness(`3,0/4,0/4,1/3,1/2,1/2,0/1,0/0,0/0,1/0,2/0,3/0,4/1,4/2,4/3,4/4,4/4,3/3,3/2,3/2,2`.split('/').map(Location.fromString))
    ,
//
// mewd 5x4 unique
// Path: d:2 m:11  Entropy: 9.17
// Contraptions do 6 visits
    Puzzle.fromString(`
1e11
111m
1w11
1d11
1111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`3,1/2,2/1,3/2,3/3,3/4,3/4,2/4,1/4,0/3,0/2,0/2,1/1,1/0,1`.split('/').map(Location.fromString))
    ,
//
// mewd 5x4 unique
// Path: d:3 m:8  Entropy: 7.58
// Contraptions do 8 visits
    Puzzle.fromString(`
1111
11m1
wd1e
1111
1111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`2,1/3,2/2,3/1,2/0,2/0,1/0,0/1,0/2,0/3,0/4,0/4,1`.split('/').map(Location.fromString))
    ,
//
// mewd 5x4 unique
// Path: m:8 d:4  Entropy: 7.58
// Contraptions do 7 visits
    Puzzle.fromString(`
e111
1111
11d1
w111
m111
`)
        .setCanonicalSolutionWithWitness(`4,0/3,0/3,1/3,2/4,2/4,3/3,3/2,3/2,2/1,3/0,2/1,1/0,0`.split('/').map(Location.fromString))
    ,
//
// mewd 5x4 unique
// Path: d:5 m:7  Entropy: 6.58
// Contraptions do 7 visits
    Puzzle.fromString(`
11e1
111m
11d1
1111
111w
`)
        .setCanonicalSolutionWithWitness(`2,2/3,1/2,0/1,1/0,2/1,3/2,3/3,3/4,3/4,2/4,1/4,0/3,0`.split('/').map(Location.fromString))
    ,
//
// mewd 5x4 unique
// Path: d:4 m:6  Entropy: 7.17
// Contraptions do 9 visits
    Puzzle.fromString(`
11m1
1111
e1dw
1111
1111
`)
        .setCanonicalSolutionWithWitness(`2,2/3,1/2,0/1,1/0,2/0,3/1,3/2,3/3,3/4,3/4,2`.split('/').map(Location.fromString))
    ,
//
// mewd 5x4 unique
// Path: m:9 d:3  Entropy: 9.58
// Contraptions do 7 visits
    Puzzle.fromString(`
111m
w111
1111
111d
e111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,3/0,2/0,1/0,0/1,0/1,1/1,2/1,3/2,3/3,3/2,2/3,1/4,0`.split('/').map(Location.fromString))
    ,
//
// mewd 5x4 unique
// Path: m:14  Entropy: 11.2
// Contraptions do 5 visits
    Puzzle.fromString(`
1111
11m1
1w11
1111
1ed1
`)
        .setCanonicalSolutionWithWitness(`1,2/2,2/3,2/3,3/2,3/1,3/0,3/0,2/0,1/0,0/1,0/2,0/3,0/4,0/4,1`.split('/').map(Location.fromString))
    ,
//
// mewd 5x4 unique
// Path: m:5 d:6  Entropy: 7.17
// Contraptions do 8 visits
    Puzzle.fromString(`
1d11
1w1m
1111
1111
1e11
`)
        .setCanonicalSolutionWithWitness(`1,3/0,3/0,2/1,2/1,1/0,1/1,0/2,1/3,0/4,1/3,2/2,3`.split('/').map(Location.fromString))
    ,
//
// mwwd 5x5 unique
// Path: m:23  Entropy: 16.8
// Contraptions do 1 visits
    Puzzle.fromString(`
1md11
111w1
11111
11w11
11111
`)
        .setCanonicalSolutionWithWitness(`0,1/0,0/1,0/1,1/1,2/2,2/2,1/2,0/3,0/4,0/4,1/4,2/4,3/4,4/3,4/2,4/1,4/0,4/0,3/1,3/2,3/3,3/3,2/3,1`.split('/').map(Location.fromString))
    ,
//
// mewd 5x4 unique
// Path: m:13 d:2  Entropy: 12.0
// Contraptions do 4 visits
    Puzzle.fromString(`
11dm
11w1
e111
1111
1111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,3/1,3/2,3/3,3/4,3/4,2/4,1/4,0/3,0/3,1/3,2/2,2/1,2/0,2/1,1/2,0`.split('/').map(Location.fromString))
    ,
//
// mewd 5x4 unique
// Path: m:11 d:3  Entropy: 10.2
// Contraptions do 5 visits
    Puzzle.fromString(`
111w
1d11
1111
1111
e11m
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`4,3/3,3/3,2/2,2/2,3/1,3/0,3/0,2/0,1/0,0/1,0/1,1/2,0/3,1/4,0`.split('/').map(Location.fromString))
    ,
//
// mewd 5x4 unique
// Path: m:9 d:4  Entropy: 7.17
// Contraptions do 6 visits
    Puzzle.fromString(`
111m
11w1
e111
1111
d111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,3/1,3/1,2/2,2/3,2/3,3/4,3/4,2/4,1/4,0/3,1/2,0/1,1/0,2`.split('/').map(Location.fromString))
    ,
//
// mewd 5x4 unique
// Path: m:10 d:4  Entropy: 7.17
// Contraptions do 5 visits
    Puzzle.fromString(`
1111
1ewm
1111
1111
d111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`1,3/0,3/0,2/1,2/2,2/3,2/3,3/4,3/4,2/4,1/4,0/3,1/2,0/1,1/0,0`.split('/').map(Location.fromString))
    ,
//
// mewd 5x4 unique
// Path: m:12 d:3  Entropy: 8.58
// Contraptions do 4 visits
    Puzzle.fromString(`
111m
1we1
1111
1111
111d
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,3/0,2/0,1/0,0/1,0/1,1/2,1/3,1/3,0/4,0/4,1/4,2/4,3/3,2/2,3/1,2`.split('/').map(Location.fromString))
    ,
//
// mewd 5x4 unique
// Path: m:14 d:2  Entropy: 9.75
// Contraptions do 3 visits
    Puzzle.fromString(`
11wm
1111
1111
11e1
1d11
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,3/1,3/2,3/2,2/2,1/1,1/1,2/0,2/0,1/0,0/1,0/2,0/3,0/4,0/4,1/3,2/4,3`.split('/').map(Location.fromString))
    ,
//
// mewd 5x4 unique
// Path: m:18  Entropy: 12.8
// Contraptions do 1 visits
    Puzzle.fromString(`
111d
11wm
11e1
1111
1111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`1,3/2,3/3,3/4,3/4,2/3,2/3,1/4,1/4,0/3,0/2,0/2,1/1,1/1,0/0,0/0,1/0,2/1,2/2,2`.split('/').map(Location.fromString))
    ,
//
// meed 5x5 unique
// Path: m:9 d:7  Entropy: 10.8
// Contraptions do 4 visits
    Puzzle.fromString(`
0d110
1e111
1e111
11111
01m10
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`4,2/4,3/3,3/3,4/2,4/1,4/1,3/0,3/0,2/0,1/1,2/2,3/3,2/4,1/3,0/2,1/1,0`.split('/').map(Location.fromString))
    ,
//
// mwwwwJ 6x5 unique
// Path: m:20 J:4  Entropy: 18.8
// Contraptions do 4 visits
    Puzzle.fromString(`
11111
11111
1mw11
Jw1w1
11w11
11111
`)
        .setCanonicalSolutionWithWitness(`2,1/1,1/1,0/0,0/0,1/0,2/0,3/0,4/1,4/1,3/2,3/2,4/3,4/4,4/5,4/5,3/4,3/4,2/4,1/4,0/3,0/5,0/5,2/3,2/1,2`.split('/').map(Location.fromString))
    ,
//
// meedd 5x5 unique
// Path: m:16 d:3  Entropy: 15.2
// Contraptions do 5 visits
    Puzzle.fromString(`
d111m
1e111
11111
1d1e1
11111
`)
        .setCanonicalSolutionWithWitness(`0,4/0,3/0,2/1,2/1,3/1,4/2,4/3,4/4,4/4,3/4,2/4,1/4,0/3,0/2,0/1,0/0,0/1,1/2,2/3,3`.split('/').map(Location.fromString))
    ,
//
// mwwwwwdd 5x6 unique
// Path: m:17 d:6  Entropy: 16.5
// Contraptions do 6 visits
    Puzzle.fromString(`
w111w1
1wdw11
d1w111
111111
11m111
`)
        .setCanonicalSolutionWithWitness(`4,2/4,3/3,3/3,4/4,4/4,5/3,5/2,5/2,4/1,4/1,5/0,5/0,4/0,3/0,2/0,1/1,1/1,2/2,3/3,2/4,1/3,0/2,1/1,0`.split('/').map(Location.fromString))
    ,
//
// mwwwwwJ 7x5 unique
// Path: m:19 J:8  Entropy: 20.1
// Contraptions do 5 visits
    Puzzle.fromString(`
1111J
w1111
1w111
11w11
1m1w1
1111w
11111
`)
        .setCanonicalSolutionWithWitness(`4,1/3,1/3,0/4,0/5,0/6,0/6,1/5,1/5,2/5,3/4,3/3,3/2,3/1,3/1,2/1,1/0,1/0,2/0,3/0,4/2,4/4,4/6,4/6,2/4,2/2,2/2,0/0,0`.split('/').map(Location.fromString))
    ,
//
// mwwwwwdd 5x6 unique
// Path: d:2 m:18 d:3  Entropy: 15.3
// Contraptions do 6 visits
    Puzzle.fromString(`
w111w1
dw1w11
11w111
d1m111
111111
`)
        .setCanonicalSolutionWithWitness(`3,0/4,1/3,2/4,2/4,3/3,3/3,4/4,4/4,5/3,5/2,5/2,4/1,4/1,5/0,5/0,4/0,3/0,2/0,1/1,1/1,0/2,1/1,2/2,3`.split('/').map(Location.fromString))
    ,
//
// msadd 4x4 unique
// Path: d:3 m:1 d:6  Entropy: 9.34
// Contraptions do 5 visits
    Puzzle.fromString(`
1s11
d111
m11a
1d11
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`3,1/2,2/1,1/2,0/1,0/0,1/1,2/2,3/3,2/2,1/3,0`.split('/').map(Location.fromString))
    ,
//
// msadd 4x4 unique
// Path: d:3 m:1 d:6  Entropy: 8.75
// Contraptions do 5 visits
    Puzzle.fromString(`
1s11
dd11
m11a
1111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`1,1/2,2/3,1/2,0/1,0/0,1/1,2/2,3/3,2/2,1/3,0`.split('/').map(Location.fromString))
    ,
//
// msadd 4x4 unique
// Path: d:1 m:5 d:4  Entropy: 7.58
// Contraptions do 5 visits
    Puzzle.fromString(`
1s11
dd11
11ma
1111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`1,1/2,2/2,1/3,1/3,0/2,0/1,0/0,1/1,2/2,3/3,2`.split('/').map(Location.fromString))
    ,
//
// msadd 4x4 unique
// Path: d:4 m:3 d:3  Entropy: 7.17
// Contraptions do 5 visits
    Puzzle.fromString(`
am11
111d
111d
11s1
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`2,3/3,2/2,1/1,2/0,1/0,2/0,3/1,3/2,2/1,1/0,0`.split('/').map(Location.fromString))
    ,
//
// msadd 4x4 unique
// Path: d:4 m:3 d:3  Entropy: 7.17
// Contraptions do 5 visits
    Puzzle.fromString(`
1s11
d11a
d111
11m1
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`1,0/0,1/1,2/2,1/3,2/3,1/3,0/2,0/1,1/2,2/1,3`.split('/').map(Location.fromString))
    ,
//
// msadd 4x4 unique
// Path: m:9 d:2  Entropy: 9.75
// Contraptions do 4 visits
    Puzzle.fromString(`
dm11
1s11
a111
1d11
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,1/0,2/0,3/1,3/2,3/3,3/3,2/2,2/2,1/3,1/2,0/1,1`.split('/').map(Location.fromString))
    ,
//
// msadd 4x4 unique
// Path: m:11  Entropy: 8.75
// Contraptions do 4 visits
    Puzzle.fromString(`
m11d
1s11
111a
111d
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,0/0,1/0,2/1,2/1,1/2,1/2,0/3,0/3,1/3,2/2,2/2,3`.split('/').map(Location.fromString))
    ,
//
// msadd 4x4 unique
// Path: d:4 m:3 d:5  Entropy: 8.92
// Contraptions do 3 visits
    Puzzle.fromString(`
as11
111d
111d
1m11
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`1,3/2,2/1,1/2,0/3,1/3,2/3,3/2,3/1,2/0,1/1,0/2,1/3,0`.split('/').map(Location.fromString))
    ,
//
// msadd 4x4 unique
// Path: m:11 d:1  Entropy: 7.58
// Contraptions do 3 visits
    Puzzle.fromString(`
d111
1am1
1111
1ds1
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`1,2/0,2/0,3/1,3/2,3/3,3/3,2/2,2/2,1/2,0/1,0/0,0/1,1`.split('/').map(Location.fromString))
    ,
//
// msadd 4x4 unique
// Path: m:11 d:1  Entropy: 8.17
// Contraptions do 3 visits
    Puzzle.fromString(`
1a11
1d1s
11d1
11m1
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`3,2/3,1/3,0/2,0/1,0/0,0/0,1/0,2/0,3/1,3/2,3/2,2/3,3`.split('/').map(Location.fromString))
    ,
//
// msadd 4x4 unique
// Path: d:4 m:5 d:5  Entropy: 8.17
// Contraptions do 1 visits
    Puzzle.fromString(`
dd11
111a
1ms1
1111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,1/1,2/2,3/3,2/2,1/3,1/3,0/2,0/1,0/0,0/1,1/0,2/1,3/2,2/3,3`.split('/').map(Location.fromString))
    ,
//
// msad 5x5 unique
// Path: m:17 d:3  Entropy: 14.3
// Contraptions do 4 visits
    Puzzle.fromString(`
d1111
1a11m
11111
11111
11s11
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`1,4/0,4/0,3/0,2/1,2/1,3/2,3/2,4/3,4/4,4/4,3/4,2/3,2/3,1/3,0/2,0/1,0/0,0/1,1/2,2/3,3`.split('/').map(Location.fromString))
    ,
//
// msad 5x5 unique
// Path: m:19 d:2  Entropy: 14.3
// Contraptions do 3 visits
    Puzzle.fromString(`
dm111
11111
11s1a
11111
11111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,1/0,2/1,2/1,3/0,3/0,4/1,4/2,4/2,3/3,3/4,3/4,2/3,2/3,1/4,1/4,0/3,0/2,0/1,0/0,0/1,1/2,2`.split('/').map(Location.fromString))
    ,
//
// msad 5x5 unique
// Path: m:21  Entropy: 14.3
// Contraptions do 3 visits
    Puzzle.fromString(`
1ma11
11d11
11111
11111
111s1
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,1/0,0/1,0/1,1/2,1/2,0/3,0/4,0/4,1/3,1/3,2/4,2/4,3/3,3/3,4/2,4/2,3/1,3/1,4/0,4/0,3/0,2`.split('/').map(Location.fromString))
    ,
//
// madd 6x4 unique
// Path: m:21 d:1  Entropy: 16.6
// Contraptions do 1 visits
    Puzzle.fromString(`
m111
1111
1a11
dd11
1111
1111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`0,0/0,1/0,2/0,3/1,3/2,3/3,3/4,3/5,3/5,2/5,1/5,0/4,0/4,1/4,2/3,2/2,2/1,2/1,1/1,0/2,0/3,0/2,1`.split('/').map(Location.fromString))
    ,
//
// madd 6x3 unique
// Path: m:13 d:1  Entropy: 11.6
// Contraptions do 3 visits
    Puzzle.fromString(`
111
1d1
111
1a1
11d
11m
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`5,2/5,1/5,0/4,0/3,0/2,0/1,0/0,0/0,1/0,2/1,2/2,2/3,2/4,2/3,1`.split('/').map(Location.fromString))
    ,
//
// mmad 6x3 unique
// Path: m:15  Entropy: 9.17
// Contraptions do 2 visits
    Puzzle.fromString(`
111
1dm
1m1
1a1
111
111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`1,2/0,2/0,1/0,0/1,0/2,0/3,0/4,0/5,0/5,1/5,2/4,2/4,1/3,1/3,2/2,2`.split('/').map(Location.fromString))
    ,
//
// madd 6x3 unique
// Path: m:14  Entropy: 11.2
// Contraptions do 3 visits
    Puzzle.fromString(`
111
111
1dm
1a1
1d1
111
`)
        .toggleEnabled()
        .setCanonicalSolutionWithWitness(`2,2/1,2/0,2/0,1/0,0/1,0/2,0/3,0/4,0/5,0/5,1/5,2/4,2/3,2/3,1`.split('/').map(Location.fromString))
    ,
]);