import React, {ComponentProps} from "react";
import {Carousel as Carousel5, Carousel} from "react-responsive-carousel";
import PuzzleSelector from "./puzzleSelector";
import {GameBodyViewType, GameView, LoadPuzzleFn} from "./userFacingGame";
import {World} from "../puzzles/world";
import Puzzle, {PuzzleOrientation} from "../puzzle/puzzle";
import Constants from "../constants";


import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "../css/worldViewer.css";
import GetProgress from "../progress/getProgress";

export type CarouselProps = Partial<ComponentProps<typeof Carousel5>>



interface WorldViewerProps {
    loadPuzzleFn: LoadPuzzleFn,
    currentView: GameView,
    previousView?: GameView,
    worlds: World[],
    currentWorld: World,
    puzzleOrientation: PuzzleOrientation,
    disallowVisibilityAnimation: boolean,
    mostRecentlyPlayedPuzzle?: Puzzle,
}

interface WorldViewerState {
}

class WorldViewerNicest extends React.Component<WorldViewerProps, WorldViewerState> {
    // // Set default props
    // static defaultProps = {
    //     disabledView: "SLIDE_OUT",
    // }

    constructor(props: WorldViewerProps) {
        super(props);
        this.state = {}
    }

    carouselClasses = () => {
        const ret = [
            'world-carousel',
            this.props.currentView.view === 'WORLD' ? 'world-carousel--active' : 'world-carousel--inactive',
        ];


        if (this.props.currentView.view === 'POPUP_SCREEN') {
            ret.push('world-carousel--dimmed');
        }

        if (this.props.currentView.view === 'WORLD' && this.props.previousView?.view === 'PUZZLE') {
            if (this.props.disallowVisibilityAnimation) {
                // It should look normal in this case.
            } else {
                ret.push('world-carousel--in-from-top');
            }
        }
        if (this.props.currentView.view === 'PUZZLE' && this.props.previousView?.view === 'WORLD') {
            if (this.props.disallowVisibilityAnimation) {
                // It should just not be visible in this case.
            } else {
                ret.push('world-carousel--out-to-top');
            }
        }
        return ret.join(' ');
    }

    render() {
        const settings: CarouselProps = {
            className: this.carouselClasses(),
            selectedItem: this.props.currentWorld.indexInWorlds,
            showArrows: false,
            showIndicators: false,
            showStatus: false,
            showThumbs: false,
            transitionTime: GetProgress.getAnimationEnabled() ? Constants.worldViewerTransitionTimeMs : 0,
            swipeable: false,

        };

        return <Carousel {...settings}>
            {this.props.worlds.map(world =>
                <PuzzleSelector
                    key={world.worldName}
                    world={world}
                    mostRecentlyPlayedPuzzle={this.props.mostRecentlyPlayedPuzzle?.sourceWorld === world ?
                        this.props.mostRecentlyPlayedPuzzle : undefined}
                    loadPuzzleFn={this.props.loadPuzzleFn}
                    puzzleOrientation={this.props.puzzleOrientation}/>
            )}
        </Carousel>
    }
}

export default WorldViewerNicest;