import React from "react";
import Utility from "../utility";
import Constants, {DisplayTheme} from "../constants";
import Switch from "react-switch";
import SetProgress from "../progress/setProgress";
import GetProgress from "../progress/getProgress";
import {globalMute, playSound} from "../audio/sounds";
import UserEventTracker, {EventName} from "../progress/userEventTracker";
import IsolatedMover from './isolatedMover';
import {devLog} from "../buildModeChecker";
import NiceButton from "./niceButton";
import DuckImg from "../img/logo256.png";
import '../css/puzzleThemeOriginal.css';
import '../css/puzzleTheme3D.css';
import '../css/popupScreen.css'
import '../css/optionsMenu.css'
// import {CheckoutForm} from "./payMeButton";

// import '@pwabuilder/pwaauth';

// import '@pwabuilder/pwaauth';
// // @ts-ignore
// import { SignInResult, PwaAuth } from '@pwabuilder/pwaauth';
//
// type ReactPwaAuth = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & PwaAuth;
// declare global {
//     // eslint-disable-next-line @typescript-eslint/no-namespace
//     namespace JSX {
//         interface IntrinsicElements {
//             'pwa-auth': ReactPwaAuth
//         }
//     }
// }

interface OptionsMenuProps {
    unallocatedHints: number,
    getHintFn: VoidFunction,
    quackFn: VoidFunction,
}

interface OptionsMenuState {
    hintsState: boolean,
    soundState: boolean,
    animationState: boolean,
}


class OptionsMenu extends React.PureComponent<OptionsMenuProps, OptionsMenuState> {
    // private pwaAuthRef = createRef<PwaAuth>();

    constructor(props: OptionsMenuProps) {
        super(props);
        this.state = {
            hintsState: GetProgress.getHintsEnabled(),
            soundState: GetProgress.getSoundEnabled(),
            animationState: GetProgress.getAnimationEnabled(),
        }
    }

    private static playSwitchSound(checked: boolean) {
        if (checked) playSound('onTick');
        else playSound('offTick');
    }

    handleHintsChange = (checked: boolean) => {
        SetProgress.setHintsEnabled(checked);
        this.setState({hintsState: checked});
        UserEventTracker.RegisterEvent(checked ? 'hints-show' : 'hints-hide');
        OptionsMenu.playSwitchSound(checked);
    }

    handleAnimationChange = (checked: boolean) => {
        SetProgress.setAnimationEnabled(checked);
        this.setState({animationState: checked});
        UserEventTracker.RegisterEvent(checked ? 'animation-on' : 'animation-off');
        OptionsMenu.playSwitchSound(checked);
    }

    handleSoundChange = (checked: boolean) => {
        globalMute(checked);
        SetProgress.setSoundEnabled(checked);
        this.setState({soundState: checked});
        UserEventTracker.RegisterEvent(checked ? 'sound-on' : 'sound-off');
        OptionsMenu.playSwitchSound(checked);
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    private setTheme = (themeName: DisplayTheme) => {
        playSound('themeSelect');
        devLog(`Theme ${themeName} selected.`);
        SetProgress.setTheme(themeName);
        let themeEvent: EventName = themeName === '3d-theme' ? `set-theme-3d-theme` : 'set-theme-original-theme';
        UserEventTracker.RegisterEvent(themeEvent);
        this.forceUpdate();
    }

    render(): React.ReactNode {
        // This is completely unrendered when we're in puzzle view, but in world view it's rendered so we can fade
        // it in and out.
        // TODO: i18n, probably with https://github.com/i18next/react-i18next


        return (
            <>
                {Constants.overrideEliminateThemeOptionsEntirely ?
                    <></> :
                    <div className={'menu__section'} style={{'--option-count': 2} as React.CSSProperties}>
                        <div className={'option-explanation'}>
                            <div className={'option-heading'}>Theme</div>
                            <div className={'explanation-text'}>How do you want the puzzles to look?</div>
                        </div>
                        <div className={'option-setter'}>
                            <IsolatedMover onClick={this.setTheme} theme={'original-theme'}/>
                        </div>
                        <div className={'option-setter'}>
                            <IsolatedMover onClick={this.setTheme} theme={'3d-theme'}/>
                        </div>
                    </div>
                }

                <div className={'menu__section'} style={{'--option-count': 1} as React.CSSProperties}>
                    <div className={'option-explanation'}>
                        <div className={'option-heading'}>Sound Effects</div>
                        <label htmlFor={'soundState'}>
                            <div className={'explanation-text'}>Sound is {this.state.soundState ? 'on' : 'off'}</div>
                        </label>
                    </div>
                    <div className={'option-setter'}>
                        {/* https://react-switch.netlify.app/ */}
                        {/* https://www.npmjs.com/package/react-switch */}
                        <Switch
                            id={'soundState'}
                            className="options-switch"
                            onChange={this.handleSoundChange}
                            checked={this.state.soundState}
                            width={65}
                        />
                    </div>
                </div>

                <div className={'menu__section'} style={{'--option-count': 1} as React.CSSProperties}>
                    <div className={'option-explanation'}>
                        <div className={'option-heading'}>Animation</div>
                        <label htmlFor={'animationState'}>
                            <div className={'explanation-text'}>Turning the animations off can help if the game's jerky
                                on your machine
                            </div>
                        </label>
                    </div>
                    <div className={'option-setter'}>
                        <Switch
                            id={'animationState'}
                            className="options-switch"
                            onChange={this.handleAnimationChange}
                            checked={this.state.animationState}
                            width={65}
                        />
                    </div>
                </div>

                {/*    TODO: Do login with this: https://github.com/firebase/firebaseui-web */}

                {/*{webOnly(() =>*/}
                {/*    <div className={'menu__section'} style={{'--option-count': 1} as React.CSSProperties}>*/}
                {/*        <div className={'option-explanation'}>*/}
                {/*            <div  className={'option-heading'}>Progress</div>*/}
                {/*            <div className={'explanation-text'}>Store your progress in the cloud so it won't get lost.</div>*/}
                {/*        </div>*/}
                {/*        <div className={'option-setter'}>*/}
                {/*            <LoginButton signInSuccess={(signIn: LoginDetails) => {*/}
                {/*                if (signIn.error) {*/}
                {/*                    console.error("Sign in failed", signIn.error);*/}
                {/*                } else {*/}
                {/*                    console.log("Email: ", signIn.email);*/}
                {/*                    console.log("Name: ", signIn.name);*/}
                {/*                    console.log("Picture: ", signIn.imageUrl);*/}
                {/*                    console.log("Access token", signIn.accessToken);*/}
                {/*                    console.log("Access token expiration date", signIn.accessTokenExpiration);*/}
                {/*                    console.log("Provider (MS, Google, FB): ", signIn.provider);*/}
                {/*                    console.log("Raw data from provider: ", signIn.providerData);*/}
                {/*                }*/}
                {/*            }}/>*/}
                {/*        </div>*/}
                {/*    </div>)}*/}

                <div className={'menu__section'} style={{'--option-count': 1} as React.CSSProperties}>
                    <div className={'option-explanation'}>
                        <div className={'option-heading'}>Credits</div>
                        <div className={'explanation-text'}>This game was made with love by Dave.</div>
                        <div className={'email-text'}>TheRestInMotion@gmail.com</div>
                    </div>
                    <div className={'option-setter'}>
                        <NiceButton name={"Quack"}
                                    display={{image: DuckImg}}
                                    action={this.props.quackFn}
                                    active={true}
                                    small={true}
                        />
                    </div>
                </div>

                {Constants.overrideEliminateHintsEntirely ?
                    <></> :
                    <div className={'menu__section'} style={{'--option-count': 1} as React.CSSProperties}>
                        <div className={'option-explanation'}>
                            <div className={'option-heading'}>Hints Interface</div>
                            <label htmlFor={'hintsState'}>
                                <div className={'explanation-text'}>Do you want to have hints available while solving
                                    puzzles?
                                </div>
                            </label>
                            <br/>
                            <small>
                                {this.hintsText()}
                            </small>
                        </div>
                        <div className={'option-setter'}>
                            <Switch
                                id={'hintsState'}
                                className="options-switch"
                                onChange={this.handleHintsChange}
                                checked={this.state.hintsState}
                                width={65}
                            />
                        </div>
                    </div>}

                {Constants.infiniteHintsMode || Constants.overrideEliminateHintsEntirely ?
                    <></> :
                    <div className={'menu__section'} style={{'--option-count': 1} as React.CSSProperties}>
                        <div className={'option-explanation'}>
                            <div className={'option-heading'}>Hints</div>
                            <label htmlFor={'get-hint-button'}>
                                <small>You currently have {this.props.unallocatedHints} hints</small>
                            </label>
                        </div>
                        <div className={'option-setter'}>
                            <button id={'get-hint-button'}
                                    className={'options-button'}
                                    onClick={this.props.getHintFn}>
                                More Hints!
                            </button>
                        </div>
                    </div>}

                {/*  TODO: Web payments https://stripe.com/docs/stripe-js/elements/payment-request-button?html-or-react=react   */}
                {/* https://github.com/GoogleChromeLabs/android-web-payment/ */}
                {/*{devOnly(() =>*/}
                {/*    <div className={'menu__section'} style={{'--option-count': 1} as React.CSSProperties}>*/}
                {/*        <div className={'option-explanation'}>*/}
                {/*            <div className={'option-heading'}>Tip Me</div>*/}
                {/*            <label htmlFor={'tip-button'}>*/}
                {/*                <small>*/}
                {/*                    This game is made by one guy and since I don't want to show ads, this is the*/}
                {/*                    only way you can support my work. Plus I'll throw in some extra hints. -dave*/}
                {/*                </small>*/}
                {/*            </label>*/}
                {/*        </div>*/}
                {/*        <div className={'option-setter'}>*/}
                {/*            <CheckoutForm/>*/}
                {/*        </div>*/}
                {/*    </div>)}*/}

                {/*{devOnly(() =>*/}
                {/*    <div className={'menu__section'} style={{'--option-count': 1} as React.CSSProperties}>*/}
                {/*        <div className={'option-explanation'}>*/}
                {/*            <div className={'option-heading'}>Dev Only</div>*/}
                {/*            <label htmlFor={'play-orphan-button'}>*/}
                {/*                <small>Let's play some of the orphan sounds.</small>*/}
                {/*            </label>*/}
                {/*        </div>*/}
                {/*        <div className={'option-setter'}>*/}
                {/*            <button id={'play-orphan-button'}*/}
                {/*                    className={'options-button'}*/}
                {/*                    onClick={cycleOrphanSounds}>*/}
                {/*                Cycle Orphans*/}
                {/*            </button>*/}
                {/*        </div>*/}
                {/*    </div>)}*/}


            </>
        );
    }

    hintsText(): React.ReactNode {
        if (Constants.infiniteHintsMode) {
            return <></>;
        } else {
            return <>You currently have {Utility.optionalS(this.props.unallocatedHints, 'unallocated hint')} to
                use, and you earn another after
                {Utility.optionalS(GetProgress.getSolvesUntilNextFreeHint(), 'more solve')}.</>
        }
    }

}

export default OptionsMenu;