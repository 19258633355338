import Puzzle from "../puzzle/puzzle";
import {rawWorlds} from './worlds'
import Constants from "../constants";

export {World}

export interface WorldTheme {
    majorHue: number,
    minorHue: number,
    // complementHue?: number,
    // randomNumber1?: number,
    // randomNumber2?: number,
}

type puzzleDef = string | Puzzle;

class World {
    public readonly puzzles: Puzzle[];

    constructor(public readonly worldName: string, levels: puzzleDef[]) {
        this.puzzles = levels.map(s => {
            if (typeof s == "string") return Puzzle.fromString(s);
            else return s;
        })
    }

    private _prevWorld: World | undefined;

    get prevWorld(): World | undefined {
        return this._prevWorld;
    }

    private _nextWorld: World | undefined;

    get nextWorld(): World | undefined {
        return this._nextWorld;
    }

    get size(): number {
        return this.puzzles.length;
    }

    private _indexInWorlds?: number;

    get indexInWorlds(): number {
        if (this._indexInWorlds === undefined) return -1;
        return this._indexInWorlds;
    }

    public toJSON() {
        return {
            worldName: this.worldName,
            puzzles: this.puzzles.map(p => p.toJSON()),
        }
    }

    // TODO: Maybe more like this https://stackoverflow.com/questions/22875636/how-do-i-cast-a-json-object-to-a-typescript-class
    static fromJSON(world: any): World | undefined {
        const levels = world.puzzles.map(Puzzle.fromJSON);
        return new World(world.worldName, levels);
    }

    public setNeighbourhood(prev: World | undefined, next: World | undefined, indexInWorlds: number) {
        this._nextWorld = next;
        this._prevWorld = prev;
        this._indexInWorlds = indexInWorlds;
    }

    public adjust(fromLoc: number, toLoc: number) {
        // Taken from https://stacooverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another
        // if positions are different and inside array
        if (fromLoc < 0 || fromLoc >= this.size || toLoc < 0 || toLoc >= this.size) {
            console.error(`Don't try to move from ${fromLoc} to ${toLoc}`)
            return;
        }
        if (fromLoc !== toLoc) {
            // save element from position 1
            let tmp = this.puzzles[fromLoc];
            // move element down and shift other elements up
            if (fromLoc < toLoc) {
                for (let i = fromLoc; i < toLoc; i++) {
                    this.puzzles[i] = this.puzzles[i + 1];
                }
            }
            // move element up and shift other elements down
            else {
                for (let i = fromLoc; i > toLoc; i--) {
                    this.puzzles[i] = this.puzzles[i - 1];
                }
            }
            // put element from position 1 to destination
            this.puzzles[toLoc] = tmp;
        }
    }

    public sameAsWorldOnDisk(): boolean {
        let foundWorld = rawWorlds.find(w => w.worldName === this.worldName);
        if (foundWorld === undefined || this.puzzles.length !== foundWorld.puzzles.length) {
            return false;
        }
        return foundWorld.puzzles.every((p, i) =>
            p.toString() === this.puzzles[i].toString() &&
            p.enabled === this.puzzles[i].enabled);
    }

    /**
     * Insert a puzzle into the World list UNDER the passed location, or at the bottom if undefined
     * @param newPuzzle
     * @param location
     */
    insertPuzzle(newPuzzle: Puzzle, location: number | undefined): number {
        if (location === undefined) {
            this.puzzles.push(newPuzzle);
            return this.puzzles.length - 1;
        } else {
            this.puzzles.splice(location + 1, 0, newPuzzle);
            return location + 1;
        }
    }

    deleteLevel(location: number) {
        if (location < 0 || location >= this.size) {
            console.error(`deleteLevel called with location out of range: ${location}`)
            return;
        }
        // Push them all down.
        for (let i = location; i < this.size - 1; i += 1) {
            this.puzzles[i] = this.puzzles[i + 1]
        }
        this.puzzles.pop()
    }

    enabledSize() {
        return this.enabledPuzzles().length;
    }

    enabledPuzzles(): Puzzle[] {
        return this.puzzles.filter(p => p.enabled);
    }

    copy(): World {
        let newWorld = new World(this.worldName, [])
        this.puzzles.forEach(p => newWorld.puzzles.push(p));
        return newWorld;
    }

    getColorSchemeTheme(): WorldTheme {
        const explicitTheme = Constants.explicitWorldThemes.get(this.indexInWorlds);
        if (explicitTheme) return explicitTheme;

        const hue1 = Math.floor((this.indexInWorlds) / (Constants.totalNumberOfWorlds) * 256) % 360;
        const hue2 = Math.floor(hue1 + 120) % 360;
        const hue3 = Math.floor(hue1 + 240) % 360;
        return {
            majorHue: hue1,
            minorHue: hue2,
            // complementHue:  hue3,
            // randomNumber1: 4,
            // randomNumber2: 5,
        }
    }
}

