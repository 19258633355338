import Constants, {DisplayTheme} from "./constants";
import Utility, {ScreenDimensions} from "./utility";
import {PuzzleOrientation} from "./puzzle/puzzle";


export type GamePanelOrientation = 'top' | 'bottom';
export type overlayTextOrientation = | 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

export interface CumbersomeCssConstantsDeliveredByJs {
    readonly worldMoveTransitionTime: number;
    readonly additionalPaddingLeft: number,
    readonly gridGapSquarePct: number,
    readonly gameBodyAspectRatio: number,
    readonly effectiveScreenSize: ScreenDimensions,
    readonly preferredPuzzleOrientation: PuzzleOrientation,
    readonly buttonHeightPx: number;
    readonly textOverlayHeightPx: number;
    readonly gameBodySlideOutPx: number;
    // readonly gameBodyHeightPx: number;
}

export interface GameLayout {
    readonly gameButtonsOrientation: GamePanelOrientation;
    readonly gameBodyOrientation: GamePanelOrientation;
    readonly levelDisplayOrientation: overlayTextOrientation;
    readonly workInProgressOrientation: overlayTextOrientation;
    readonly buttonHeightPct: number;
    readonly textOverlayHeightPct: number;
}


class LayoutConstants {
    static gameLayout: GameLayout = {
        gameButtonsOrientation: 'bottom',
        gameBodyOrientation: 'top',
        levelDisplayOrientation: 'top-left',
        workInProgressOrientation: 'top-right',
        buttonHeightPct: 15,
        textOverlayHeightPct: 5,
    }


    static layoutConstantsToPutInCss(): CumbersomeCssConstantsDeliveredByJs {
        function getTheme(): DisplayTheme {
            const fromLS = Utility.getFromStorage('displayTheme', (s: DisplayTheme) => s);
            return fromLS || Constants.themeDefaultValue;
        }

        let gameBodyAspectRatio = Utility.getGameBodyAspectRatio();
        let effectiveScreenSize = Utility.effectiveScreenSize();
        const gameLayout = LayoutConstants.gameLayout;
        const totalHackIncreaseHeight = 2;
        const common = {
            gameBodyAspectRatio: gameBodyAspectRatio,
            effectiveScreenSize: effectiveScreenSize,
            preferredPuzzleOrientation: gameBodyAspectRatio >= 1 ?
                PuzzleOrientation.tall : PuzzleOrientation.wide,
            buttonHeightPx: gameLayout.buttonHeightPct * effectiveScreenSize.heightPx * .01,
            textOverlayHeightPx: gameLayout.textOverlayHeightPct * effectiveScreenSize.heightPx * .01,
            // Here we're assuming the buttons are on the bottom, which they are.
            gameBodySlideOutPx: effectiveScreenSize.heightPx * (100 - gameLayout.textOverlayHeightPct) * .01,
            // gameBodyHeightPx: effectiveScreenSize.heightPx * (totalHackIncreaseHeight + 100 - gameLayout.textOverlayHeightPct - gameLayout.buttonHeightPct) * .01,
            worldMoveTransitionTime: Constants.worldMoveTransitionTimeMs,
        };

        if (getTheme() === '3d-theme')
            return {
                ...common,
                additionalPaddingLeft: 0,
                gridGapSquarePct: 2,
            }
        else if (getTheme() === 'original-theme')
            return {
                ...common,
                additionalPaddingLeft: 0,
                gridGapSquarePct: 6,
            }
        else {
            throw new Error('How could you possibly end up here?')
        }

    }

}

export default LayoutConstants;