import React from "react";
import {VisitableThing} from "../../puzzle/movement";

import '../../css/lab/puzzleLab.css';
import Puzzle from "../../puzzle/puzzle";

// TODO: Make 2nd clicks on these sorting functions invert the sort order.
// TODO: Put tooltips on the clickable sorters.
let solutionDescriptorPreliminaries: SolutionDescriptorProps[] = [
    {
        name: 'visitables',
        label: 'Visitables: ',
        text: (puzzle: Puzzle) => `${puzzle.visitableTypesString()}`,
        filterFn: puzzle => {
            return (lp) => lp.visitableTypesString() === puzzle.visitableTypesString()
        },
    },
    {
        name: 'diagonalParityRatio',
        include: (s: Puzzle) => s.visitableThingsList.filter(vt => vt === VisitableThing.Diagonal).length >= 2,
        label: 'Diagonal parity ratio: ',
        text: (s: Puzzle) => `${s.diagonalParityRatio.toFixed(2)}`,
        compareFn: (a, b) => b.diagonalParityRatio - a.diagonalParityRatio,
        filterFn: s => {
            return (ls) => ls.diagonalParityRatio === s.diagonalParityRatio
        },
    },
    {
        name: 'entropy',
        label: 'Entropy: ',
        text: (s: Puzzle) => `${s.solutionInfo!.pathEntropy.toPrecision(3)}`,
        compareFn: (a, b) => b.solutionInfo!.pathEntropy - a.solutionInfo!.pathEntropy,
    },
    {
        name: 'hasBoomlessBomb',
        include: (s: Puzzle) => s.canonicalSolutionMoves!.some(m => s.contraptionLocations.some(loc => loc.equals(m.newLocation)) && m.contraptionVisits.length === 0),
        label: 'Boomless bomb: ',
        text: (s: Puzzle) => `BOOMLESS BOMB`,
    },
    {
        // TODO: Let us click on each character in the path to filter by that prefix.
        name: 'path',
        label: 'Path: ',
        text: (s: Puzzle) => `${s.solutionInfo!.describePath}`,
        compareFn: (a, b) => b.solutionInfo!.shortestMovementTypeUse - a.solutionInfo!.shortestMovementTypeUse,
    },
    {
        name: 'pathIntersections',
        // include: (s: Solution) => s.pathIntersections > 0,
        label: 'Path Intersections: ',
        text: (s: Puzzle) => `${s.solutionInfo!.pathIntersections}`,
        compareFn: (a, b) => b.solutionInfo!.pathIntersections - a.solutionInfo!.pathIntersections,
        filterFn: s => {
            return (ls) => ls.solutionInfo!.pathIntersections === s.solutionInfo!.pathIntersections;
        },
    },
    {
        name: 'interactingBombCount',
        include: (puzzle: Puzzle) => puzzle.contraptionLocations.length >= 2,
        label: 'Bombs on bomb action: ',
        text: (puzzle: Puzzle) => `${puzzle.interactingBombCount}`,
        compareFn: (a, b) => b.interactingBombCount - a.interactingBombCount,
        filterFn: puzzle => {
            return (ls) => ls.interactingBombCount === puzzle.interactingBombCount;
        },
    },
    {
        name: 'movePathsReused',
        include: (s: Puzzle) => s.multiTapSquareCount > 0,
        label: 'Move paths reused: ',
        text: (s: Puzzle) => `${s.solutionInfo!.pathReuse.join(", ")}`,
        compareFn: (a, b) => b.solutionInfo!.movesReusingAPath() - a.solutionInfo!.movesReusingAPath(),
        filterFn: s => {
            return (ls) => ls.solutionInfo!.movesReusingAPath() === s.solutionInfo!.movesReusingAPath();
        },
    },
    {
        name: 'movementTypeChanges',
        label: 'Movement Type Changes: ',
        text: (s: Puzzle) => `${s.solutionInfo!.movementTypeChanges}`,
        compareFn: (a, b) => b.solutionInfo!.movementTypeChanges - a.solutionInfo!.movementTypeChanges,
        filterFn: s => {
            return (ls) => ls.solutionInfo!.movementTypeChanges === s.solutionInfo!.movementTypeChanges;
        },
    },
    {
        name: 'goldTouches',
        include: (s: Puzzle) => s.goldCount > 0,
        label: 'Gold Touches: ',
        text: (s: Puzzle) => `${s.solutionInfo!.goldTouchCounts.join(", ")}`,
        compareFn: (a, b) => (b.solutionInfo!.goldTouchCounts[0] || 0) - (a.solutionInfo!.goldTouchCounts[0] || 0),
        filterFn: s => {
            return (ls) => (ls.solutionInfo!.goldTouchCounts[0] || 0) === (s.solutionInfo!.goldTouchCounts[0] || 0);
        },
    },
    {
        name: 'goldSquaresUntouched',
        include: s => s.solutionInfo!.untouchedGolds > 0,
        label: 'Gold squares untouched: ',
        text: s => `${s.solutionInfo!.untouchedGolds}`,
        compareFn: (a, b) => b.solutionInfo!.untouchedGolds - a.solutionInfo!.untouchedGolds,
        filterFn: s => {
            return (ls) => ls.solutionInfo!.untouchedGolds === s.solutionInfo!.untouchedGolds
        },
    },
    {
        name: 'contraptionCausedVisitsCount',
        include: s => s.solutionInfo!.contraptionCausedVisitsCount > 0,
        label: 'contraptionCausedVisitsCount: ',
        text: s => `${s.solutionInfo!.contraptionCausedVisitsCount}`,
        compareFn: (a, b) => b.solutionInfo!.contraptionCausedVisitsCount - a.solutionInfo!.contraptionCausedVisitsCount,
        filterFn: s => {
            return (ls) => ls.solutionInfo!.contraptionCausedVisitsCount === s.solutionInfo!.contraptionCausedVisitsCount
        },
    },
    {
        name: 'moveOfFirstContraptionCausedVisit',
        include: s => s.solutionInfo!.contraptionCausedVisitsCount > 0,
        label: 'First bomb visit: ',
        text: s => `${s.solutionInfo!.moveOfFirstContraptionCausedVisit()}`,
        compareFn: (a, b) => a.solutionInfo!.moveOfFirstContraptionCausedVisit() - b.solutionInfo!.moveOfFirstContraptionCausedVisit(),
        filterFn: s => {
            return (ls) => ls.solutionInfo!.moveOfFirstContraptionCausedVisit() === s.solutionInfo!.moveOfFirstContraptionCausedVisit()
        },
    },
    {
        name: 'unusedMovementTypes',
        // include: (s: Solution) => s.unusedMovementTypes() > 0,
        label: 'Unused MovementTypes: ',
        text: s => `${s.solutionInfo!.unusedMovementTypes}`,
        compareFn: (a, b) => b.solutionInfo!.unusedMovementTypes - a.solutionInfo!.unusedMovementTypes,
        filterFn: s => {
            return (ls) => ls.solutionInfo!.unusedMovementTypes === s.solutionInfo!.unusedMovementTypes
        },
    },
    {
        name: 'finalMovementTypeUnused',
        include: s => s.solutionInfo!.hasUnusedEndSchema(),
        label: 'Final MovementType: ',
        text: s => s.solutionInfo!.hasUnusedEndSchema() ? `unused` : "used",
        filterFn: s => {
            return (ls) => ls.solutionInfo!.hasUnusedEndSchema() === s.solutionInfo!.hasUnusedEndSchema()
        },
    },
];

interface SolutionDescriptorProps {
    name: string,
    include?: (s: Puzzle) => boolean,
    label: string,
    text: (s: Puzzle) => string,
    compareFn?: (a: Puzzle, b: Puzzle) => number,
    callToFocusSearchResults?: () => void,
    filterFn?: (s: Puzzle) => ((ls: Puzzle) => boolean),
    callToSort?: (compareFn: (a: Puzzle, b: Puzzle) => number) => void,
    solvedPuzzle?: Puzzle,
    callOnFilterChange?: () => void,
}

interface SolutionDescriptorState {
    checkboxState: boolean,
    capturedFilterFn: (s: Puzzle) => boolean,
}

class SolutionDescriptor extends React.Component<SolutionDescriptorProps, SolutionDescriptorState> {
    constructor(props: SolutionDescriptorProps) {
        super(props);
        this.state = {
            checkboxState: false,
            capturedFilterFn: (s) => true,
        }
        this.checkboxChange = this.checkboxChange.bind(this);
    }

    private checkboxChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
                checkboxState: event.target.checked,
                capturedFilterFn: event.target.checked ?
                    this.props.filterFn!(this.props.solvedPuzzle!) : (s) => true,
            },
            this.props.callOnFilterChange);

    }

    public reset() {
        this.setState({
            checkboxState: false,
            capturedFilterFn: (s) => true,
        });
    }

    public effectiveFilterFn(): (s: Puzzle) => boolean {
        return this.state.capturedFilterFn;
    }

    render(): React.ReactNode {
        if (!this.props.solvedPuzzle) return null;
        let s = this.props.solvedPuzzle;

        // Drop out early if this descriptor isn't used for our current solution.
        if (this.props.include && !this.props.include(s)) return null;

        let checkbox: React.ReactNode | null = null;
        if (this.props.filterFn) {
            checkbox = <input type="checkbox"
                              className={'puzzle-lab__checkbox puzzle-lab__checkbox--floating-label'}
                              name={this.props.name}
                              checked={this.state.checkboxState}
                              onChange={this.checkboxChange}/>
        }

        if (!this.props.compareFn) {
            return <span key={this.props.name}>
                {checkbox}
                <p key={this.props.name}
                   className={'info-text info-text--small info-text--beside-checkbox'}>
                    {this.props.label}{this.props.text(s)}
                </p>
            </span>
        }

        // We have a compareFn
        return <span key={this.props.name}>
            {checkbox}
            <p className={"info-text info-text--small info-text--clickable info-text--beside-checkbox"}
               onClick={e => {
                   this.props.callToSort!(this.props.compareFn!);
                   if (this.props.callToFocusSearchResults) this.props.callToFocusSearchResults();
               }}>
                {this.props.label}{this.props.text(s)}
            </p>
        </span>

    }

}


interface SolutionDescriberProps {
    callToSort: (compareFn: (a: Puzzle, b: Puzzle) => number) => void,
    callToFocusSearchResults?: () => void,
    callToSetFilter: (filterFn: (s: Puzzle) => boolean) => void,
}

interface SolutionDescriberState {
    // solution: Solution | undefined,
    puzzle: Puzzle | undefined,
    // solutionDescriptors: SolutionDescriptor[],
}

class SolutionDescriber extends React.Component<SolutionDescriberProps, SolutionDescriberState> {
    private solutionDescriptorRefs: Map<string, SolutionDescriptor>;

    constructor(solutionDescriberProps: SolutionDescriberProps) {
        super(solutionDescriberProps);
        this.solutionDescriptorRefs = new Map<string, SolutionDescriptor>();
        this.state = {
            puzzle: undefined,
        }
        this.updateFilterFn = this.updateFilterFn.bind(this);
    }

    public setSolvedPuzzle(s: Puzzle) {
        this.setState({
            puzzle: s,
        });
    }

    private updateFilterFn() {
        let fns = Array.from(this.solutionDescriptorRefs.values()).map(ref => ref.effectiveFilterFn())
        this.props.callToSetFilter((s: Puzzle) => {
            // console.log('function built in updateFilterFn called');
            return fns.every(f => f(s));
        });
    }

    // public searchResultsFilterFn(s: Solution) {
    //     // To filter, we just run the effective filter of all of our underlying solutionDescriptors, which just check
    //     // if their checkbox is ticked.
    //     return Array.from(this.solutionDescriptorRefs.values()).every(sd => sd.effectiveFilter(s));
    // }

    public resetFilters() {
        this.solutionDescriptorRefs.forEach(sd => sd.reset());
        this.updateFilterFn();
        // this.forceUpdate();
    }

    render(): React.ReactNode {
        // Simple fallback if we have nothing to work with yet.
        if (!this.state.puzzle) return <div>
            <h2 className={'panel-header'}>Solution Info</h2>
            <p className={'info-text'}>Load a Solution to see it described here.</p>
        </div>;

        return <>
            <h2 className={'panel-header'}>Solution Info</h2>
            {/*solutionDescriptorPreliminaries*/}
            {solutionDescriptorPreliminaries.map(props =>
                <SolutionDescriptor
                    {...props}
                    key={props.name}
                    callToSort={this.props.callToSort}
                    callToFocusSearchResults={this.props.callToFocusSearchResults}
                    callOnFilterChange={this.updateFilterFn}
                    solvedPuzzle={this.state.puzzle}
                    ref={(ref) => this.solutionDescriptorRefs.set(props.name, ref!)}/>)}
        </>
    }
}

export default SolutionDescriber;