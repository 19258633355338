export {Directions};

class Offset {
    constructor(public verticalOffset: number, public horizontalOffset: number) {
    }

    public add(offset: Offset) {
        return new Offset(this.verticalOffset + offset.verticalOffset,
            this.horizontalOffset + offset.horizontalOffset)
    }

    doubled() {
        return new Offset(this.verticalOffset * 2,
            this.horizontalOffset * 2);
    }

    hFlip = () => new Offset(this.verticalOffset, -this.horizontalOffset);
    vFlip = () => new Offset(-this.verticalOffset, this.horizontalOffset);
    transpose = () => new Offset(this.horizontalOffset, this.verticalOffset);
    rotate = () => new Offset(this.verticalOffset, -this.horizontalOffset);

    withRotations(): Offset[] {
        // This is inefficient but who care?
        return [this, this.rotate(), this.rotate().rotate(), this.rotate().rotate().rotate()];
    }

}

class Directions {
    static up = new Offset(-1, 0);
    static down = new Offset(1, 0);
    static left = new Offset(0, -1);
    static right = new Offset(0, 1);
    static upleft = Directions.up.add(Directions.left);
    static upright = Directions.up.add(Directions.right);
    static downleft = Directions.down.add(Directions.left);
    static downright = Directions.down.add(Directions.right);
    static knightUpLeft = Directions.up.add(Directions.upleft);
    static knightUpRight = Directions.up.add(Directions.upright);
    static knightRightUp = Directions.right.add(Directions.upright);
    static knightRightDown = Directions.right.add(Directions.downright);
    static knightDownRight = Directions.down.add(Directions.downright);
    static knightDownLeft = Directions.down.add(Directions.downleft);
    static knightLeftDown = Directions.left.add(Directions.downleft);
    static knightLeftUp = Directions.left.add(Directions.upleft);
    static manhattans = [Directions.up, Directions.down, Directions.left, Directions.right];
    static diagonals = [Directions.upleft, Directions.downleft, Directions.upright, Directions.downright];
    static neighbourhood8 = [...Directions.manhattans, ...Directions.diagonals];
    static knightMoves = [
        Directions.knightUpLeft, Directions.knightUpRight, Directions.knightRightUp, Directions.knightRightDown,
        Directions.knightDownRight, Directions.knightDownLeft, Directions.knightLeftDown, Directions.knightLeftUp
    ];
}

export default Offset;