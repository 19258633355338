import Location from "./location";
import {VisitableThing} from "./movement";

/**
 * This is a move in the game. It changes the state of the game and can be stacked up as offsets are made and then
 * undone seamlessly. undefined is used for the first location of each game, where no previous VisitableThing
 * had yet been established.
 */
export type MoveData = {
    oldLocation?: Location,
    newLocation: Location,
    oldMovementType?: VisitableThing,
    newMovementType: VisitableThing
    requiredVisits: Location[],
    optionalVisits: Location[],
    contraptionVisits: Location[],
}

export class Move {
    constructor(private data: MoveData) {
    }

    get contraptionVisits(): Location[] {
        return this.data.contraptionVisits;
    }

    get optionalVisits(): Location[] {
        return this.data.optionalVisits;
    }

    get requiredVisits(): Location[] {
        return this.data.requiredVisits;
    }

    get newMovementType(): VisitableThing {
        return this.data.newMovementType;
    }

    get oldMovementType(): VisitableThing | undefined {
        return this.data.oldMovementType;
    }

    get oldLocation(): Location | undefined {
        return this.data.oldLocation;
    }

    get newLocation(): Location {
        return this.data.newLocation;
    }

    public static FromAnyJSON(s: any): Move {
        let makeloc = (loc: any) => {
            if (loc === undefined) return undefined;
            if (loc.row !== undefined) return new Location(loc.row, loc.column);
            return Location.fromString(loc);
        }

        if (typeof s == "string") s = JSON.parse(s);
        // This happens coming back from the worker.
        if (s.data) s = s.data;

        if (!s.requiredVisits) {
            console.log(s);
            throw new Error();
        }

        return new Move({
            oldLocation: makeloc(s.oldLocation),
            newLocation: makeloc(s.newLocation)!,
            newMovementType: s.newMovementType,
            oldMovementType: s.oldMovementType === undefined ? undefined : s.oldMovementType as VisitableThing,
            requiredVisits: s.requiredVisits.map(makeloc),
            optionalVisits: s.optionalVisits.map(makeloc),
            contraptionVisits: s.contraptionVisits.map(makeloc),
        });
    }

    /**
     * Get all of the optional, required, contraption, and destination visits due to this move.
     */
    public allVisits() {
        return [this.newLocation, ...this.requiredVisits, ...this.optionalVisits, ...this.contraptionVisits];
    }

    public equals(other: Move) {
        function locArrayEq(a1: Location[], a2: Location[]): boolean {
            if (a1.length !== a2.length) return false;
            const containsEach = (b: Location[], c: Location[]) =>
                b.every(loc => c.some(loc2 => loc.equals(loc2)));
            return containsEach(a1, a2) && containsEach(a2, a1);
        }

        if (!this.newLocation.equals(other.newLocation)) return false;
        if (this.oldLocation === undefined) {
            if (other.oldLocation !== undefined) return false;
        } else {
            if (!this.oldLocation.equals(other.oldLocation)) return false;
        }
        if (this.oldMovementType !== other.oldMovementType) return false;
        if (this.newMovementType !== other.newMovementType) return false;
        if (!locArrayEq(this.requiredVisits, other.requiredVisits)) return false;
        if (!locArrayEq(this.optionalVisits, other.optionalVisits)) return false;
        if (!locArrayEq(this.contraptionVisits, other.contraptionVisits)) return false;
        return true;
    }

    public toJSON() {
        return {
            oldLocation: this.oldLocation?.toJSON(),
            newLocation: this.newLocation.toJSON(),
            oldMovementType: this.oldMovementType,
            newMovementType: this.newMovementType,
            requiredVisits: this.requiredVisits.map(loc => loc.toJSON()),
            optionalVisits: this.optionalVisits.map(loc => loc.toJSON()),
            contraptionVisits: this.contraptionVisits.map(loc => loc.toJSON()),
        };
    }
}

export type MoveSequence = Move[];