import {Howl, Howler} from 'howler';
import {devLog, isDev, isProd} from "../buildModeChecker";
// @ts-ignore
import quack from './quack-edited-short.mp3';
// @ts-ignore
import themeSelect from './navigation_hover-tap.wav';
// @ts-ignore
import chime1 from './186719__andromadax24__chime-01.mp3';
// @ts-ignore
import navRight2 from './navRight2.mp3';
// @ts-ignore
import sparkle from './138410__cameronmusic__item-collect-2.mp3';
// @ts-ignore
import stateChangeConfirm from './state-change_confirm-up.mp3';
// @ts-ignore
import twinkle from './544015__mr-fritz__item-pick-up.mp3';
// @ts-ignore
import oneup from './518888__ash-rez__1up-video-game.mp3';
// @ts-ignore
import correct from './131660__bertrof__game-sound-correct.mp3';
// @ts-ignore
import errorSnd from './142608__autistic-lucario__error.wav';
// @ts-ignore
import success1 from './171671__leszek-szary__success-1.mp3';
// @ts-ignore
import success2 from './171670__leszek-szary__success-2.mp3';
// @ts-ignore
import correctDing from './335908__littlerainyseasons__correct.mp3';
// @ts-ignore
import negativeBeeps from './253886__themusicalnomad__negative-beeps.mp3';
// @ts-ignore
import gameOver from './133283__leszek-szary__game-over.mp3';
// @ts-ignore
import guitarChord from './181425__serylis__guitar-chord.mp3';
// @ts-ignore
import successful from './162473__kastenfrosch__successful.mp3';
// @ts-ignore
import heroDecorativeCelebration01 from './material/01 Hero Sounds/hero_decorative-celebration-01.ogg';
// @ts-ignore
import heroDecorativeCelebration02 from './material/01 Hero Sounds/hero_decorative-celebration-02.ogg';
// @ts-ignore
import heroDecorativeCelebration03 from './material/01 Hero Sounds/hero_decorative-celebration-03.ogg';
// @ts-ignore
import heroSimpleCelebration01 from './material/01 Hero Sounds/hero_simple-celebration-01.ogg';
// @ts-ignore
import heroSimpleCelebration02 from './material/01 Hero Sounds/hero_simple-celebration-02.ogg';
// @ts-ignore
import heroSimpleCelebration03 from './material/01 Hero Sounds/hero_simple-celebration-03.ogg';
// @ts-ignore
import alarm_gentle from './material/02 Alerts and Notifications/alarm_gentle.ogg';
// @ts-ignore
import alert_highIntensity from './material/02 Alerts and Notifications/alert_high-intensity.ogg';
// @ts-ignore
import alert_simple from './material/02 Alerts and Notifications/alert_simple.ogg';
// @ts-ignore
import notification_ambient from './material/02 Alerts and Notifications/notification_ambient.ogg';
// @ts-ignore
import notification_decorative01 from './material/02 Alerts and Notifications/notification_decorative-01.ogg';
// @ts-ignore
import notification_decorative02 from './material/02 Alerts and Notifications/notification_decorative-02.ogg';
// @ts-ignore
import notification_highIntensity from './material/02 Alerts and Notifications/notification_high-intensity.ogg';
// @ts-ignore
import notification_simple01 from './material/02 Alerts and Notifications/notification_simple-01.ogg';
// @ts-ignore
import notification_simple02 from './material/02 Alerts and Notifications/notification_simple-02.ogg';
// @ts-ignore
import navigation_backwardSelection from './material/03 Primary System Sounds/navigation_backward-selection.ogg';
// @ts-ignore
import navigation_backwardSelectionMinimal    from './material/03 Primary System Sounds/navigation_backward-selection-minimal.ogg';
// @ts-ignore
import navigation_forwardSelection from './material/03 Primary System Sounds/navigation_forward-selection.ogg';
// @ts-ignore
import navigation_forwardSelectionMinimal    from './material/03 Primary System Sounds/navigation_forward-selection-minimal.ogg';
// @ts-ignore
import navigation_hoverTap from './material/03 Primary System Sounds/navigation_hover-tap.ogg';
// @ts-ignore
import navigation_selectionCompleteCelebration    from './material/03 Primary System Sounds/navigation_selection-complete-celebration.ogg';
// @ts-ignore
import stateChange_confirmDown from './material/03 Primary System Sounds/state-change_confirm-down.ogg';
// @ts-ignore
import stateChange_confirmUp from './material/03 Primary System Sounds/state-change_confirm-up.ogg';
// @ts-ignore
import ui_cameraShutter from './material/03 Primary System Sounds/ui_camera-shutter.ogg';
// @ts-ignore
import ui_lock from './material/03 Primary System Sounds/ui_lock.ogg';
// @ts-ignore
import ui_tapVariant01 from './material/03 Primary System Sounds/ui_tap-variant-01.ogg';
// @ts-ignore
import ui_tapVariant02 from './material/03 Primary System Sounds/ui_tap-variant-02.ogg';
// @ts-ignore
import ui_tapVariant03 from './material/03 Primary System Sounds/ui_tap-variant-03.ogg';
// @ts-ignore
import ui_tapVariant04 from './material/03 Primary System Sounds/ui_tap-variant-04.ogg';
// @ts-ignore
import ui_unlock from './material/03 Primary System Sounds/ui_unlock.ogg';
// @ts-ignore
import alert_error01 from './material/04 Secondary System Sounds/alert_error-01.ogg';
// @ts-ignore
import alert_error02 from './material/04 Secondary System Sounds/alert_error-02.ogg';
// @ts-ignore
import alert_error03 from './material/04 Secondary System Sounds/alert_error-03.ogg';
// @ts-ignore
import navigation_transitionLeft from './material/04 Secondary System Sounds/navigation_transition-left.ogg';
// @ts-ignore
import navigation_transitionRight from './material/04 Secondary System Sounds/navigation_transition-right.ogg';
// @ts-ignore
import navigation_unavailableSelection    from './material/04 Secondary System Sounds/navigation_unavailable-selection.ogg';
// @ts-ignore
import navigationCancel from './material/04 Secondary System Sounds/navigation-cancel.ogg';
// @ts-ignore
import ui_loading from './material/04 Secondary System Sounds/ui_loading.ogg';
// @ts-ignore
import ui_refreshFeed from './material/04 Secondary System Sounds/ui_refresh-feed.ogg';
// @ts-ignore
import move_wood1 from './move-136771__otbtechno__wood-on-wood.mp3';
// @ts-ignore
import move_wood2 from './move-251365__kwahmah-02__dropping-wood-4.wav';
// @ts-ignore
import move_wood3 from './move-411790__inspectorj__door-wooden-close-a-h4n.mp3';
// @ts-ignore
import move_wood4 from './move-547383__diakunik__wood-dropping.mp3';
// @ts-ignore
import move_wood5 from './move-547414__ian-g__wood-hit.wav';
// @ts-ignore




// Howler Documentation: https://github.com/goldfire/howler.js

interface SingleSound {
    name: string,
    src: string,
    volume?: number,
}

type NamedHowl = {
    name: string,
    howl: Howl,
}

interface SoundOptions {
    eventName: string,
    options: SingleSound[],
}

interface GameSound {
    namedHowls: NamedHowl[],
    nextToPlay: number,
}

type GameSounds = Map<string, GameSound>;


let soundOptions: SoundOptions[] = [
    {
        eventName: 'quack',
        options: [{name: 'quack', src: quack, volume: 0.5},],
    }, {
        eventName: 'move',
        options: [
            // {name: 'move_wood1', src: move_wood1,},
            // {name: 'move_wood2', src: move_wood2,},
            // {name: 'move_wood3', src: move_wood3,},
            {name: 'move_wood4', src: move_wood4, volume: .40},
            // {name: 'move_wood5', src: move_wood5,},
        ],
    }, {
        // This sucks but maybe I use it somewhere? The sounds are too long.
        eventName: 'useless_alarm',
        options: [
            {name: 'alarm_gentle', src: alarm_gentle,},
            {name: 'alert_highIntensity', src: alert_highIntensity,},
            {name: 'alert_simple', src: alert_simple,},
        ],
    }, {
        eventName: 'onTick',
        options: [{name: 'ui_unlock', src: ui_unlock,},],
    }, {
        eventName: 'offTick',
        options: [{name: 'ui_lock', src: ui_lock,},],
    }, {
        eventName: 'themeSelect',
        options: [{name: 'themeSelect', src: themeSelect,},],
    }, {
        eventName: 'completion',
        options: [
            {name: 'success2', src: success2,},
        ],
    }, {
        eventName: 'completion-with-earned-hint',
        options: [
            {name: 'success1', src: success1,},
        ],
    }, {
        eventName: 'restart initiated',
        options: [
            {name: 'successful', src: successful,},
        ],
    }, {
        eventName: 'acquire-free-hint',
        options: [{name: 'oneup', src: oneup,},],
    }, {
        eventName: 'allocate-hint',
        options: [{name: 'sparkle', src: sparkle,},],
    }, {
        eventName: 'world-previous',
        options: [
            {name: 'navigation_transitionLeft', src: navigation_transitionLeft,},
        ],
    }, {
        eventName: 'world-next',
        options: [
            {name: 'navigation_transitionRight', src: navigation_transitionRight,},
        ],
    }, {
        eventName: 'puzzle-start',
        options: [{name: 'quack', src: quack, volume: 0.2},],
    }, {
        eventName: 'deadend',
        options: [
            {name: 'gameOver', src: gameOver,},
        ],
    },

];

// let orphanSoundsInit: SingleSound[] = [
//     {name: 'navigation_unavailableSelection', src: navigation_unavailableSelection,},
//     {name: 'stateChangeConfirm', src: stateChangeConfirm},
//     {name: 'ui_cameraShutter', src: ui_cameraShutter,},
//     {name: 'navRight2', src: navRight2,},
//     {name: 'twinkle', src: twinkle,},
//     {name: 'chime1', src: chime1,},
//     {name: 'correct', src: correct,},
//     {name: 'correctDing', src: correctDing,},
//     {name: 'errorSnd', src: errorSnd,},
//     {name: 'heroDecorativeCelebration01', src: heroDecorativeCelebration01,},
//     {name: 'heroDecorativeCelebration02', src: heroDecorativeCelebration02,},
//     {name: 'heroDecorativeCelebration03', src: heroDecorativeCelebration03,},
//     {name: 'heroSimpleCelebration01', src: heroSimpleCelebration01,},
//     {name: 'heroSimpleCelebration02', src: heroSimpleCelebration02,},
//     {name: 'heroSimpleCelebration03', src: heroSimpleCelebration03,},
//     {name: 'notification_ambient', src: notification_ambient,},
//     {name: 'notification_decorative01', src: notification_decorative01,},
//     {name: 'notification_decorative02', src: notification_decorative02,},
//     {name: 'notification_highIntensity', src: notification_highIntensity,},
//     {name: 'notification_simple01', src: notification_simple01,},
//     {name: 'notification_simple02', src: notification_simple02,},
//     {name: 'navigation_backwardSelection', src: navigation_backwardSelection,},
//     {name: 'navigation_backwardSelectionMinimal', src: navigation_backwardSelectionMinimal,},
//     {name: 'navigation_forwardSelection', src: navigation_forwardSelection,},
//     {name: 'navigation_forwardSelectionMinimal', src: navigation_forwardSelectionMinimal,},
//     {name: 'navigation_hoverTap', src: navigation_hoverTap,},
//     {name: 'navigation_selectionCompleteCelebration', src: navigation_selectionCompleteCelebration,},
//     {name: 'stateChange_confirmDown', src: stateChange_confirmDown,},
//     {name: 'stateChange_confirmUp', src: stateChange_confirmUp,},
//     {name: 'ui_tapVariant01', src: ui_tapVariant01,},
//     {name: 'ui_tapVariant02', src: ui_tapVariant02,},
//     {name: 'ui_tapVariant03', src: ui_tapVariant03,},
//     {name: 'ui_tapVariant04', src: ui_tapVariant04,},
//     {name: 'alert_error01', src: alert_error01,},
//     {name: 'alert_error02', src: alert_error02,},
//     {name: 'alert_error03', src: alert_error03,},
//     {name: 'navigationCancel', src: navigationCancel,},
//     {name: 'ui_loading', src: ui_loading,},
//     {name: 'ui_refreshFeed', src: ui_refreshFeed,},
//     {name: 'negativeBeeps', src: negativeBeeps,},
//     {name: 'guitarChord', src: guitarChord,},
// ]

let gameSounds: GameSounds;
let orphanSounds: GameSound;

export function initializeSounds() {
    if (gameSounds || orphanSounds) {
        console.error('initializeSounds seems to be getting run more than once.')
        return;
    }
    gameSounds = new Map<string, GameSound>();
    // In dev we cycle through the options provided.
    if (isDev()) {
        soundOptions.forEach(so => {
            gameSounds.set(so.eventName, {
                namedHowls: so.options.map(op => {
                    return {
                        name: op.name,
                        howl: new Howl({src: [op.src], volume: op.volume}),
                    }
                }),
                nextToPlay: 0,
            })
        });
    }
    // In prod, just the first sound is ever used so we don't bother initializing the others.
    if (isProd()) {
        soundOptions.forEach(so => {
            gameSounds.set(so.eventName, {
                namedHowls: [{
                    name: so.options[0].name,
                    howl: new Howl({src: so.options[0].src, volume: so.options[0].volume}),
                }],
                nextToPlay: 0,
            })
        });
    }

    // orphanSounds = {
    //     nextToPlay: 0,
    //     namedHowls: orphanSoundsInit.map(os => {
    //         return {
    //             name: os.name,
    //             howl: new Howl({src: [os.src], volume: os.volume}),
    //         }
    //     })
    // }
}


// export function cycleOrphanSounds() {
//     const snd = orphanSounds.namedHowls[orphanSounds.nextToPlay];
//     snd.howl.play()
//     orphanSounds.nextToPlay = (orphanSounds.nextToPlay + 1) % orphanSounds.namedHowls.length;
//     console.log(`Just played sound ${snd.name}`);
// }

function playAndIncrement(sound: GameSound) {
    const namedHowl = sound.namedHowls[sound.nextToPlay];
    namedHowl.howl.play();
    // Cycle through the sounds. Of course, in prod we never even load the alts so only the first sound plays.
    sound.nextToPlay = (sound.nextToPlay + 1) % sound.namedHowls.length;
    devLog(`Just played sound ${namedHowl.name}`);
}

export function playSound(eventName: string) {
    const sound = gameSounds.get(eventName);
    if (!sound) {
        console.error(`Couldn't find sound for event ${eventName}`);
        return;
    }
    if (sound.namedHowls.length === 0) {
        console.error(`No sounds allocated for event ${eventName}`);
        return;
    }
    playAndIncrement(sound);
}

// TODO: Need a better sound for this. Use audacity to pitch it to a C, in any case.
let moveSound: SingleSound = {name: 'ui_refreshFeed', src: ui_refreshFeed,};

export function playMoveSound(moveNum: number, totalMoves: number) {
    const rate = 1.0 + (moveNum - 1) / (totalMoves - 1);
    const sound = new Howl({...moveSound, rate: rate, volume: .15});
    sound.play();
}

export function globalMute(soundOn: boolean) {
    Howler.mute(!soundOn);
}