import React from "react";

import '../css/animations.css'
import '../css/niceButton.css'
import Constants from "../constants";
import {devLog} from "../buildModeChecker";

type ButtonShape = 'squarelike' | 'circlelike';

export interface NiceButtonDisplayOptions {
    image?: string,
    component?: React.ReactNode,
}

interface NiceButtonProps {
    name: string,
    shape: ButtonShape,
    display: NiceButtonDisplayOptions,
    action: () => void,
    requiresFullClick: boolean,
    // This is to make the thing temporarily glow for hints and maybe other stuff.
    // It shows a temporary animation.
    highlighted: boolean,
    insetNumber?: number,

    encouraged: boolean,
    visible: boolean,
    active: boolean,
    small: boolean,
    // // Really just so we don't fade when changing worlds.
    // disableFadeAnimation? : boolean,
}

type ButtonActivateEvent = React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement>;

type NiceButtonState = {
    pressed: boolean,
    everPressed: boolean,
}

class NiceButton extends React.Component<NiceButtonProps, NiceButtonState> {
    static defaultProps = {
        shape: "circlelike",
        requiresFullClick: false,
        action: () => {},
        visible: true,
        active: true,
        highlighted: false,
        encouraged: false,
        small: false,
    }

    constructor(props: NiceButtonProps) {
        super(props);
        this.state = {
            pressed: false,
            everPressed: false,
        }
        this.buttonActivate = this.buttonActivate.bind(this);
        this.finishPress = this.finishPress.bind(this);
    }

    private buttonActivate(event: ButtonActivateEvent) {
        // This is redundant, but fine.
        if (!this.props.active) return;
        devLog(`buttonActivate ${this.props.name}`)
        this.setState({pressed: true, everPressed: true},
            () => {
                setImmediate(() => {
                    this.props.action();
                    setTimeout(this.finishPress, Constants.buttonUnpressDelayMs);
                });
            });
    }

    private finishPress() {
        // console.log('done press')
        this.setState({pressed: false});
    }

    buttonClasses = () => {
        const buttonClasses = [
            'nice-button',
            `nice-button--${this.props.shape}`,
            this.props.active ? 'nice-button--active' : 'nice-button--inactive',
            this.props.visible ? 'nice-button--visible' : 'nice-button--hidden',
        ];
        if (this.props.small) buttonClasses.push('nice-button--small');
        if (this.props.highlighted) buttonClasses.push('nice-button--highlighted');
        if (this.props.active && this.props.encouraged) buttonClasses.push('nice-button--encouraged');
        if (this.state.pressed) buttonClasses.push('nice-button--pressed');
        return buttonClasses.join(' ')
    }

    render(): React.ReactNode {
        const niceButtonNumberContainerClasses = [
            'nice-button__number-container',
        ].join(' ');

        return (
            <div key={this.props.name}
                 id={this.props.name + 'Button'}
                 className={this.buttonClasses()}

                 onPointerUp={e => {
                     e.preventDefault();
                     this.finishPress();
                 }}
                 onPointerDown={e => {
                     // onTouchEnd stuff helps in the emulator. https://github.com/facebook/react/issues/9809
                     e.preventDefault();
                     this.buttonActivate(e);
                 }}

                 // onMouseDown={this.buttonActivate}
                 // onMouseUp={e => {
                 //     e.preventDefault();
                 //     this.finishPress();
                 // }}
                 // onTouchStart={e=> {
                 //     // Unable to preventDefault inside passive event listener invocation
                 //     this.buttonActivate(e);
                 // }}
                 // // onTouchMove={e=> e.preventDefault()}
                 // onTouchEnd={e => {
                 //     // onTouchEnd stuff helps in the emulator. https://github.com/facebook/react/issues/9809
                 //     e.preventDefault();
                 //     this.finishPress();
                 // }}
            >

                <div className={'nice-button__inner'}>
                    {this.props.insetNumber !== undefined ?
                        <div className={niceButtonNumberContainerClasses}>
                            {this.props.insetNumber}
                        </div> :
                        <></>}
                    {this.props.display.image ?
                        <img className={'nice-button__img'}
                             src={this.props.display.image}
                             alt={this.props.name}
                        /> :
                        this.props.display.component}
                </div>
            </div>)
    }

    //
    // public everPressed(): boolean {
    //     return this.state.everPressed;
    // }

}

export default NiceButton;