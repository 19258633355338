import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import reportWebVitals, {sendToGoogleAnalytics} from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import './css/index.css';

const rootElement = document.getElementById("root");

// debugger

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    rootElement);
