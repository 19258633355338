import React from "react";
import Puzzle, {PuzzleOrientation, PuzzleOrientationHelper} from "../puzzle/puzzle";
import Location from "../puzzle/location";
import {VisitableThing, VisitableThingInfo} from "../puzzle/movement";
import VisitableRenderer, {ImgRenderTechnique} from "./visitableRenderer";

import '../css/staticPuzzleComponent.css';

interface StaticPuzzleProps {
    puzzle: Puzzle,
    renderMethod: ImgRenderTechnique,
    orientation: PuzzleOrientation,
}

class StaticPuzzleComponent extends React.PureComponent<StaticPuzzleProps> {
    constructor(props: StaticPuzzleProps) {
        super(props);
    }

    render() {
        const viableMoves = this.props.puzzle.startingLocations;
        const {puzzle} = this.props;
        const puzzleLayoutConstants = puzzle.orientedLayoutConstants(
            this.props.orientation || PuzzleOrientation.default);


        const style = {
            "--num-cols": puzzleLayoutConstants.width,
            "--num-rows": puzzleLayoutConstants.height,
        } as React.CSSProperties;

        const squareClasses = (loc: Location) => {
            const visits = puzzle.visitsNeeded(loc);
            let classes = [
                'static-puzzle__grid__square',
            ];

            switch (visits.visitCount) {
                case 0:
                    if (!visits.gold) classes.push('static-puzzle__grid__square--not-in-puzzle');
                    break;
                case 1:
                    classes.push('static-puzzle__grid__square--single-border');
                    break;
                case 2:
                    classes.push('static-puzzle__grid__square--double-border');
                    break;
                case 3:
                    classes.push('static-puzzle__grid__square--triple-border');
                    break;
            }

            if (viableMoves.some(v => v.equals(loc)))
                classes.push('static-puzzle__grid__square--starting-square');

            if (visits.gold) {
                classes.push('static-puzzle__grid__square--gold');
            } else if (puzzle.visitableAtLocation(loc) !== VisitableThing.None) {
                if (VisitableThingInfo.isContraption(puzzle.visitableAtLocation(loc)))
                    classes.push('static-puzzle__grid__square--contraption');
                // classes.push(VisitableThingInfo.classNames(puzzle.visitableAtLocation(loc)));
            } else classes.push('static-puzzle__grid_square--empty');

            return classes.join(' ');
        }

        const image = (loc: Location) => {
            let relevantVisitable = puzzle.visitableAtLocation(loc);
            if (relevantVisitable === VisitableThing.None) return <></>;
            const imgClasses = [
                'static-puzzle-spot__img',
                // These don't get used in CSS because they're not important enough since the imgs are miniscule.
                ...VisitableThingInfo.additionalImageClassSuffixes(relevantVisitable)
                    .map(suffix => `static-puzzle-spot__img--${suffix}`),
            ];
            return <img src={VisitableThingInfo.imageSrc(relevantVisitable)}
                        className={imgClasses.join(' ')}/>

        }

        return <div className={'static-puzzle'} style={style}>
            <div className="static-puzzle__grid">
                {puzzleLayoutConstants.locationList.map((loc, i) => {
                    return <div className={squareClasses(loc)} key={loc.toString()}>
                        {/*{image(loc)}*/}
                        <VisitableRenderer
                            renderMethod={this.props.renderMethod}
                            defaultClassName={'static-puzzle-spot__inner'}
                            visitable={puzzle.visitableAtLocation(loc)}/>
                    </div>
                })}
            </div>

        </div>
    }

}

export default StaticPuzzleComponent;