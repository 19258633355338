import React, {PureComponent} from 'react';
import Constants from "../constants";
import Location from "../puzzle/location";
import Utility, {GridLayoutConstants} from "../utility";
import {Move, MoveSequence} from "../puzzle/move";
import {VisitableThingInfo} from "../puzzle/movement";
import '../css/path.css';

interface SolutionPathProps {
    showSolutionPath: boolean,
    layoutConstants: GridLayoutConstants,
    canonicalSolutionMoves?: MoveSequence,
    orientationSwapped: boolean,
}

interface SolutionPathState {
}


class SolutionPath extends PureComponent<SolutionPathProps, SolutionPathState> {
    constructor(props: SolutionPathProps) {
        super(props);
    }

    locToPixels(loc: Location) {
        return Utility.findSquareCentre(
            this.props.layoutConstants,
            this.props.orientationSwapped,
            loc);
    }

    pathClasses(move: Move) {
        let ret = ['solution-path__move'];
        if (move.oldMovementType) {
            ret.push('solution-path__move--' + VisitableThingInfo.cssName(move.oldMovementType));
        }
        return ret.join(' ');
    }

    render() {
        if (!this.props.showSolutionPath || !this.props.canonicalSolutionMoves) return <></>;

        const locX = (loc: Location) => this.locToPixels(loc).x;
        const locY = (loc: Location) => this.locToPixels(loc).y;

        return (
            <svg className={`solution-path`} key={'PathDrawing'}>
                {this.props.canonicalSolutionMoves.map(
                    (move, i) => {
                        if (!move.oldLocation) return <circle
                            className={"solution-path__origin"}
                            cx={locX(move.newLocation)}
                            cy={locY(move.newLocation)}
                            r={this.props.layoutConstants.squareSizePx * Constants.pathStartingCircleRadiusProportionOfSquareSize}
                            key={`${move.newLocation.toString()}`}/>
                        else return <g key={i}>
                            <line className={this.pathClasses(move)}
                                  key={`${move.oldLocation.toString()}/${move.newLocation.toString()}`}
                                  x1={locX(move.oldLocation)}
                                  y1={locY(move.oldLocation)}
                                  x2={locX(move.newLocation)}
                                  y2={locY(move.newLocation)}
                            />
                            {move.contraptionVisits.map(v =>
                                <circle className={'solution-path__contraption-visit'}
                                        key={v.toString()}
                                        cx={this.locToPixels(v).x}
                                        cy={this.locToPixels(v).y}
                                        r={this.props.layoutConstants.squareSizePx * Constants.pathContraptionVisitRadiusProportionOfSquareSize}
                                />)}
                        </g>
                    })}
            </svg>
        );
    }
}

export default SolutionPath;