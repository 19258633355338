import Puzzle from "./puzzle";
import Offset, {Directions} from "./offset";
import VisitCounter from "./visitCounter";
import Location from "./location";
import ActivePuzzle from "./activePuzzle";
/* The good easy svg editor: https://mediamodifier.com/design# */
import manhattanSvg from "../img/manhattan-mover-1.svg";
import manhattanJumpSvg from "../img/jumper.svg";
import manhattanDragSvg from "../img/bike-1.svg";
import manhattanSkipSvg from "../img/kangaroo-1.svg";
import manhattanSkateSvg from "../img/skate-5.svg";
import manhattanBulletSvg from '../img/bullet-manhattan-1-flipped.svg';

import diagonalSvg from "../img/diagonal-mover-1.svg";
import diagonalJumpSvg from "../img/hang-glider.svg";
import diagonalDragSvg from "../img/kayak.svg";
import diagonalSkipSvg from "../img/pogo.svg";
import diagonalSkateSvg from "../img/skate-1.svg";
import diagonalBulletSvg from '../img/bullet-diagonal-1.svg';

import liegeSvg from "../img/crown-king-1.svg";
import liegeSkateSvg from "../img/luge.svg";
import liegeBulletSvg from "../img/crown-queen-1.svg";

import cannonSvg from '../img/cannon-1.svg';
import cannon2Svg from '../img/cannon-3.svg';

import knightSvg from "../img/knight-2.svg";
import legitHorseSvg from "../img/horse-oracle-rotated.svg";
import chineseHorseSvg from "../img/horse-realistic-1.svg";
import chineseDiagonalHorsePng from "../img/horse-realistic-3.svg";
import chineseOptionalHorseSvg from "../img/horse-realistic-4.png";
import chineseOptionalDiagonalHorsePng from "../img/horse-realistic-2.svg";

import rWyePng from "../img/why-r.png";
import tWyePng from "../img/why-t.png";
import yWyePng from "../img/why-y.png";
import hWyePng from "../img/why-h.png";
import nWyePng from "../img/why-n.png";
import bWyePng from "../img/why-b.png";
import vWyePng from "../img/why-v.png";
import fWyePng from "../img/why-f.png";

import bombDiagonalSvg from "../img/bomb-diagonal-3.svg"
import bombAllDirectionsSvg from "../img/bomb-alldirections.svg"
import bombVerticalSvg from "../img/bomb-vertical-2.svg"
import bombHorizontalSvg from "../img/bomb-manhattan-2.svg"
import bombManhattanSvg from "../img/bomb-manhattan-1.svg"


export type {ImmediateEffect}
export {
    MoveEffect,
    VisitableThing,
    VisitableThingInfo,
}

type MoverFn = (ap: ActivePuzzle) => MoveEffect[];

enum VisitableThing {
    // Movement Types
    None,

    Manhattan,
    ManhattanJump,
    ManhattanDrag,
    ManhattanSkip,
    ManhattanSkate,
    ManhattanBullet,

    Diagonal,
    DiagonalJump,
    DiagonalDrag,
    DiagonalSkip,
    DiagonalSkate,
    DiagonalBullet,

    Liege,
    LiegeSkate,
    LiegeBullet,

    Cannon,
    FreeCannon,

    Knight,
    LegitHorse,
    ChineseHorse,
    ChineseDiagonalHorse,
    ChineseOptionalHorse,
    ChineseOptionalDiagonalHorse,

    RWye,
    TWye,
    YWye,
    HWye,
    NWye,
    BWye,
    VWye,
    FWye,

    // Contraptions
    BombQueen,
    BombManhattan,
    BombHorizontal,
    BombVertical,
    BombDiagonal,
}

class MoveEffect {
    constructor(
        public destination: Location,
        public requiredSideEffectVisits: Location[],
        public optionalSideEffects: Location[]) {
    }

}

class MoveEffectScheme {
    // The side effects can be blank, which is common.
    constructor(private destinationOffset: Offset,
                private requiredSideEffects: Offset[] = [],
                private optionalSideEffects: Offset[] = []) {
    }

    public moveEffectFromLocation(loc: Location) {
        return new MoveEffect(
            loc.move(this.destinationOffset),
            this.requiredSideEffects.map(offset => loc.move(offset)),
            this.optionalSideEffects.map(offset => loc.move(offset)));
    }
}

function effectSchemesToMover(moveEffectSchemes: MoveEffectScheme[]): MoverFn {
    return (ap: ActivePuzzle) => {
        const currentLocation = ap.currentLocation()!;
        return moveEffectSchemes.map(es => es.moveEffectFromLocation(currentLocation));
    }
}


interface VisitableThingInfooo {
    fullName: string;
    keyCode: string;
    cssName: string;
    // isMovementType: boolean;
    moveMaker?: MoverFn;
    svgUrl?: string,
    additionalImageClassSuffixes?: string[],
    rotatedVersion?: VisitableThing;
    transposeVersion?: VisitableThing;
    hFlippedVersion?: VisitableThing;
    vFlippedVersion?: VisitableThing;
    immediateEffect?: ImmediateEffect;
}

type ImmediateEffect = (location: Location, puzzle: Puzzle, visitCounter: VisitCounter) => Location[];
let blankImmediateEffect: ImmediateEffect = (l, p, v) => [];

function bombEffect(directions: Offset[]): ImmediateEffect {
    return (startLoc: Location, puzzle: Puzzle, visitCounter: VisitCounter) => {
        let locs: Location[] = [];
        directions.forEach(dir => {
            let curr = startLoc.move(dir);
            // Accumulate all moves in the direction until we find somewhere we can't visit.
            while (puzzle.visitsNeeded(curr).gold || (visitCounter.getVisitCount(curr) < puzzle.visitsNeeded(curr).visitCount)) {
                locs.push(curr);
                curr = curr.move(dir);
            }
        })
        return locs;
    }
}

function jumpToWallOrNewMovementType(directions: Offset[], requireIntermediates: boolean): (ap: ActivePuzzle) => MoveEffect[] {
    return (ap: ActivePuzzle) => {
        const currentLocation = ap.currentLocation();
        if (!currentLocation) return [];
        const ret: MoveEffect[] = [];
        // let dirs = directions.filter(o => ap.canBeVisited(currentLocation.move(o)));
        directions.forEach(dir => {
            let latest = currentLocation.move(dir);
            if (!ap.canBeVisited(latest)) return;
            let next = latest.move(dir);
            const intermediates: Location[] = [];
            // We stop when we hit a wall or a new movement type.
            while (ap.canBeVisited(next)) {
                if (VisitableThingInfo.isMovementType(ap.puzzle.visitableAtLocation(latest))) break;
                if (requireIntermediates) intermediates.push(latest);
                latest = next;
                next = next.move(dir);
            }
            ret.push(new MoveEffect(latest, intermediates, []));
        })
        return ret;
    }
}

//
// function jumpToWallOrNewMovementType(directions: Offset[]): (ap: ActivePuzzle) => MoveEffect[] {
//     return (ap: ActivePuzzle) => {
//         const currentLocation = ap.currentLocation();
//         if (!currentLocation) return [];
//         const locs: Location[] = [];
//
//         directions.forEach(dir => {
//             let next = currentLocation.move(dir);
//             if (!ap.canBeVisited(next)) return;
//             while (ap.canBeVisited(next.move(dir)) && !VisitableThingInfo.isMovementType(ap.puzzle.visitableAtLocation(next))) {
//                 next = next.move(dir);
//             }
//             locs.push(next);
//         })
//
//         return locs.map(o => new MoveEffect(o, [], []));
//     };
// }

let visitableThingInfo = new Map<VisitableThing, VisitableThingInfooo>(
    [
        [VisitableThing.None,
            {
                fullName: "None",
                keyCode: "?",
                cssName: "mt-none",
            }],
        [VisitableThing.Manhattan,
            {
                fullName: "Manhattan",
                keyCode: "m",
                cssName: "mt-manhattan",
                svgUrl: manhattanSvg,
                additionalImageClassSuffixes: ['slightly-smaller', 'smaller-in-circle'],
                moveMaker: effectSchemesToMover
                (Directions.manhattans.map(offset => new MoveEffectScheme(offset))),
            }],
        [VisitableThing.ManhattanJump,
            {
                fullName: "Manhattan Jump",
                keyCode: "u",
                cssName: "mt-manhattan-jump",
                svgUrl: manhattanJumpSvg,
                moveMaker: effectSchemesToMover(Directions.manhattans.map(
                    offset => new MoveEffectScheme(offset.doubled()))),
            }],
        [VisitableThing.ManhattanDrag,
            {
                fullName: "ManhattanDrag",
                keyCode: "j",
                cssName: "mt-manhattan-drag",
                svgUrl: manhattanDragSvg,
                moveMaker: effectSchemesToMover(Directions.manhattans.map(
                    offset => new MoveEffectScheme(offset.doubled(), [offset]))),
            }],
        [VisitableThing.ManhattanSkip,
            {
                fullName: "ManhattanSkip",
                keyCode: "J",
                cssName: "mt-manhattan-skip",
                svgUrl: manhattanSkipSvg,
                additionalImageClassSuffixes: ['smaller-in-circle'],
                moveMaker: effectSchemesToMover(Directions.manhattans.map(
                    offset => new MoveEffectScheme(offset.doubled(), [], [offset]))),
            }],
        [VisitableThing.ManhattanSkate,
            {
                fullName: "ManhattanSkate",
                keyCode: "S",
                cssName: "mt-manhattan-skate",
                svgUrl: manhattanSkateSvg,
                additionalImageClassSuffixes: ['slightly-smaller', 'smaller-in-circle'],
                moveMaker: jumpToWallOrNewMovementType(Directions.manhattans, true),
            }],
        [VisitableThing.ManhattanBullet,
            {
                fullName: "ManhattanBullet",
                keyCode: "X",
                cssName: "mt-manhattan-bullet",
                svgUrl: manhattanBulletSvg,
                additionalImageClassSuffixes: ['smaller-in-circle'],
                moveMaker: jumpToWallOrNewMovementType(Directions.manhattans, false),
            }],
        [VisitableThing.Diagonal,
            {
                fullName: "Diagonal",
                keyCode: "d",
                cssName: "mt-diagonal",
                svgUrl: diagonalSvg,
                additionalImageClassSuffixes: ['slightly-smaller', 'smaller-in-circle'],

                moveMaker: effectSchemesToMover(Directions.diagonals.map(
                    offset => new MoveEffectScheme(offset))),
            }],
        [VisitableThing.DiagonalJump,
            {
                fullName: "DiagonalJump",
                keyCode: "i",
                cssName: "mt-diagonal-jump",
                svgUrl: diagonalJumpSvg,
                moveMaker: effectSchemesToMover(Directions.diagonals.map(
                    offset => new MoveEffectScheme(offset.doubled()))),
            }],
        [VisitableThing.DiagonalDrag,
            {
                fullName: "Diagonal Drag",
                keyCode: "k",
                cssName: "mt-diagonal-drag",
                svgUrl: diagonalDragSvg,
                moveMaker: effectSchemesToMover(Directions.diagonals.map(
                    offset => new MoveEffectScheme(offset.doubled(), [offset]))),
            }],
        [VisitableThing.DiagonalSkip,
            {
                fullName: "Diagonal Skip",
                keyCode: "K",
                cssName: "mt-diagonal-skip",
                svgUrl: diagonalSkipSvg,
                moveMaker: effectSchemesToMover(Directions.diagonals.map(
                    offset => new MoveEffectScheme(offset.doubled(), [], [offset]))),
            }],
        [VisitableThing.DiagonalSkate,
            {
                fullName: "DiagonalSkate",
                keyCode: "D",
                cssName: "mt-diagonal-skate",
                svgUrl: diagonalSkateSvg,
                moveMaker: jumpToWallOrNewMovementType(Directions.diagonals, true),
            }],
        [VisitableThing.DiagonalBullet,
            {
                fullName: "DiagonalBullet",
                keyCode: "C",
                cssName: "mt-diagonal-bullet",
                svgUrl: diagonalBulletSvg,
                moveMaker: jumpToWallOrNewMovementType(Directions.diagonals, false),
            }],
        [VisitableThing.Liege,
            {
                fullName: "Liege",
                keyCode: "l",
                cssName: "mt-liege",
                svgUrl: liegeSvg,
                additionalImageClassSuffixes: ['slightly-smaller', 'smaller-in-circle'],
                moveMaker: effectSchemesToMover(Directions.neighbourhood8.map(offset => new MoveEffectScheme(offset))),
            }],
        [VisitableThing.LiegeSkate,
            {
                fullName: "LiegeSkate",
                keyCode: "L",
                cssName: "mt-liege-skate",
                svgUrl: liegeSkateSvg,
                moveMaker: jumpToWallOrNewMovementType(Directions.neighbourhood8, true),
            }],
        [VisitableThing.LiegeBullet,
            {
                fullName: "LiegeBullet",
                keyCode: "Z",
                cssName: "mt-liege-bullet",
                svgUrl: liegeBulletSvg,
                moveMaker: jumpToWallOrNewMovementType(Directions.neighbourhood8, false),
            }],
        [VisitableThing.FreeCannon,
            {
                fullName: "FreeCannon",
                keyCode: "x",
                cssName: "mt-free-cannon",
                svgUrl: cannon2Svg,
                moveMaker: (ap: ActivePuzzle) => {
                    const currentLocation = ap.currentLocation();
                    if (!currentLocation) return [];
                    let locs: Location[] = [];

                    const nextUnvisitable = (loc: Location, dir: Offset) => {
                        let next = loc.move(dir);
                        while (ap.canBeVisited(next)) next = next.move(dir);
                        return next;
                    }
                    const nextVisitable = (loc: Location, dir: Offset) => {
                        let next = loc.move(dir);
                        while (!ap.canBeVisited(next) && ap.puzzle.isOnBoard(next)) next = next.move(dir);
                        return next;
                    }

                    Directions.manhattans.forEach(dir => {
                        // Find somewhere we can't move.
                        const blocker = nextUnvisitable(currentLocation, dir);
                        // Find the next place we can move.
                        const destination = nextVisitable(blocker, dir);
                        if (destination) locs.push(destination);
                    })

                    return locs.map(o => new MoveEffect(o, [], []));
                },
            }],
        [VisitableThing.Cannon,
            {
                fullName: "Cannon",
                keyCode: "c",
                cssName: "mt-cannon",
                svgUrl: cannonSvg,
                moveMaker: (ap: ActivePuzzle) => {
                    const currentLocation = ap.currentLocation();
                    if (!currentLocation) return [];
                    let locs: Location[] = [];

                    const nextUnvisitable = (loc: Location, dir: Offset) => {
                        let next = loc.move(dir);
                        while (ap.canBeVisited(next)) next = next.move(dir);
                        return next;
                    }
                    const nextVisitable = (loc: Location, dir: Offset) => {
                        let next = loc.move(dir);
                        while (!ap.canBeVisited(next) && ap.puzzle.isOnBoard(next)) next = next.move(dir);
                        return next;
                    }

                    Directions.manhattans.forEach(dir => {
                        // If our neighbour isn't visitable...
                        const blocker = currentLocation.move(dir);
                        if (ap.canBeVisited(blocker)) return;
                        // Find the next place we can move.
                        const destination = nextVisitable(blocker, dir);
                        if (destination) locs.push(destination);
                    })

                    return locs.map(o => new MoveEffect(o, [], []));
                },
            }],
        [VisitableThing.Knight,
            {
                fullName: "Knight",
                keyCode: "p",
                cssName: "mt-knight",
                svgUrl: knightSvg,
                moveMaker: effectSchemesToMover(Directions.knightMoves.map(
                    offset => new MoveEffectScheme(offset))),
            }],
        [VisitableThing.LegitHorse,
            {
                fullName: "LegitHorse",
                keyCode: "M",
                cssName: "mt-legit-horse",
                svgUrl: legitHorseSvg,
                moveMaker: (ap: ActivePuzzle) => {
                    const currentLocation = ap.currentLocation();
                    if (!currentLocation) return [];

                    let locs = [];
                    if (ap.canBeVisited(currentLocation.move(Directions.up))) locs.push(Directions.knightUpLeft, Directions.knightUpRight);
                    if (ap.canBeVisited(currentLocation.move(Directions.right))) locs.push(Directions.knightRightUp, Directions.knightRightDown);
                    if (ap.canBeVisited(currentLocation.move(Directions.down))) locs.push(Directions.knightDownLeft, Directions.knightDownRight);
                    if (ap.canBeVisited(currentLocation.move(Directions.left))) locs.push(Directions.knightLeftUp, Directions.knightLeftDown);

                    return locs.map(o =>
                        new MoveEffect(currentLocation.move(o), [], []));
                },
            }],
        [VisitableThing.ChineseHorse,
            {
                fullName: "ChineseHorse",
                keyCode: "H",
                cssName: "mt-chinese-horse",
                svgUrl: chineseHorseSvg,
                moveMaker: effectSchemesToMover([
                    new MoveEffectScheme(Directions.up.add(Directions.upright), [Directions.up]),
                    new MoveEffectScheme(Directions.up.add(Directions.upleft), [Directions.up]),
                    new MoveEffectScheme(Directions.down.add(Directions.downright), [Directions.down]),
                    new MoveEffectScheme(Directions.down.add(Directions.downleft), [Directions.down]),
                    new MoveEffectScheme(Directions.left.add(Directions.downleft), [Directions.left]),
                    new MoveEffectScheme(Directions.left.add(Directions.upleft), [Directions.left]),
                    new MoveEffectScheme(Directions.right.add(Directions.downright), [Directions.right]),
                    new MoveEffectScheme(Directions.right.add(Directions.upright), [Directions.right]),
                ]),
            }],
        [VisitableThing.ChineseDiagonalHorse,
            {
                fullName: "ChineseDiagonalHorse",
                keyCode: "Y",
                cssName: "mt-chinese-diagonal-horse",
                svgUrl: chineseDiagonalHorsePng,
                moveMaker: effectSchemesToMover([
                    new MoveEffectScheme(Directions.upright.add(Directions.up), [Directions.upright]),
                    new MoveEffectScheme(Directions.upright.add(Directions.right), [Directions.upright]),
                    new MoveEffectScheme(Directions.downright.add(Directions.down), [Directions.downright]),
                    new MoveEffectScheme(Directions.downright.add(Directions.right), [Directions.downright]),
                    new MoveEffectScheme(Directions.upleft.add(Directions.up), [Directions.upleft]),
                    new MoveEffectScheme(Directions.upleft.add(Directions.left), [Directions.upleft]),
                    new MoveEffectScheme(Directions.downleft.add(Directions.down), [Directions.downleft]),
                    new MoveEffectScheme(Directions.downleft.add(Directions.left), [Directions.downleft]),
                ]),
            }],
        [VisitableThing.ChineseOptionalHorse,
            {
                fullName: "ChineseOptionalHorse",
                keyCode: "G",
                cssName: "mt-chinese-optional-horse",
                svgUrl: chineseOptionalHorseSvg,
                moveMaker: effectSchemesToMover([
                    new MoveEffectScheme(Directions.up.add(Directions.upright), [], [Directions.up]),
                    new MoveEffectScheme(Directions.up.add(Directions.upleft), [], [Directions.up]),
                    new MoveEffectScheme(Directions.down.add(Directions.downright), [], [Directions.down]),
                    new MoveEffectScheme(Directions.down.add(Directions.downleft), [], [Directions.down]),
                    new MoveEffectScheme(Directions.left.add(Directions.downleft), [], [Directions.left]),
                    new MoveEffectScheme(Directions.left.add(Directions.upleft), [], [Directions.left]),
                    new MoveEffectScheme(Directions.right.add(Directions.downright), [], [Directions.right]),
                    new MoveEffectScheme(Directions.right.add(Directions.upright), [], [Directions.right]),
                ]),
            }],
        [VisitableThing.ChineseOptionalDiagonalHorse,
            {
                fullName: "ChineseOptionalDiagonalHorse",
                keyCode: "T",
                cssName: "mt-chinese-optional-diagonal-horse",
                svgUrl: chineseOptionalDiagonalHorsePng,
                moveMaker: effectSchemesToMover([
                    new MoveEffectScheme(Directions.upright.add(Directions.up), [], [Directions.upright]),
                    new MoveEffectScheme(Directions.upright.add(Directions.right), [], [Directions.upright]),
                    new MoveEffectScheme(Directions.downright.add(Directions.down), [], [Directions.downright]),
                    new MoveEffectScheme(Directions.downright.add(Directions.right), [], [Directions.downright]),
                    new MoveEffectScheme(Directions.upleft.add(Directions.up), [], [Directions.upleft]),
                    new MoveEffectScheme(Directions.upleft.add(Directions.left), [], [Directions.upleft]),
                    new MoveEffectScheme(Directions.downleft.add(Directions.down), [], [Directions.downleft]),
                    new MoveEffectScheme(Directions.downleft.add(Directions.left), [], [Directions.downleft]),
                ]),
            }],
        [VisitableThing.RWye,
            {
                fullName: "RWye",
                keyCode: "r",
                cssName: "mt-r-wye",
                svgUrl: rWyePng,
                moveMaker: effectSchemesToMover([Directions.upleft, Directions.down, Directions.right].map(
                    offset => new MoveEffectScheme(offset))),
                rotatedVersion: VisitableThing.YWye,
                transposeVersion: VisitableThing.RWye,
                hFlippedVersion: VisitableThing.YWye,
                vFlippedVersion: VisitableThing.VWye,
            }],
        [VisitableThing.TWye,
            {
                fullName: "TWye",
                keyCode: "t",
                cssName: "mt-t-wye",
                svgUrl: tWyePng,
                moveMaker: effectSchemesToMover([Directions.up, Directions.downleft, Directions.downright].map(
                    offset => new MoveEffectScheme(offset))),
                rotatedVersion: VisitableThing.HWye,
                transposeVersion: VisitableThing.FWye,
                hFlippedVersion: VisitableThing.TWye,
                vFlippedVersion: VisitableThing.BWye,
            }],
        [VisitableThing.YWye,
            {
                fullName: "YWye",
                keyCode: "y",
                cssName: "mt-y-wye",
                svgUrl: yWyePng,
                moveMaker: effectSchemesToMover([Directions.upright, Directions.left, Directions.down].map(
                    offset => new MoveEffectScheme(offset))),
                rotatedVersion: VisitableThing.NWye,
                transposeVersion: VisitableThing.VWye,
                hFlippedVersion: VisitableThing.RWye,
                vFlippedVersion: VisitableThing.NWye,
            }],
        [VisitableThing.HWye,
            {
                fullName: "HWye",
                keyCode: "h",
                cssName: "mt-h-wye",
                svgUrl: hWyePng,
                moveMaker: effectSchemesToMover([Directions.right, Directions.upleft, Directions.downleft].map(
                    offset => new MoveEffectScheme(offset))),
                rotatedVersion: VisitableThing.BWye,
                transposeVersion: VisitableThing.BWye,
                hFlippedVersion: VisitableThing.FWye,
                vFlippedVersion: VisitableThing.HWye,
            }],
        [VisitableThing.NWye,
            {
                fullName: "NWye",
                keyCode: "n",
                cssName: "mt-n-wye",
                svgUrl: nWyePng,
                moveMaker: effectSchemesToMover([Directions.downright, Directions.up, Directions.left].map(
                    offset => new MoveEffectScheme(offset))),
                rotatedVersion: VisitableThing.VWye,
                transposeVersion: VisitableThing.NWye,
                hFlippedVersion: VisitableThing.VWye,
                vFlippedVersion: VisitableThing.YWye,
            }],
        [VisitableThing.BWye,
            {
                fullName: "BWye",
                keyCode: "b",
                cssName: "mt-b-wye",
                svgUrl: bWyePng,
                moveMaker: effectSchemesToMover([Directions.down, Directions.upleft, Directions.upright].map(
                    offset => new MoveEffectScheme(offset))),
                rotatedVersion: VisitableThing.FWye,
                transposeVersion: VisitableThing.HWye,
                hFlippedVersion: VisitableThing.BWye,
                vFlippedVersion: VisitableThing.TWye,
            }],
        [VisitableThing.VWye,
            {
                fullName: "VWye",
                keyCode: "v",
                cssName: "mt-v-wye",
                svgUrl: vWyePng,
                moveMaker: effectSchemesToMover([Directions.downleft, Directions.up, Directions.right].map(
                    offset => new MoveEffectScheme(offset))),
                rotatedVersion: VisitableThing.RWye,
                transposeVersion: VisitableThing.YWye,
                hFlippedVersion: VisitableThing.NWye,
                vFlippedVersion: VisitableThing.RWye,
            }],
        [VisitableThing.FWye,
            {
                fullName: "FWye",
                keyCode: "f",
                cssName: "mt-f-wye",
                svgUrl: fWyePng,
                moveMaker: effectSchemesToMover([Directions.left, Directions.upright, Directions.downright].map(
                    offset => new MoveEffectScheme(offset))),
                rotatedVersion: VisitableThing.TWye,
                transposeVersion: VisitableThing.TWye,
                hFlippedVersion: VisitableThing.HWye,
                vFlippedVersion: VisitableThing.FWye,
            }],
        [VisitableThing.BombQueen,
            {
                fullName: "QueenBomb",
                keyCode: "q",
                cssName: "contraption-queen-bomb",
                svgUrl: bombAllDirectionsSvg,
                immediateEffect: bombEffect(Directions.neighbourhood8),
            }],
        [VisitableThing.BombManhattan,
            {
                fullName: "BombManhattan",
                keyCode: "e",
                cssName: "contraption-manhattan-bomb",
                svgUrl: bombManhattanSvg,
                additionalImageClassSuffixes: ['slightly-smaller'],
                immediateEffect: bombEffect(Directions.manhattans),
            }],
        [VisitableThing.BombHorizontal,
            {
                fullName: "BombHorizontal",
                keyCode: "s",
                cssName: "contraption-horizontal-bomb",
                svgUrl: bombHorizontalSvg,
                rotatedVersion: VisitableThing.BombVertical,
                immediateEffect: bombEffect([Directions.left, Directions.right]),
            }],
        [VisitableThing.BombVertical,
            {
                fullName: "BombVertical",
                keyCode: "a",
                cssName: "contraption-vertical-bomb",
                svgUrl: bombVerticalSvg,
                rotatedVersion: VisitableThing.BombHorizontal,
                immediateEffect: bombEffect([Directions.up, Directions.down]),
            }],
        [VisitableThing.BombDiagonal,
            {
                fullName: "BombDiagonal",
                keyCode: "w",
                cssName: "contraption-diagonal-bomb",
                additionalImageClassSuffixes: ['slightly-smaller', 'smaller-in-circle'],
                svgUrl: bombDiagonalSvg,
                immediateEffect: bombEffect(Directions.diagonals),
            }],
    ]
)

class VisitableThingInfo {
    static isMovementType(visitableThingType: VisitableThing) {
        let info = visitableThingInfo.get(visitableThingType);
        if (info) return !!info.moveMaker;
        console.error("How did you break isMovementType, moron?")
        return false;
    }

    static keyCode(visitableThingType: VisitableThing) {
        let info = visitableThingInfo.get(visitableThingType);
        if (info) return info.keyCode;
        console.error("How did you break keyCode, moron?")
        return "?";
    }

    static fullName(visitableThingType: VisitableThing) {
        let info = visitableThingInfo.get(visitableThingType);
        if (info) return info.fullName;
        console.error("How did you break fullName, moron?")
        return "?";
    }

    static moveEffects(currentMovementType: VisitableThing, ap: ActivePuzzle): MoveEffect[] {
        let info = visitableThingInfo.get(currentMovementType);
        if (!info) return [];
        // New, cooler way to generate moves.
        if (info.moveMaker !== undefined) return info.moveMaker(ap);
        return [];
    }

    static imageSrc(visitableThingType: VisitableThing): string | undefined {
        let info = visitableThingInfo.get(visitableThingType);
        return info?.svgUrl;
    }

    static additionalImageClassSuffixes(visitableThingType: VisitableThing): string[] {
        let info = visitableThingInfo.get(visitableThingType);
        return info?.additionalImageClassSuffixes || [];
    }

    static cssName(visitableThingType: VisitableThing): string {
        let info = visitableThingInfo.get(visitableThingType);
        if (info && info.cssName) return info.cssName;
        console.error("How did you break cssName, moron?")
        return "?";
    }

    static immediateEffect(visitableThingType: VisitableThing) {
        let info = visitableThingInfo.get(visitableThingType);
        if (info && info.immediateEffect) return info.immediateEffect;
    }

    static rotatedVersion(visitableThingType: VisitableThing) {
        let info = visitableThingInfo.get(visitableThingType);
        if (info && info.rotatedVersion) return info.rotatedVersion;
        else return visitableThingType;
    }

    static transposeVersion(visitableThingType: VisitableThing) {
        let info = visitableThingInfo.get(visitableThingType);
        if (info && info.transposeVersion) return info.transposeVersion;
        else return visitableThingType;
    }

    static hFlippedVersion(visitableThingType: VisitableThing) {
        let info = visitableThingInfo.get(visitableThingType);
        if (info && info.hFlippedVersion) return info.hFlippedVersion;
        else return visitableThingType;
    }

    static vFlippedVersion(visitableThingType: VisitableThing) {
        let info = visitableThingInfo.get(visitableThingType);
        if (info && info.vFlippedVersion) return info.vFlippedVersion;
        else return visitableThingType;
    }

    static isStartableThing(thingType: VisitableThing): boolean {
        let info = visitableThingInfo.get(thingType);
        return info !== undefined && VisitableThingInfo.isMovementType(thingType);
    }

    static isContraption(thingType: VisitableThing): boolean {
        let info = visitableThingInfo.get(thingType);
        return info !== undefined && !!info.immediateEffect;
    }

    static parseVisitableThing(str: String): VisitableThing {
        // Return the first visitable thing code we encounter.
        let result = VisitableThing.None;
        // This is awkward and bad but only runs when loading puzzles.
        for (let i = 0; i < str.length; i++) {
            let char = str[i];
            visitableThingInfo.forEach((info, movement) => {
                if (info.keyCode === char) result = movement;
            });
        }
        return result;
    }
}

