// goldworld generated by WorldBuilder
import Puzzle from "../puzzle/puzzle";
import {World} from "./world";
import Location from "../puzzle/location";

export let goldworld = new World("goldworld", [
//
// m 6x2 unique
// Path: m:6  Entropy: 3.18
// Golds touched 0 0 0 0
    Puzzle.fromString(`
0m
01
gg
gg
10
10
`).setCanonicalSolutionWithWitness(JSON.parse('["0,1","1,1","2,1","2,0","3,0","4,0","5,0"]').map(Location.fromString)),
//
// d 5x5 unique
// Path: d:16  Entropy: 7.34
// Golds touched 0 0 0 0
    Puzzle.fromString(`
// dave 2017-05-28 training puzzle
10101
0g0g0
10d01
0g0g0
10101
`).setCanonicalSolutionWithWitness(JSON.parse('["2,2","3,3","4,4","3,3","2,4","3,3","4,2","3,1","4,0","3,1","2,0","1,1","0,0","1,1","0,2","1,3","0,4"]').map(Location.fromString)),
//
// m 6x5 unique
// Path: m:43  Entropy: 37.0
// Golds touched 0 0 0 0 0 0
    Puzzle.fromString(`
// dave 2017-05-28 training puzzle
00110
01ggm
01g10
01g10
1gg10
01100
`).setCanonicalSolutionWithWitness(JSON.parse('["1,4","1,3","1,2","1,3","1,2","1,3","1,2","1,1","1,2","1,3","1,2","1,3","1,2","0,2","0,3","1,3","1,2","1,3","1,2","1,3","2,3","2,2","2,1","2,2","3,2","3,3","3,2","3,1","3,2","4,2","4,3","4,2","4,1","4,2","4,1","4,2","5,2","5,1","4,1","4,2","4,1","4,2","4,1","4,0"]').map(Location.fromString)),
//
// Jd 5x5 unique
// Path: d:7 J:8  Entropy: 11.6
// Golds touched 0
    Puzzle.fromString(`
//
// PUZZLES
//
// danny 2017-06-02
11100
1d110
11g11
01111
J1101
`).setCanonicalSolutionWithWitness(JSON.parse('["1,1","2,2","3,3","2,2","1,3","2,2","3,1","4,0","4,2","2,2","2,0","0,0","0,2","2,2","2,4","4,4"]').map(Location.fromString)),
//
// mmdd 6x6 unique
// Path: d:8 m:1 d:11 m:4  Entropy: 8.84
// Golds touched 0
    Puzzle.fromString(`
// laura 2017-06-04
d10m11
111011
011101
01g100
m11110
d00100
`).toggleEnabled().setCanonicalSolutionWithWitness(JSON.parse('["0,0","1,1","2,2","3,3","4,4","5,3","4,2","3,1","4,0","5,0","4,1","3,2","4,3","3,2","2,1","1,0","0,1","1,2","2,3","1,4","0,3","0,4","0,5","1,5","2,5"]').map(Location.fromString)),
//
// md 4x4 unique
// Path: m:15 d:0  Entropy: 5.95
// Doesn't use end movementType
// Golds touched 0
    Puzzle.fromString(`
d11m
1101
111g
1111
`).setCanonicalSolutionWithWitness(JSON.parse('["0,3","0,2","0,1","1,1","2,1","2,2","2,3","1,3","2,3","3,3","3,2","3,1","3,0","2,0","1,0","0,0"]').map(Location.fromString)),
//
// md 5x5 unique
// Path: d:5 m:8  Entropy: 5.38
// Golds touched 0
    Puzzle.fromString(`
m1100
011d0
00g11
00011
00001
`).setCanonicalSolutionWithWitness(JSON.parse('["1,3","2,2","3,3","2,2","1,1","0,0","0,1","0,2","1,2","2,2","2,3","2,4","3,4","4,4"]').map(Location.fromString)),
//
// mdd 4x5 unique
// Path: d:4 m:15 d:1  Entropy: 9.25
// Golds touched 0
    Puzzle.fromString(`
1d111
1dg11
110m1
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["0,1","1,2","2,1","1,2","2,3","1,3","1,2","0,2","0,3","0,4","1,4","2,4","3,4","3,3","3,2","3,1","3,0","2,0","1,0","1,1","0,0"]').map(Location.fromString)),
//
// mdd 5x5 unique
// Path: d:6 m:17 d:1  Entropy: 9.94
// Golds touched 0
    Puzzle.fromString(`
dd111
11111
11g0m
11011
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["0,0","1,1","2,2","3,1","2,2","3,3","2,4","3,4","4,4","4,3","4,2","4,1","4,0","3,0","2,0","2,1","2,2","1,2","1,3","1,4","0,4","0,3","0,2","0,1","1,0"]').map(Location.fromString)),
//
// mdd 5x5 unique
// Path: d:7 m:13 d:3  Entropy: 7.86
// Golds touched 0
    Puzzle.fromString(`
d11d1
11111
11g01
1m011
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["0,0","1,1","0,2","1,3","2,2","3,3","2,2","3,1","2,1","2,0","3,0","4,0","4,1","4,2","4,3","4,4","3,4","2,4","1,4","0,4","0,3","1,2","0,1","1,0"]').map(Location.fromString)),
//
// d 8x6 unique
// Path: d:23  Entropy: 8.84
// Golds touched 0
    Puzzle.fromString(`
01010d
101010
010100
101010
0g0101
101010
010101
101010
`).setCanonicalSolutionWithWitness(JSON.parse('["0,5","1,4","0,3","1,2","0,1","1,0","2,1","3,0","4,1","5,0","4,1","5,2","4,3","3,2","2,3","3,4","4,5","5,4","6,5","7,4","6,3","7,2","6,1","7,0"]').map(Location.fromString)),
//
// m 7x5 unique
// Path: m:21  Entropy: 6.76
// Golds touched 0
    Puzzle.fromString(`
0m000
01100
11110
11111
111g1
01110
00100
`).setCanonicalSolutionWithWitness(JSON.parse('["0,1","1,1","1,2","2,2","2,3","3,3","3,4","4,4","4,3","5,3","4,3","4,2","3,2","3,1","2,1","2,0","3,0","4,0","4,1","5,1","5,2","6,2"]').map(Location.fromString)),
//
// d 8x6 unique
// Path: d:23  Entropy: 8.67
// Golds touched 0
    Puzzle.fromString(`
01010d
10g010
010101
101010
010101
101010
010101
100010
`).setCanonicalSolutionWithWitness(JSON.parse('["0,5","1,4","2,5","3,4","4,5","5,4","6,5","7,4","6,3","5,2","4,3","3,2","2,3","1,2","0,3","1,2","0,1","1,0","2,1","3,0","4,1","5,0","6,1","7,0"]').map(Location.fromString)),
//
// mdd 3x4 unique
// Path: d:1 m:9 d:3  Entropy: 7.05
// Golds touched 0
    Puzzle.fromString(`
1d11
m11d
11g1
`).setCanonicalSolutionWithWitness(JSON.parse('["0,1","1,0","2,0","2,1","2,2","2,3","2,2","1,2","0,2","0,3","1,3","2,2","1,1","0,0"]').map(Location.fromString)),
//
// mdd 5x5 unique
// Path: d:5 m:19 d:0  Entropy: 10.2
// Doesn't use end movementType
// Golds touched 0
    Puzzle.fromString(`
1d111
1d111
11g01
11011
11m11
`).setCanonicalSolutionWithWitness(JSON.parse('["1,1","2,2","1,3","2,2","3,3","4,2","4,3","4,4","3,4","2,4","1,4","0,4","0,3","0,2","1,2","2,2","2,1","3,1","4,1","4,0","3,0","2,0","1,0","0,0","0,1"]').map(Location.fromString)),
//
// mdd 5x5 unique
// Path: d:1 m:17 d:5  Entropy: 10.6
// Golds touched 0
    Puzzle.fromString(`
1d111
m11d1
11g01
11011
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["0,1","1,0","2,0","2,1","3,1","3,0","4,0","4,1","4,2","4,3","4,4","3,4","2,4","1,4","0,4","0,3","0,2","1,2","1,3","2,2","3,3","2,2","1,1","0,0"]').map(Location.fromString)),
//
// mdd 5x5 unique
// Path: d:5 m:19 d:0  Entropy: 10.9
// Doesn't use end movementType
// Golds touched 0
    Puzzle.fromString(`
1d111
111d1
m1g01
11011
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["1,3","2,2","3,3","2,2","3,1","2,0","3,0","4,0","4,1","4,2","4,3","4,4","3,4","2,4","1,4","0,4","0,3","0,2","1,2","2,2","2,1","1,1","1,0","0,0","0,1"]').map(Location.fromString)),
//
// mdd 5x5 unique
// Path: d:1 m:17 d:6  Entropy: 11.2
// Golds touched 0
    Puzzle.fromString(`
1d111
m1111
11g01
11011
11d11
`).setCanonicalSolutionWithWitness(JSON.parse('["0,1","1,0","2,0","3,0","4,0","4,1","3,1","2,1","2,2","1,2","0,2","0,3","0,4","1,4","2,4","3,4","4,4","4,3","4,2","3,3","2,2","1,3","2,2","1,1","0,0"]').map(Location.fromString)),
//
// mdd 5x5 unique
// Path: d:1 m:20 d:0  Entropy: 8.15
// Doesn't use end movementType
// Golds touched 0
    Puzzle.fromString(`
11d11
11111
11g0d
110m1
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["2,4","3,3","3,4","4,4","4,3","4,2","4,1","4,0","3,0","3,1","2,1","2,0","1,0","0,0","0,1","1,1","1,2","1,3","1,4","0,4","0,3","0,2"]').map(Location.fromString)),
//
// mdd 5x5 unique
// Path: d:3 m:17 d:3  Entropy: 11.7
// Golds touched 0 0
    Puzzle.fromString(`
g11m1
11d11
11011
111d1
1111g
`).setCanonicalSolutionWithWitness(JSON.parse('["1,2","2,3","1,4","0,3","0,2","0,1","0,0","1,0","1,1","2,1","2,0","3,0","4,0","4,1","3,1","3,2","4,2","4,3","4,4","3,4","3,3","2,4","1,3","0,4"]').map(Location.fromString)),
//
// mdd 5x5 unique
// Path: d:3 m:17 d:1  Entropy: 11.3
// Golds touched 0 0
    Puzzle.fromString(`
g1111
11d11
11011
11111
dm11g
`).setCanonicalSolutionWithWitness(JSON.parse('["1,2","2,1","3,2","4,1","4,2","4,3","3,3","3,4","2,4","2,3","1,3","1,4","0,4","0,3","0,2","0,1","1,1","1,0","2,0","3,0","4,0","3,1"]').map(Location.fromString)),
//
// mdd 5x4 unique
// Path: d:3 m:13 d:1  Entropy: 8.55
// Golds touched 0 0
    Puzzle.fromString(`
111g
1d11
1011
1111
g1md
`).setCanonicalSolutionWithWitness(JSON.parse('["1,1","2,2","3,1","4,2","4,1","4,0","3,0","2,0","1,0","0,0","0,1","0,2","1,2","1,3","2,3","3,3","4,3","3,2"]').map(Location.fromString)),
//
// mdd 5x5 unique
// Path: d:4 m:15 d:4  Entropy: 11.0
// Golds touched 0 0
    Puzzle.fromString(`
01111
11dg1
11d11
1g101
m1110
`).setCanonicalSolutionWithWitness(JSON.parse('["2,2","3,1","4,2","3,1","4,0","4,1","3,1","3,0","2,0","1,0","1,1","0,1","0,2","0,3","0,4","1,4","2,4","2,3","1,3","1,2","2,1","3,2","4,3","3,4"]').map(Location.fromString)),
//
// mdd 5x5 unique
// Path: d:4 m:11 d:10  Entropy: 12.4
// Golds touched 0 0
    Puzzle.fromString(`
d0111
1gm11
11111
111gd
11101
`).setCanonicalSolutionWithWitness(JSON.parse('["3,4","2,3","1,4","0,3","1,2","1,1","2,1","3,1","3,2","4,2","4,1","4,0","3,0","2,0","1,0","0,0","1,1","0,2","1,1","2,2","3,3","4,4","3,3","2,4","1,3","0,4"]').map(Location.fromString)),
//
// mdd 5x5 unique
// Path: d:10 m:15 d:2  Entropy: 12.0
// Golds touched 0 0
    Puzzle.fromString(`
10111
dg111
1111d
111g1
m1101
`).setCanonicalSolutionWithWitness(JSON.parse('["2,4","3,3","4,4","3,3","2,2","1,1","0,0","1,1","2,0","3,1","4,0","4,1","4,2","3,2","3,3","3,4","3,3","2,3","1,3","1,4","0,4","0,3","0,2","1,2","1,1","1,0","2,1","3,0"]').map(Location.fromString)),
//
// mdd 6x4 unique
// Path: d:1 m:21 d:0  Entropy: 11.7
// Doesn't use end movementType
// Golds touched 0 0
    Puzzle.fromString(`
111d
1g11
11m1
111d
1g11
1111
`).setCanonicalSolutionWithWitness(JSON.parse('["3,3","2,2","2,3","1,3","1,2","1,1","2,1","3,1","3,2","4,2","4,3","5,3","5,2","5,1","5,0","4,0","3,0","2,0","1,0","0,0","0,1","0,2","0,3"]').map(Location.fromString)),
//
// mdd 6x4 unique
// Path: d:8 m:9 d:9  Entropy: 12.4
// Golds touched 0 0
    Puzzle.fromString(`
1111
1g1d
1111
11d1
1g11
1m11
`).setCanonicalSolutionWithWitness(JSON.parse('["1,3","0,2","1,1","0,0","1,1","2,0","3,1","4,0","5,1","4,1","4,2","5,2","5,3","4,3","3,3","2,3","2,2","3,2","4,1","5,0","4,1","3,0","2,1","1,0","0,1","1,2","0,3"]').map(Location.fromString)),
//
// mdd 7x6 unique
// Path: d:5 m:23 d:5  Entropy: 10.9
// Golds touched 0
    Puzzle.fromString(`
011110
111111
101101
101g11
d0110m
d11111
011110
`).setCanonicalSolutionWithWitness(JSON.parse('["5,0","6,1","5,2","6,3","5,4","4,5","3,5","2,5","1,5","1,4","0,4","0,3","1,3","2,3","3,3","3,4","3,3","4,3","4,2","3,2","2,2","1,2","0,2","0,1","1,1","1,0","2,0","3,0","4,0","5,1","6,2","5,3","6,4","5,5"]').map(Location.fromString)),
//
// mdd 7x6 unique
// Path: d:3 m:29 d:2  Entropy: 10.3
// Golds touched 0
    Puzzle.fromString(`
011110
111111
10m101
10dg11
101101
111d11
011110
`).setCanonicalSolutionWithWitness(JSON.parse('["5,3","4,2","3,3","2,2","1,2","0,2","0,1","1,1","1,0","2,0","3,0","4,0","5,0","5,1","6,1","6,2","6,3","6,4","5,4","5,5","4,5","3,5","2,5","1,5","1,4","0,4","0,3","1,3","2,3","3,3","3,4","3,3","3,2","4,3","5,2"]').map(Location.fromString)),
//
// mdd 5x5 unique
// Path: d:7 m:9 d:7  Entropy: 10.5
// Golds touched 0
    Puzzle.fromString(`
01110
111d1
m1111
dg111
11110
`).setCanonicalSolutionWithWitness(JSON.parse('["1,3","2,4","3,3","4,2","3,1","4,0","3,1","2,0","1,0","1,1","0,1","0,2","1,2","2,2","2,1","3,1","3,0","4,1","3,2","4,3","3,4","2,3","1,4","0,3"]').map(Location.fromString)),
//
// mdd 5x5 unique
// Path: d:3 m:11 d:6  Entropy: 10.3
// Golds touched 0
    Puzzle.fromString(`
01m10
11111
1d111
1g1d1
11110
`).setCanonicalSolutionWithWitness(JSON.parse('["3,3","2,4","1,3","0,2","0,1","1,1","1,0","2,0","3,0","4,0","4,1","4,2","3,2","2,2","2,1","1,2","0,3","1,4","2,3","3,4","4,3"]').map(Location.fromString)),
//
// mdd 7x3 unique
// Path: d:6 m:13 d:1  Entropy: 7.45
// Golds touched 0
    Puzzle.fromString(`
111
111
11m
0g0
111
111
dd1
`).setCanonicalSolutionWithWitness(JSON.parse('["6,0","5,1","4,0","3,1","2,0","3,1","2,2","1,2","0,2","0,1","0,0","1,0","1,1","2,1","3,1","4,1","4,2","5,2","6,2","6,1","5,0"]').map(Location.fromString)),
//
// md 4x5 unique
// Path: m:20 d:0  Entropy: 8.84
// Doesn't use end movementType
// Golds touched 0
    Puzzle.fromString(`
// Good stuff. Hard for danny.
11g11
1m111
11111
111d1
`).setCanonicalSolutionWithWitness(JSON.parse('["1,1","2,1","2,2","3,2","3,1","3,0","2,0","1,0","0,0","0,1","0,2","1,2","0,2","0,3","0,4","1,4","1,3","2,3","2,4","3,4","3,3"]').map(Location.fromString)),
//
// mdd 5x5 unique
// Path: d:6 m:11 d:8  Entropy: 13.1
// Golds touched 0
    Puzzle.fromString(`
1m111
11111
1dg1d
11111
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["2,1","3,2","2,3","1,4","0,3","1,2","0,1","0,0","1,0","2,0","3,0","4,0","4,1","4,2","4,3","4,4","3,4","2,4","3,3","2,2","3,1","2,2","1,1","0,2","1,3","0,4"]').map(Location.fromString)),
//
// mdd 5x5 unique
// Path: d:2 m:19 d:4  Entropy: 12.7
// Golds touched 0
    Puzzle.fromString(`
111m1
11111
11gd1
11111
11d11
`).setCanonicalSolutionWithWitness(JSON.parse('["2,3","1,2","0,3","0,4","1,4","2,4","3,4","4,4","4,3","3,3","3,2","2,2","2,1","1,1","0,1","0,0","1,0","2,0","3,0","4,0","4,1","4,2","3,1","2,2","1,3","0,2"]').map(Location.fromString)),
//
// mdd 5x5 unique
// Path: d:6 m:15 d:2  Entropy: 11.3
// Golds touched 0 0 0 0
    Puzzle.fromString(`
m1111
1gdg1
1010d
1g1g1
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["2,4","1,3","0,4","1,3","0,2","1,1","0,0","0,1","1,1","1,0","2,0","3,0","4,0","4,1","4,2","4,3","4,4","3,4","3,3","3,2","2,2","1,2","0,3","1,4"]').map(Location.fromString)),
//
// mdd 5x5 unique
// Path: d:10 m:11 d:4  Entropy: 12.7
// Golds touched 0 0 0 0
    Puzzle.fromString(`
m1111
1g1g1
1010d
dg1g1
11111
`).setCanonicalSolutionWithWitness(JSON.parse('["2,4","3,3","4,4","3,3","4,2","3,1","4,0","3,1","2,2","1,1","0,0","0,1","0,2","0,3","0,4","1,4","1,3","1,2","1,1","1,0","2,0","3,0","4,1","3,2","4,3","3,4"]').map(Location.fromString)),

    Puzzle.fromString(`
l1g11
d10d1
10101
1m011
111m1
`),
//
// mdd 6x7 unique
// Path: d:10 m:13 d:11  Entropy: 16.7
// Golds touched 0
    Puzzle.fromString(`
// laura 2017-06-03
// 0d1100
// 111111
// 111111
// 111111
// gmd110
// 111100
// 111100
0111g11
d111m11
1111d11
1111111
0111100
0111000
`).setCanonicalSolutionWithWitness(JSON.parse('["1,0","0,1","1,2","2,1","3,0","4,1","5,2","4,3","3,2","2,3","1,4","0,4","0,3","0,4","0,5","0,6","1,6","1,5","2,5","2,6","3,6","3,5","3,4","2,4","1,3","0,2","1,1","2,0","3,1","2,2","3,3","4,4","5,3","4,2","5,1"]').map(Location.fromString)),
]);