// This is handled in userFacingGame componentDidMount instead of here with the other initialization stuff.
// TODO: Consider putting this in lab somehow for when we fuck up and push lab to pages
import {devLog, isDev} from "./buildModeChecker";
import Progress from "./progress/progress";

export async function initializeServiceWorker(showSkipWaitingPrompt) {
  if (isDev()) {
    devLog('Not doing service worker stuff in dev environment.');
    return;
  }

  // // If you want your app to work offline and load faster, you can change
  // // unregister() to register() below. Note this comes with some pitfalls.
  // // Learn more about service workers: https://cra.link/PWA
  const wb = Progress.getServiceWorkerWorkbox();
  if (!wb) {
    console.error(`Couldn't find the service worker.`);
    return;
  }

  wb.addEventListener('activated', (event) => {
    console.log('WorkBox activated event fired.')
    // `event.isUpdate` will be true if another version of the service
    // worker was controlling the page when this version was registered.
    if (!event.isUpdate) {
      console.log('Service worker activated for the first time!');

      // If your service worker is configured to precache assets, those
      // assets should all be available now.
    }
  });

  wb.addEventListener('controlling', (event) => {
    console.log('WorkBox controlling event fired.')
  });

  wb.addEventListener('waiting', (event) => {
    console.log(`A new service worker has installed, but it can't activate` +
      `until all tabs running the current version have fully unloaded.`);
  });

  wb.addEventListener('message', (event) => {
    if (event.data.type === 'CACHE_UPDATED') {
      const {updatedURL} = event.data.payload;
      console.log(`A newer version of ${updatedURL} is available!`);
    }
  });

  // Add an event listener to detect when the registered
  // service worker has installed but is waiting to activate.
  wb.addEventListener('waiting', showSkipWaitingPrompt);

  // This is a fancy way to do it.
  // serviceWorkerRegistration.register();

  var registration = await wb.register();

  // Feature detection
  if (!registration.paymentManager) return;

  // console.log('Attempting registration.paymentManager.instruments.');
  // await registration.paymentManager.instruments.set(
  //   // Payment instrument key
  //   'bobpay_card_1',
  //   // Payment instrument details
  //   {
  //     // This parameter will be ignored in Chrome
  //     name: 'Payment Handler Example',
  //     // This parameter will be used to match against
  //     // the PaymentRequest.
  //     method: 'https://bobpay.xyz/pay'
  //   }
  // );


}
