import {EventCounter} from "./userEventTracker";
import Key from "./key";
import Utility from "../utility";
import Puzzle from "../puzzle/puzzle";
import {World} from "../puzzles/world";
import Progress, {PuzzleHintState} from './progress'
import GetProgress from './getProgress'
import Constants, {DisplayTheme} from "../constants";

// TODO: This is great: https://developers.google.com/games/services/common/concepts/savedgames


class SetProgress {
    // Set an event counter for one of 'global' or the tostring of a puzzle or the worldname of a world.
    static setEventCounter(key: string, eventCounter: EventCounter) {
        localStorage.setItem(Key.keyForEventCounter(key), eventCounter.toJSON());
    }

    static mergeIntoStoredEventCounter(key: string, eventCounter: EventCounter) {
        let counter = GetProgress.getEventCounter(key);
        if (counter !== undefined) {
            counter.registerEvents(eventCounter);
        } else {
            counter = eventCounter;
        }
        SetProgress.setEventCounter(key, counter);
    }

    static setHintsEnabled(hintsEnabled: boolean) {
        const key = Key.keyForHintsEnabled();
        Utility.setInStorage(key, hintsEnabled);
    }

    static setTheme(themeName: DisplayTheme) {
        Utility.setInStorage('displayTheme', themeName);
    }

    static toggleTheme() {
        SetProgress.setTheme(
            GetProgress.getTheme() === 'original-theme' ?
                '3d-theme' : 'original-theme'
        );
    }

    static setSoundEnabled(soundEnabled: boolean) {
        const key = Key.keyForSoundEnabled();
        Utility.setInStorage(key, soundEnabled);
    }

    static setAnimationEnabled(animationEnabled: boolean) {
        const key = Key.keyForAnimationEnabled();
        Utility.setInStorage(key, animationEnabled);
    }

    static setUnallocatedHints(number: number) {
        const key = Key.keyForUnallocatedHints()
        Utility.setInStorage(key, number);
    }

    static incrementUnallocatedHints() {
        const currentHints = GetProgress.getUnallocatedHints();
        SetProgress.setUnallocatedHints(currentHints + 1);
    }

    // Returns true on success.
    static decrementUnallocatedHints(): boolean {
        const currentHints = GetProgress.getUnallocatedHints();
        if (currentHints <= 0) {
            console.error('Trying to decrement UnallocatedHints below 0.')
            return false;
        }

        SetProgress.setUnallocatedHints(currentHints - 1);
        return true;
    }

    static setPuzzleCommentText(puzzle: Puzzle, newText: string) {
        const key = Key.keyForPuzzleComment(puzzle);
        Utility.setInStorage(key, newText);
    }

    static setLastPlayedWorld(world: World) {
        const key = Key.keyForLastPlayedWorld();
        Utility.setInStorage(key, world.indexInWorlds);
    }

    static setPuzzleHintState(puzzle: Puzzle, puzzleHintState: PuzzleHintState) {
        const key = Key.keyForPuzzleHintState(puzzle);
        Utility.setInStorage(key, puzzleHintState);

        Progress.worldProgressChanged(puzzle.sourceWorld);
    }

    static setUserHasPlayed(world: World, puzzle: Puzzle) {
        const key = Key.keyForUserHasPlayed(world);
        let current: string[] = GetProgress.getUserHasPlayed(world);
        if (current.some(s => s === puzzle.toString())) return;
        Utility.setInStorage(key, [...current, puzzle.toString()]);

        Progress.worldProgressChanged(world);
    }

    // Returns true if this is the first time the puzzle's been solved, false on a re-solve.
    static setUserHasSolved(world: World, puzzle: Puzzle): boolean {
        const key = Key.keyForUserHasSolved(world);
        let current = GetProgress.getUserHasSolved(world);
        if (current.some(s => s === puzzle.toString())) {
            // User has already solved the puzzle.
            return false;
        }
        Utility.setInStorage(key, [...current, puzzle.toString()]);
        // localStorage.setItem(key, JSON.stringify([...current, puzzle.toString()]));

        Progress.worldProgressChanged(world);
        return true;
    }

    static purgeAllocatedHints(puzzle: Puzzle) {
        const key = Key.keyForPuzzleHintState(puzzle);
        localStorage.removeItem(key);
    }

    static trySpendUnallocatedHint(puzzle: Puzzle, ownedCountIfBought: number): boolean {
        const unallocatedHints = GetProgress.getUnallocatedHints();
        if (unallocatedHints < 1 && !Constants.infiniteHintsMode) return false;
        let puzzleHintState = GetProgress.getPuzzleHintState(puzzle);
        if (puzzleHintState.ownedHints >= ownedCountIfBought) {
            console.error('Trying to buy a hint worse than the one we own. WTF.');
            console.error(`owned ${puzzleHintState.ownedHints} ownedCountIfBought ${ownedCountIfBought}`);
            return false;
        }
        puzzleHintState.hintPurchases++;
        puzzleHintState.ownedHints = ownedCountIfBought;

        // This error checking is redundant but so what.
        if (Constants.infiniteHintsMode || SetProgress.decrementUnallocatedHints()) {
            SetProgress.setPuzzleHintState(puzzle, puzzleHintState);
            return true;
        } else {
            return false;
        }
    }


}

export default SetProgress;