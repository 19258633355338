import {World} from "../puzzles/world";
import Puzzle from "../puzzle/puzzle";
import {Workbox} from "workbox-window";
import Constants from "../constants";

export type PuzzleHintState = {
    ownedHints: number,
    hintPurchases: number,
}

export interface LevelProgress {
    puzzle: Puzzle,
    played: boolean,
    solved: boolean,
    puzzleHintState: PuzzleHintState,
}

export interface WorldProgress {
    levelProgress: LevelProgress[],
    totalPuzzleCount: number,
    solvedCount: number,
    playedCount: number,
    worldHintState: PuzzleHintState,
    lastPlayable: number,
    nextWorldAccessible: boolean,
    infiniteLevelAccessible: boolean,
}

class Progress {

    private static _worldProgressCache = new Map<string, WorldProgress>();
    private static _progressChangeCallback = new Map<string, (() => void)>();

    static generateRandomUserID() {
        return 'user_' + Math.random().toString(36).substr(2, 9);
    }

    // Cache invalidation for the world.
     static markWorldProgressOld(world: World) {
        Progress._worldProgressCache.delete(world.worldName);
    }

    static registerProgressChangeCallback(world: World, callback: () => void) {
        Progress._progressChangeCallback.set(world.worldName, callback);
    }

     static worldProgressChanged(world: World | undefined) {
        if (!world) {
            console.error('worldProgressChanged called with undefined world. Naughty.')
            return;
        }
        Progress.markWorldProgressOld(world);
        // In practice, this asks the PuzzleSelector for this world to update.
        const callback = Progress._progressChangeCallback.get(world.worldName);
        if (callback) callback();
    }

    static worldProgressCache() {
        return Progress._worldProgressCache;
    }

    private static _serviceWorker : Workbox | undefined;

    static getServiceWorkerWorkbox() : Workbox | undefined {
        if (!this._serviceWorker) {
            if (!('serviceWorker' in navigator)) {
                console.log('Service Worker not supported in this environment.')
                return;
            }
            this._serviceWorker = new Workbox(Constants.serviceWorkerUrl);
        }
        return this._serviceWorker;
    }
}

export default Progress;